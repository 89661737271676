import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from "./Button";

const Wrapper = styled(Button)`
  box-sizing: border-box;
  height: 40px;
  margin: 2rem;
  &:disabled,
  &.disabled {
    height: 40px;
    cursor: not-allowed;
    background: #fff;
    color: #aebeca;
    border-color: #aebeca;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
      & path {
        transition: all 0.3s;
        stroke: #fff;
      }
    }
    & path {
      stroke: #aebeca;
    }
  }
`;

const SmallBackButton = (props: ButtonProps) => {
  return (
    <Wrapper {...props}>{props.children || <>&lt; Back</>}</Wrapper>
  );
};

export default SmallBackButton;
