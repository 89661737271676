import React from 'react';
import { Input as AntInput, InputProps } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(AntInput)`
  &.ant-input {
    border-radius: ${({ theme }) => theme.radius};
    border: 1px solid #aebeca;
    &:disabled {
      color: #627d95;
      border: solid 1px #aebeca;
      background-color: ${({ theme }) => theme.lozengeColor};
    }
    &::placeholder {
      color: #aebeca;
    }
  }
  &.ant-input-lg {
    padding: 0.813rem 1rem;
    height: auto;
    line-height: 1.43;
    font-size: 0.875rem;
    color: #061c2e;
    &.search-input {
      padding: 0.813rem 2rem;
    }
  }
  &:not(.ant-input-affix-wrapper) .ant-input-lg:hover,
  &.ant-input:hover {
    border: solid 1px ${({ theme }) => theme.hoverColor};
  }
  &: not(.ant-input-affix-wrapper).ant-input-lg: focus,
  &.ant-input:focus {
  box-shadow: 0 0 8px 0 ${({ theme }) => theme.primaryColor};
  border-color: transparent;
}

`;
const Input = ({ ...rest }: InputProps) => {
  return <StyledInput {...rest} />;
};

export default Input;
