import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import styled from 'styled-components';


const ButtonCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper{
    padding: .5rem 1rem;
    background-color: ${({ theme }) => theme.lozengeColor};
    color: #627d95;
    margin: 0;
    border-right: 1px solid #dae5ec;
    transition:all ease .3s;
    &.ant-checkbox-wrapper-checked{
       background: #627d95;
       color: #ffffff;
    }
    & .ant-checkbox{
      display: none;
    }
  }
`
const CheckboxButton = ({ children, ...rest }: CheckboxProps) => {
  return (
    <ButtonCheckbox {...rest} >{children}</ButtonCheckbox>
  );
};

export default CheckboxButton;
