import { ReactNode } from 'react';
import styled from 'styled-components';

interface TextWithIconProps {
  icon?: ReactNode;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

const ItemWrapper = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const ItemIcon = styled.div`
  margin-right: 1rem;
  color: ${({ theme }) => theme.primaryColor};
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const ItemText = styled.div`
  font-size: 14px;
  line-height: 24px;

  & p {
    color: #061c2e;
    margin-bottom: 0;

    &.subtitle {
      color: #627d95;
    }
  }

  & span {
    display: block;
    color: #aebeca;
  }
`;

const TextWithIcon = ({
  icon,
  title,
  subtitle,
  children,
  ...rest
}: TextWithIconProps) => (
  <ItemWrapper {...rest}>
    <ItemIcon>{icon}</ItemIcon>
    <ItemText>
      <p>{title}</p>

      {subtitle && (
        <p className="subtitle">{subtitle}</p>
      )}

      {children}
    </ItemText>
  </ItemWrapper>
);

export default TextWithIcon;
