import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import {
  PROFILE_SETTINGS,
  CANDIDATES_SPECIFICATION_SETTINGS,
  PAYMENT_INFO_SETTINGS,
  NOTIFICATIONS_SETTINGS,
  ATS_SETTINGS,
} from '../../constants/routes';

interface SettingsMenuProps {
  menu: string;
}

const SettingsMenuContainer = styled.div`
  position: absolute;
  left: -17.5rem;
  padding: 16px 32px 32px;
  box-shadow: 0 25px 60px 0 rgba(6, 28, 46, 0.1),
    0 12px 14px 0 rgba(6, 28, 46, 0.05);
  background-color: #ffffff;
  width: 17.5rem;
  border-radius: ${({ theme }) => theme.radius};
`;

const SettingsMenuTitle = styled.h3`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  color: #061c2e;
  text-transform: uppercase;
  border-bottom: 1px solid #dae5ec;
  margin: 0;
  padding: 1rem 0;
`;

const SettingsMenuSteps = styled.ul`
  list-style: none;
  margin: 1rem 0 0 0;
`;

interface SettingsMenuStepProps {
  readonly isActive: boolean;
}

const SettingsMenuStep = styled.li<SettingsMenuStepProps>`
  padding: 1.5rem 1rem;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #061c2e;
  border-top: ${(props) => (props.isActive ? 'none' : '1px solid #dae5ec')};
  border-radius: ${(props) => (props.isActive ? '8px' : '0px')};
  background-color: ${({ theme, isActive }) => isActive ? theme.lozengeColor : 'transparent'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & + li {
    border-top: ${(props) => (props.isActive ? 'none' : '1px solid #dae5ec')};
  }
  & {
    border-top: ${(props) => props.isActive && 'none'} !important;
  }
  &:first-child {
    border-top: none;
  }
`;

export const MENUS = [
  {
    title: 'PROFILE_SETTINGS',
    value: 'profile',
    visibility: [CLIENT_ROLE, RECRUITER_ROLE],
    route: PROFILE_SETTINGS,
  },
  {
    title: 'CANDIDATE_TITLE',
    value: 'candidate',
    visibility: [RECRUITER_ROLE],
    route: CANDIDATES_SPECIFICATION_SETTINGS,
  },
  {
    title: 'PAYMENT_INFO',
    value: 'payment',
    visibility: [CLIENT_ROLE, RECRUITER_ROLE],
    route: PAYMENT_INFO_SETTINGS,
  },
  {
    title: 'ats.ATS_SETTINGS_TITLE',
    value: 'ats',
    visibility: [CLIENT_ROLE],
    route: ATS_SETTINGS,
  },
  {
    title: 'NOTIFICATION_SETTINGS',
    value: 'notification',
    visibility: [CLIENT_ROLE, RECRUITER_ROLE],
    route: NOTIFICATIONS_SETTINGS,
  },
];

const SettingsMenu = (props: SettingsMenuProps) => {
  const { menu } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const user = useAppSelector((state) => state.user.user);

  return (
    <SettingsMenuContainer>
      <SettingsMenuTitle>{t('SETTINGS_TITLE')}</SettingsMenuTitle>
      <SettingsMenuSteps>
        {MENUS.map((s, index) => {
          if (user?.role && s.visibility.indexOf(user?.role) !== -1) {
            return (
              <SettingsMenuStep
                key={index}
                isActive={s.value === menu}
                onClick={() => history.push(s.route)}
              >
                {t(s.title)}
              </SettingsMenuStep>
            );
          } else {
            return null;
          }
        })}
      </SettingsMenuSteps>
    </SettingsMenuContainer>
  );
};

export default SettingsMenu;
