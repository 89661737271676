import React from 'react';
import styled from 'styled-components';

import { Education } from '../../../types';

interface SkillSectionProps {
  title: string;
  skills: { id: string; name: string }[];
  light?: boolean;
  certificates?: { id: string; name: string }[];
  education?: Education[],
  languages?: string[];
  languageList: { [key: string]: string };
  softSkills?: string[],
  industrySkills?: string[],
}
const SkillSectionWrapper = styled.div`
  margin-bottom: 1rem;
`;
const SectionTitle = styled.h5`
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  margin-bottom: 0.5rem;
  color: #627d95;
  text-transform: uppercase;
`;
const SkillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const SkillItem = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lozengeColor};
  font-size: 0.75rem;
  line-height: 1.33;
  color: #4b6276;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  &.light {
    opacity: 0.5;
  }
`;

const SkillSection = ({
  title,
  skills,
  light = false,
  certificates,
  education,
  languages,
  languageList,
  softSkills,
  industrySkills
}: SkillSectionProps) => (
  <SkillSectionWrapper>
    <SectionTitle>{title}</SectionTitle>
    <SkillWrapper>
      {skills.map((skill) => (
        <SkillItem key={skill.id} className={light ? 'light' : ''}>
          {skill.name}
        </SkillItem>
      ))
      }
    </SkillWrapper>

    {softSkills && (
      <SkillWrapper>
        {softSkills.map((skill, index) => (
          <SkillItem key={index} className={light ? 'light' : ''}>
            {skill}
          </SkillItem>
        ))
        }
      </SkillWrapper>
    )}

    {industrySkills && (
      <SkillWrapper>
        {industrySkills.map((skill, index) => (
          <SkillItem key={index} className={light ? 'light' : ''}>
            {skill}
          </SkillItem>
        ))
        }
      </SkillWrapper>
    )}

    {education && (
      <SkillWrapper>
        {education.map((ed) => (
          <SkillItem
            key={ed.id}
            className={light ? 'light' : ''}
            style={{ backgroundColor: '#fcedde' }}
          >
            {ed.name}
          </SkillItem>
        ))}
      </SkillWrapper>
    )}

    {certificates && (
      <SkillWrapper>
        {certificates.map((certificate) => (
          <SkillItem
            key={certificate.id}
            className={light ? 'light' : ''}
            style={{ backgroundColor: '#fcedde' }}
          >
            {certificate.name}
          </SkillItem>
        ))}
      </SkillWrapper>
    )}

    {languages && (
      <SkillWrapper>
        {languages.map((language) => (
          <SkillItem
            key={language}
            className={light ? 'light' : ''}
            style={{ backgroundColor: '#eff9f3' }}
          >
            {languageList[language]}
          </SkillItem>
        ))}
      </SkillWrapper>
    )}
  </SkillSectionWrapper>
);

export default SkillSection;
