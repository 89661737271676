import { useTranslation } from 'react-i18next';
import {
  numberRuleNotRequired,
  numberRuleRequired,
  requiredRule,
} from '../../../../utils/validationRules';
import FormItem from '../../FormItem';
import Input from '../../../../UI/inputs/Input';
import { InputNumberInput } from '../../../../UI/buttons/NumberInput';
import { EmploymentTypeField } from '../../../../types';
import { CaretDownOutlined } from '@ant-design/icons';
import { InternalNamePath } from 'antd/lib/form/interface';
import SelectInput from '../../../../UI/inputs/Select';
import { Select } from 'antd';

type SingleEmploymentTypeFieldProps = {
  field: EmploymentTypeField;
  name: InternalNamePath;
  label: string;
  isRequired: boolean;
};

const SingleEmploymentTypeField = ({
  field,
  name,
  label,
  isRequired,
}: SingleEmploymentTypeFieldProps) => {
  const { t } = useTranslation();
  return field.is_numeric ? (
    <FormItem
      label={t(label)}
      name={[...name, 'numeric_value']}
      rules={
        isRequired && !field.is_optional
          ? numberRuleRequired(t)
          : numberRuleNotRequired(t)
      }
    >
      <InputNumberInput
        size="large"
        parser={(value) => value || 0}
        formatter={(value) =>
          value?.valueOf().toString() && value?.valueOf().toString() > '0'
            ? value.toLocaleString()
            : ''
        }
        min={0}
      />
    </FormItem>
  ) : field.is_select ? (
    <FormItem
      label={t(label)}
      name={[...name, 'text_value']}
      rules={isRequired && !field.is_optional ? requiredRule(t) : []}
    >
      <SelectInput
        size="large"
        suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
      >
        {field.select_fields?.split(',').map((value, index) => (
          <Select.Option value={value} key={index}>
            {value}
          </Select.Option>
        ))}
      </SelectInput>
    </FormItem>
  ) : (
    <FormItem
      label={t(label)}
      name={[...name, 'text_value']}
      rules={isRequired && !field.is_optional ? requiredRule(t) : []}
    >
      <Input size="large" />
    </FormItem>
  );
};

export default SingleEmploymentTypeField;
