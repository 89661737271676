import styled from 'styled-components';
import { Contact } from '../../types';
import NameAvatar from '../../UI/NameAvatar';
import { Badge } from 'antd';

type ChatMessagesProps = {
  contacts: Contact[];
  showUnreadCount: boolean;
  onItemClick: (contact: Contact) => void;
  selectedContact: Contact | undefined;
  showRole: boolean;
}

export const ContactItem = styled.li<{ active?: boolean }>`
  padding: 0.75rem 1rem;
  position: relative;
  border-radius: ${({ theme }) => theme.radius};
  cursor: pointer;
  display: flex;
  background: ${(props) => (props.active === true ? '#fff' : 'transparent')};
  &:hover {
    background: #fff;
  }
  margin-bottom: 5px;
`;

export const ContactItemInfo = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const ContactItemTitle = styled.h5`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 84%;
`;

export const ContactItemDescription = styled.h6`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
`;

export const UnreadMessagesBadge = styled(Badge)`
  & .ant-badge-count {
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    min-width: 1rem;
    height: 1rem;
    padding: 3px;
    line-height: 1rem;
    background: ${({ theme }) => theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    & .ant-scroll-number-only {
      height: auto;
      & > p.ant-scroll-number-only-unit {
        height: auto;
      }
    }
  }
`;

const ChatMessages = ({ contacts, showUnreadCount, onItemClick, selectedContact, showRole }: ChatMessagesProps) => {

  return (
    <>
      {contacts.map((contact) => {
        const isSelected = selectedContact && selectedContact.id === contact.id;
        const unreadCount = (showUnreadCount && contact.unread_message_cnt) || 0;
        return (
          <ContactItem
            key={contact.id}
            onClick={() => onItemClick(contact)}
            active={isSelected}
          >
            <NameAvatar
              name={contact.name}
              color={contact.color}
              image={contact.image}
            />
            <ContactItemInfo>
              <ContactItemTitle>{contact.name}</ContactItemTitle>
              {showRole && (
                <ContactItemDescription>
                  {contact.role}
                </ContactItemDescription>
              )}
            </ContactItemInfo>
            <UnreadMessagesBadge count={unreadCount} />
          </ContactItem>
        );
      })}
    </>
  );
}

export default ChatMessages;
