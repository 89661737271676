import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import Routes from './components/route/Routes';
import store from './store';
import 'antd/dist/antd.css';
import './index.css';
import './i18n';
import ReactGa from 'react-ga4';
import { EmploymentTypesProvider } from './hooks/useEmploymentTypes';
import { HirePortContextProvider } from './hooks/useHirePortContext';
import { TestUIProvider } from './components/helper/TestUIWrapper';

function App() {
  useEffect(() => {
    process.env.REACT_APP_GOOGLE_ANALYTICS_ID &&
      ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <TestUIProvider>
          <HirePortContextProvider>
            <EmploymentTypesProvider>
              <Routes />
            </EmploymentTypesProvider>
          </HirePortContextProvider>
        </TestUIProvider>
      </Provider>
    </div>
  );
}

export default App;
