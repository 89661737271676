import styled from 'styled-components';
import { Job } from "../../types";
import { addToFavorites, removeFromFavorites } from "../../api/jobs";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from "../../constants/roles";

interface ComingSoonProps extends React.HTMLAttributes<HTMLDivElement> {
    job: Job | null,
    favorite: boolean,
    onSetFavorite?: any
    recruiterShortText?: boolean
}

const StyledComingSoon = styled.div`
  h3 {
    text-align: center;
    color: #4b6276;
  }
  p {
    text-align: center;
    color: #627d95;
  }
  span {
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};;
  }
`;
const ComingSoon = ({ recruiterShortText, onSetFavorite, favorite, job, children, ...rest }: ComingSoonProps) => {
    const [t] = useTranslation();

    const { user } = useAppSelector((state) => state.user);
    const isAdmin = user?.role === ADMIN_ROLE;
    const isClient = user?.role === CLIENT_ROLE;
    const isRecruiter = user?.role === RECRUITER_ROLE;

    const handleFavorite = () => {
        if (!job) {
            return;
        }
        if (favorite) {
            removeFromFavorites(job.id);
        } else {
            addToFavorites(job.id);
        }
        favorite = !favorite;
        onSetFavorite(favorite);
    };

    return (
        <StyledComingSoon className={'coming-soon'} {...rest}>
            {recruiterShortText && isRecruiter ?
                <p>{t('MORE_INFO')}</p>
                :
                isAdmin ?
                    <p>{t('ADMIN_WILL_BE_NOTIFIED')}</p> :
                    isClient ?
                        <p>{t('CLIENT_WILL_BE_NOTIFIED')}</p> :
                        favorite ? <p>{t('WILL_BE_NOTIFIED')}</p> : <p>Want to be notified? <span onClick={() => handleFavorite()}>Click here</span> or add this job to your job notifications🔔</p>
            }
        </StyledComingSoon>
    );
};

export default ComingSoon;
