import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { loginUserWithLinkedin } from '../../store/userSlice';
// import { ReactComponent as GoogleIcon } from '../../icons/google.svg';
import { ReactComponent as LinkedinIcon } from '../../icons/linkedin.svg';
import { DASHBOARD } from '../../constants/routes';

const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID || '';

const AlternateWrapper = styled.div`
  margin-top: auto;
`;
const AlternateTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  color: #061d31;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;
const AlternateItem = styled.div`
  padding: 14px 24px;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.lozengeColor};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  color: #061d31;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  & .social-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
  }
`;

const LoginWith = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onLinkedinSuccess = async (data: any) => {
    const user = await dispatch(
      loginUserWithLinkedin({
        auth_code: data.code,
        redirect_uri: window.location.origin + '/linkedin',
      }),
    );
    if (user.payload?.user) {
      localStorage.setItem('hp_linkedin_login', 'true');
      history.push(DASHBOARD);
    }
    if (user.payload?.error) {
      window.open('https://linkedin.com/m/logout');
      message.error(user.payload.error, 5);
    }
    localStorage.removeItem('linkedin_oauth2_state');
  };

  const onLinkedinFailure = (error: any) => {
    window.open('https://linkedin.com/m/logout');
    message.error(error.errorMessage, 5);
  };

  return (
    <AlternateWrapper>
      <AlternateTitle>{t('SIGN_IN_SOCIAL_TEXT')}</AlternateTitle>
      {/* <a
        href={
          process.env.REACT_APP_API_URL +
          'rest-auth/social/google/server/login/?action=reauthenticate'
        }
      >
        <AlternateItem>
          <GoogleIcon className="social-icon" />
          Google
        </AlternateItem>
      </a> */}
      <LinkedIn
        clientId={linkedinClientId}
        onFailure={onLinkedinFailure}
        onSuccess={onLinkedinSuccess}
        redirectUri={window.location.origin + '/linkedin'}
        scope={'r_emailaddress r_liteprofile'}
        renderElement={({ onClick }: { onClick: any }) => (
          <AlternateItem onClick={onClick}>
            <LinkedinIcon className="social-icon" />
            Linkedin
          </AlternateItem>
        )}
      />
    </AlternateWrapper>
  );
};

export default LoginWith;
