import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FormItem from './FormItem';
import TabRadioButtonFeedback from '../../UI/radioButton/TabRadioButtonFeedback';
import Button from '../../UI/buttons/Button';
import RadioButtonTab from '../../UI/buttons/RadioButtonTab';
import { requiredRule } from '../../utils/validationRules';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../icons/minus.svg';
import { ErrorMessage } from '../auth/LoginForm';
import FeedbackResult from '../candidates/FeedbackResult';
import Textarea from '../../UI/inputs/Textarea';
import CheckboxInput from '../../UI/inputs/CheckboxInput';

interface FeedbackFormProps {
  onComplete: (data: any) => void;
  completed: boolean;
  isClient: boolean;
  loading: boolean;
  hideMessage?: boolean;
  data: any;
}

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};
const commentLayout = {
  wrapperCol: { sm: { span: 14, offset: 10 }, xs: { span: 24, offset: 0 } },
};
const buttonLayout = {
  wrapperCol: { span: 24 },
};

const StyledForm = styled(Form)`
  & .ant-form-item {
    & .ant-form-item-label {
      & > label {
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #061c2e;
        text-transform: initial;
        width: 100%;
      }
    }
    &.uppercase {
      & .ant-form-item-label {
        & > label {
          text-transform: uppercase;
          font-size: 0.625rem;
          font-weight: 700;
        }
      }
    }
  }

  & .ant-radio-wrapper {
    font-size: 0.75rem;
  }
  & .ant-form-item-control-input {
    min-height: 26px;
  }
`;
const FormItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const StyledButton = styled(Button)`
  &.grey {
    border-color: #627d95;
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1.33;
    color: #627d95;
    border-radius: 0.25rem;
    & svg {
      color: #627d95;
    }
  }
`;
const StyledCheckbox = styled(CheckboxInput)`
  & span {
    font-size: 0.875rem;
    line-height: 1.71;
    color: #061c2e;
  }
`;
const RuleSection = styled.div`
  width: 100%;
  padding: 1.5rem 2rem 2rem;
  background: ${({ theme }) => theme.lozengeColor};
  border-radius: ${({ theme }) => theme.radius};
  margin: 1rem 0;
`;
const RuleItem = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0;
`;
const FeedbackForm = ({
  onComplete,
  isClient,
  completed,
  data,
  hideMessage = false,
  loading,
}: FeedbackFormProps) => {
  const [t] = useTranslation();
  const [comments, setComments] = useState<{ [key: string]: boolean }>({
    team: false,
    culture: false,
    career: false,
    tech: false,
    interviewers: false,
  });
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      setComments({
        team: !!data.match_with_team_comment,
        culture: !!data.match_with_culture_comment,
        career: !!data.match_with_career_comment,
        tech: !!data.match_with_tech_comment,
        interviewers: !!data.match_with_interviewers_comment,
      });
    }
  }, [data]);
  const onFinish = (values: any) => {
    onComplete(values);
  };
  const handleClick = (key: string) => {
    setComments((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };
  const feedbackTypes: { title: string; value: string }[] = [
    {
      title: t('POSITIVE_DECISION'),
      value: 'positive',
    },
    {
      title: t('NEGATIVE_DECISION'),
      value: 'negative',
    },
  ];
  const feedbacks: { name: string; value: string }[] = [
    {
      name: t('NEGATIVE'),
      value: 'negative',
    },
    {
      name: t('NEUTRAL'),
      value: 'neutral',
    },
    {
      name: t('POSITIVE'),
      value: 'positive',
    },
  ];
  return (
    <div>
      {data ? (
        <>
          <FeedbackResult data={data} />
          {completed && !hideMessage && (
            <ErrorMessage
              message={t(isClient ? 'RECRUITER_FEEDBACK' : 'CLIENT_FEEDBACK')}
              type="success"
            />
          )}
        </>
      ) : (
        !completed && (
          <StyledForm
            scrollToFirstError={true}
            name="add-feedback"
            onFinish={onFinish}
            {...formLayout}
            form={form}
          >
            <FormItem
              name="match_with_company"
              label={t('MATCH_WITH_COMPANY')}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} />
            </FormItem>

            <FormItem
              {...commentLayout}
              className="mb-5"
              name="match_with_company_comment"
            >
              <Textarea
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder={t('COMMENT')}
              />
            </FormItem>

            <FormItem
              name="match_with_role"
              label={t('MATCH_WITH_ROLE')}
              className="mb-1"
            >
              <RadioButtonTab items={feedbacks} />
            </FormItem>

            <FormItem
              className="mb-5"
              name="match_with_role_comment"
              {...commentLayout}
            >
              <Textarea
                autoSize={{ minRows: 3, maxRows: 3 }}
                placeholder={t('COMMENT')}
              />
            </FormItem>

            <FormItem label={t('TEAM_LABEL')}>
              <FormItemWrapper>
                <FormItem
                  name="match_with_team"
                  className="mb-1"
                  style={{ width: '100%' }}
                >
                  <RadioButtonTab items={feedbacks} />
                </FormItem>
                <StyledButton
                  size="small"
                  className="grey"
                  icon={comments.team ? <MinusIcon /> : <PlusIcon />}
                  onClick={() => handleClick('team')}
                >
                  {t('COMMENT')}
                </StyledButton>
              </FormItemWrapper>
              {comments.team && (
                <FormItem name="match_with_team_comment" className="mb-1">
                  <Textarea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder={t('COMMENT')}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem label={t('CULTURE')}>
              <FormItemWrapper>
                <FormItem
                  name="match_with_culture"
                  className="mb-1"
                  style={{ width: '100%' }}
                >
                  <RadioButtonTab items={feedbacks} />
                </FormItem>
                <StyledButton
                  size="small"
                  className="grey"
                  icon={comments.culture ? <MinusIcon /> : <PlusIcon />}
                  onClick={() => handleClick('culture')}
                >
                  {t('COMMENT')}
                </StyledButton>
              </FormItemWrapper>
              {comments.culture && (
                <FormItem name="match_with_culture_comment" className="mb-1">
                  <Textarea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder={t('COMMENT')}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem label={t('CAREER-OPPORTUNITIES')}>
              <FormItemWrapper>
                <FormItem
                  name="match_with_career"
                  className="mb-1"
                  style={{ width: '100%' }}
                >
                  <RadioButtonTab items={feedbacks} />
                </FormItem>
                <StyledButton
                  size="small"
                  className="grey"
                  icon={comments.career ? <MinusIcon /> : <PlusIcon />}
                  onClick={() => handleClick('career')}
                >
                  {t('COMMENT')}
                </StyledButton>
              </FormItemWrapper>
              {comments.career && (
                <FormItem name="match_with_career_comment" className="mb-1">
                  <Textarea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder={t('COMMENT')}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem label={t('TECH_USED')}>
              <FormItemWrapper>
                <FormItem
                  name="match_with_tech"
                  className="mb-1"
                  style={{ width: '100%' }}
                >
                  <RadioButtonTab items={feedbacks} />
                </FormItem>
                <StyledButton
                  size="small"
                  className="grey"
                  icon={comments.tech ? <MinusIcon /> : <PlusIcon />}
                  onClick={() => handleClick('tech')}
                >
                  {t('COMMENT')}
                </StyledButton>
              </FormItemWrapper>
              {comments.tech && (
                <FormItem name="match_with_tech_comment" className="mb-1">
                  <Textarea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder={t('COMMENT')}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem label={t('INTERVIEWERS')}>
              <FormItemWrapper>
                <FormItem
                  name="match_with_interviewers"
                  className="mb-1"
                  style={{ width: '100%' }}
                >
                  <RadioButtonTab items={feedbacks} />
                </FormItem>
                <StyledButton
                  size="small"
                  className="grey"
                  icon={comments.interviewers ? <MinusIcon /> : <PlusIcon />}
                  onClick={() => handleClick('interviewers')}
                >
                  {t('COMMENT')}
                </StyledButton>
              </FormItemWrapper>
              {comments.interviewers && (
                <FormItem
                  name="match_with_interviewers_comment"
                  className="no-margin"
                >
                  <Textarea
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    placeholder={t('COMMENT')}
                  />
                </FormItem>
              )}
            </FormItem>

            <FormItem
              name="comments"
              className="uppercase"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              label={t('GENERAL_COMMENTS')}
              rules={requiredRule(t)}
            >
              <Textarea autoSize={{ minRows: 3, maxRows: 3 }} />
            </FormItem>

            <FormItem
              name="decision"
              {...buttonLayout}
              className="no-margin mb-5"
              rules={requiredRule(t)}
            >
              <TabRadioButtonFeedback data={feedbackTypes} className={'mb-0'} />
            </FormItem>

            <RuleSection>
              <RuleItem>• {t('FEEDBACK_TEXT_1')}</RuleItem>
              <RuleItem>• {t('FEEDBACK_TEXT_2')}</RuleItem>
            </RuleSection>

            <FormItem label={''} name="agree" rules={requiredRule(t)} className={'mb-1'} valuePropName="checked">
              <StyledCheckbox >
                {t('DOUBLE_CHECKED')}
              </StyledCheckbox>
            </FormItem>

            {completed && (
              <ErrorMessage
                message={t(isClient ? 'RECRUITER_FEEDBACK' : 'CLIENT_FEEDBACK')}
                type="success"
              />
            )}
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('SEND')}
              </Button>
            </FormItem>
          </StyledForm>
        )
      )}
    </div>
  );
};

export default FeedbackForm;
