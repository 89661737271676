import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isPast, parseISO, format } from 'date-fns';
import FullCalendar, { EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import enLocale from '@fullcalendar/core/locales/es-us';
import nlLocale from '@fullcalendar/core/locales/nl';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-US';

import './calendar-widget.css';
import { getEvents } from '../../api/events';
import NameAvatar from '../../UI/NameAvatar';
import { CANDIDATES, JOBS, MESSAGES } from '../../constants/routes';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE } from '../../constants/roles';
import { useHistory } from 'react-router-dom';

const UnconfirmedPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 35px;
  width: 368px;
  padding: 16px 32px 32px;
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: 0 32px 80px 0 rgba(6, 28, 46, 0.2),
    0 16px 20px 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  z-index: 9999;
  max-height: 50vh;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
`;

const PanelHeader = styled.div`
  border-bottom: 1px solid #dae5ec;
  padding: 1rem 0;
  display: flex;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UnconfirmedList = styled.ul`
  list-style: none;
  max-height: calc(100% - 3rem);
  overflow-y: auto;
  margin: 1rem 0 0;
`;

const UnconfirmedEvent = styled.li`
  padding: 12px 16px 12px 0;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius};
  margin-bottom: 8px;
`;

const UnconfirmedEventInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const UnconfirmedEventInfoTitle = styled.h5`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 4px;
`;

const UnconfirmedEventInfoDescription = styled.h5`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin: 0;
`;

const CalendarContainer = styled.div`
  background: #fff;
  border-radius: ${({ theme }) => theme.radius};
  padding: 2rem 2rem 0.5rem;
   @media  (max-height:740px){
  padding: .5rem 2rem 0;
  }
`;

const CalendarEvent = styled.div<{ temp: boolean; past: boolean }>`
  width: 4px;
  height: 4px;
  flex-grow: 0;
  border-radius: 4px;
`;

const CalendarWidget = () => {
  const [, i18n] = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;
  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  const handleLoadEvents = (
    info: {
      start: Date;
      end: Date;
      startStr: string;
      endStr: string;
      timeZone: string;
    },
    successCallback: (events: EventInput[]) => void,
    failureCallback: (error: {
      message: string;
      response?: any;
      [otherProp: string]: any;
    }) => void,
  ) => {
    const start = format(new Date(info.start), 'yyyy-MM-dd');
    const end = format(new Date(info.end), 'yyyy-MM-dd');
    getEvents('', start, end).then((result) => {
      successCallback(
        result.data
          .filter((e: any) => e.event_type !== 2)
          .map((e: any) => ({
            ...e,
            date: e.event_date,
            title: e.data.title,
          })),
      );
    });
  };

  const handleEventClick = useCallback(
    async (info: any, el: any, events: any) => {
      if (events.length) {
        let child = el
          .closest('.fc-daygrid-day')
          .appendChild(document.createElement('div'));

        ReactDOM.render(
          <UnconfirmedPanel
            id="eventPanel"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PanelHeader>
              <span>
                {format(new Date(info), 'MMMM d', {
                  locale: i18n.language === 'en' ? en : nl,
                })}
              </span>
            </PanelHeader>
            <UnconfirmedList>
              {events.map((ev: any) => {
                const search = new URLSearchParams();
                let stage = '';
                let link = '';
                if (ev.event_type === 6 || ev.event_type === 5) {
                  search.set('history', ev.data.candidate.id);
                  search.set('interview_id', ev.data.interview_id);
                  ev.data.interview_stage_name &&
                    search.set('stage_name', ev.data.interview_stage_name);
                  const query = search.toString();
                  link += `${CANDIDATES}?filter=in-process&${query}`;
                }
                if (ev.event_type === 7) {
                  const user_id = isClient
                    ? ev.data.recruiter.id
                    : ev.data.client.company_id
                      ? ev.data.client.company_id
                      : ev.data.client.id;
                  const interview_id = ev.data.interview_id;
                  link = `${MESSAGES}/${user_id}/${interview_id}`;
                }
                if (ev.event_type === 2) {
                  stage = ev.data.interview_stage_name;
                  search.set('proposed_date', ev.data.interview_id);
                  search.set('stage', stage.toLowerCase());
                  const query = search.toString();
                  link += `${CANDIDATES}?filter=in-process&${query}`;
                }
                if (ev.event_type === 8) {
                  link += `${JOBS}/${ev.data.job_id}`;
                }
                if (ev.event_type === 3) {
                  stage = ev.data.interview_stage_name;
                  search.delete('feedback');
                  const query = search.toString();
                  link += `${CANDIDATES}?filter=in-process&${query}`;
                }
                if (ev.event_type === 4) {
                  stage = ev.data.interview_stage_name;
                  search.set('feedback', ev.data.interview_id);
                  search.set('stage', stage.toLowerCase());
                  const query = search.toString();
                  link += `${CANDIDATES}?filter=in-process&${query}`;
                }
                return (
                  <UnconfirmedEvent
                    key={ev.title}
                    onClick={() => history.push(link)}
                  >
                    <NameAvatar
                      name={ev.data.title}
                      color={{
                        main: '#7949cd',
                        light: '#f2edfa',
                      }}
                    />
                    <UnconfirmedEventInfo>
                      <UnconfirmedEventInfoTitle>
                        {ev.data.title}
                      </UnconfirmedEventInfoTitle>
                      <UnconfirmedEventInfoDescription>
                        {ev.data.interview_stage_name} – {ev.company_name}
                      </UnconfirmedEventInfoDescription>
                    </UnconfirmedEventInfo>
                  </UnconfirmedEvent>
                );
              })}
            </UnconfirmedList>
          </UnconfirmedPanel>,
          child,
        );
        document.addEventListener('click', (e) => {
          e.stopPropagation();
          document.getElementById('eventPanel')?.remove();
        });
      }
    },
    [history, isClient, i18n],
  );
  const handleClick = (info: any) => {
    let date: any;
    const target = info.jsEvent.target.closest('.fc-daygrid-day');
    if (target) {
      date = target.dataset.date;
    }
    date &&
      getEvents('', date, date).then((result) => {
        const res = result.data
          .filter((e: any) => e.event_type !== 2)
          .map((e: any) => ({
            ...e,
            date: e.event_date,
            title: e.data.title,
          }));
        date && res.length && handleEventClick(date, target, res);
      });
  };
  const renderEventContent = (eventInfo: any) => {
    const type = eventInfo.event.extendedProps.event_type;
    const date = eventInfo.event.extendedProps.event_date;
    return (
      <CalendarEvent
        temp={type === 2}
        past={isPast(parseISO(date))}
        title={
          eventInfo.event.extendedProps.company_name +
          ' (' +
          eventInfo.event.title +
          ')'
        }
      ></CalendarEvent>
    );
  };
  return (
    <CalendarContainer className="calendar-widget">
      <FullCalendar
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        events={handleLoadEvents}
        headerToolbar={{
          start: 'title prev,next',
          end: '',
        }}
        selectable={true}
        locales={[enLocale, nlLocale]}
        locale={locale}
        weekNumberCalculation="ISO"
        eventContent={renderEventContent}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        }}
        dayHeaderContent={(date: any) => {
          return format(date.date, 'cccccc', {
            locale: i18n.language === 'en' ? en : nl,
          });
        }}
        dateClick={(info) => handleClick(info)}
      />
    </CalendarContainer>
  );
};

export { CalendarWidget };
