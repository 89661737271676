import { ReactNode } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ChromiumRoutes from './chromium/routes';

export const PLUGIN_PATHS = {
  chromium: 'chromium',
}

export const BrowserPluginRoutes = ({ noMatch }: { noMatch: ReactNode }) => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/${PLUGIN_PATHS.chromium}`}>
        <ChromiumRoutes noMatch={noMatch} />
      </Route>
      {noMatch}
    </Switch>
  )
};
