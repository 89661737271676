import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserInfo,
  loginUserRequest,
  logoutUserRequest,
  loginUserWithGoogleRequest,
  confirmLoginWithGoogleRequest,
  loginUserWithLinkedinRequest,
} from '../api/user';
import { removeToken, storeToken } from '../utils/localStorage';
import { User } from '../types';
import { IS_ADMIN_KEY, ADMIN_TOKEN } from '../constants/roles';

interface UserState {
  isAuth: boolean;
  user: null | User;
  loading: boolean;
}

const initialState = { isAuth: false, user: null, loading: true } as UserState;

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (data: object, { rejectWithValue }) => {
    const response = await loginUserRequest(data);
    if (response.status === 401 || response.status === 400) {
      return rejectWithValue({ error: response?.data?.message });
    }
    return response;
  },
);

export const logoutUser = createAsyncThunk('user/logoutUser', async () => {
  // @ts-ignore
  const response = await logoutUserRequest();
  return response;
});

export const loginUserWithGoogle = createAsyncThunk(
  'user/loginUserWithGoogle',
  async (data: object, { rejectWithValue }) => {
    const response = await loginUserWithGoogleRequest(data);
    if (response.status === 401) {
      return rejectWithValue({ error: response.data.message });
    }
    return response;
  },
);

export const confirmLoginWithGoogle = createAsyncThunk(
  'user/confirmLoginWithGoogle',
  async (query: string, { rejectWithValue }) => {
    const response = await confirmLoginWithGoogleRequest(query);
    if (response.status === 401 || response.status === 403) {
      return rejectWithValue({ error: response.data.message });
    }
    return response;
  },
);

export const loginUserWithLinkedin = createAsyncThunk(
  'user/loginUserWithLinkedin',
  async (data: object, { rejectWithValue }) => {
    const response = await loginUserWithLinkedinRequest(data);
    if (response.status === 401) {
      return rejectWithValue({ error: 'Invalid email or password' });
    }
    return response;
  },
);
export const getUserByToken = createAsyncThunk(
  'user/getUserByToken',
  async (token: any, { rejectWithValue }) => {
    const response = await getUserInfo(token);
    if (response.status === 401) {
      return rejectWithValue({ error: 'Invalid token' });
    }
    return response;
  },
);
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuth = true;
      state.user = action.payload.user;
      state.loading = false;
    },
    updateClientCompany: (state, action) => {
      state.isAuth = true;
      state.user = action.payload;
      state.loading = false;
    },
    updateUser: (state, action) => {

     state.isAuth = true;
      state.user = {...state.user,...action.payload};
      state.loading = false;
    },
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
      state.loading = false;
      axios.defaults.headers.common['Authorization'] = null;
      removeToken('access_token');
      removeToken(IS_ADMIN_KEY);
      removeToken(ADMIN_TOKEN);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.access_token) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Token ${action.payload.access_token}`;
        storeToken(action.payload.access_token);
        state.loading = false;
        state.isAuth = true;
        state.user = action.payload.user;
        axios.interceptors.response.use(
          (res) => res,
          (err) => {
            if (err.response.status === 401) {
              axios.defaults.headers.common.Authorization = null;
              removeToken('access_token');
              removeToken(IS_ADMIN_KEY);
              removeToken(ADMIN_TOKEN);
              // @ts-ignore
              window.location = '/';
            }
            return Promise.reject(err);
          },
        );
      } else {
        state.loading = false;
      }
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
    builder.addCase(logoutUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      axios.defaults.headers.common['Authorization'] = null;
      removeToken('access_token');
      removeToken(IS_ADMIN_KEY);
      removeToken(ADMIN_TOKEN);
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
    builder.addCase(logoutUser.rejected, (state, { payload }) => {
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
    builder.addCase(loginUserWithGoogle.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUserWithGoogle.fulfilled, (state, action) => {
      if (action.payload.access_token) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Token ${action.payload.access_token}`;
        storeToken(action.payload.access_token);
        state.loading = false;
        state.isAuth = true;
        state.user = action.payload.user;
      } else {
        state.loading = false;
      }
    });
    builder.addCase(loginUserWithGoogle.rejected, (state, { payload }) => {
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
    builder.addCase(confirmLoginWithGoogle.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(confirmLoginWithGoogle.fulfilled, (state, action) => {
      if (action.payload.access_token) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Token ${action.payload.access_token}`;
        storeToken(action.payload.access_token);
        state.loading = false;
        state.isAuth = true;
        state.user = action.payload.user;
      } else {
        state.loading = false;
      }
    });
    builder.addCase(confirmLoginWithGoogle.rejected, (state, { payload }) => {
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
    builder.addCase(loginUserWithLinkedin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUserWithLinkedin.fulfilled, (state, action) => {
      if (action.payload.access_token) {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Token ${action.payload.access_token}`;
        storeToken(action.payload.access_token);
        state.loading = false;
        state.isAuth = true;
        state.user = action.payload.user;
      } else {
        state.loading = false;
      }
    });
    builder.addCase(loginUserWithLinkedin.rejected, (state, { payload }) => {
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
    builder.addCase(getUserByToken.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserByToken.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.user = action.payload;
    });
    builder.addCase(getUserByToken.rejected, (state, { payload }) => {
      removeToken('access_token');
      removeToken(IS_ADMIN_KEY);
      removeToken(ADMIN_TOKEN);
      state.loading = false;
      state.isAuth = false;
      state.user = null;
    });
  },
});

export const { login, logout, updateClientCompany ,updateUser} = userSlice.actions;

export default userSlice.reducer;
