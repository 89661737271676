import {
  CandidateInfo,
  CandidateStagesGroup,
  Certificate,
  City,
  Interview,
  Role,
  Skill,
  Specialization,
  Education,
} from '../types';
import { getRandomColor } from '../constants/palette';

export const groupByStageName = (
  stages: Interview[],
  list: { [key: string]: any[] },
) => {
  return stages.reduce((group, stage) => {
    if (stage.status === 'offered') {
      (group['offered'] = group['offered'] || []).push({
        ...stage.candidate,
        stage_name: 'offered',
        color: getRandomColor(),
        interview_id: stage.id,
      });
    } else if (stage.status === 'rejected') {
    } else if (stage.status === 'hired') {
      (group['hired'] = group['hired'] || []).push({
        ...stage.candidate,
        stage_name: 'hired',
        color: getRandomColor(),
        interview_id: stage.id,
      });
    } else if (stage.status === 'introduced') {
    } else {
      const key = stage?.current_stage?.stage_name || null;
      if (key?.toLowerCase() === '1st interview') {
        // @ts-ignore
        (group['1st Interview'] = group['1st Interview'] || []).push({
          ...stage.candidate,
          stage_name: key,
          color: getRandomColor(),
          interview_id: stage.id,
        });
      } else {
        (group['2nd+ Interview'] = group['2nd+ Interview'] || []).push({
          ...stage.candidate,
          color: getRandomColor(),
          stage_name: key,
          interview_id: stage.id,
        });
      }
    }

    return group;
  }, list);
};

export const getCandidateStatistics = (stages: Interview[]) => {
  return stages.reduce(
    (group, stage) => {
      const key = stage?.status || null;
      if (key) {
        // @ts-ignore
        group[key] = group[key] + 1 || 0;
      }
      group.sum += 1;

      return group;
    },
    { sum: 0, introduced: 0, 'in process': 0, hired: 0, rejected: 0 },
  );
};

export const getNameFirstLetters = (name: string) => {
  const separatedName = name?.split(' ');
  const first =
    separatedName && separatedName.length ? separatedName[0][0] : 'A';
  const second =
    separatedName && separatedName.length > 1 ? separatedName[1][0] : 'A';
  return (first + second).toUpperCase();
};

export const addColorToCandidate = (data: CandidateInfo[]) => {
  return data.map((item) => ({ ...item, color: getRandomColor() }));
};

export const filterSkills = (skills: Skill[], ids: string[]) => {
  return ids.map((id) => skills.find((skill) => skill.id === id)?.name);
};

export const filterRoles = (roles: Role[], ids: string[] | string) => {
  if (Array.isArray(ids)) {
    return ids.map((id) => roles.find((role) => role.id === id)?.name);
  } else {
    return roles.find((role) => role.id === ids)?.name;
  }
};

export const filterCities = (cities: City[], id: string) => {
  return cities.find((city) => city.id === id)?.name;
};

export const filterDomains = (domains: Specialization[], ids: string[]) => {
  return ids.map((id) => domains.find((domain) => domain.id === id)?.name);
};

export const filterCertificates = (
  certificates: Certificate[],
  ids: string[],
) => {
  return ids.map(
    (id) => certificates.find((certificate) => certificate.id === id)?.name,
  );
};

export const filterEducation = (education: Education[], ids: string[]) =>
  ids.map((id) => education.find((ed) => ed.id === id)?.name);

type IgetAllInfo = {
  candidates: CandidateInfo[];
  cities: City[];
  skills: Skill[];
  roles: Role[];
  domains: Specialization[];
  certificates: Certificate[];
  education: Education[];
};

// @TODO do we really need roles?
export const getAllInfo = ({
  candidates,
  cities,
  skills,
  roles,
  domains,
  certificates,
  education,
}: IgetAllInfo) => {
  return candidates.map((candidate) => {
    return {
      ...candidate,
      skills: filterSkills(skills, candidate.skills || []) as [],
      city_of_residence: filterCities(
        cities,
        candidate.city_of_residence,
      ) as string,
      specializations: filterDomains(
        domains,
        candidate.specializations || [],
      ) as [],
      certificates: filterCertificates(
        certificates,
        candidate.certificates || [],
      ) as [],
      education: filterEducation(education, candidate.education || []) as [],
      color: getRandomColor(),
    };
  });
};

interface IgroupByStages {
  candidates: CandidateInfo[];
  stages: CandidateStagesGroup;
  showReject?: boolean;
  showHired?: boolean;
}

export const groupByStages = async ({
  candidates,
  stages,
  showReject,
  showHired,
}: IgroupByStages) => {
  return candidates.reduce(
    (obj: CandidateStagesGroup, current) => {
      if (showReject) {
        obj['rejected'] = obj['rejected'] ? [...obj['rejected']] : [];
      }
      const formatted = { ...current };
      formatted?.interviews.forEach((int) => {
        if (int.status === 'introduced' || int.status === 'offered') {
          obj[int.status].push({ ...formatted, interviews: [int] });
        } else if (int.status === 'rejected' && showReject) {
          obj[int.status].push({ ...formatted, interviews: [int] });
        } else if (int.status === 'hired' && showHired) {
          obj[int.status].push({ ...formatted, interviews: [int] });
        } else if (int.status === 'on hold') {
          obj[int.status].push({ ...formatted, interviews: [int] });
        } else {
          if (
            int.current_stage &&
            int.status !== 'rejected' &&
            int.status !== 'hired'
          ) {
            obj[int.current_stage.stage_name.toLowerCase()]
              ? obj[int.current_stage.stage_name.toLowerCase()].push({
                  ...formatted,
                  interviews: [int],
                })
              : (obj[int.current_stage.stage_name.toLowerCase()] = [
                  {
                    ...formatted,
                    interviews: [int],
                  },
                ]);
          }
        }
      });
      return obj;
    },
    //{ introduced: [], ...stages, '1st interview': [], '2nd interview': [], offered: [], hired: [] },
    { 'on hold': [], introduced: [], ...stages, offered: [], hired: [] },
  );
};
export const generateKey = (length: number) => {
  let result = [];
  let characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }
  return result.join('');
};

export const orderTasks = (array: any[]) => {
  let newArray: any[] = [];
  array.forEach((e) => {
    if (e.task_type === 'Plan Interview') newArray.unshift(e);
    else newArray.push(e);
  });

  return newArray;
};

export const addDecimalPoints = (num: string) => {
  let newValue = '';
  if (num) {
    num = num.replace(/\D/g, '');
    let inputValue = num.replace('.', '').split('').reverse().join(''); // reverse
    for (let i = 0; i < inputValue.length; i++) {
      if (i % 3 === 0) {
        newValue += '.';
      }
      newValue += inputValue[i];
    }
  }

  return newValue.split('').reverse().join('').slice(0, -1);
};
