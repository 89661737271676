import PageWrapper from '../components/layout/PageWrapper';

const InvoicingPage = () => (
  <PageWrapper>
    <div>
      <iframe title="Invoicing" style={{ width: '100%', height: 800, padding: 0, border: 0 }} src={'https://e3c816ad-bb0b-42d4-a2ed-e689b554630d.weweb-preview.io/finances/?ats=smartrecruiters'}></iframe>
    </div>
  </PageWrapper>
);

export default InvoicingPage;
