import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormPopUp from '../form/FormPopUp';
import {
  CandidateHistory,
  CandidateInfo,
  InterviewRecruiter,
  InterviewTask,
} from '../../types';
import CandidateCompany from './CandidateCompany';
import CandidatePersonalInfo from './CandidatePersonalInfo';
import { Col, message, Row, Tabs } from 'antd';
import GeneralInfo from './GeneralInfo';
import SalaryPackage from './SalaryPackage';
import InfoItem from './InfoItem';
import styled from 'styled-components';
import Button from '../../UI/buttons/Button';
import {
  getCandidateHistory,
  inviteCandidate,
  rejectCandidateInterview,
} from '../../api/candidates';
import { useHistory } from 'react-router-dom';
import { getInterview } from '../../api/interviews';
import Tab from '../../UI/Tab';
import HistoryList from './HistoryList';
import { navigate } from '../../utils/routes';
import CandidateRejectForm from '../form/CandidateRejectForm';
import { useAppSelector } from '../../store/hooks';
import { trackChatClick } from '../../api/events';
import { SHOW_COMPANY_CHAT } from '../../constants/queryParams';

interface CandidateInfoProps {
  current: CandidateInfo | null;
  setCandidates?: React.Dispatch<React.SetStateAction<CandidateInfo[]>>;
}

const ActionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-right: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 992px) {
    width: 100%;
    text-align: center;
    & span {
      margin: 0 auto;
    }
  }
`;

const Divider = styled.div`
  margin: 1rem;
  width: 100%;
`;

const { TabPane } = Tabs;

const CandidateInfoModal = ({ current, setCandidates }: CandidateInfoProps) => {
  const [visible, setVisible] = useState(!!current);
  const [reject, setReject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const [history, setHistory] = useState<CandidateHistory[]>([]);
  const routerHistory = useHistory();
  const params = new URLSearchParams(routerHistory.location.search);
  const candidate = params.get('history');
  const [t] = useTranslation();

  useEffect(() => {
    setVisible(!!current);
  }, [current]);

  useEffect(() => {
    if (!visible) {
      setReject(false);
      navigate(routerHistory, undefined, [
        'history',
        'interview_id',
        'stage_name',
      ]);
    }
  }, [visible, routerHistory]);

  useEffect(() => {
    if (visible) {
      candidate &&
        getCandidateHistory(candidate, routerHistory).then((res) =>
          setHistory(res.data),
        );
    }
  }, [visible, candidate, routerHistory]);

  const interviewInvite = () => {
    setLoading(true);
    const data = {
      job: current?.interviews[0].job.id,
      candidate: candidate,
    };
    if (current?.interviews[0].id) {
      setCandidates &&
        inviteCandidate(current?.interviews[0].id, data)
          .then((r) => {
            getInterview(current?.interviews[0].id, routerHistory).then(
              (res) => {
                const newInterview = res.data;
                const tasks = newInterview.current_stage.tasks;
                const planInterview = tasks.find(
                  (task: InterviewTask) => task.is_planning_task,
                );
                const isDecisionMaker = planInterview?.email === user?.email;
                setCandidates((prevState) => {
                  return prevState.map((el) => {
                    if (el.id === candidate) {
                      return {
                        ...el,
                        interviews: el.interviews.map((int) => {
                          if (int.id === current?.interviews[0].id) {
                            return res.data;
                          } else {
                            return int;
                          }
                        }),
                      };
                    } else {
                      return el;
                    }
                  });
                });
                setLoading(false);
                navigate(
                  routerHistory,
                  isDecisionMaker
                    ? {
                      stage: res.data.current_stage.stage_name.toLowerCase(),
                      proposed_date: res.data.id,
                    }
                    : undefined,
                  ['history', 'interview_id', 'stage_name'],
                );
                setVisible(false);
                message.success(t('INTERVIEW_INVITE_SENT'), 5);
              },
            );
          })
          .catch(() => setLoading(false));
    }
  };

  const startChat = () => {
    const search = new URLSearchParams(routerHistory.location.search);
    search.set(SHOW_COMPANY_CHAT, 'true');
    const query = search.toString();
    routerHistory.push(window.location.pathname + '?' + query);
  };

  const rejectCandidate = (rejection_reason: string) => {
    setLoadingReject(true);
    if (current?.interviews[0].id) {
      setCandidates &&
        rejectCandidateInterview(current?.interviews[0].id, {
          rejection_reason,
        }).then((res) => {
          setCandidates((prevState) => {
            setLoadingReject(false);
            return prevState.map((prev) => {
              if (prev.id === candidate) {
                return {
                  ...prev,
                  interviews: prev.interviews.map((int) => {
                    if (int.id === current?.interviews[0].id) {
                      return { ...int, status: 'rejected' };
                    } else {
                      return int;
                    }
                  }),
                };
              } else {
                return prev;
              }
            });
          });
          setVisible(false);
          message.success(t('CANDIDATE_REJECT_MESSAGE'), 5);
        });
    }
    return true;
  };

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('CANDIDATE_INFO')}
      large={true}
    >
      <CandidateCompany
        role={current?.interviews[0].job?.title}
        company={`${t('AT')} ${current?.interviews[0].job?.company?.name
            ? current?.interviews[0].job?.company?.name
            : ''
          }`}
        companyId={current?.interviews[0].job?.company.id}
        image={`${current?.interviews[0].job.company?.image
            ? current?.interviews[0].job.company?.image
            : process.env.PUBLIC_URL + '/images/company-logo.png'
          }`}
      />
      <CandidatePersonalInfo
        name={current?.name || ''}
        recruiter={current?.interviews[0].recruiter as InterviewRecruiter}
        color={current?.color}
        role={current?.current_role}
        company={`${t('AT')} ${current?.current_company ? current?.current_company : ''
          }`}
        cv={current?.cv as string}
        linkedin={current?.linkedin_link as string}
      />
      <Tab defaultActiveKey="1" className="mt-5">
        <TabPane tab={t('CANDIDATE_INFO')} key="1">
          <Row justify="space-between">
            <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
              <GeneralInfo candidate={current as CandidateInfo} />
            </Col>
            {user?.hide_salary_info ? (
              <></>
            ) : (
              <Col xs={{ span: 24 }} lg={{ span: 11 }} xl={{ span: 10 }}>
                <SalaryPackage candidate={current as CandidateInfo} />
              </Col>
            )}
            <Divider />
            <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 10 }}>
              <InfoItem
                title={t('INTRODUCTION')}
                result={current?.interviews[0].introduction}
                direction="vertical"
              />
              <InfoItem
                title={t('WHY_MATCH')}
                result={current?.interviews[0].match_reason}
                direction="vertical"
              />
              <InfoItem
                title={t('REASON_NEXT_STEP')}
                result={current?.interviews[0].next_step_reason}
                direction="vertical"
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 10 }}>
              <InfoItem
                title={t('COMMENTS')}
                result={current?.interviews[0].comments}
                direction="vertical"
              />
            </Col>
          </Row>
          <ActionWrapper>
            <StyledButton
              size="large"
              type="primary"
              onClick={interviewInvite}
              disabled={current?.interviews[0].status !== 'introduced'}
              loading={loading}
            >
              {t('INTERVIEW_INVITE')}
            </StyledButton>
            <StyledButton
              size="large"
              type="default"
              onClick={() => {
                trackChatClick(current?.interviews[0].id || '');
                startChat();
              }}
            >
              {t('CHAT_WIDTH')}
            </StyledButton>
            <StyledButton
              size="large"
              type="default"
              danger
              onClick={() => setReject(true)}
              disabled={current?.interviews[0].status !== 'introduced'}
            >
              {t('REJECT_CANDIDATE')}
            </StyledButton>
          </ActionWrapper>
          {reject && (
            <CandidateRejectForm
              rejectCandidate={rejectCandidate}
              loading={loadingReject}
            />
          )}
        </TabPane>
        <TabPane tab={t('HISTORY')} key="2">
          <HistoryList history={history} />
        </TabPane>
      </Tab>
    </FormPopUp>
  );
};

export default CandidateInfoModal;
