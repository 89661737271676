import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface TitleProps {
  text: string | ReactElement;
  className?: string,
  marginBottom?: string,
}

interface ITitle {
  marginBottom?: string,
}

const Title = styled.h3<ITitle>`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #061d31;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '2.5rem'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.mb-4{
    margin-bottom: 2.5rem;
  }
  @media (max-width: 992px) and (min-width: 768px){
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
  & span {
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.71;
    color: #627d95;
    font-weight: 400;
    & a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  &.no-margin{
  margin-bottom: .5rem;
  }
`;

const FormTitle = ({ text, className, marginBottom }: TitleProps) => {
  return <Title className={className} marginBottom={marginBottom}>{text}</Title>;
};

export default FormTitle;
