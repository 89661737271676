import { useTranslation } from 'react-i18next';

import FormPopUp from '../../../form/FormPopUp';
import { InterviewRoom, Message } from '../../../../types';
import useCompanyChatModal from './hooks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../../../icons/icon-close.svg';
import { ReactComponent as SendIcon } from '../../../../icons/icon-send.svg';
import { ReactComponent as BackBtnIcon } from '../../../../icons/back-btn.svg';
import Loader from "../../../../UI/Loader";
import { ReactComponent as MobileLogo } from '../../../../icons/nav-logo.svg';
import {
  CompanyChatMessagesList,
  LogoLink,
  MessageButton,
  MessageDatetime,
  MessageHeader,
  MessageHeaderItem,
  MessageItem,
  CompanyMessagesContainer,
  MessageSenderAvatar,
  MessageSenderInfo,
  MessageSenderName,
  MessagesListHeader,
  MessagesListHeaderSmall,
  MessagesListHeaderWrapper,
  MessageText,
  NewCompanyChatMessage,
  NewMessageField,
  NewMessageHeader,
  RoundButton,
  StyledAvatar
} from "../../../messages/RoomStyles";
import { CANDIDATES, CLIENT, HOME, MESSAGES, RECRUITER } from "../../../../constants/routes";
import isToday from "date-fns/isToday";
import { format } from "date-fns";
import en from "date-fns/locale/en-US";
import nl from "date-fns/locale/nl";
import isYesterday from "date-fns/isYesterday";
import isThisWeek from "date-fns/isThisWeek";
import { MESSAGE_TYPES } from "../../../../constants/messageTypes";
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from "../../../../constants/roles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getInterviewRoom,
  sendInterviewMessage,
  sendSupportMessage,
  sendUserMessage
} from "../../../../api/messages";
import {
  INTERVIEW_ID_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  SHOW_OFFER_PARAM,
  STAGE_PARAM
} from "../../../../constants/queryParams";
import { getInterviewsUnread } from "../../../../store/messagesSlice";
import TooltipPrompt from "../../../../UI/tootlip";
import useRatingRequest from '../../../messages/hooks/useRatingRequest';

interface CompanyChatModalProps {
  interview: InterviewRoom | { [key: string]: any }; // !WB: wtaf...
  setShowCompanyChatModal: (show: boolean) => void,
  visible: boolean,
}

const CompanyChatModal = ({
  interview,
  setShowCompanyChatModal,
  visible,
}: CompanyChatModalProps) => {
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const { t, i18n } = useTranslation();
  const loader = useRef(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [sending, setSending] = useState(false);
  const [newMessageText, setNewMessageText] = useState('');
  const [newMessageExpanded, setNewMessageExpanded] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState<InterviewRoom>();
  // eslint-disable-next-line
  const [nextPage, setNextPage] = useState(null);
  const dispatch = useAppDispatch();

  const getInterviewMessages = useCallback(
    async (interviewId: string) => {
      setLoading(true);
      setNewMessageExpanded(false);
      setNewMessageText('');
      const result = await getInterviewRoom(interviewId);
      if (result && result.data && result.data.pagination) {
        setMessages(result.data.results);
        setLoading(false);
        // @ts-ignore
        setNextPage(result.data.pagination.next);
        dispatch(getInterviewsUnread(interviewId));
      }
    },
    [dispatch, setNextPage],
  );

  const contact = useMemo(() => {
    return {
      id: interview?.candidate?.id,
      name: interview?.candidate?.name,
      email: interview?.candidate?.email,
      company_name: interview?.job?.company.name,
      recruiter_name: interview?.recruiter?.name,
      recruiter_id: interview?.recruiter?.id,
      company_id: interview?.job?.company.id,
      phone: interview?.candidate?.phone,
    };
  }, [interview])

  const reloadMessages = useCallback(() => {
    if (interview && interview.id && visible) {
      if (isClient || isRecruiter) {
        getInterviewMessages(interview.id);
      }
    }
  }, [interview, isClient, isRecruiter, getInterviewMessages, visible]);

  useEffect(() => { reloadMessages() }, [reloadMessages]);

  const toggleNewMessageForm = () => {
    setNewMessageExpanded(!newMessageExpanded);
  };

  const handleSendMessage = useCallback(async () => {
    if (newMessageText) {
      setSending(true);
      let result = null as any;
      if (isAdmin && contact && contact.id) {
        result = await sendUserMessage(contact?.id, newMessageText);
      }
      if ((isClient || isRecruiter) && contact) {
        if (interview && contact.id !== 'support') {
          result = await sendInterviewMessage(
            interview.id,
            newMessageText,
          );
        } else if (!interview && contact.id === 'support') {
          result = await sendSupportMessage(newMessageText);
        }
      }
      if (result && result.data) {
        setSending(false);
        setNewMessageExpanded(false);
        setNewMessageText('');
        setMessages((state) => [result.data, ...state]);
      }
    }
  }, [
    newMessageText,
    interview,
    contact,
    isAdmin,
    isClient,
    isRecruiter,
  ]);

  const handleSystemMessageClick = useCallback(
    (type, feedback?: string) => {
      if (interview) {
        if (type === MESSAGE_TYPES.DATES_ARE_PROPOSED) {
          history.push(
            CANDIDATES +
            `?filter=in-process&${INTERVIEW_ID_PARAM}=` +
            interview.id +
            `&${STAGE_PARAM}=` +
            interview.current_stage.stage_name +
            `&${SHOW_DATE_PROPOSE_PARAM}=true`,
          );
        } else if (type === MESSAGE_TYPES.FEEDBACK_REQUIRED) {
          history.push(
            CANDIDATES +
            '?filter=in-process&feedback=' +
            interview.id +
            '&stage=' +
            interview.current_stage.stage_name,
          );
        } else if (type === MESSAGE_TYPES.FEEDBACK_RECEIVED) {
          history.push(
            CANDIDATES +
            ('?filter=in-process&' +
              'history=' +
              interview.candidate.id +
              '&interview_id=' +
              interview.id +
              '&feedback_id=' +
              feedback +
              '&feedback_interview_id=' +
              interview.id +
              '&stage_name=' +
              ''),
          );
        } else if (type === MESSAGE_TYPES.OFFER_MAKE) {
          history.push(
            CANDIDATES +
            ('?filter=in-process&' +
              `${SHOW_OFFER_PARAM}=` +
              interview.candidate.id +
              `&${INTERVIEW_ID_PARAM}=` +
              interview.id),
          );
        }
      } else {
        history.push(CANDIDATES + '?filter=in-process');
      }
    },
    [history, interview],
  );

  const {
    actions: {
      handleCloseModal,
    },
  } =
    // @ts-ignore
    useCompanyChatModal({ interview, setShowCompanyChatModal });

  // !WB: this type assertion is really awful but I don't have time to fix the abysmal typing issue on this component (see wtaf comment above)
  const [RatingRequestMessage, getMessageText] = useRatingRequest(reloadMessages, interview as any, user);

  return (
    <FormPopUp
      visible={visible}
      customClass='company-chat-modal'
      setVisible={handleCloseModal}
      title={isClient ?
        <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={RECRUITER + '/' + contact?.recruiter_id}>Chat with {interview.recruiter?.name} about {contact?.name}</Link></TooltipPrompt> :
        <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={CLIENT + '/' + contact?.company_id}>Chat with {contact?.company_name} about {contact?.name}</Link></TooltipPrompt>
      }
    >
      <CompanyMessagesContainer
        className={
          selectedInterview || (isAdmin && contact) || contact?.id === 'support'
            ? ''
            : 'hide-mobile'
        }
      >
        <div className="loader" ref={loader} />
        <Loader spinning={loading}>
          {!!(
            (isClient || isRecruiter) &&
            messages &&
            messages.length &&
            contact?.id !== 'support'
          ) && (
              <MessagesListHeaderWrapper>
                <BackBtnIcon
                  className="back"
                  onClick={() => {
                    history.push(`${MESSAGES}/${contact?.id}`);
                    setSelectedInterview(undefined);
                  }}
                />
                <MessagesListHeader>
                  <MessageHeaderItem>{messages[0].info?.name}</MessageHeaderItem>
                  <MessagesListHeaderSmall>
                    {messages[0].info?.company_name}
                  </MessagesListHeaderSmall>
                  <MessagesListHeaderSmall>
                    {messages[0].info?.phone}
                  </MessagesListHeaderSmall>
                  <MessagesListHeaderSmall>
                    {messages[0].info?.email}
                  </MessagesListHeaderSmall>
                </MessagesListHeader>
                <LogoLink to={HOME}><MobileLogo style={{ width: 40, marginLeft: 12 }} /></LogoLink>
              </MessagesListHeaderWrapper>
            )}
          {isAdmin && contact && (
            <MessagesListHeaderWrapper>
              <BackBtnIcon
                className="back"
                onClick={() => {
                }}
              />
              <MessagesListHeader>
                <MessageHeaderItem>{contact?.name}</MessageHeaderItem>
                <MessagesListHeaderSmall>
                  {contact?.company_name}
                </MessagesListHeaderSmall>
                <MessagesListHeaderSmall>
                  {contact?.phone}
                </MessagesListHeaderSmall>
                <MessagesListHeaderSmall>
                  {contact?.email}
                </MessagesListHeaderSmall>
              </MessagesListHeader>
              <LogoLink to={HOME}><MobileLogo style={{ width: 40 }} /></LogoLink>
            </MessagesListHeaderWrapper>
          )}
          {contact && (
            <CompanyChatMessagesList
              empty={messages && messages.length === 0}
              withHeader={
                ((isClient || isRecruiter) &&
                  selectedInterview !== undefined) ||
                (isAdmin && contact !== null)
              }
              expanded={newMessageExpanded}
            >
              {(!messages || (!loading && messages.length === 0)) && (
                <p>{t('NO_MESSAGES_YET')}</p>
              )}
              {!loading && messages &&
                messages.map((message, index) => (
                  <MessageItem key={message.id}>
                    <MessageHeader>
                      <MessageSenderInfo>
                        {message.sender.image ? (
                          <MessageSenderAvatar
                            style={{
                              backgroundImage:
                                'url(' +
                                (message.message_type === MESSAGE_TYPES.USER_TYPE
                                  ? message.sender.image
                                  : message.info.image) +
                                ')',
                            }}
                          />
                        ) : (
                          <StyledAvatar
                            name={
                              message.message_type === MESSAGE_TYPES.USER_TYPE
                                ? message.sender.name
                                : message.info.name
                            }
                            image={
                              message.message_type === MESSAGE_TYPES.USER_TYPE
                                ? message.sender.image
                                : message.info.image
                            }
                          />
                        )}
                        <MessageSenderName>
                          {message.message_type === MESSAGE_TYPES.USER_TYPE
                            ? message.sender.name
                            : message.info.name}
                        </MessageSenderName>
                      </MessageSenderInfo>
                      <MessageDatetime>
                        {isToday(new Date(message.created_at))
                          ? t('TODAY') +
                          format(new Date(message.created_at), ', HH:mm', {
                            locale: i18n.language === 'en' ? en : nl,
                          })
                          : isYesterday(new Date(message.created_at))
                            ? t('YESTERDAY') +
                            format(new Date(message.created_at), ', HH:mm', {
                              locale: i18n.language === 'en' ? en : nl,
                            })
                            : isThisWeek(new Date(message.created_at))
                              ? format(
                                new Date(message.created_at),
                                'EEEE, HH:mm',
                                {
                                  locale: i18n.language === 'en' ? en : nl,
                                },
                              )
                              : format(
                                new Date(message.created_at),
                                'MMM d, HH:mm',
                                {
                                  locale: i18n.language === 'en' ? en : nl,
                                },
                              )}
                      </MessageDatetime>
                    </MessageHeader>
                    <MessageText
                      system={message.message_type !== MESSAGE_TYPES.USER_TYPE}
                      disabled={
                        message.is_old &&
                        message.message_type !== MESSAGE_TYPES.FEEDBACK_RECEIVED
                      }
                    >
                      {(
                        message.message_type === MESSAGE_TYPES.USER_TYPE
                          ? message.text
                          : getMessageText(message)
                      )}
                      {message.message_type ===
                        MESSAGE_TYPES.DATES_ARE_PROPOSED && (
                          <MessageButton
                            type="primary"
                            onClick={() =>
                              handleSystemMessageClick(
                                MESSAGE_TYPES.DATES_ARE_PROPOSED,
                              )
                            }
                          >
                            {t('CHOOSE_DATES')}
                          </MessageButton>
                        )}
                      {(message.message_type ===
                        MESSAGE_TYPES.FEEDBACK_RECEIVED ||
                        message.message_type === MESSAGE_TYPES.OFFER_MAKE) && (
                          <MessageButton
                            type="primary"
                            onClick={() =>
                              handleSystemMessageClick(
                                message.message_type,
                                message.feedback_id,
                              )
                            }
                          >
                            {t('REVIEW')}
                          </MessageButton>
                        )}
                      {message.message_type ===
                        MESSAGE_TYPES.FEEDBACK_REQUIRED && (
                          <MessageButton
                            type="primary"
                            onClick={() =>
                              handleSystemMessageClick(
                                MESSAGE_TYPES.FEEDBACK_REQUIRED,
                              )
                            }
                          >
                            {t('SEND_FEEDBACK')}
                          </MessageButton>
                        )}
                      <RatingRequestMessage message={message} />
                    </MessageText>
                  </MessageItem>
                ))}
            </CompanyChatMessagesList>
          )}
        </Loader>
        {((isAdmin && contact) ||
          (!isAdmin && contact?.name === 'support') ||
          (!isAdmin && contact && selectedInterview && !isRecruiter) ||
          !(
            isRecruiter &&
            selectedInterview?.status === 'introduced' &&
            !messages.length
          )
        ) &&
          contact?.name !== 'support'
          && (
            <NewCompanyChatMessage expanded={newMessageExpanded}>
              <Loader spinning={sending}>
                <NewMessageHeader
                  expanded={newMessageExpanded}
                  onClick={() => toggleNewMessageForm()}
                >
                  {t('WRITE_A_MESSAGE')}
                  {newMessageExpanded && <CloseIcon />}
                </NewMessageHeader>
                {newMessageExpanded && (
                  <NewMessageField
                    autoSize={{ minRows: 10, maxRows: 10 }}
                    maxLength={2000}
                    value={newMessageText}
                    onChange={(e: any) => setNewMessageText(e.target.value)}
                  />
                )}
              </Loader>
              {newMessageExpanded && (
                <RoundButton
                  disabled={newMessageText === '' || sending}
                  onClick={() => handleSendMessage()}
                >
                  <SendIcon />
                </RoundButton>
              )}
            </NewCompanyChatMessage>
          )}
      </CompanyMessagesContainer>
    </FormPopUp>
  )
};

export default CompanyChatModal;
