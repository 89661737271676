import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import FormItem from '../form/FormItem';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getUrlString } from '../../constants/queryParams';
import Input from '../../UI/inputs/Input';

const FilterWrapper = styled.div`
  padding: 1rem 2rem 0;
  border-radius: ${({ theme }) => theme.radius};
  background: #ffffff;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 998;
  @media (max-width: 576px) {
    position: relative;
  }
`;

const StyledButton = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.primaryColor};
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  left: 100%;
  transform: translateX(-50%);
  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    background: ${({ theme }) => theme.hoverColor};
  }
`;
const ClientListFilter = () => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const company_name = params.get('company_name');
  const department = params.get('department');
  const contact_name = params.get('contact_name');
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const query = getUrlString(values);
    history.push({ search: '?' + query });
  };
  useEffect(() => {
    form.setFieldsValue({
      company_name,
      department,
      contact_name,
    });
  }, [form, company_name, department, contact_name]);
  return (
    <div>
      <FilterWrapper>
        <Form
          scrollToFirstError={true}
          layout="vertical"
          name="client-filter"
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={16}>
            <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label={t('COMPANY_NAME_TITLE')} name="company_name">
                <Input placeholder={t('COMPANY_NAME_TITLE')} size={'large'} />
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label={t('DEPARTMENT_TITLE')} name="department">
                <Input placeholder={t('DEPARTMENT_TITLE')} size={'large'} />
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
              <FormItem label={t('CONTACT_PERSON_NAME')} name="contact_name">
                <Input placeholder={t('CONTACT_PERSON_NAME')} size={'large'} />
              </FormItem>
            </Col>
          </Row>
          <StyledButton type="submit">
            <SearchIcon />
          </StyledButton>
        </Form>
      </FilterWrapper>
    </div>
  );
};

export default ClientListFilter;
