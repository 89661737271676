import React from 'react';
import JobStepsPage from '../JobSteps';

const EmbedJobStepsPage = () => {
  return (
    <JobStepsPage title={'Edit job spec'} />
  )
};

export default EmbedJobStepsPage;
