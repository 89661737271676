import axios from 'axios';
import { History } from 'history';

const api_url = process.env.REACT_APP_API_URL;

export const getRecruiterList = async (query?: string) => {
  return await axios.get(`${api_url}recruiters/${query ? query : ''}`);
};

export const getRecrutersSelectedForJob = async ({
  query,
  job_id,
}: {
  query?: string;
  job_id?: string | null;
}) => {
  return await axios.get(
    `${api_url}recruiters/selected/?job_tier=${
      query ? query : ''
    }&selected=true${job_id ? `&job_id=${job_id}` : ''}}`,
  );
};

export const getRecrutersForRecruiterManagement = async (
  showMarketplaceRecruiters: boolean,
  query?: string,
) => {
  return await axios.get(
    `${api_url}recruiters/recruiter-management/?own_recruiter=${showMarketplaceRecruiters}&${
      query ? query : ''
    }`,
  );
};

export const getPreviouslySelectedRecruiters = async (query?: string) => {
  return await axios.get(
    `${api_url}recruiters/previously-selected/?job_tier=${query ? query : ''}`,
  );
};

export const getRecruiterCompanyAgreements = async () => {
  return await axios.get(`${api_url}recruiters-agreement/`);
};

export const getRecruiterCompanyAgreement = async (
  recruiterCompanyAgreementId: string,
) => {
  return await axios.get(
    `${api_url}recruiters-agreement/${recruiterCompanyAgreementId}/`,
  );
};

export const patchRecruiterCompanyAgreement = async (
  id: string,
  payload: any,
) => await axios.patch(`${api_url}recruiters-agreement/${id}/`, payload);

export const deleteRecruiterCompanyAgreement = async ({
  recruiter_id,
  client_company_id,
}: {
  recruiter_id: string;
  client_company_id: string;
}) => {
  return await axios.delete(`${api_url}recruiters-agreement/delete/`, {
    params: {
      recruiter_id,
      client_company_id,
    },
  });
};

export const sendInviteToRecruiters = async ({
  company_name,
  selected_recruiters = [],
  loaded_recruiters = [],
  job_id,
}: {
  company_name: string;
  selected_recruiters?: string[];
  loaded_recruiters?: string[];
  job_id: string;
}) => {
  return await axios.post(`${api_url}recruiters/invite-list/`, {
    company_name,
    selected_recruiters,
    loaded_recruiters,
    job_id,
  });
};

export const inviteAllRecruitersToJob = async ({
  company_name,
  job_id,
  unselected_recruiters,
}: {
  company_name: string;
  job_id: string;
  unselected_recruiters: string[];
}) => {
  return await axios.post(`${api_url}recruiters/invite-all/`, {
    company_name,
    job_id,
    unselected_recruiters,
  });
};

export const inviteOwnSupplier = async (reqBody: FormData) => {
  return await axios.post(`${api_url}recruiters/invite-own/`, reqBody);
};

export const getRecruiter = async (id: string, history?: History) => {
  return await axios
    .get(`${api_url}recruiters/${id}/`)
    .then((res) => res)
    .catch((e) => {
      if (e?.response?.data?.error_code === 'resource_not_found' && history)
        history.push('/not-found');
      return e.response;
    });
};

export const editRecruiter = async (id: string, info: any) => {
  return await axios.put(`${api_url}recruiters/${id}/`, info);
};

export const sendRecruiterInvitation = async (info: any) => {
  const company =
    info.newCompany &&
    (await createRecruiterCompany({ name: info.newCompany }));
  if (info.newCompany) {
    info.company = company.data.id;
  }
  delete info.newCompany;
  const invite = await axios.post(`${api_url}recruiters/invite/`, info);
  return invite;
};

export const getRecruiterInfo = async () => {
  return await axios.get(`${api_url}recruiters/me/`);
};

export const updateRecruiter = async (info: any) => {
  return await axios.patch(`${api_url}recruiters/me/`, info);
};

export const createRecruiterCompany = async (info: any) => {
  return await axios.post(`${api_url}recruiter-companies/`, info);
};

export const getRecruitersCompanyList = async () => {
  return await axios.get(`${api_url}recruiter-companies/`);
};
