import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SettingsMenu from '../../components/settings/SettingsMenu';
import {
  ATS_JOB_FILTER_OPEN_JOBS,
  AtsStatus,
  connectJob,
  disconnectAts,
  getAtsStatus,
} from '../../api/integrations';
import ConnectAtsForm from '../../components/settings/atsSettings/ConnectAtsForm';
import AtsSettingsForm from '../../components/settings/atsSettings/AtsSettingsForm';
import AwaitingInitialSyncNotice from '../../components/settings/atsSettings/AwaitingInitialSyncNotice';
import { useHistory } from 'react-router-dom';
import { ATS_SETTINGS, JOBS } from '../../constants/routes';
import debounce from 'lodash/debounce';

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.lozengeColor};
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

const AtsSettings = () => {
  const [atsStatus, setAtsStatus] = useState<AtsStatus | null>(null);
  const history = useHistory();

  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  const fetchAtsOptions = useCallback(async (searchValue: string) => {
    const query = {
      ...ATS_JOB_FILTER_OPEN_JOBS,
      ...(searchValue ? { name_contains: searchValue } : {}),
    };
    const fetched = await getAtsStatus(query);
    setAtsStatus(fetched);
  }, []);
  const debounceFetchAtsJobOptions = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      fetchAtsOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setFetching(false);
      });
    };

    return debounce(loadOptions, 500);
  }, [fetchAtsOptions]);

  const checkAtsStatus = () => {
    getAtsStatus(ATS_JOB_FILTER_OPEN_JOBS).then(setAtsStatus);
  };

  useEffect(checkAtsStatus, []);
  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu="ats" />

        {atsStatus &&
          (!atsStatus || atsStatus?.state === 'NOT_CONNECTED' ? (
            <ConnectAtsForm onConnectionComplete={checkAtsStatus} />
          ) : atsStatus?.state === 'AWAITING_INITIAL_SYNC' ? (
            <AwaitingInitialSyncNotice onRefresh={checkAtsStatus} />
          ) : (
            <AtsSettingsForm
              atsStatus={atsStatus}
              onCheckAtsStatus={() => {
                checkAtsStatus();
              }}
              onDisconnectAts={async () => {
                await disconnectAts();
                checkAtsStatus();
              }}
              onConnectJob={async (localJobId, remoteJobId) => {
                await connectJob(localJobId, remoteJobId);
                history.push(`${ATS_SETTINGS}${JOBS}/${localJobId}`);
              }}
              onSearch={debounceFetchAtsJobOptions}
              fetching={fetching}
            />
          ))}
      </FormWrapper>
    </PageWrapper>
  );
};

export default AtsSettings;
