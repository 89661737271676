import React from 'react';
import styled from 'styled-components';

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
  light?: boolean;
}
const TagItem = styled.div`
  padding: 0.5rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.lozengeColor};
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  max-width: max-content;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  &.light {
    opacity: 0.4;
  }
  &.green {
    background-color: #eff9f3;
  }
  &.yellow {
    background-color: #faf9e8;
  }
`;

const TagButton = ({ children, light, ...rest }: TagProps) => {
  return <TagItem className={light ? 'light' : ''} title={children} {...rest}>{children}</TagItem>;
};

export default TagButton;
