import { ReactComponent as AddIcon } from '../../../icons/plus.svg';
import { useEffect, useState, useCallback } from 'react';
import { Col, Row, Select, Form, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import FormTitle from '../../auth/FormTitle';
import Button from '../../../UI/buttons/Button';
import { ReactComponent as DeleteBaseIcon } from '../../../icons/delete.svg';
import {
  updateJobInterviewProcess,
  getJobInterviewProcess,
} from '../../../api/jobs';
import FormItem from '../FormItem';
import { Job, JobStageForm, InterviewStage } from '../../../types';
import { getInterviewStages } from '../../../api/interview-stages';
import { publishJob } from '../../../api/jobs';
import NumberInput from '../../../UI/buttons/NumberInput';
import SelectInput from '../../../UI/inputs/Select';
import Textarea from '../../../UI/inputs/Textarea';
import Input from '../../../UI/inputs/Input';
import CheckboxInput from '../../../UI/inputs/CheckboxInput';
import RouterBlocker from '../../jobs/RouterBlocker';
import { JOBS } from '../../../constants/routes';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import {
  FormSubtitle,
  FormActions,
  JobStageContainer,
  RemoveStageButton,
} from './styles';
import { ADMIN_ROLE } from '../../../constants/roles';
import { useAppSelector } from '../../../store/hooks';
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';

const { Option } = Select;

type JobProcessFormProps = {
  job: Job | undefined;
  onFinish: (id: string) => void;
  progress: number;
  hasPSL: boolean;
}

const JobProcessForm = ({
  job,
  onFinish,
  progress,
  hasPSL,
}: JobProcessFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const [blockTransition, setBlockTransition] = useState(false);
  const [interviewStages, setInterviewStages] = useState<InterviewStage[]>([]);

  const [stages, setStages] = useState<JobStageForm[]>([
    {
      stage: '',
      description: '',
      interviewer_name: '',
      interviewer_role: '',
      interview_goal: '',
      interview_duration: '',
      interview_location: '',
      candidate_preparation: '',
      tasks: [],
    },
  ]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [stepProgress, setStepProgress] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(
    (job?.sections as any)['interview_process'].progress || 0,
  );

  const { user } = useAppSelector((state) => state.user);
  const role = user?.role;
  const isAdmin = role === ADMIN_ROLE;
  const disabled = (stepProgress + currentProgress) / 2 < 100;

  useEffect(() => {
    if (job && job.sections) {
      const keys = Object.keys(job.sections).filter(
        (key) => key !== 'interview_process',
      );

      let percentages = keys.map((key: any) => {
        return (job.sections as any)[key].progress;
      });
      let percentage = Math.round(
        percentages.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) /
        percentages.length,
      );
      if (percentage > 0) {
        setStepProgress(percentage);
      }
    }
  }, [job]);

  useEffect(() => {
    if (job && job.id) {
      getInterviewStages().then((res) => {
        const intStages = res.data;
        setInterviewStages(intStages);

        getJobInterviewProcess(job.id).then((res) => {
          let formData = { ...res.data };
          form.setFieldsValue(formData);
          if (res.data.stages.length > 0) {
            res.data.stages.forEach((stage: any, index: number) => {
              form.setFieldsValue({
                ['stage_desc' + index]: stage.description,
                ['interviewer_name' + index]: stage.interviewer_name,
                ['interviewer_role' + index]: stage.interviewer_role,
                ['interview_goal' + index]: stage.interview_goal,
                ['interview_duration' + index]: stage.interview_duration,
                ['interview_location' + index]: stage.interview_location,
                ['candidate_preparation' + index]: stage.candidate_preparation,
              });
            });
            setStages(res.data.stages);
          } else {
            setStages([
              {
                stage: intStages[0].id,
                description: '',
                interviewer_name: '',
                interviewer_role: '',
                interview_goal: '',
                interview_duration: '',
                interview_location: '',
                candidate_preparation: '',
                tasks: [],
              },
            ]);
          }
        });
      });
    }
  }, [job, form]);

  const collectFormValues = useCallback(() => {
    const draft = form.getFieldsValue();

    draft.stages = stages;
    return draft;
  }, [form, stages]);

  const saveJobInfo = useCallback(
    async (isDraft: boolean) => {
      let jobId = job?.id;
      if (jobId) {
        const draft = collectFormValues();
        await form
          .validateFields()
          .then(async (values) => {
            jobId && (await updateJobInterviewProcess(jobId, draft));
            setBlockTransition(false);
            message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
            return jobId;
          })
          .catch((err) => {
            if (err.response.data.error_code === 'access_forbidden_error') {
              throw err;
            }
            form.submit();
          });
      }
      return jobId;
    },
    [collectFormValues, form, job, t],
  );

  const handleSave = useCallback(
    async (e: any, publish = false) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        setSaveLoading(true);
        await saveJobInfo(true);
        setBlockTransition(false);
        setSaveLoading(false);
      } catch (error: any) {
        if (error.response.data.error_code === 'access_forbidden_error') {
          setSaveLoading(false);
          setCreateLoading(false);
          message.error(t('WARNING_CANDIDATES_IN_PROCESS'), 5);
          throw error;
        }
        message.error(error.response.data.message, 5);
        setBlockTransition(false);
        setSaveLoading(false);
      }
    },
    [saveJobInfo, t],
  );

  const handleFinish = async (e: any) => {
    if (job?.id) {
      try {
        await handleNext(e);
      } catch (e) {
        return false;
      }
      if (hasPSL) {
        history.push(`${JOBS}/edit/${job.id}/psl`);
      } else {
        history.push(`${JOBS}/`);
      }
    }
  };

  const handleNext = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (job?.id) {
        //if (!blockTransition) {
        try {
          setCreateLoading(true);
          await handleSave(e, true)
            .then(() => {
              setTimeout(() => {
                publishJob(job.id)
                  .then(() => {
                    setCreateLoading(false);
                    onFinish(job.id);
                  })
                  .catch((err) => {
                    //Temporary disabled error notification
                    //message.error(err.response.data.message, 5);
                    setCreateLoading(false);
                  });
              }, 1000);
            })
            .catch((err) => {
              if (err.response.data.error_code === 'access_forbidden_error') {
                throw err;
              }
              message.error(err.response.data.message, 5);
              setCreateLoading(false);
            });
        } catch (error: any) {
          if (error.response.data.error_code === 'access_forbidden_error') {
            throw error;
          }
          message.error(error.response.data.message, 5);
          setCreateLoading(false);
        }
      } else {
        message.info(t('PLEASE_SAVE_THE_FORM'), 5);
      }
      //}
    },
    // eslint-disable-next-line
    [job?.id, onFinish, t, handleSave, disabled],
  );

  const handleAddMoreStages = useCallback(() => {
    setStages((state) => [
      ...state,
      {
        stage: '',
        description: '',
        interviewer_name: '',
        interviewer_role: '',
        interview_goal: '',
        interview_duration: '',
        interview_location: '',
        candidate_preparation: '',
        tasks: [],
      },
    ]);
  }, []);

  const handleRemoveStage = (stageIndex: number) => {
    setStages((state) => state.filter((stage, i) => i !== stageIndex));
    form.setFieldsValue({
      ['stage_desc' + stageIndex]: '',
      ['interviewer_name' + stageIndex]: '',
      ['interviewer_role' + stageIndex]: '',
      ['interview_goal' + stageIndex]: '',
      ['interview_duration' + stageIndex]: '',
      ['interview_location' + stageIndex]: '',
      ['candidate_preparation' + stageIndex]: '',
    });
  };

  const handleChangeStageField = (
    stageIndex: number,
    field: string,
    value: any,
  ) => {
    let objectValue = value;
    if (field === 'stage') {
      objectValue = interviewStages.find((s) => s.id === value)?.id || '';
    }
    setStages((state) =>
      state.map((stage, i) => {
        if (i === stageIndex) {
          return {
            ...state[i],
            [field]: objectValue,
          };
        }
        return {
          ...state[i],
        };
      }),
    );
  };

  const handleFieldsChange = (changed: any, all: any) => {
    if (stepProgress === 100) {
      let res: boolean[] = [];
      all.forEach((data: any) => {
        if (!data.name[0].startsWith('task')) {
          res.push(!!data.value);
        }
      });
      const count = res.filter(Boolean).length;
      if (count < 13) {
        setCurrentProgress(0);
      } else {
        setCurrentProgress(100);
      }
      setBlockTransition(false);
    } else {
      setBlockTransition(true);
    }
  };

  const isPublished = job?.status === 'published';
  const isProcessDisabled = form.getFieldValue('has_active_interview') === true;

  return (
    <>
      <RouterBlocker isBlocked={blockTransition} />
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="job-form"
        form={form}
        onFieldsChange={handleFieldsChange}
      >
        <FormTitle text={t('INTERVIEW_PROCESS')} />
        <FormItem
          label={t('HOW_PROCESS_LOOK_LIKE')}
          name="description"
          tooltip={
            t('JOB_PROCESS_FORM_DESCRIPTION_TOOLTIP')
              ? {
                title: t('JOB_PROCESS_FORM_DESCRIPTION_TOOLTIP'),
                icon: <InfoIcon />,
              }
              : false
          }
        //rules={requiredRule(t)}
        >
          <Textarea autoSize={{ minRows: 2 }} />
        </FormItem>
        <FormItem className="no-margin">
          <FormSubtitle>{t('STAGES_TITLE')}</FormSubtitle>
          <p>{t('STAGES_DESCRIPTION')}</p>
        </FormItem>
        {stages.map((stage, stageIndex) => {
          return (
            <JobStageContainer>
              {!isProcessDisabled && (
                <RemoveStageButton
                  type="ghost"
                  size={'small'}
                  danger
                  icon={<DeleteBaseIcon />}
                  onClick={() => handleRemoveStage(stageIndex)}
                >
                  {t('DELETE_STAGE')}
                </RemoveStageButton>
              )}
              <FormItem className="no-margin">
                <Row gutter={16} justify="space-between">
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <FormItem
                      label={t('STAGE_TITLE')}
                      //rules={requiredRule(t)}
                      tooltip={
                        t('JOB_PROCESS_FORM_STAGE_TOOLTIP')
                          ? {
                            title: t('JOB_PROCESS_FORM_STAGE_TOOLTIP'),
                            icon: <InfoIcon />,
                          }
                          : false
                      }
                    >
                      <SelectInput
                        size="large"
                        filterOption={true}
                        defaultActiveFirstOption={true}
                        suffixIcon={
                          <CaretDownOutlined style={{ color: '#000' }} />
                        }
                        onChange={(e) =>
                          handleChangeStageField(stageIndex, 'stage', e)
                        }
                        value={stage.stage}
                      /*disabled={isProcessDisabled}*/
                      >
                        {interviewStages
                          .filter((interviewStage) => {
                            if (interviewStage.id !== stage.stage) {
                              return (
                                stages
                                  .map((s) => s.stage)
                                  .indexOf(interviewStage.id) === -1
                              );
                            }
                            return true;
                          })
                          .map((interviewStage) => (
                            <Option
                              key={interviewStage.id}
                              value={interviewStage.id}
                            >
                              {interviewStage.name}
                            </Option>
                          ))}
                      </SelectInput>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>

              <FormItem className="no-margin">
                <Row gutter={16} justify="space-between">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                  >
                    <FormItem
                      label={t('INTERVIEWER_NAME_LABEL')}
                      name={'interviewer_name' + stageIndex}
                      // rules={requiredRule(t)}
                      tooltip={
                        t('JOB_PROCESS_FORM_STAGE_INTERVIEWER_NAME_TOOLTIP')
                          ? {
                            title: t(
                              'JOB_PROCESS_FORM_STAGE_INTERVIEWER_NAME_TOOLTIP',
                            ),
                            icon: <InfoIcon />,
                          }
                          : false
                      }
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          handleChangeStageField(
                            stageIndex,
                            'interviewer_name',
                            e.target.value,
                          )
                        }
                      />
                    </FormItem>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                  >
                    <FormItem
                      label={t('INTERVIEWER_ROLE_LABEL')}
                      name={'interviewer_role' + stageIndex}
                      // rules={requiredRule(t)}
                      tooltip={
                        t('JOB_PROCESS_FORM_STAGE_INTERVIEWER_ROLE_TOOLTIP')
                          ? {
                            title: t(
                              'JOB_PROCESS_FORM_STAGE_INTERVIEWER_ROLE_TOOLTIP',
                            ),
                            icon: <InfoIcon />,
                          }
                          : false
                      }
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          handleChangeStageField(
                            stageIndex,
                            'interviewer_role',
                            e.target.value,
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>

              <FormItem
                label={t('DESCRIPTION')}
                name={'stage_desc' + stageIndex}
                // rules={requiredRule(t)}
                tooltip={
                  t('JOB_PROCESS_FORM_STAGE_DESC_TOOLTIP')
                    ? {
                      title: t('JOB_PROCESS_FORM_STAGE_DESC_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input
                  size="large"
                  onChange={(e) =>
                    handleChangeStageField(
                      stageIndex,
                      'description',
                      e.target.value,
                    )
                  }
                />
              </FormItem>

              <FormItem
                label={t('INTERVIEW_GOAL_LABEL')}
                name={'interview_goal' + stageIndex}
                // rules={requiredRule(t)}
                tooltip={
                  t('JOB_PROCESS_FORM_STAGE_INTERVIEW_GOAL_TOOLTIP')
                    ? {
                      title: t(
                        'JOB_PROCESS_FORM_STAGE_INTERVIEW_GOAL_TOOLTIP',
                      ),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input
                  size="large"
                  onChange={(e) =>
                    handleChangeStageField(
                      stageIndex,
                      'interview_goal',
                      e.target.value,
                    )
                  }
                />
              </FormItem>

              <FormItem className="no-margin">
                <Row gutter={16} justify="space-between">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                  >
                    <FormItem
                      label={t('INTERVIEW_DURATION_LABEL')}
                      name={'interview_duration' + stageIndex}
                      // rules={requiredRule(t)}
                      tooltip={
                        t('JOB_PROCESS_FORM_STAGE_INTERVIEW_DURATION_TOOLTIP')
                          ? {
                            title: t(
                              'JOB_PROCESS_FORM_STAGE_INTERVIEW_DURATION_TOOLTIP',
                            ),
                            icon: <InfoIcon />,
                          }
                          : false
                      }
                    >
                      <Input
                        size="large"
                        onChange={(e) =>
                          handleChangeStageField(
                            stageIndex,
                            'interview_duration',
                            e.target.value,
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>

              <FormItem
                label={t('INTERVIEW_LOCATION_LABEL')}
                name={'interview_location' + stageIndex}
                tooltip={
                  t('JOB_PROCESS_FORM_STAGE_INTERVIEW_LOCATION_TOOLTIP')
                    ? {
                      title: t(
                        'JOB_PROCESS_FORM_STAGE_INTERVIEW_LOCATION_TOOLTIP',
                      ),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input
                  size="large"
                  onChange={(e) =>
                    handleChangeStageField(
                      stageIndex,
                      'interview_location',
                      e.target.value,
                    )
                  }
                />
              </FormItem>

              <FormItem
                label={t('CANDIDATE_PREPARATION_LABEL')}
                name={'candidate_preparation' + stageIndex}
                tooltip={
                  t('JOB_PROCESS_FORM_STAGE_CANDIDATE_PREPARATION_TOOLTIP')
                    ? {
                      title: t(
                        'JOB_PROCESS_FORM_STAGE_CANDIDATE_PREPARATION_TOOLTIP',
                      ),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input
                  size="large"
                  onChange={(e) =>
                    handleChangeStageField(
                      stageIndex,
                      'candidate_preparation',
                      e.target.value,
                    )
                  }
                />
              </FormItem>
            </JobStageContainer>
          );
        })}
        {/* Removed to stop editing of jobs stages. Review if we need to bring this back */}
        {(isAdmin || !isProcessDisabled) && !isPublished && (
          <FormItem>
            <Button
              type="ghost"
              size="large"
              icon={<AddIcon />}
              onClick={handleAddMoreStages}
            >
              {t('ADD_STAGE')}
            </Button>
          </FormItem>
        )}
        <FormItem
          label={t('EXTRA_NOTES_LABEL')}
          name="extra_notes"
          tooltip={
            t('JOB_PROCESS_FORM_EXTRA_NOTES_TOOLTIP')
              ? {
                title: t('JOB_PROCESS_FORM_EXTRA_NOTES_TOOLTIP'),
                icon: <InfoIcon />,
              }
              : false
          }
        >
          <Textarea autoSize={{ minRows: 2 }} />
        </FormItem>
        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('TOTAL_DURATION_LABEL')}
                name="total_duration"
                tooltip={
                  t('JOB_PROCESS_FORM_TOTAL_DURATION_TOOLTIP')
                    ? {
                      title: t('JOB_PROCESS_FORM_TOTAL_DURATION_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <NumberInput
                  size="large"
                  type={'number'}
                /*disabled={isProcessDisabled}*/
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('FEEDBACK_TIME')}
                name="feedback_time"
                //rules={numberRule(t)}
                tooltip={
                  t('JOB_PROCESS_FORM_FEEDBACK_TIME_TOOLTIP')
                    ? {
                      title: t('JOB_PROCESS_FORM_FEEDBACK_TIME_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <NumberInput size="large" type={'number'} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem name="feedback_within_48h" valuePropName="checked">
          <CheckboxInput
          /*disabled={isProcessDisabled}*/
          >
            {t('48H_FEEDBACK_RECRUITER')}
          </CheckboxInput>
        </FormItem>
        <FormItem name="may_contact_within_48h" valuePropName="checked">
          <CheckboxInput
          /*disabled={isProcessDisabled}*/
          >
            {t('CS_48H_CONTACT')}
          </CheckboxInput>
        </FormItem>
        <FormItem name="contact_via_recruiter" valuePropName="checked">
          <CheckboxInput
          /*disabled={isProcessDisabled}*/
          >
            {t('CONTACT_ALWAYS_VIA_RECRUITER')}
          </CheckboxInput>
        </FormItem>
        <FormItem
          name="use_hireport_for_contact_with_recruiter"
          valuePropName="checked"
        >
          <CheckboxInput
          /*disabled={isProcessDisabled}*/
          >
            {t('USE_HIREPORT_MESSAGING')}
          </CheckboxInput>
        </FormItem>
        <FormItem name="recruiter_may_contact_directly" valuePropName="checked">
          <CheckboxInput
          /*disabled={isProcessDisabled}*/
          >
            {t('RECRUITER_MAY_CONTACT_DIRECTLY')}
          </CheckboxInput>
        </FormItem>
        <FormItem name="offer_via_recruiter" valuePropName="checked">
          <CheckboxInput
          /*disabled={isProcessDisabled}*/
          >
            {t('OFFER_VIA_RECRUITER')}
          </CheckboxInput>
        </FormItem>
        <FormItem className="no-margin">
          <FormSubtitle>{t('CONTACT_PERSON_AT_COMPANY')}</FormSubtitle>
        </FormItem>
        <FormItem className="no-margin">
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <FormItem
                label={t('NAME_LABEL')}
                name="contact_name"
                tooltip={
                  t('JOB_PROCESS_FORM_CONTACT_NAME_TOOLTIP')
                    ? {
                      title: t('JOB_PROCESS_FORM_CONTACT_NAME_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input size="large" />
              </FormItem>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <FormItem
                label={t('EMAIL_LABEL')}
                name="contact_email"
                rules={[
                  {
                    type: 'email',
                    message: t('INVALID_EMAIL_ERROR'),
                  },
                ]}
                tooltip={
                  t('JOB_PROCESS_FORM_CONTACT_EMAIL_TOOLTIP')
                    ? {
                      title: t('JOB_PROCESS_FORM_CONTACT_EMAIL_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input size="large" />
              </FormItem>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 8 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              <FormItem
                label={t('PHONE_TEXT')}
                name="contact_phone"
                //rules={phoneNumberRequiredRule(t)}
                tooltip={
                  t('JOB_PROCESS_FORM_CONTACT_PHONE_TOOLTIP')
                    ? {
                      title: t('JOB_PROCESS_FORM_CONTACT_PHONE_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <Input
                  size="large"
                  onChange={(e) => {
                    const regex = /^[+]?\d+$/;
                    if (regex.test(e.target.value)) {
                      form.setFieldsValue({ contact_phone: e.target.value });
                    }
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        {isAdmin && (
          <FormItem name="candidates_pre_screened" valuePropName="checked">
            <CheckboxInput
            /*disabled={isProcessDisabled}*/
            >
              {t('PRE_SCREENED_HIREPORT')}
            </CheckboxInput>
          </FormItem>
        )}
        <FormActions>
          <Button
            type="ghost"
            loading={saveLoading}
            onClick={(e) => {
              form.submit();
              form.validateFields().then((res) => {
                handleSave(e).then(() => {
                  history.push(`${JOBS}/${job?.id}`);
                });
              });
            }}
          >
            {t('SAVE_AND_PREVIEW_DRAFT_TITLE')}
          </Button>

          {/*{disabled ? (
            <Tooltip placement="top" title={t('FILL_ALL')}>
              <Button
                type="primary"
                loading={createLoading}
                className={'disabled'}
              >
                {t('CREATE_JOB')}
              </Button>
            </Tooltip>
          ) : (*/}
          <Button type="primary" loading={createLoading} onClick={handleFinish}>
            {hasPSL ? t('NEXT_TITLE') : t('UPDATE_JOB')}
          </Button>
          {/*)}*/}
        </FormActions>
      </Form>
    </>
  );
};

export default JobProcessForm;
