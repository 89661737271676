import Banner from './Banner';
import Loader from '../../UI/Loader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getRecruiter,
  getRecruiterCompanyAgreement,
} from '../../api/recruiter';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import SectionItem from '../../components/jobs/singleJob/SectionItem';
import DescriptionSection from '../../components/jobs/singleJob/DescriptionSection';
import { useTranslation } from 'react-i18next';
import { Space, List } from 'antd';
import { ReactComponent as LocationIcon } from '../../icons/info-location.svg';
import { ReactComponent as EmailIcon } from '../../icons/info-email.svg';
import { ReactComponent as PhoneIcon } from '../../icons/info-phone.svg';
import { ReactComponent as StarIcon } from '../../icons/start-rating.svg';
import { ReactComponent as EmptyStarIcon } from '../../icons/star-empty.svg';
import { CandidateInfo, CompanyAgreement, Specialization } from '../../types';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import {
  CandidateActionContainer,
  CandidateInfoContainer,
  CandidateName,
  CandidateNameContainer,
  CandidatePosition,
  EmptyText,
} from '../../components/recruiter/Dashboard/styles';
import NameAvatar from '../../UI/NameAvatar';
import {
  HISTORY_PARAM,
  INTERVIEW_ID_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  SHOW_FEEDBACK_PARAM,
  SHOW_OFFER_PARAM,
  STAGE_PARAM,
} from '../../constants/queryParams';
import { CANDIDATES, DASHBOARD } from '../../constants/routes';
import { OFFERED } from '../../constants/statuses';
import { useHistory } from 'react-router';
import { getCandidateList } from '../../api/candidates';
import SalaryText from '../../components/candidates/SalaryText';
import { addDecimalPoints } from '../../utils/candidate';
import Button from '../../UI/buttons/Button';
import { getSpecializationList } from '../../api/specialization';
import { ReactComponent as EmptyGreyStarIcon } from '../../icons/star-empty-grey.svg';
import { ADMIN_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import { useAppSelector } from '../../store/hooks';
import RecruiterEditAgreement from '../../components/recruitment_management/RecruiterEditAgreement';
import useHirePortContext from '../../hooks/useHirePortContext';
import useHirePortTheme from '../../hooks/useHirePortTheme';
import useCurrency from '../../hooks/useCurrency';

const CandidatesListItem = styled.li`
  display: grid;
  grid-template-columns: 200px 150px 200px 200px 100px;
  column-gap: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #dae5ec;
  position: relative;
  cursor: pointer;
`;

export const RecruiterProfileBody = styled.div`
  margin: -4rem auto 0;
  padding-bottom: 5rem;
  max-width: 1140px;
  width: 100%;
`;

interface UrlParams {
  recruiterId: string;
  recruiterCompanyAgreementId: string;
}

interface Ratings {
  subject?: string | null;
  rating?: number | null;
}

interface Badge {
  name?: string | null;
  icon?: string | null;
}

const SpecItem = styled.div`
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lozengeColor};
  font-size: 0.75rem;
  line-height: 1.33;
  color: #4b6276;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  &.light {
    opacity: 0.5;
  }
`;

export interface RecruiterProfile {
  company: { name: string; id: string };
  city: { name: string; id: string };
  recruiter_type: string;
  description: string;
  about_company: string;
  preferable_specializations: string;
  email: string;
  phone: string;
  onboarding_status: string;
  id: string;
  image: string;
  name: string;
  title: string;
  ratings?: Ratings[] | null;
  badges?: Badge[] | null;
  total_ratings: number;
}

interface Score {
  title?: string | null;
  stars?: any[];
}

const allowedSubjects = ['Overall', 'Accuracy', 'Speed', 'Communication'];
const RecruiterPublicProfile = () => {
  const scoreData = [
    {
      title: 'Overall',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
    {
      title: 'Accuracy',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
    {
      title: 'Speed',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
    {
      title: 'Communication',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
  ];

  const { recruiterId, recruiterCompanyAgreementId }: UrlParams = useParams();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const [specializationsLabels, setSpecializationsLabels] = useState<string[]>(
    [],
  );
  const [recruiterAgreement, setRecruiterAgreement] =
    useState<CompanyAgreement>();
  const [recruiter, setRecruiter] = useState<RecruiterProfile | null>(null);
  const [candidates, setCandidates] = useState<CandidateInfo[]>([]);
  const [scores, setScores] = useState<Score[]>(scoreData);
  const [candidatesHistory, setCandidatesHistory] = useState<CandidateInfo[]>(
    [],
  );
  const [t] = useTranslation();
  const defaultCurrency = useCurrency();
  const theme = useHirePortTheme();
  const history = useHistory();
  const { renderCustom: { recruiterPublicProfile: customRender } } = useHirePortContext();
  const { user } = useAppSelector((state) => state.user);
  const isRecruiter = user?.role === RECRUITER_ROLE;
  const isAdmin = user?.role === ADMIN_ROLE;
  const isClient = user?.role === 'client';

  const handleCandidateClick = (candidate: CandidateInfo) => {
    let interview = candidate.interviews[0];
    if (isAdmin) {
      history.push(
        CANDIDATES +
        `?filter=in-process&${HISTORY_PARAM}=` +
        candidate.id +
        `&${INTERVIEW_ID_PARAM}=` +
        interview.id,
      );
      return;
    }
    if (interview.status !== 'offered') {
      const task = interview.current_stage?.tasks[0];
      if (task && task.task_type === 'feedback') {
        // show feedback modal
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, interview.id);
        query.set(STAGE_PARAM, interview.current_stage.stage_name);
        query.set(SHOW_FEEDBACK_PARAM, 'true');

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (task && task.is_planning_task) {
        // show proposed date modal
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, interview.id);
        query.set(STAGE_PARAM, interview.current_stage.stage_name);
        query.set(SHOW_DATE_PROPOSE_PARAM, 'true');

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (task && task.task_type === 'move_next_stage') {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, interview.id);
        query.set(STAGE_PARAM, interview.current_stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (
        task &&
        (task.is_decision_task || task.is_upload_document_task)
      ) {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, interview.id);
        query.set(STAGE_PARAM, interview.current_stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else {
        // show candidate info
        history.push(
          CANDIDATES +
          `?filter=in-process&${HISTORY_PARAM}=` +
          candidate.id +
          `&${INTERVIEW_ID_PARAM}=` +
          interview.id,
        );
      }
    } else if (interview.status === 'offered') {
      // show offer modal
      const query = new URLSearchParams();
      query.set(INTERVIEW_ID_PARAM, interview.id);
      query.set(STAGE_PARAM, OFFERED);
      query.set(SHOW_OFFER_PARAM, interview?.candidate?.id);

      history.push(CANDIDATES + '?filter=in-process&' + query.toString());
    }
  };

  const sortCandidatesByFirstInterview = (
    candidates: CandidateInfo[],
  ): CandidateInfo[] => {
    const stageOrder = {
      hired: 0,
      offered: 1,
      '5th_interview': 2,
      '4th_interview': 3,
      '3rd_interview': 4,
      '2nd_interview': 5,
      '1st_interview': 6,
      introduced: 7,
    };

    candidates.sort((a, b) => {
      const aInterview = a.interviews[0];
      const bInterview = b.interviews[0];

      if (aInterview.status.toLowerCase() === 'hired') {
        return -1;
      } else if (bInterview.status.toLowerCase() === 'hired') {
        return 1;
      } else if (
        aInterview.status.toLowerCase() === 'rejected' &&
        bInterview.status.toLowerCase() === 'rejected'
      ) {
        const aStage = aInterview.current_stage?.stage_name
          .toLowerCase()
          .split(' ')
          .join('_');
        const bStage = bInterview.current_stage?.stage_name
          .toLowerCase()
          .split(' ')
          .join('_');

        // @ts-ignore
        if (stageOrder[aStage] < stageOrder[bStage]) {
          return -1;
          // @ts-ignore
        } else if (stageOrder[aStage] > stageOrder[bStage]) {
          return 1;
        } else {
          return 0;
        }
      } else if (aInterview.status.toLowerCase() === 'rejected') {
        return 1;
      } else {
        return -1;
      }
    });

    return candidates;
  };

  useEffect(() => {
    if (isRecruiter && user?.id !== recruiterId) {
      history.push(DASHBOARD);
    }
  }, [recruiterId, user, isRecruiter, history]);

  useEffect(() => {
    getRecruiter(recruiterId)
      .then((res) => {
        setRecruiter(res.data);
        let currentScores = res.data.ratings ?? [];
        currentScores = currentScores.filter((score: any) =>
          allowedSubjects.includes(score.subject),
        );
        currentScores = currentScores.map((score: any) => {
          if (score && score.subject === 'Overall') {
            score.rating = res.data.total_ratings ? res.data.total_ratings : 0;
          }
          return score;
        });
        if (currentScores && currentScores.length > 0) {
          let formattedScores = [];
          for (let score in currentScores) {
            let scoreValue = currentScores[score].rating.toFixed(0);
            let stars: any = new Array(5);
            stars.fill(<StarIcon />, 0);
            if (scoreValue < 5) {
              stars.fill(<EmptyStarIcon />, scoreValue);
            }
            scores.splice(
              scores.findIndex(
                (el) => el.title === currentScores[score].subject,
              ),
              1,
            );
            formattedScores.push({
              title: currentScores[score].subject,
              stars: stars,
            });
          }
          setScores([...formattedScores, ...scores]);
        }
        const prefSpec = res.data.preferable_specializations;

        getSpecializationList()
          .then((res) => {
            setSpecializations(res.data);
            return res;
          })
          .then((res) => {
            if (prefSpec && prefSpec.length) {
              // @ts-ignore
              let specLabels = res.data
                .map((spec: Specialization) => {
                  return prefSpec.includes(spec.id) ? spec.name : null;
                })
                .filter(Boolean);
              // @ts-ignore
              setSpecializationsLabels(specLabels);
            }
          });

        return res;
      })
      .then((res) => {
        let query = 'in-process';
        /*if (isAdmin) {
                query += '?recruiter_name=' + res.data.name.split(' ')[0];
            }*/
        getCandidateList(query)
          .then((res) => {
            let filteredCandidates = res.data.results.filter(
              (candidate: any) =>
                candidate.interviews[0].status !== 'rejected' &&
                candidate.interviews[0].status !== 'hired' &&
                candidate.interviews[0].recruiter.id === recruiterId,
            );
            setCandidates(filteredCandidates);

            return res;
          })
          .then((res) => {
            let filteredHistory = res.data.results.filter((candidate: any) => {
              const mainInterview = candidate.interviews[0];
              let isRecruiterCandidate =
                mainInterview?.recruiter.id === recruiterId;
              let isHired = mainInterview?.status === 'hired';
              let isRejected = mainInterview?.status === 'rejected';
              // @ts-ignore
              return isRecruiterCandidate && (isHired || isRejected);
            });
            filteredHistory = sortCandidatesByFirstInterview(filteredHistory);
            setCandidatesHistory(filteredHistory);
          });
      });
    setLoading(false);
    // eslint-disable-next-line
  }, [recruiterId]);

  useEffect(() => {
    if (recruiterCompanyAgreementId) {
      getRecruiterCompanyAgreement(recruiterCompanyAgreementId)
        .then((res) => {
          setRecruiterAgreement(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [recruiterCompanyAgreementId]);

  const contactData = [
    {
      title: recruiter?.city?.name,
      icon: <LocationIcon />,
    },
    {
      title: recruiter?.email,
      icon: <EmailIcon />,
    },
    {
      title: recruiter?.phone,
      icon: <PhoneIcon />,
    },
  ];

  return (
    <div style={{ backgroundColor: theme.lozengeColor }}>
      <Loader spinning={loading}>
        <Banner loading={loading} recruiter={recruiter} />
        <RecruiterProfileBody>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row gutter={[24, 24]}>
              <Col xs={{ span: 24 }} md={{ span: 16 }} xl={{ span: 16 }}>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: 'flex' }}
                >
                  {isClient &&
                    recruiterCompanyAgreementId &&
                    recruiterAgreement && (
                      <RecruiterEditAgreement
                        recruiterCompanyAgreementId={
                          recruiterCompanyAgreementId
                        }
                        recruiterAgreement={recruiterAgreement}
                      />
                    )}
                  <SectionItem title={t('ABOUT_RECRUITER')}>
                    <DescriptionSection
                      text={
                        recruiter && recruiter?.description?.length > 0
                          ? recruiter?.description
                          : t('MORE_INFO_SOON')
                      }
                    ></DescriptionSection>
                  </SectionItem>

                  <SectionItem title={t('ABOUT_AGENCY')} name="about_agency">
                    <DescriptionSection
                      text={
                        recruiter && recruiter?.about_company?.length > 0
                          ? recruiter?.about_company
                          : t('MORE_INFO_SOON')
                      }
                    ></DescriptionSection>
                  </SectionItem>
                </Space>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: 'flex' }}
                >
                  <SectionItem>
                    <List
                      itemLayout="horizontal"
                      dataSource={contactData}
                      bordered={false}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={item.icon}
                            title={item.title}
                          />
                        </List.Item>
                      )}
                    />
                  </SectionItem>

                  <SectionItem title={t('KEY_METRICS')}>
                    {scores.length > 0 && (
                      <List
                        itemLayout="horizontal"
                        dataSource={scores}
                        bordered={false}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta title={item.title} />
                            <div>{item?.stars?.map((i) => i)}</div>
                          </List.Item>
                        )}
                      />
                    )}
                  </SectionItem>

                  {specializationsLabels.length > 0 && (
                    <SectionItem title={t('SPECIALIZATIONS')}>
                      {specializationsLabels.length > 0 &&
                        specializationsLabels.map((label) => (
                          <SpecItem>{label}</SpecItem>
                        ))}
                    </SectionItem>
                  )}
                </Space>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }}>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: 'flex' }}
                >
                  {candidates?.length > 0 && !customRender?.hideCandidateList && (
                    <SectionItem title={t('ACTIVE_INTERVIEW')}>
                      {candidates?.length === 0 && (
                        <EmptyText>{t('NO_CANDIDATE_YET')}</EmptyText>
                      )}
                      {candidates?.length > 0 &&
                        candidates?.map((candidate) => {
                          const { curr } = defaultCurrency.changeCurrency(candidate.compensation_currency);
                          return (
                            <CandidatesListItem
                              onClick={() => handleCandidateClick(candidate)}
                              key={candidate.id}
                            >
                              <CandidateNameContainer>
                                <NameAvatar
                                  name={candidate.name}
                                  color={{
                                    main: '#7949cd',
                                    light: 'rgba(121, 73, 205, 0.1)',
                                  }}
                                />
                                <CandidateName>{candidate.name}</CandidateName>
                              </CandidateNameContainer>
                              <CandidateInfoContainer>
                                {/*<CandidateCompanyName>
                                                        {interview.job.company.name}
                                                    </CandidateCompanyName>*/}
                                <CandidatePosition>
                                  {/* {interview.job.title}*/}
                                  {(candidate.interviews[0].current_stage
                                    ?.stage_name ||
                                    candidate.interviews[0].status) &&
                                    (candidate.interviews[0].status ===
                                      'offered' ||
                                      candidate.interviews[0].status ===
                                      'on hold' ? (
                                      <>{candidate.interviews[0].status}</>
                                    ) : (
                                      <>
                                        {candidate.interviews[0].current_stage
                                          ?.stage_name
                                          ? candidate.interviews[0].current_stage
                                            ?.stage_name
                                          : candidate.interviews[0].status}
                                      </>
                                    ))}
                                </CandidatePosition>
                              </CandidateInfoContainer>

                              <CandidateActionContainer>
                                <SalaryText>
                                  {curr(addDecimalPoints(
                                    candidate.salary.toString(),
                                  ))}{' '}
                                  /{' '}
                                  {`${curr(candidate.salary &&
                                    addDecimalPoints(
                                      Math.round(
                                        candidate.salary * 12.96 +
                                        (candidate.bonus
                                          ? 12 * candidate.bonus
                                          : 0),
                                      ).toString(),
                                    )
                                  )} ${t('PER_YEAR')}`}
                                </SalaryText>
                              </CandidateActionContainer>

                              <CandidateActionContainer>
                                {`${candidate.current_role} at ${candidate.current_company}`}
                              </CandidateActionContainer>

                              <CandidateActionContainer>
                                <Button
                                  size="small"
                                  type="primary"
                                  onClick={() => handleCandidateClick(candidate)}
                                >
                                  {t('REVIEW')}
                                  <ArrowRightIcon
                                    style={{
                                      marginRight: 0,
                                      marginLeft: '.5rem',
                                    }}
                                  />
                                </Button>
                              </CandidateActionContainer>

                              {/*<CandidateActionIcon src={chevronRightIcon} />*/}
                            </CandidatesListItem>
                          )
                        })}
                    </SectionItem>
                  )}
                </Space>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }}>
                {candidatesHistory?.length > 0 && (
                  <SectionItem title={t('INTERVIEW_HISTORY')}>
                    {candidatesHistory?.length === 0 && (
                      <EmptyText>{t('NO_CANDIDATE_YET')}</EmptyText>
                    )}
                    {candidatesHistory?.length > 0 &&
                      candidatesHistory?.map((candidate) => {
                        const { curr } = defaultCurrency.changeCurrency(candidate.compensation_currency);
                        return (
                          <CandidatesListItem key={candidate.id}>
                            <CandidateNameContainer>
                              <NameAvatar
                                name={candidate.name}
                                color={{
                                  main: '#7949cd',
                                  light: 'rgba(121, 73, 205, 0.1)',
                                }}
                              />
                              <CandidateName>{candidate.name}</CandidateName>
                            </CandidateNameContainer>
                            <CandidateInfoContainer>
                              <CandidatePosition>
                                {(candidate.interviews[0]?.current_stage
                                  ?.stage_name ||
                                  candidate.interviews[0]?.status) && (
                                    <>
                                      {candidate.interviews[0]?.current_offer &&
                                        candidate.interviews[0]?.status !== 'hired'
                                        ? 'offered'
                                        : candidate.interviews[0]?.current_stage
                                          ?.stage_name
                                          ? candidate.interviews[0]?.current_stage
                                            ?.stage_name
                                          : candidate.interviews[0].status}
                                    </>
                                  )}
                              </CandidatePosition>
                            </CandidateInfoContainer>

                            <CandidateActionContainer>
                              <SalaryText>
                                {curr(addDecimalPoints(
                                  candidate.salary.toString(),
                                ))}{' '}
                                /{' '}
                                {`${curr(candidate.salary &&
                                  addDecimalPoints(
                                    Math.round(
                                      candidate.salary * 12.96 +
                                      (candidate.bonus
                                        ? 12 * candidate.bonus
                                        : 0),
                                    ).toString(),
                                  )
                                )} ${t('PER_YEAR')}`}
                              </SalaryText>
                            </CandidateActionContainer>

                            <CandidateActionContainer>
                              {`${candidate.current_role} at ${candidate.current_company}`}
                            </CandidateActionContainer>
                          </CandidatesListItem>
                        )
                      })}
                  </SectionItem>
                )}
              </Col>
            </Row>
          </Space>
        </RecruiterProfileBody>
      </Loader>
    </div>
  );
};

export default RecruiterPublicProfile;
