import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { JobActivity } from "../../../types";
import { Dropdown, Menu, Button } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { format } from "date-fns";
interface ActivityFeedProps {
  activityFeed?: JobActivity[] | null
}

const ActivityFeedWrapper = styled.div`
    margin-bottom: 2rem;
    & .ant-btn.ant-dropdown-trigger {
      height: 24px;
      width: 145px;
      padding: 0 8px;
      border-radius: 4px;
      background-color: #fff;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #627d95;
    }
`

const MenuWrapper = styled.div`
  max-width: 560px;
  & .ant-menu.ant-menu-root {
    border-radius: ${({ theme }) => theme.radius};
  }
  & .activity-date {
    padding-top: 3px;
    display: inline-block;
    min-width: 85px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #627d95;
    margin-right: 16px;
    &.ant-menu-title-content {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-menu-item {
    white-space: normal;
    height: auto;
    line-height: 20px;
    margin-bottom: 16px;
    & .ant-menu-title-content {
      display: flex;
    }
  }
`

const JobActivityFeed = ({ activityFeed, ...rest }: ActivityFeedProps) => {
  const [t] = useTranslation();

  const menu = (
    <MenuWrapper>
      <Menu>
        {(!activityFeed || activityFeed?.length === 0) && (
          <Menu.Item>
            {t('EMPTY_JOB_ACTIVITY_FEED')}
          </Menu.Item>
        )}
        {activityFeed && activityFeed.length && activityFeed?.map((feedItem) => (
          <Menu.Item><span className={'activity-date'}>{format(new Date(feedItem.updated_at), 'MMMM d, yyyy')}</span> <span>{feedItem.text}</span></Menu.Item>
        ))
        }
      </Menu>
    </MenuWrapper>
  );

  return (
    <ActivityFeedWrapper>
      <Dropdown trigger={['click']} overlay={menu}>
        <Button>
          {t('JOB_ACTIVITY_FEED')}
          <CaretDownFilled />
        </Button>
      </Dropdown>
    </ActivityFeedWrapper>
  );
};

export default JobActivityFeed;
