import React from 'react';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AtsJobSettingsForm from '../../../components/settings/atsSettings/AtsJobSettingsForm';
import SmallBackButton from '../../../UI/buttons/SmallBackButton';
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';

const PageLayout = styled.div`
  padding: 0 2rem 0 2rem;
  max-width: 1200px;
`;

const ChromiumAtsJobSettingsPage = () => {
  const [t] = useTranslation();
  const history = useHistoryWithUrlBase();
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <SmallBackButton size="middle" onClick={() => { history.push(`/assign-recruiters?local_id=${id}`) }} />
      <PageLayout>
        {(
          id && <AtsJobSettingsForm
            id={id}
            allowDisconnect={false}
            onFinished={() => {
              message.success(t('INFORMATION_SAVE_SUCCESS'), 10);
            }}
          />
        ) || null
        }
      </PageLayout>
    </>
  )
};

export default ChromiumAtsJobSettingsPage;
