const HIREPORT_IFRAME_MESSAGE_TYPE = "HirePortIFrameMessage";

// !TODO: review this - the targetOrigin parameter specifies which domains are allowed to receive messages from this page
// when it's rendered in an iframe. This is essentially the list of ATSes (and their sandbox alternatives)
// which are supported. For now I'm setting to allow posting messages to any origin, but this is not maximally
// secure.
const TARGET_ORIGIN = "*";

export const postMessage = (payload: unknown) => {
  window.parent.postMessage({ type: HIREPORT_IFRAME_MESSAGE_TYPE, payload }, TARGET_ORIGIN);
};
