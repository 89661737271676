import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import FormTitle from '../../auth/FormTitle';
import FormItem from '../FormItem';
import { Form, Col, Row, Select } from 'antd';
import Button from '../../../UI/buttons/Button';
import Input from '../../../UI/inputs/Input';
import { FormActions } from '../JobInfoForm/styles';
import styled from 'styled-components';
import Textarea from '../../../UI/inputs/Textarea';
import { ATS_JOB_FILTER_OPEN_JOBS, AtsStatus, JobRef, getAtsStatus } from '../../../api/integrations';
import SelectInput from '../../../UI/inputs/Select';
import { CaretDownOutlined } from '@ant-design/icons';
import RadioInput from '../../../UI/inputs/RadioInput';
import { ReactComponent as CheckIcon } from '../../../icons/check-icon-brand-inverted.svg';
import { ReactComponent as CrossIcon } from '../../../icons/icon-close-danger-inverted.svg';
import axios from 'axios';
import { useAppSelector } from '../../../store/hooks';
import { Link, useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';

const AI_ENDPOINT =
  'https://hook.eu1.make.com/9g1r22ffgqf7q1vsged6u6xulk4kk14g';

type AiCreationType = 'LINKEDIN' | 'ATS' | 'JOB_DESCRIPTION';
type CreationType = AiCreationType | 'MANUAL';

type WizardState = {
  aiSelected: CreationType | null;
  aiInProgress: boolean;
  completed: boolean;
  inProgressItems: {
    label: string;
    completed: boolean;
    inProgress: boolean;
    error?: string;
  }[];
  atsState: AtsStatus['state'] | null;
  atsJobs: JobRef[];
  createdJobId: string | null;
};

type ButtonClickedAction = {
  type: 'BUTTON_CLICKED';
  button: CreationType;
};

type AiCancelClickedAction = {
  type: 'AI_CANCEL_CLICKED';
};

type AiStepCompletedAction = {
  type: 'AI_SINGLE_STEP_COMPLETED';
};

type AiFinishedAction = {
  type: 'AI_FINISHED';
  result: { 'job-id': string };
};

type AiErroredAction = {
  type: 'AI_ERRORED';
  error: unknown;
};

type AiCreateClickedAction = {
  type: 'AI_CREATE_CLICKED';
};

type AtsStatusUpdateAction = {
  type: 'ATS_STATUS_UPDATE';
  atsStatus: AtsStatus;
};

type WizardStateAction =
  | ButtonClickedAction
  | AiCancelClickedAction
  | AiCreateClickedAction
  | AiStepCompletedAction
  | AiFinishedAction
  | AiErroredAction
  | AtsStatusUpdateAction;

const inProgressItems: Record<
  AiCreationType,
  [string, string, string, string]
> = {
  ATS: [
    'Contacting ATS',
    'Reading the job data',
    'Generating HirePort job data',
    'Creating job in HirePort',
  ],
  JOB_DESCRIPTION: [
    'Reading text input',
    'Processing text',
    'Generating job data',
    'Creating job in HirePort',
  ],
  LINKEDIN: [
    'Checking your URL',
    'Scraping data',
    'Generating job data',
    'Creating job in HirePort',
  ],
};

const reducer: (
  currentState: WizardState,
  action: WizardStateAction,
) => WizardState = (currentState, action) => {
  switch (action.type) {
    case 'BUTTON_CLICKED':
      return {
        ...currentState,
        aiSelected: action.button,
      };
    case 'AI_CANCEL_CLICKED':
      return {
        ...currentState,
        aiSelected: null,
      };
    case 'AI_CREATE_CLICKED':
      if (!currentState.aiSelected) return currentState;
      const items =
        inProgressItems[currentState.aiSelected as AiCreationType] || [];
      return {
        ...currentState,
        aiInProgress: items.length > 0,
        inProgressItems: items.map((label, index) => ({
          completed: false,
          inProgress: index === 0,
          label,
        })),
      };
    case 'AI_SINGLE_STEP_COMPLETED': {
      const inProgressIndex = currentState.inProgressItems.findIndex(
        (item) => item.inProgress,
      );
      if (inProgressIndex < 0)
        return {
          ...currentState,
          completed: true,
        };
      return {
        ...currentState,
        inProgressItems: currentState.inProgressItems.map((item, index) => ({
          ...item,
          completed: index <= inProgressIndex,
          inProgress: index === inProgressIndex + 1,
        })),
      };
    }
    case 'AI_FINISHED':
      return {
        ...currentState,
        inProgressItems: currentState.inProgressItems.map((item) => ({
          ...item,
          completed: true,
          inProgress: false,
        })),
        createdJobId: action.result['job-id'] || null,
      };
    case 'AI_ERRORED': {
      const inProgressIndex = currentState.inProgressItems.findIndex(
        (item) => item.inProgress,
      );
      return {
        ...currentState,
        inProgressItems: currentState.inProgressItems.map((item, index) => ({
          ...item,
          error: index === inProgressIndex ? String(action.error) : undefined,
          inProgress: false,
        })),
      };
    }
    case 'ATS_STATUS_UPDATE':
      return {
        ...currentState,
        atsState: action.atsStatus.state,
        atsJobs: action.atsStatus.ats_jobs.filter((ats_job) => ats_job.url),
      };
  }
};

export const WizardSubTitle = styled.h2`
  margin-top: 2rem;
  font-weight: bold;
`;

const BigButton = styled(Button)`
  width: 100%;
  margin: 0;
  padding: 1.3rem 2rem !important;
  border: solid 1px #dae5ec !important;
  &:disabled > .ant-radio-wrapper {
    color: white;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
`;

const WizardFormItem = styled(FormItem)`
  & > .ant-form-item-label > label {
    font-size: 0.7rem;
  }
`;

const ProgressRow = styled(Row)`
  fontsize: 16px;
  height: 40px;
  margin-bottom: 0.2rem;
`;

const ProgressCheckIcon = styled(CheckIcon)`
  display: block;
  margin: auto;
`;

const ProgressCrossIcon = styled(CrossIcon)`
  display: block;
  margin: auto;
`;

const ProgressLabel = styled.span`
  &.errored {
    color: red;
  }
`;

const ProgressInProgressIcon = () => (
  <img
    alt="Step in progress"
    style={{ width: '40px' }}
    src="/images/hireport-waiting2.gif"
  />
);

const { Option } = Select;

type JobCreationWizardBlockProps = {
  onSelectionChanged: (type: CreationType) => void;
};

const JobCreationWizardBlock = ({
  onSelectionChanged,
}: JobCreationWizardBlockProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, {
    aiSelected: null,
    aiInProgress: false,
    completed: false,
    inProgressItems: [],
    atsState: null,
    atsJobs: [],
    createdJobId: null,
  });

  const user = useAppSelector((state) => state.user.user);

  const handleAiStart = ({
    atsJob,
    jobDescription,
    url,
  }: Record<string, string>) => {
    if (!user) {
      console.error('User was not set');
      return;
    }
    if (!user.company?.id) {
      console.error('Company ID was not found');
      return;
    }
    setTimeout(() => {
      dispatch({ type: 'AI_SINGLE_STEP_COMPLETED' });
    }, 3000);
    setTimeout(() => {
      dispatch({ type: 'AI_SINGLE_STEP_COMPLETED' });
    }, 32000);
    setTimeout(() => {
      dispatch({ type: 'AI_SINGLE_STEP_COMPLETED' });
    }, 61000);
    setTimeout(() => {
      dispatch({ type: 'AI_ERRORED', error: 'timeout' });
    }, 180000);

    const payloadData = (() => {
      switch (state.aiSelected) {
        case 'ATS':
          return state.atsJobs.find(({ id }) => id === atsJob)?.url;
        case 'JOB_DESCRIPTION':
          return jobDescription;
        case 'LINKEDIN':
          return url;
      }
    })();
    const payload = {
      environment: process.env.REACT_ENV_NAME || 'local',
      url: window.location.href || 'unknown',
      'company-id': user.company.id,
      job: payloadData,
      'client-user-id': user.id,
    };
    axios
      .create()
      .post(AI_ENDPOINT, payload)
      .then((result) => {
        dispatch({ type: 'AI_FINISHED', result: result.data });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'AI_ERRORED', error: error.message || error });
      });
    dispatch({ type: 'AI_CREATE_CLICKED' });
  };

  const handleButtonClick = (type: CreationType) => {
    dispatch({ type: 'BUTTON_CLICKED', button: type });
    onSelectionChanged(type);
  };

  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  const fetchAtsOptions = useCallback(async (searchValue: string) => {
    const query = {
      ...ATS_JOB_FILTER_OPEN_JOBS,
      ...(searchValue ? { name_contains: searchValue } : {}),
    };
    const atsStatus = await getAtsStatus(query);
    dispatch({ type: 'ATS_STATUS_UPDATE', atsStatus });
  }, []);
  const debounceFetchAtsJobOptions = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      fetchAtsOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setFetching(false);
      });
    };

    return debounce(loadOptions, 500);
  }, [fetchAtsOptions]);

  useEffect(() => {
    getAtsStatus(ATS_JOB_FILTER_OPEN_JOBS).then((atsStatus) => {
      dispatch({ type: 'ATS_STATUS_UPDATE', atsStatus });
    });
  }, []);

  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="simple-job-form"
      onFinish={handleAiStart}
      initialValues={{
        url: 'https://',
        atsJob: '',
      }}
    >
      <FormTitle text={t('CREATE_NEW_JOB')} marginBottom="16px" />
      <Row>
        <Col xs={{ span: 24 }}>
          <WizardSubTitle>
            {t('How would you like to create your job?')}
          </WizardSubTitle>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={{ span: 12 }}>
          <FormItem noStyle>
            <BigButton
              size="large"
              type="default"
              htmlType="button"
              disabled={state.aiInProgress}
              onClick={() => {
                handleButtonClick('LINKEDIN');
              }}
            >
              <RadioInput checked={state.aiSelected === 'LINKEDIN'}>
                {t('Use a job page or LinkedIn job URL')}
              </RadioInput>
            </BigButton>
          </FormItem>
        </Col>
        <Col xs={{ span: 12 }}>
          <FormItem>
            <BigButton
              type="default"
              htmlType="button"
              disabled={state.aiInProgress}
              onClick={() => {
                handleButtonClick('ATS');
              }}
            >
              <RadioInput checked={state.aiSelected === 'ATS'}>
                {t('Use a job from your ATS')}
              </RadioInput>
            </BigButton>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={{ span: 12 }}>
          <FormItem noStyle>
            <BigButton
              type="default"
              htmlType="button"
              disabled={state.aiInProgress}
              onClick={() => {
                handleButtonClick('JOB_DESCRIPTION');
              }}
            >
              <RadioInput checked={state.aiSelected === 'JOB_DESCRIPTION'}>
                {t('Copy and paste your own job description')}
              </RadioInput>
            </BigButton>
          </FormItem>
        </Col>
        <Col xs={{ span: 12 }}>
          <FormItem>
            <BigButton
              type="default"
              htmlType="button"
              disabled={state.aiInProgress}
              onClick={() => {
                handleButtonClick('MANUAL');
              }}
            >
              <RadioInput checked={state.aiSelected === 'MANUAL'}>
                {t('Start from scratch (manual)')}
              </RadioInput>
            </BigButton>
          </FormItem>
        </Col>
      </Row>

      {!state.aiInProgress && state.aiSelected === 'LINKEDIN' && (
        <>
          <Row>
            <Col xs={{ span: 24 }}>
              <WizardSubTitle>{t('Enter LinkedIn or Job URL')}</WizardSubTitle>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <Paragraph>
                {t(
                  'Just enter the URL of your LinkedIn job page and our super smart AI will do the rest!',
                )}
              </Paragraph>
              <Paragraph>
                {t(
                  'You can also use a link to a page on your careers website.',
                )}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <WizardFormItem
                label={t('URL to job page or LinkedIn')}
                name="url"
                rules={[
                  {
                    required: true,
                    type: 'url',
                    message: t('INVALID_URL_ERROR'),
                  },
                ]}
              >
                <Input size="large" />
              </WizardFormItem>
            </Col>
          </Row>
        </>
      )}

      {!state.aiInProgress && state.aiSelected === 'ATS' && (
        <>
          <Row>
            <Col xs={{ span: 24 }}>
              <WizardSubTitle>{t('Choose an ATS job')}</WizardSubTitle>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              {state.atsState === 'NOT_CONNECTED' && (
                <Paragraph>
                  {t("You haven't connected HirePort to your ATS yet.")}
                  <br />
                  {t('Go to the')}{' '}
                  <Link to="/ats-settings" target="_blank">
                    {t('ATS_SETTINGS_TITLE')}
                  </Link>{' '}
                  {t('page to set up a new connection.')}
                </Paragraph>
              )}
              {state.atsState === 'AWAITING_INITIAL_SYNC' && (
                <Paragraph>
                  {t('Your ATS connection is still being established.')}
                  <br />
                  {t('Go to the')}{' '}
                  <Link to="/ats-settings" target="_blank">
                    {t('ATS_SETTINGS_TITLE')}
                  </Link>{' '}
                  {t('page to check the status of your connection.')}
                </Paragraph>
              )}
              {state.atsState === 'CONNECTED' && (
                <Paragraph>
                  {t(
                    "Select from your list of open ATS jobs below and we'll create you a new HirePort job to match!",
                  )}
                </Paragraph>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={18}>
              <WizardFormItem
                name="atsJob"
                label={t('Jobs in your ATS')}
                rules={[
                  { required: true, message: t('Please select an ATS job') },
                ]}
              >
                <SelectInput
                  showSearch
                  filterOption={false}
                  size="large"
                  onSearch={debounceFetchAtsJobOptions}
                  suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
                >
                  {fetching && <Option value={''}>{t('Loading...')}</Option>}
                  {!fetching && (
                    <Option value={''}>{t('-- Select ATS job --')}</Option>
                  )}
                  {state.atsJobs.map((atsJob) => (
                    <Option key={atsJob.id} value={atsJob.id}>
                      {`${atsJob.name} ${atsJob.location ? `(${atsJob.location})` : ''
                        }`}
                    </Option>
                  ))}
                </SelectInput>
              </WizardFormItem>
              {state.atsState === 'CONNECTED' && state.atsJobs.length <= 0 && (
                <Paragraph>
                  {t(
                    "No ATS jobs found.",
                  )}
                </Paragraph>
              )}
            </Col>
          </Row>
        </>
      )}

      {!state.aiInProgress && state.aiSelected === 'JOB_DESCRIPTION' && (
        <>
          <Row>
            <Col xs={{ span: 24 }}>
              <WizardSubTitle>{t('Enter job description')}</WizardSubTitle>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }}>
              <Paragraph>
                {t(
                  'Type or paste your job description below, and our AI will start turning it into a HirePort job for you!',
                )}
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <WizardFormItem
                name="jobDescription"
                label={t('Enter job description text')}
                rules={[
                  {
                    required: true,
                    message: t('Please enter at least 300 characters.'),
                  },
                  {
                    min: 300,
                    message: t('Please enter at least 300 characters.'),
                  },
                ]}
              >
                <Textarea autoSize={{ minRows: 10 }} />
              </WizardFormItem>
            </Col>
          </Row>
        </>
      )}

      {!state.aiInProgress &&
        state.aiSelected &&
        state.aiSelected !== 'MANUAL' && (
          <FormActions>
            <Button
              type="text"
              onClick={() => {
                dispatch({ type: 'AI_CANCEL_CLICKED' });
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {t('Create my job')}
            </Button>
          </FormActions>
        )}

      {state.aiInProgress && (
        <>
          <WizardSubTitle>{t('Progress')}</WizardSubTitle>
          <Paragraph>
            {t("We've started working on your job import.")}
          </Paragraph>
          <Paragraph>
            {t(
              "This might take a few minutes, so please be patient and don't navigate away from this page.",
            )}
          </Paragraph>
          <Paragraph>
            {t(
              "When the import process is finished, you'll be able to review your job.",
            )}
          </Paragraph>
          {state.inProgressItems.map(
            ({ label, completed, inProgress, error }) => (
              <ProgressRow>
                <Col xs={1}>
                  {completed && <ProgressCheckIcon />}
                  {error && <ProgressCrossIcon />}
                  {inProgress && <ProgressInProgressIcon />}
                </Col>
                <Col xs={16}>
                  <ProgressLabel className={error ? 'errored' : ''}>
                    {t(label)}
                    {error && ` – error: ${error}`}
                  </ProgressLabel>
                </Col>
              </ProgressRow>
            ),
          )}
          <Button
            type="primary"
            htmlType="button"
            disabled={!state.createdJobId}
            onClick={() => {
              history.push(`/jobs/edit/${state.createdJobId}/company_info`);
            }}
          >
            {t('Completed - go to job')}
          </Button>
        </>
      )}
    </Form>
  );
};

export default JobCreationWizardBlock;
