import { TFunction } from 'react-i18next';
import { Job } from '../../../../types';

export const formatJobStatusForClient = ({
  role,
  status,
  t,
  online,
}: {
  role: string;
  status: Job['status'];
  t: TFunction<'translation'>;
  online?: boolean;
}) => {
  if (role !== 'client') return status;

  if (online) {
    return t('JOB_STATUS_ONLINE');
  } else if (online === false) {
    return t('JOB_STATUS_OFFLINE');
  }

  switch (status) {
    case 'published':
    case 'upcoming':
    case 'under review':
      return t('JOB_STATUS_ONLINE');
    default:
      return t('JOB_STATUS_OFFLINE');
  }
};
