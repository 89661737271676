import { useState } from 'react';
import styled from 'styled-components';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { useBeforeunload as useBeforeUnload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import SettingsMenu from '../../components/settings/SettingsMenu';
import AtsJobSettingsForm from '../../components/settings/atsSettings/AtsJobSettingsForm';
import { ATS_SETTINGS } from '../../constants/routes';
import { ReactComponent as ArrowLeftIcon } from '../../icons/arrow-left.svg';

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.lozengeColor};
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-flow: row-reverse;
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

type BackTitleProps = {
  backTo: string
  text: string
}

const BackTitleWrapper = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #061d31;
  margin-bottom: 2.5rem;
  margin-left: -3rem;
  display: flex;
  justify-content: start;
  align-items: center;
  &.mb-4{
    margin-bottom: 2.5rem;
  }
  @media (max-width: 992px) and (min-width: 768px){
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
  & span {
    text-align: right;
    font-size: 0.875rem;
    line-height: 1.71;
    color: #627d95;
    font-weight: 400;
    & a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  &.no-margin{
  margin-bottom: .5rem;
  }
`;

const BackLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  color: black;
  &:hover{
    color: ${({ theme }) => theme.primaryColor};
  }
  transition: all ease .3s;
  & * {
    width: 100%;
    height: 100%;
  };
  margin-right: 0.5rem;
`;

const BackTitle = ({ backTo, text }: BackTitleProps) => {
  return (
    <BackTitleWrapper>
      <BackLink to={backTo}><ArrowLeftIcon /></BackLink>
      {text}
    </BackTitleWrapper>
  );
};

const AtsJobSettingsFormContainer = styled.div`
  margin-top: 2.5rem;
  width: 100%;
`;

const AtsJobSettings = () => {
  const { id } = useParams<{ id: string }>();
  const [t] = useTranslation();
  const [shouldWarnState, setShouldWarnState] = useState(true);
  const [jobTitleState, setJobTitleState] = useState('');
  const history = useHistory();

  const navigationPrompt = shouldWarnState
    ? t('ats.NAVIGATION_PROMPT')
    : ''
  useBeforeUnload(() => navigationPrompt || null);

  return (
    <PageWrapper>
      <FormWrapper>
        <SettingsMenu menu='ats' />
        <AtsJobSettingsFormContainer>
          {id && (
            <>
              <Prompt when={!!navigationPrompt} message={navigationPrompt} />
              <BackTitle backTo={ATS_SETTINGS} text={jobTitleState} />
              <AtsJobSettingsForm
                id={id}
                onShouldWarnChanged={(shouldWarn) => setShouldWarnState(shouldWarn)}
                onJobTitleChanged={(jobTitle) => setJobTitleState(jobTitle)}
                onFinished={() => { history.push(ATS_SETTINGS) }}
              />
            </>
          )}
        </AtsJobSettingsFormContainer>
      </FormWrapper>
    </PageWrapper >
  );
};

export default AtsJobSettings;
