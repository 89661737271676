import React, { useState } from 'react';
import { Job, Language } from '../../../types';
import ProgressBar from '@ramonak/react-progress-bar';
import JobDescription from './JobDescription';
import styled from 'styled-components';
import { ADMIN_ROLE, RECRUITER_ROLE } from '../../../constants/roles';
import JobListInfoItem from './JobListInfoItem';
import { ReactComponent as LocationIcon } from '../../../icons/location.svg';
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg';
import SectionHeading from './SectionHeading';
import TagButton from '../../../UI/buttons/TagButton';
import SpotItem from './SpotItem';
import { useTranslation } from 'react-i18next';
import JobStatistic from './JobStatistic';
import { getCandidateStatistics } from '../../../utils/candidate';
import ActionButton from './ActionButton';
import ListAction from './ListAction';
import { deleteJob, publishJob } from '../../../api/jobs';
import { JOBS } from '../../../constants/routes';
import { Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RemoteIcon } from '../../../icons/remote-days.svg';
import { ReactComponent as VisaIcon } from '../../../icons/visa-sponsorship.svg';
import { workingTypeOptions } from '../../../constants/common';
import { ReactComponent as InfoIcon } from '../../../icons/info-icon-rounded.svg';
import FormItem from '../../form/FormItem';
import UnpublishJobModal from '../UnpublishJobModal';
import useEmploymentTypes from '../../../hooks/useEmploymentTypes';
import getEmploymentTypeSummary from '../../../utils/getEmploymentTypeSummary';
import getRecruiterFeeSummary from '../../../utils/getRecruiterFeeSummary';
import useHirePortTheme from '../../../hooks/useHirePortTheme';
import useCurrency from '../../../hooks/useCurrency';

interface JobProps {
  job: Job;
  languages: Language[];
  setJobs: React.Dispatch<React.SetStateAction<Job[]>>;
  role?: string;
}

const ListItem = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #dae5ec;
  padding: 1.5rem 0 1rem;
  display: flex;
  justify-content: space-between;
  &.disabled {
    & .job-title,
    & p,
    & h5,
    & div {
      color: #aebeca;
    }
  }
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;
const JobInfo = styled.div`
  width: 15%;
  @media (max-width: 992px) {
    width: 20%;
  }
  @media (max-width: 768px) {
    width: 40%;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 1rem;
    order: 3;
  }
`;
const JobSkillsWrapper = styled.div`
  width: 22%;
  @media (max-width: 992px) {
    width: 20%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
    order: 4;
  }
`;
const JobSalaryWrapper = styled.div`
  width: 16%;
  @media (max-width: 992px) {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: center;
    order: 6;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
const JobSkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 105px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const SalaryItem = styled.div`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
`;

const SalaryItemLight = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: #4b6276;
  padding-top: 8px;
`;

const JobSpotWrapper = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  &.top {
    justify-content: flex-start;
  }
  @media (max-width: 992px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    order: 6;
    margin-top: 1rem;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const SpotTag = styled(SpotItem)`
  color: white !important;
  border-radius: 0.25rem;
  margin-top: 0;
  &:first-child {
    margin-top: 2rem;
  }
`;

interface RecruiterPlacementFeeProps {
  job: Job;
}

function RecruiterPlacementFee({ job }: RecruiterPlacementFeeProps) {
  const [t] = useTranslation();
  const currency = useCurrency(job.fee_currency);

  // this checks to see if there is a fee agreement between the company and the recruiter
  const { recruiterFee } = getRecruiterFeeSummary(t, currency, job);

  // if there is a fee agreement, HirePort fees do not apply
  return (
    <>
      <FormItem
        label={t('PLACEMENT_FEE')}
        name="fee"
        style={{ marginBottom: 0, marginTop: '30px' }}
      />
      <SpotTag color="#35ce72">{recruiterFee}</SpotTag>
    </>
  );
}

const { confirm } = Modal;
const RecruiterJobListItem = ({
  job,
  role = RECRUITER_ROLE,
  setJobs,
  languages,
}: JobProps) => {
  const [t] = useTranslation();
  const currency = useCurrency(job.compensation_currency);
  const theme = useHirePortTheme();
  const history = useHistory();
  const [isUnpublishModalOpen, setisUnpublishModalOpen] =
    useState<boolean>(false);
  const [online, setOnline] = useState(
    Boolean(job.status === 'published' || job.status === 'under review'),
  );

  const addToFavorites = (id: string) => {
    // setJobs((prevState) => prevState.filter((job) => job.id !== id));
  };

  const statistics = job.interviews
    ? getCandidateStatistics(job.interviews)
    : { sum: 0, introduced: 0, 'in process': 0, hired: 0, rejected: 0 };

  const onDelete = () => {
    confirm({
      title: `${t('DELETE_POPUP_TITLE')}?`,
      okText: t('CONFIRM_TITLE'),
      okType: 'danger',
      cancelText: t('REJECT_TITLE'),
      cancelButtonProps: {
        type: 'default',
      },
      onOk() {
        deleteJob(job.id).then(() => {
          message.error(t('JOB_IS_DELETED'), 5);
          setJobs &&
            setJobs((st: any) => st.filter((el: any) => el.id !== job.id));
        });
      },
    });
  };

  const editJob = () => {
    history.push(JOBS + `/edit/${job.id}/info`);
  };

  const onPublish = () => {
    publishJob(job.id).then((res) => {
      setJobs &&
        setJobs((st: any) =>
          st.map((el: any) => {
            if (el.id === job.id) {
              return { ...el, status: res.data.status };
            }
            return el;
          }),
        );
    });
  };

  const onUnpublish = () => {
    setisUnpublishModalOpen(true);
  };

  const workingTp = workingTypeOptions.find(
    ({ id }) => id === job.working_type,
  );

  const employmentTypes = useEmploymentTypes();
  const employmentTypeSummary = getEmploymentTypeSummary(
    t,
    currency,
    job.employment_type,
    employmentTypes,
  );
  const detailFields =
    employmentTypeSummary?.fields['recruiter_list_detail'] || [];
  const headlineFields =
    employmentTypeSummary?.fields['recruiter_list_headline'] || [];

  return (
    <ListItem className={job.open_spots ? '' : 'disabled'}>
      <JobDescription
        job={job}
        role={role}
        online={online}
        setOnline={setOnline}
        language={job.languages?.map(
          (el) => languages.find((lng) => lng.code === el)?.name,
        )}
        addToFavoriteList={addToFavorites}
        employmentTypeName={employmentTypeSummary?.employmentType}
      />
      <JobInfo>
        <SectionHeading
          src={
            job.company.image
              ? job.company.image
              : process.env.PUBLIC_URL + '/images/company-logo.png'
          }
          title={job.company.name}
          companyId={job.company.id}
        />
        <JobListInfoItem
          icon={<LocationIcon />}
          title={
            <>
              <span style={{ textTransform: 'capitalize' }}>
                {job.job_type},{' '}
              </span>
              <span>{job.company?.city?.name}</span>
            </>
          }
        />
        {detailFields.map(({ id, label, value }) => (
          <JobListInfoItem
            key={id}
            icon={<ClockIcon />}
            title={`${label} ${value}`}
          />
        ))}
        {job.working_type === 'hybrid' && (
          <JobListInfoItem
            icon={<RemoteIcon />}
            title={t('REMOTE_WORKING_DAY_TEXT', {
              day: job.days_working_from_home || 0,
            })}
          />
        )}
        {job.working_type === 'full_office' && (
          <JobListInfoItem
            icon={<RemoteIcon />}
            title={t('REMOTE_NOT_POSSIBLE_TEXT')}
          />
        )}
        {job.working_type !== 'hybrid' &&
          job.working_type !== 'full_office' && (
            <JobListInfoItem
              icon={<RemoteIcon />}
              title={t(`${workingTp?.label}_POSSIBLE` || '')}
            />
          )}
        <JobListInfoItem
          icon={<VisaIcon />}
          title={
            job.is_visa_available ? t('VISA_TITLE') : t('NO_VISA_POSSIBLE_TEXT')
          }
        />
      </JobInfo>
      <JobSkillsWrapper>
        <SectionHeading title={t('SKILL_TITLE')} />
        <JobSkills>
          {job?.key_skills?.map((skill) => (
            <TagButton key={skill.id}>{skill.name}</TagButton>
          ))}
          {job?.preferable_skills?.map((skill) => (
            <TagButton key={skill.id} light={true}>
              {skill.name}
            </TagButton>
          ))}
          {!job?.key_skills?.length && !job.preferable_skills?.length && '-'}
        </JobSkills>
      </JobSkillsWrapper>

      <JobSalaryWrapper>
        <SectionHeading title={t('Compensation')} className="salary" />
        {headlineFields.map(({ secondaryLabel, value, displayHint }, index) =>
          index === 0 ? (
            <SalaryItem>
              {value} {secondaryLabel}
            </SalaryItem>
          ) : (
            <SalaryItemLight>
              {value} {secondaryLabel}
            </SalaryItemLight>
          ),
        )}
      </JobSalaryWrapper>

      <JobSpotWrapper className={role === ADMIN_ROLE ? 'top' : ''}>
        {role === RECRUITER_ROLE ? (
          <>
            {job.spots_available > 0 && (
              <>
                <FormItem
                  label={t('CANDIDATES_IN_PROCESS')}
                  name="open_spots"
                  tooltip={{ title: t('OPEN_SPOTS_TEXT'), icon: <InfoIcon /> }}
                  style={{ marginBottom: 0 }}
                />
                <ProgressBar
                  maxCompleted={job?.spots_available * 10}
                  completed={
                    job?.points <= job?.spots_available * 10
                      ? job?.points
                      : job?.spots_available * 10
                  }
                  customLabel=" "
                  bgColor="#ddd3ed"
                  baseBgColor={theme.lozengeColor}
                  height="10px"
                />
              </>
            )}
            <RecruiterPlacementFee job={job} />
          </>
        ) : (
          <>
            <JobStatistic title={`${job.open_spots} ${t('OPEN_SPOT_TITLE')}`} />
            <JobStatistic
              title={`${t('INTRODUCED')}: ${statistics.introduced} `}
            />
            <JobStatistic
              title={`${t('REJECTED_TITLE')}: ${statistics.rejected} `}
            />
            <JobStatistic
              title={`${t('IN_PROCESS_TITLE')}: ${statistics['in process']} `}
            />
            <JobStatistic title={`${t('HIRES_TITLE')}: ${statistics.hired}`} />
          </>
        )}
      </JobSpotWrapper>
      {role === ADMIN_ROLE && (
        <ListAction
          status={job.status}
          id={job.id}
          content={
            <>
              {job.status !== 'draft' && (
                <ActionButton onClick={onUnpublish} actionType="unpublish" />
              )}
              {(job.status === 'unpublished' ||
                job.status === 'on_hold' ||
                job.status === 'under review') && (
                  <ActionButton onClick={onPublish} actionType="publish" />
                )}
              <ActionButton onClick={editJob} actionType="edit" />
              <ActionButton onClick={onDelete} actionType="delete" />
            </>
          }
        />
      )}
      <UnpublishJobModal
        onAction={(res) => {
          setJobs((st: any) =>
            st.map((el: any) => {
              if (el.id === job.id) {
                return { ...el, status: res.data.status };
              }
              return el;
            }),
          );
        }}
        job={job}
        show={isUnpublishModalOpen}
      />
    </ListItem>
  );
};

export default RecruiterJobListItem;
