import React, { useEffect, useState } from 'react';
import Loader from '../../UI/Loader';
import Banner from './Banner';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import SectionItem from '../../components/jobs/singleJob/SectionItem';
import DescriptionSection from '../../components/jobs/singleJob/DescriptionSection';
import { useTranslation } from 'react-i18next';
import {
  City,
  CompanyActivity,
  Industry,
  Language,
  OfficeLocation,
  Region,
  Specialization,
} from '../../types';
import { getClientCompanyById } from '../../api/company';
import { Space, List } from 'antd';
import { ReactComponent as StarIcon } from '../../icons/start-rating.svg';
import { ReactComponent as EmptyStarIcon } from '../../icons/star-empty.svg';
import { ReactComponent as EmptyGreyStarIcon } from '../../icons/star-empty-grey.svg';
import { getJobs } from '../../api/jobs';
//import AdminJobs from "../../components/admin/AdminJobs";
import RecruiterJobs from '../../components/recruiter/RecruiterJobs';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE, ADMIN_ROLE } from '../../constants/roles';
import { getLanguagesList } from '../../api/languages';
import { Typography } from 'antd';
import AdminJobs from '../../components/admin/AdminJobs';
import { getSpecializationList } from '../../api/specialization';
import { DASHBOARD } from '../../constants/routes';
import { useHistory } from 'react-router';
import ClientJobs from '../../components/client/ClientJobs';
import { getCompanyActivityFeed } from '../../api/client';
import useHirePortTheme from '../../hooks/useHirePortTheme';

const { Title, Text } = Typography;

export interface UrlParams {
  clientId: string;
}

interface Department {
  department: string;
  clients: Client[];
}

interface Client {
  name: string;
  title: string;
}

interface Ratings {
  subject?: string | null;
  rating?: number | null;
}

interface Badge {
  name?: string | null;
  icon?: string | null;
}

export interface ClientProfile {
  company: { name: string; id: string };
  department: string;
  email: string;
  phone: string;
  id: string;
  image: string;
  name: string;
  title: string;
}

export interface CompanyProfile {
  id: string;
  image: string;
  name: string;
  description: string;
  difference_description: string;
  notable_clients: string;
  number_of_employees: number;
  average_age: number;
  male_percentage: number;
  dress_code: string;
  city: City;
  address: string;
  industry: Industry;
  region: Region;
  office_locations: OfficeLocation[];
  company_culture: string;
  excluded_companies?: string;
  departments?: Department[] | null;
  ratings?: Ratings[] | null;
  badges?: Badge[] | null;
  total_ratings: number | null;
}

export const ClientProfileBody = styled.div`
  margin: -4rem auto 0;
  padding-bottom: 5rem;
  max-width: 1140px;
  width: 100%;
`;

const JobList = styled.div`
  padding: 0 2rem;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;

interface Score {
  title?: string | null;
  stars?: any[];
}

const ClientPublicProfile = () => {
  const allowedSubjects = ['Overall', 'Accuracy', 'Speed', 'Communication'];

  const scoreData = [
    {
      title: 'Overall',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
    {
      title: 'Accuracy',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
    {
      title: 'Speed',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
    {
      title: 'Communication',
      stars: [
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
        <EmptyGreyStarIcon />,
      ],
    },
  ];

  const [scores, setScores] = useState<Score[]>(scoreData);
  const [t] = useTranslation();
  const theme = useHirePortTheme();
  const { clientId }: UrlParams = useParams();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<CompanyProfile | null>(null);
  const [categorizedJobs, setCategorizedJobs] = useState<any>({});
  const [languages, setLanguages] = useState<Language[]>([]);
  const { user } = useAppSelector((state) => state.user);
  const [activityFeed, setActivityFeed] = useState<null | CompanyActivity[]>(
    null,
  );
  const [specializations, setSpecializations] = useState<Specialization[]>([]);
  const history = useHistory();

  const isAdmin = user?.role === ADMIN_ROLE;
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;

  useEffect(() => {
    if (isClient && user?.company?.id !== clientId) {
      history.push(DASHBOARD);
    }
  }, [clientId, history, user, isClient]);

  const getMainDomain = (domain: string) => {
    let mainDomain = domain;
    for (let specialization of specializations) {
      // @ts-ignore
      for (let subDomain of specialization.sub_domains) {
        // @ts-ignore
        if (subDomain.sub_domains.length) {
          // @ts-ignore
          for (let subSubDomain of subDomain.sub_domains) {
            // @ts-ignore
            if (subSubDomain.name === domain) {
              // @ts-ignore
              mainDomain = specialization.name;
              break;
            }
          }
        }
        // @ts-ignore
        if (subDomain.name === domain) {
          // @ts-ignore
          mainDomain = specialization.name;
          //break;
        }
      }
    }

    return mainDomain;
  };

  useEffect(() => {
    getClientCompanyById(clientId)
      .then((res) => {
        setCompany(res.data);
        let currentScores = res.data.ratings;
        if (!currentScores) {
          return res;
        }
        currentScores = currentScores.filter((score: any) =>
          allowedSubjects.includes(score.subject),
        );
        currentScores = currentScores.map((score: any) => {
          if (score && score.subject === 'Overall') {
            score.rating = res.data.total_ratings ? res.data.total_ratings : 0;
          }
          return score;
        });
        if (currentScores && currentScores.length > 0) {
          let formattedScores = [];
          for (let score in currentScores) {
            let scoreValue = currentScores[score].rating.toFixed(0);
            let stars: any = new Array(5);
            stars.fill(<StarIcon />, 0);
            if (scoreValue < 5) {
              stars.fill(<EmptyStarIcon />, scoreValue);
            }
            scores.splice(
              scores.findIndex(
                (el) => el.title === currentScores[score].subject,
              ),
              1,
            );
            formattedScores.push({
              title: currentScores[score].subject,
              stars: stars,
            });
          }
          setScores([...formattedScores, ...scores]);
        }
        return res;
      }) /*.then((res) => {
            let query = '?company_name=' + res.data.name;
            getJobs(query).then((res) => {
                const fetchedJobs = res.data.results;
                let categorizedJobsArr: any = {};
                for (let job of fetchedJobs) {
                    let mainDomain = getMainDomain(job.specialization.name);
                    if (!categorizedJobsArr[mainDomain]) {
                        categorizedJobsArr[mainDomain] = {
                            'department': mainDomain,
                            'jobs': [],
                        }
                    }
                    // @ts-ignore
                    categorizedJobsArr[mainDomain].jobs.push(job);
                }
                setCategorizedJobs(categorizedJobsArr);
                setJobs(fetchedJobs);
                return res;
            })*/
      .then((res) => {
        getLanguagesList().then((res) => setLanguages(res.data));
      });
    // eslint-disable-next-line
  }, [clientId]);

  useEffect(() => {
    getSpecializationList().then((res) => {
      let mainDomains: any[] = res.data.filter((domain: any) => {
        return domain.sub_domains.length > 0;
      });
      setSpecializations(mainDomains);
    });
  }, []);

  useEffect(() => {
    if (!company?.name) {
      return;
    }
    let query = '?company_name=' + company?.name + '&favorite=true';
    getJobs(query).then(async (res) => {
      const fetchedJobs = res.data.results;
      let categorizedJobsArr: any = {};
      for (let job of fetchedJobs) {
        let mainDomain = getMainDomain(job.specialization.name);
        if (!categorizedJobsArr[mainDomain]) {
          categorizedJobsArr[mainDomain] = {
            department: mainDomain,
            jobs: [],
          };
        }
        // @ts-ignore
        categorizedJobsArr[mainDomain].jobs.push(job);
      }

      let pagination = res.data.pagination.next;

      while (pagination) {
        query = query + '&cursor=' + pagination;
        let res = await getJobs(query);
        const fetchedJobs = res.data.results;
        pagination = res.data.pagination.next;
        //let categorizedJobsArr: any = {};
        for (let job of fetchedJobs) {
          let mainDomain = getMainDomain(job.specialization?.name);
          if (!mainDomain) {
            continue;
          }
          if (!categorizedJobsArr[mainDomain]) {
            categorizedJobsArr[mainDomain] = {
              department: mainDomain,
              jobs: [],
            };
          }
          // @ts-ignore
          categorizedJobsArr[mainDomain].jobs.push(job);
        }
      }
      //setCategorizedJobs(categorizedJobs);
      setCategorizedJobs(categorizedJobsArr);
      setLoading(false);

      return res;
    });
    // eslint-disable-next-line
  }, [company, specializations]);

  useEffect(() => {
    if (clientId && user) {
      getCompanyActivityFeed(clientId).then((res: any) => {
        if (res && res?.data) {
          let feed = res?.data;
          setActivityFeed(feed);
        }
      });
    }
  }, [clientId, user]);

  return (
    <div style={{ backgroundColor: theme.lozengeColor }}>
      <Loader spinning={loading}>
        <Banner
          loading={loading}
          company={company}
          activityFeed={activityFeed}
        />
        <ClientProfileBody>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row gutter={[24, 24]}>
              <Col xs={{ span: 24 }} md={{ span: 16 }} xl={{ span: 16 }}>
                <SectionItem title={t('ABOUT')}>
                  <DescriptionSection
                    text={
                      company && company?.description?.length > 0
                        ? company?.description
                        : t('MORE_INFO_SOON')
                    }
                  ></DescriptionSection>

                  <SectionItem title={t('COMPANY_CULTURE_LABEL')}>
                    <DescriptionSection
                      text={
                        company && company?.company_culture?.length > 0
                          ? company?.company_culture
                          : t('MORE_INFO_SOON')
                      }
                    ></DescriptionSection>
                  </SectionItem>
                </SectionItem>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 8 }}>
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: 'flex' }}
                >
                  <SectionItem title={t('KEY_METRICS')}>
                    <List
                      itemLayout="horizontal"
                      dataSource={scores}
                      bordered={false}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta title={item.title} />
                          <div>
                            {
                              // @ts-ignore
                              item.stars.map((i) => i)
                            }
                          </div>
                        </List.Item>
                      )}
                    />
                  </SectionItem>
                  {company?.departments &&
                    company?.departments.map((department) => (
                      <SectionItem title={department.department}>
                        {department.clients.length > 0 &&
                          department.clients.map((client) => (
                            <>
                              <Title level={5}>{client.name}</Title>
                              <Text type="secondary">{client.title}</Text>
                            </>
                          ))}
                      </SectionItem>
                    ))}
                </Space>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              {/*<Space direction="vertical" size="large" style={{ display: 'flex' }}>*/}
              {Object.keys(categorizedJobs).length > 0 &&
                Object.keys(categorizedJobs).map((job: any) => (
                  <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }}>
                    <Title level={3}>{`${job} jobs`}</Title>
                    <JobList>
                      {isRecruiter && (
                        <RecruiterJobs
                          jobs={categorizedJobs[job].jobs}
                          setJobs={setCategorizedJobs}
                          languages={languages}
                        />
                      )}
                      {isClient && (
                        <ClientJobs
                          jobs={categorizedJobs[job].jobs}
                          setJobs={setCategorizedJobs}
                          languages={languages}
                        />
                      )}
                      {isAdmin && (
                        <AdminJobs
                          jobs={categorizedJobs[job].jobs}
                          setJobs={setCategorizedJobs}
                          languages={languages}
                        />
                      )}
                      {!loading && Object.keys(categorizedJobs).length === 0 ? (
                        <NoResultText>{t('NO_RESULT')}</NoResultText>
                      ) : (
                        ''
                      )}
                    </JobList>
                  </Col>
                ))}
              {/*</Space>*/}
            </Row>
          </Space>
        </ClientProfileBody>
      </Loader>
    </div>
  );
};

export default ClientPublicProfile;
