import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef } from 'react';
import flagsmith from 'flagsmith';
import { FlagsmithContextType, FlagsmithProvider, useFlags, useFlagsmith } from 'flagsmith/react';
import { getToken } from '../../utils/localStorage';
import { useAppSelector } from '../../store/hooks';
import { ADMIN_ROLE, CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import useHirePortTheme from '../../hooks/useHirePortTheme';

export const TEST_UI_KEYS = {
  SelectTalentSuppliers: "test_ui_select_talent_suppliers",
} as const;

const FlagsmithIdentifier = ({ children }: { children: FlagsmithContextType["children"] }) => {
  const { user } = useAppSelector((state) => state.user);
  const flagsmith = useFlagsmith();

  const initFlagsmith = useCallback(async () => {
    if (!user || !process.env.REACT_APP_FLAGSMITH_CLIENT_SDK_KEY)
      return;
    await flagsmith.init({
      environmentID: process.env.REACT_APP_FLAGSMITH_CLIENT_SDK_KEY,
    })
    await flagsmith.identify(
      user.id,
      {
        email: user?.email ?? null,
        name: user?.name ?? null,
        companyName: user?.company?.name ?? null,
        companyId: user?.company?.id ?? null,
        isClient: user?.role === CLIENT_ROLE,
        isRecruiter: user?.role === RECRUITER_ROLE,
        isAdmin: user?.role === ADMIN_ROLE,
      },

    );
  }, [user, flagsmith]);

  useEffect(() => {
    initFlagsmith();
  }, [initFlagsmith]);

  return <>{children}</>;
}

export const TestUIProvider = ({ children }: { children: FlagsmithContextType["children"] }) => (
  <FlagsmithProvider
    flagsmith={flagsmith}
  >
    <FlagsmithIdentifier>{children}</FlagsmithIdentifier>
  </FlagsmithProvider>
)

const tryParse = (str?: string | number | boolean | null) => {
  if (!str)
    return null;
  try {
    return JSON.parse(str.toString());
  } catch (e) {
    return null;
  }
}

type TestUIWrapperProps = {
  testKey: string;
  params: Record<string, any>;
  defaultWidth?: number | string;
  defaultHeight?: number | string;
  sendToken?: boolean;
  children: ReactNode;
};

const TestUIWrapper = ({ testKey, params, defaultWidth = 800, defaultHeight = 600, sendToken = true, children }: TestUIWrapperProps) => {
  const messageListener = useRef<((ev: MessageEvent<any>) => void) | null>(null);
  const theme = useHirePortTheme();

  useEffect(() => {
    if (messageListener.current)
      return;
    const callback: (ev: MessageEvent<any>) => any = (ev) => {
      if (ev.data.type === "HirePortIFrameMessage")
        window.postMessage(ev.data, "*");
    };
    messageListener.current = callback;
    window.addEventListener("message", callback);
    return () => {
      if (messageListener.current)
        window.removeEventListener("message", messageListener.current);
    };
  }, [messageListener]);

  const flags = useFlags([testKey]);
  if (!flags[testKey].enabled)
    return <>{children}</>;

  const config = tryParse(flags[testKey].value);
  if (!config)
    return <>{children}</>;

  if (config.type === "iframe" && config.args.url) {
    const token = sendToken ? getToken('access_token') : null;
    const url = `${config.args.url}${config.args.url.includes("?") ? "&" : "?"}${new URLSearchParams({
      themeName: theme.themeName,
      ...(token ? { token } : null),
      url: window.location.toString(),
      ...params,
    }).toString()}`;
    return (
      <div>
        <iframe title="Test UI frame" style={{ width: config.args.width || defaultWidth, height: config.args.height || defaultHeight, padding: 0, border: 0 }} src={url}></iframe>
      </div>
    );
  }

  return <>{children}</>;
}

export const withTestUIWrapper: <P = {}>(Fallback: FunctionComponent<P>, options: (props: P) => Omit<TestUIWrapperProps, 'children'>) => FunctionComponent<P> = (Fallback, options) => {
  return (fallbackProps) => (
    <TestUIWrapper {...options(fallbackProps)}>
      <Fallback {...fallbackProps} />
    </TestUIWrapper>
  )
}

export default TestUIWrapper;
