import styled from 'styled-components';

import { ReactComponent as HeartIcon } from '../../../../icons/heart-centered.svg';

export const JobDescriptionWrapper = styled.div`
  width: 32%;
  &.company-img {
    display: flex;
    align-items: flex-start;
  }
  @media (max-width: 992px) {
    width: 50%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
`;

export const JobCompanyWrapper = styled.div`
  width: 100%;
  //display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  &.draft p,
  &.draft div,
  &.draft a,
  &.draft .title {
    color: #aebeca;
  }
`;

export const CompanyLogo = styled.div`
  min-width: 5rem;
  min-height: 5rem;
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    min-width: 3.5rem;
    min-height: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const JobTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
  color: #061c2e;
  margin-bottom: 0.5rem;
  & a,
  & .title {
    color: #061c2e;
    max-height: 3.5rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
  &.grey {
    & a,
    & h3 {
      color: #aebeca;
    }
  }
  & .views {
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    align-items: center;
    height: 28px;
  }

  .views-wrap {
    & svg {
      color: #aebeca;
      margin: 0 0.25rem 0 0.5rem;
      font-size: 1rem;
    }
  }

  & .counter {
    display: inline-block;
    vertical-align: top;
  }
`;

export const NewCandidates = styled.div`
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 53px;
  background-color: #34ce72;
  margin-left: 0.5rem;
  align-items: center;

  & svg {
    font-size: 1rem;
    color: #ffffff;
    margin-right: 0.25rem;
  }
`;

export const JobAbout = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin-bottom: 1rem;
  display: -webkit-box;
  padding-right: 1rem;
`;

export const TagWrapper = styled.div``;

export const FavoriteIcon = styled(HeartIcon)`
  margin-left: 0.25rem;
  color: #aebeca;
  cursor: pointer;
  min-width: 1.5rem;
  min-height: 1.5rem;
  vertical-align: top;
  &.active {
    color: #34ce72;
    fill: #34ce72;
  }
`;

export const ClientTitleRight = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const BadgeNew = styled.span`
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 4px;
  color: #fff;
  font-size: 8px;
  line-height: 1.5;
  letter-spacing: 0.16px;
  font-weight: normal;
  padding: 2px 6px;
  border-radius: 40px;
  background-color: #34ce72;
  text-transform: uppercase;
  vertical-align: middle;

  & svg {
    vertical-align: middle;
    margin-right: 2px;
  }
`;

export const BadgeUpdated = styled(BadgeNew)`
  background-color: #606fe3;
`;

export const BadgeCustom = styled(BadgeNew)`
  background-color: #aebfca;
`;

export const BadgeLive = styled(BadgeNew)`
  color: #627d95;
  background-color: #f2f6fa;
`;

export const BadgeWarning = styled(BadgeNew)`
  background-color: #f58316;
`;
