import React, { useEffect, useState } from 'react';
import Banner from '../components/auth/Banner';
import styled from 'styled-components';
import ClientInvitationForm from '../components/form/ClientInvitationForm';
import { useParams, useHistory, Link } from 'react-router-dom';
import { getInvitedUserDetails } from '../api/user';
import RecruiterInvitationForm from '../components/form/RecruiterInvitationForm';
import { ErrorMessage } from '../components/auth/LoginForm';
import { LOGIN } from '../constants/routes';
import FormTitle from '../components/auth/FormTitle';
import { useTranslation } from 'react-i18next';

export interface UrlParams {
  uid: string;
  token: string;
}

const PageWrapper = styled.section`
  background: #0c1e2d;
  width: 100%;
  display: flex;
  min-height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const FormWrapper = styled.div`
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

export interface UserInterface {
  city: string | null;
  company: object | null;
  department: string;
  email: string;
  id: string;
  image: string | null;
  locations: [] | null;
  name: string;
  phone: string;
  preference_relevance_interval: null;
  recruiter_type: null;
  role: string;
  seniority: string | null;
  specializations: null;
  title: string;
  is_complete: boolean;
}

const InvitationConfirm = (props: any) => {
  const [user, setUser] = useState<UserInterface | null>(null);
  const [isValid, setIsValid] = useState(true);
  const { uid, token }: UrlParams = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getInvitedUserDetails(uid, token)
      .then((res) => {
        if (res.data.is_complete) {
          history.push(LOGIN);
        } else {
          setUser(res.data);
        }
      })
      .catch((e) => setIsValid(false));
  }, [uid, token, history]);

  return (
    <PageWrapper>
      <Banner size={'small'} title={t('GET_STARTED_LABEL')} />
      <FormWrapper>
        {isValid ? (
          <>
            {user && user?.role === 'client' && (
              <ClientInvitationForm user={user} />
            )}
            {user && user.role === 'recruiter' && (
              <RecruiterInvitationForm user={user} />
            )}
          </>
        ) : (
          <>
            <ErrorMessage message={t('EXPIRED_LINK_ERROR')} type="error" />
            <FormTitle
              text={
                <>
                  <span>
                    {t('GO_TO_TITLE')}{' '}
                    <Link to={LOGIN}>{t('LOG_IN_TEXT')}</Link>
                  </span>
                </>
              }
            />
          </>
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

export default InvitationConfirm;
