import React, { useState } from 'react';
import styled from 'styled-components';
import FormTitle from '../components/auth/FormTitle';
import Banner from '../components/auth/Banner';
import { useHistory, useParams } from 'react-router-dom';
import { UrlParams } from './InvitationConfirm';
import { ErrorMessage } from '../components/auth/LoginForm';
import FormItem from '../components/form/FormItem';
import { Form } from 'antd';
import Button from '../UI/buttons/Button';
import InputPassword from '../UI/inputs/InputPassword';
import { confirmResetPassword } from '../api/user';
import { LOGIN } from '../constants/routes';
import { passwordRule } from '../utils/validationRules';
import { useTranslation } from 'react-i18next';

const PageWrapper = styled.section`
  background: #0c1e2d;
  width: 100%;
  display: flex;
  min-height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FormWrapper = styled.div`
  background: #fff;
  width: 32.5%;
  padding: 4.5rem 4rem;
  @media (max-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 80%;
    padding: 3rem;
  }
`;
const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const SubTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: 700;
  color: #061c2e;
`;
const SuccessText = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  margin: 0 0 2.5rem;
  color: #061c2e;
`;
const RestForm = styled(Form)`
  margin-top: 2.5rem;
`;
const PasswordHint = styled.p`
  font-size: 0.625rem;
  color: #627d95;
  margin: 0.5rem 0 0;
`;
const ResetPassword = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { uid, token }: UrlParams = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const onFinish = (values: any) => {
    setLoading(true);
    confirmResetPassword({ uid, token, new_password: values.new_password })
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response?.data?.message);
      });
  };
  return (
    <PageWrapper>
      <Banner title={t('LOGIN_BANNER_TEXT')} />
      <FormWrapper>
        <FormHeader>
          <FormTitle text={t('RESET_PASSWORD_TITLE')} />
        </FormHeader>

        {!success ? (
          <>
            <SubTitle>{t('PICK_PASSWORD_TITLE')}</SubTitle>
            <RestForm
              scrollToFirstError={true}
              layout="vertical"
              name="reset-request"
              onFinish={onFinish}
              form={form}
            >
              {error && <ErrorMessage message={error} type="error" />}
              <FormItem
                className="no-margin"
                label={t('PASSWORD_LABEL')}
                name="new_password"
                rules={passwordRule(t)}
              >
                <InputPassword />
              </FormItem>
              <PasswordHint>{t('PASSWORD_HINT')}</PasswordHint>
              <FormItem className="m-4">
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('NEW_PASSWORD_SUCCESS')}
                </Button>
              </FormItem>
            </RestForm>
          </>
        ) : (
          <>
            <SubTitle>{t('PASSWORD_CHANGED_SUCCESS')}</SubTitle>
            <SuccessText>{t('PASSWORD_CHANGED_SUCCESS_2')}</SuccessText>
            <Button type="primary" onClick={() => history.push(LOGIN)}>
              {t('PROCEED_TITLE')}
            </Button>
          </>
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

export default ResetPassword;
