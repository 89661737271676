import React, { useEffect, useState } from 'react';
import { Recruiter, Specialization } from '../../types';
import { TFunction, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RECRUITER } from '../../constants/routes';
import TooltipPrompt from '../../UI/tootlip';
import NameImage from '../client/NameImage';
import { Text } from './RecruiterList';
import { ReactComponent as Logo } from '../../icons/logo-ufo.svg';
import styled from 'styled-components';
import { ReactComponent as StarIcon } from '../../icons/star-green-filled.svg';
import CheckboxInput from '../../UI/inputs/CheckboxInput';
import {
  formatRating,
  trimSpecialisations,
} from '../../utils/formatRecruiters';
import { Tag } from 'antd';
import useHirePortTheme from '../../hooks/useHirePortTheme';
import { formatFeeForRecruiterManagement } from '../recruitment_management/RecruiterManagementTableRow';
import useHirePortContext from '../../hooks/useHirePortContext';
import useCurrency from '../../hooks/useCurrency';

export const calcRecruiterType = (
  rec: Recruiter,
  t: TFunction<'translation'>,
) => {
  const StyledLogo = styled(Logo)`
    width: 1em;
    height: 1em;
    stroke-width: 1.5;
    & path {
      fill: ${({ theme }) => theme.primaryColor};
    }
  `;

  let CommunityRecruiter = () => {
    const theme = useHirePortTheme();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <StyledLogo />
        <Text
          style={{
            marginTop: 0,
            marginLeft: '5px',
            marginRight: '10px',
            color: theme.primaryColor,
          }}
        >
          {t('MARKETPLACE')}
        </Text>
      </div>
    );
  };
  return rec.own_recruiter ? (
    <Text>{t('OWN_SUPPLIER')}</Text>
  ) : (
    <CommunityRecruiter />
  );
};

const RecruiterName = ({ recruiter }: { recruiter: Recruiter }) => {
  return <NameImage
    title={recruiter.name}
    image={recruiter.image}
    color={recruiter.own_recruiter
      ? { main: '#7949cd', light: '#f2edfa' }
      : { main: '#29ae5e', light: '#eaf7ef' }}
    description={recruiter.recruiter_type !== 'freelance'
      ? recruiter.company.name
      : recruiter.title} />;
};

type RecruiterSelectTableRowProps = {
  recruiter: Recruiter;
  selectedJobSpecialization?: {
    id: string;
    name: string;
  } | null;
  domains: Specialization[];
  setRecruiterIsSelectedField: (rec: Recruiter, is_selected: boolean) => void;
};

export default function RecruiterSelectTableRow({
  recruiter,
  domains,
  selectedJobSpecialization,
  setRecruiterIsSelectedField,
}: RecruiterSelectTableRowProps) {
  const theme = useHirePortTheme();
  const { renderCustom: { recruiterSelection: customRender } } = useHirePortContext();
  const [t] = useTranslation();
  const { curr } = useCurrency(recruiter.fee_currency);

  const [specializations, setSpecializations] = useState<string[]>([]);
  const [suffix, setSuffix] = useState<string>('');

  useEffect(() => {
    // sort by having the selected specialization first
    const recruiterSpecializations = trimSpecialisations(
      recruiter.specializations,
      selectedJobSpecialization?.name,
    );
    setSpecializations(() => recruiterSpecializations.structured_specs);
    setSuffix(() => recruiterSpecializations.suffix ?? '');
  }, [recruiter.specializations, selectedJobSpecialization?.name]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderBottom: '1px solid #DAE5EC',
        padding: '6px 0',
      }}
    >
      <CheckboxInput
        style={{ flex: '5%' }}
        checked={recruiter.is_selected}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setRecruiterIsSelectedField(recruiter, e.target.checked);
        }}
      />
      <div style={{ flex: '20%' }}>
        {!customRender?.disableLinkToProfile &&
          <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}>
            <Link
              to={`${RECRUITER}/${recruiter.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RecruiterName recruiter={recruiter} />
            </Link>
          </TooltipPrompt>
        }
        {customRender?.disableLinkToProfile &&
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setRecruiterIsSelectedField(recruiter, !recruiter.is_selected);
            }}
          >
            <RecruiterName recruiter={recruiter} />
          </Link>
        }
      </div>
      <div style={{ flex: '11%' }}>{calcRecruiterType(recruiter, t)}</div>
      <Text style={{ flex: '35', marginBottom: '0.4rem' }}>
        {specializations.length > 0
          ? specializations.map((spec: string) => (
            <Tag
              key={spec}
              style={{
                borderRadius: '8px',
              }}
              color={domains.find((x) => x.name === spec)?.color ?? 'blue'}
            >
              {spec}
            </Tag>
          ))
          : '-'}
        {suffix && (
          <Tag
            style={{
              borderRadius: '8px',
            }}
            color={'#A6B1BB'}
          >
            {suffix}
          </Tag>
        )}
      </Text>
      <Text style={{ flex: '17.5%', marginBottom: '0.4rem' }}>
        {formatFeeForRecruiterManagement(
          recruiter.fee_type,
          recruiter.fee_description,
          curr,
        )}
      </Text>
      <Text
        style={{
          flex: '6.5%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: '0.4rem',
        }}
      >
        {formatRating(recruiter) === '-' ? (
          <div
            style={{
              marginLeft: '0.5em',
            }}
          >
            -
          </div>
        ) : (
          [...Array(formatRating(recruiter))].map((_, i) => (
            <StarIcon
              key={i}
              style={{
                height: '1em',
                width: '1em',
                color: theme.primaryColor,
              }}
            />
          ))
        )}
      </Text>
    </div>
  );
}
