import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getInterview, rateInterview } from '../api/interviews';
import { ReactComponent as Logo } from '../icons/logo-black.svg';
import { ReactComponent as StarFilled } from '../icons/star-green-filled.svg';
import { useTranslation } from 'react-i18next';
import { InterviewRoom } from '../types';
import { useAppSelector } from '../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../constants/roles';
import Loader from '../UI/Loader';
import RateForm from '../components/form/RateForm';
import { message, Rate } from 'antd';
import { CLIENT, DASHBOARD, RECRUITER } from '../constants/routes';
import ReactGa from 'react-ga4';

const Container = styled.div`
  width: 100%;
  max-width: 44%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem 0;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem 0;
  align-items: center;
  padding: 1.5rem 0 2.5rem;
`;
const FooterLink = styled.a`
  font-size: 11px;
  line-height: 1.45;
  text-align: left;
  color: #627d95;
  padding: 0 0.75rem;
  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;
const Body = styled.div`
  padding: 4rem 6rem;
  background: ${({ theme }) => theme.lozengeColor};
`;
const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #061c2e;
  margin: 0;
`;
const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  text-align: center;
  color: #061c2e;
  margin: 1.5rem 0 2.5rem;
  padding: 0 4rem;
`;
const Rating = styled.div`
  margin-bottom: 2.5rem;
  text-align: center;
`;
const SubTitle = styled.h5`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #061c2e;
  margin: 0 0 1.5rem;
`;
const StyledRate = styled(Rate)`
  & .ant-rate-star {
    & svg {
      color: transparent;
    }
  }
  & .ant-rate-star-full {
    & svg {
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  & svg {
    transition: color ease 0.3s;
  }
`;
const RateInterview = () => {
  const [interview, setInterview] = useState<InterviewRoom | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadSave, setLoadSave] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);
  const params: { interviewId: string } = useParams();
  const interviewId = params.interviewId;
  const [t] = useTranslation();
  useEffect(() => {
    ReactGa.pageview('/rate');
  }, []);
  useEffect(() => {
    if (interviewId) {
      getInterview(interviewId).then((res) => {
        setInterview(res.data);
        setLoading(false);
      });
    }
  }, [interviewId]);
  const onFinish = (data: any) => {
    setLoadSave(true);
    rateInterview(interviewId, { ...data, rating })
      .then(() => {
        setLoadSave(false);
        history.push(DASHBOARD);
      })
      .catch((e) => {
        setLoadSave(false);
        message.error(e.response?.data?.message, 5);
      });
  };
  return (
    <Container>
      <Loader spinning={loading}>
        {interview ? (
          <div>
            <Header>
              <Logo />
            </Header>
            <Body>
              <Link
                to={
                  user?.role === CLIENT_ROLE
                    ? RECRUITER + '/' + interview?.recruiter.id
                    : CLIENT + '/' + interview?.client.id
                }
              >
                <Title>
                  {t('HOW_ENJOY')}{' '}
                  {user?.role === RECRUITER_ROLE
                    ? interview?.client.name
                    : interview?.recruiter.name}{' '}
                  ?
                </Title>
              </Link>
              <Description>{t('LEAVE_FEEDBACK')} </Description>
              <SubTitle>{t('OVERALL_RATING')} </SubTitle>
              <Rating>
                <StyledRate
                  value={rating}
                  character={<StarFilled />}
                  onChange={(value) => setRating(value)}
                />
              </Rating>
              <SubTitle>{t('SPECIFY_ANSWER')} </SubTitle>
              <RateForm onFinish={onFinish} loading={loadSave} />
            </Body>
            <Footer>
              <FooterLink target="_blank" href={'/'}>
                © {new Date().getFullYear()} HirePort
              </FooterLink>
              <FooterLink target="_blank" href={'/privacy-policy'}>
                {t('PRIVACY_POLICY')}
              </FooterLink>
            </Footer>
          </div>
        ) : (
          ''
        )}
      </Loader>
    </Container>
  );
};

export default RateInterview;
