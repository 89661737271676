import { HirePortTheme } from '../../../hooks/useHirePortTheme';

export const packs = [
  'recruitee',
  'jobylon',
  'smartrecruiters',
] as const;

export type PackName = typeof packs[number];

const themeSettings = {
  recruitee: {
    primaryColor: '#1999e3',
    hoverColor: '#147ab6',
    activeColor: '#0f5c88',
    lozengeColor: '#f1f4f7',
    backgroundColor: 'transparent',
    radius: '8px',
    radiusSmall: '8px',
    simplified: true,
    renderPageHeader: false, // should get overwritten anyway by context
  },
  jobylon: {
    primaryColor: '#0068FF',
    hoverColor: '#045cdb',
    activeColor: 'rgba(0, 104, 255, .1)',
    lozengeColor: 'rgba(0, 104, 255, .1)',
    backgroundColor: '#f9f9fa',
    radius: '3px',
    radiusSmall: '1px',
    simplified: true,
    renderPageHeader: false, // should get overwritten anyway by context
  },
  smartrecruiters: {
    primaryColor: 'rgb(35, 135, 64)',
    hoverColor: 'rgb(8, 87, 35)',
    activeColor: '#dadddb',
    lozengeColor: 'rgb(248, 250, 249)',
    backgroundColor: 'white',
    radius: '4px',
    radiusSmall: '4px',
    simplified: true,
    renderPageHeader: false, // should get overwritten anyway by context
  },
} as const;

export const themes = Object.fromEntries(Object.entries(themeSettings).map(([themeName, settings]) => [themeName, { themeName, ...settings }])) as Record<PackName, HirePortTheme>;
