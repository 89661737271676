import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../components/layout/Header';
import PageWrapper from '../components/layout/PageWrapper';
import { useAppSelector } from '../store/hooks';
import { DASHBOARD } from '../constants/routes';
import Retool from 'react-retool';
import styled from 'styled-components';
import { getRetoolEmbedUrlAnalytics } from '../api/retool';
import Loader from '../UI/Loader';

const NoResultText = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: center;
  color: #061c2e;
  padding: 1rem;
`;

const AnalyticsPage = () => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const history = useHistory();
  const analyticsModuleEnabled = user?.company?.analytics_module_enabled;
  const loader = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState('');

  useEffect(() => {
    if (!analyticsModuleEnabled) {
      history.push(DASHBOARD);
    }
  }, [analyticsModuleEnabled, history]);

  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        const response = await getRetoolEmbedUrlAnalytics();
        setEmbedUrl(response.data.embed_url);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, []);

  return (
    <PageWrapper>
      <Header title={t('ANALYTICS_TITLE')}>
        <span></span>
      </Header>
      <Loader spinning={loading}>
        {!embedUrl ? (
          <NoResultText>{t('NO_RESULT')}</NoResultText>
        ) : (
          <div style={{ height: 'calc(100vh - 64px - 3rem)' }}>
            <Retool url={embedUrl} height="100%" />
          </div>
        )}
        <div className="loader" ref={loader} />
      </Loader>
    </PageWrapper>
  );
};

export default AnalyticsPage;
