import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { getRecruiterList } from '../../../../api/recruiter';
import { getClientList } from '../../../../api/client';
import { Client, Recruiter } from '../../../../types';
import { loginAsUser } from '../../../../api/user';
import {
  storeToken,
  storeAdminMarker,
  getToken,
  storeAdminToken,
  removeToken,
} from '../../../../utils/localStorage';
import {
  ADMIN_ROLE,
  IS_ADMIN_KEY,
  ADMIN_TOKEN,
} from '../../../../constants/roles';
import { useAppSelector } from '../../../../store/hooks';

const useSwitchUserPopup = (isVisible: boolean) => {
  const [selectedClientId, selectClientId] = useState('');
  const [selectedRecruiterId, selectRecruiterId] = useState('');
  const [loading, setLoading] = useState(false);

  const [clients, setClients] = useState<Client[]>([]);
  const [recruiters, setRecruiters] = useState<Recruiter[]>([]);

  const history = useHistory();

  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;

  const handleSelectClient = useCallback(
    (clientId: string) => {
      selectClientId(clientId);
      selectRecruiterId('');
    },
    [selectClientId, selectRecruiterId],
  );

  const handleSelectRecruiter = useCallback(
    (recruiterId: string) => {
      selectClientId('');
      selectRecruiterId(recruiterId);
    },
    [selectClientId, selectRecruiterId],
  );

  const handleSearchClient = useCallback(
    (search: string) => {
      getClientList(`?search=${search}`).then((res) =>
        setClients(res.data.results),
      );
    },
    [setClients],
  );

  const handleSearchRecruiter = useCallback(
    (search: string) => {
      getRecruiterList(`?search=${search}`).then((res) =>
        setRecruiters(res.data.results),
      );
    },
    [setRecruiters],
  );

  useEffect(() => {
    if (isVisible) {
      getClientList('?order_by=company__name').then((res) =>
        setClients(res.data.results),
      );
      getRecruiterList().then((res) => {
        setRecruiters(res.data.results);
      });
    }
  }, [isVisible]);

  const handleConfirm = useCallback(() => {
    let selectedId = selectedClientId;
    if (selectedRecruiterId.length) selectedId = selectedRecruiterId;

    if (selectedId) {
      setLoading(true);

      loginAsUser(selectedId).then((res) => {
        // save old token so admin can return to his acc
        const adminToken = getToken('access_token') || '';
        storeAdminToken(adminToken);

        storeToken(res.access_token);
        storeAdminMarker();

        // no need to store res.user via updateUser, app will do it in the common way
        // reload page, so we can get new ui and data
        history.go(0);
      });
    }
  }, [setLoading, selectedClientId, selectedRecruiterId, history]);

  const handleBackToAdminRole = useCallback(() => {
    const adminToken = getToken(ADMIN_TOKEN) || '';
    storeToken(adminToken);
    removeToken(ADMIN_TOKEN);
    removeToken(IS_ADMIN_KEY);

    if (history.location.pathname === '/recruiter-management') {
      history.push('/dashboard');
    }

    history.go(0);
  }, [history]);

  return {
    actions: {
      handleSearchClient,
      handleSearchRecruiter,
      handleSelectClient,
      handleSelectRecruiter,
      handleConfirm,
      handleBackToAdminRole,
    },
    data: {
      clients,
      recruiters,
      selectedClientId,
      selectedRecruiterId,
      loading,
      isAdmin,
    },
  };
};

export default useSwitchUserPopup;
