import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { ClientFeeTemplate } from '../../types';

import {
  deleteCompanyFeeTemplate,
  getCompanyFeeTemplate,
  updateCompanyFeeTemplate,
} from '../../api/company';
import { useParams } from 'react-router-dom';
import { MASTER_CONTRACTS } from '../../constants/routes';
import Header from '../../components/layout/Header';
import PageWrapper from '../../components/layout/PageWrapper';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';
import MasterContractForm from '../../components/client/MasterContractForm';

const EditMasterContractPage = () => {
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [companyFeeTemplate, setCompanyFeeTemplate] =
    useState<ClientFeeTemplate | null>(null);

  useEffect(() => {
    if (id) {
      getCompanyFeeTemplate(id)
        .then((res) => {
          setCompanyFeeTemplate(res.data);
        })
        .catch(() => { });
    } else {
      history.push(MASTER_CONTRACTS);
    }
  }, [id, setCompanyFeeTemplate, history]);

  const onSubmit = (data: FormData) => {
    setIsLoading(true);
    updateCompanyFeeTemplate(id, data)
      .then(() => {
        setIsLoading(false);
        message.success(t('FEE_SETTINGS_UPDATED'), 5);
        history.push(MASTER_CONTRACTS);
      })
      .catch(() => setIsLoading(false));
  };

  const onContractDelete = () => {
    deleteCompanyFeeTemplate(id)
      .then(() => {
        history.push(MASTER_CONTRACTS);
        message.success(t('FEE_SETTINGS_DELETED'), 5);
      })
      .catch(() => {
        message.error(t('FEE_SETTINGS_DELETE_ERROR'), 5);
      });
  };

  return (
    <PageWrapper>
      <Header title={t('MASTER_CONTRACT_TEMPLATE')}>
        <span></span>
      </Header>
      {companyFeeTemplate &&
        <MasterContractForm
          isLoading={isLoading}
          initialData={companyFeeTemplate}
          submitLabel="UPDATE"
          onSubmit={onSubmit}
          onContractDelete={onContractDelete}
          onBack={() => history.push(MASTER_CONTRACTS)}
        />
      }
    </PageWrapper>
  );
};

export default EditMasterContractPage;
