import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'antd';

const StyledButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.primaryColor};
  border-radius: ${({ theme, size }) => (size === 'small' ? theme.radiusSmall : theme.radius)};
  font-weight: 800;
  box-shadow: none;
  display: flex;
  align-items: center;
  background: transparent;

  &:hover{
    background: transparent;
  }

  &.ant-btn-default {
    border: solid 1px ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
    background: transparent;

    &:hover {
      border: solid 1px ${({ theme }) => theme.hoverColor};
      color: ${({ theme }) => theme.hoverColor};
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.hoverColor};
    color: ${({ theme }) => theme.hoverColor};
  }

  &:focus:not(.disabled) {
    box-shadow: 0 0 8px 0 ${({ theme }) => theme.primaryColor};
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background: #dde7ee;
    color: #ffffff;
    border-color: #dde7ee;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
    }
  }

  &.ant-btn-primary {
    color: #ffffff;
    background: ${({ theme }) => theme.primaryColor};
    border: 1px solid ${({ theme }) => theme.primaryColor};

    &:hover {
      color: #ffffff;
      background: ${({ theme }) => theme.hoverColor};
      border: 1px solid ${({ theme }) => theme.hoverColor};
    }

    &:active,
    &:focus {
      color: #ffffff;
      background: ${({ theme }) => theme.activeColor};
      border: 1px solid ${({ theme }) => theme.activeColor};
    }

    &:after {
      box-shadow: 0 0 0 0 ${({ theme }) => theme.primaryColor};
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background: #dde7ee;
      border-color: #dde7ee;
      color: #ffffff;
      &:hover {
        background: #dde7ee;
        color: #ffffff;
      }
    }
  }

  &.ant-btn-lg {
    padding: 0.813rem 2rem;
    height: auto;
    line-height: 1.25;
  }

  &.ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    &:focus,
    &:hover {
      color: #ff7875;
      border-color: #ff7875;
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background: #dde7ee;
      border-color: #dde7ee;
      color: #ffffff;
      &:hover {
        background: #dde7ee;
        color: #ffffff;
      }
    }

    &.ant-btn-primary {
      background: #ff4d4f;
      color: #fff;
    }
  }

  &.ant-btn-ghost {
    background: transparent;
    &:focus,
    &:active {
        color: ${({ theme }) => theme.hoverColor};
        box-shadow: 0 0 8px 0 ${({ theme }) => theme.primaryColor};
        border-color: ${({ theme }) => theme.hoverColor};
      }
  }
  &.ant-btn.ant-btn-dangerous.ant-btn-ghost {
    background-color: transparent;
  }
  & svg {
    margin-right: 0.75rem;
  }

  &.ant-btn-sm {
    padding: 3px 0.5rem;
    height: auto;
    font-size: 0.75rem;
    line-height: 1.33;
  }

  &.ant-btn-background-ghost {
    &.ant-btn-primary {
      border: 1px solid ${({ theme }) => theme.primaryColor};
      color: ${({ theme }) => theme.primaryColor};
      &:hover {
        border: 1px solid ${({ theme }) => theme.hoverColor};
        color: ${({ theme }) => theme.hoverColor};
      }
      &:active,
      &:focus {
        border: 1px solid ${({ theme }) => theme.activeColor};
        color: ${({ theme }) => theme.activeColor};
      }
    }
  }
`;

export interface ButtonProps extends BaseButtonProps { }

const Button = ({ children, size = 'large', ...rest }: ButtonProps) => {
  return (
    <StyledButton size={size} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
