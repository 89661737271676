import { Form, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from '../../UI/buttons/Button';
import FormItem from '../form/FormItem';
import { requiredRule } from '../../utils/validationRules';
import Textarea from '../../UI/inputs/Textarea';
import { onholdJob, unpublishJob } from '../../api/jobs';
import { Job } from '../../types';

interface UnpublishJobModalProps {
  job: Job;
  show: boolean;
  showOnHold?: boolean;
  onAction?: React.Dispatch<React.SetStateAction<any>>;
  onClose?: () => void;
}

const StyledForm = styled(Form)`
  padding: 3px;
`;
export const StyledModal = styled(Modal)`
  & .ant-modal-content {
    padding: 32px;
  }
  & .ant-modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 16px;
    & div {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-body {
    padding: 0;
    & p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #061c2e;
    }
  }
  & .ant-modal-footer {
    padding: 0;
    display: flex;
    border-top: none;
  }
`;
const UnpublishJobModal = ({
  job,
  onAction,
  show,
  onClose,
  showOnHold = true,
}: UnpublishJobModalProps) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    show ? setOpen(true) : setOpen(false);
    if (!show) {
      setLoading(false);
    }
  }, [show, form]);

  const handleUnpublish = () => {
    form.submit();
    const reason = form.getFieldValue('unpublished_reason');
    form.validateFields().then((res) => {
      unpublishJob(job.id, reason)
        .then((res) => {
          message.success(t('JOB_IS_UNPUBLISHED'), 5);
          if (onAction) {
            onAction(res);
          }
        })
        .then((res) => handleOnClose());
    });
  };

  const handleOnHold = () => {
    form.submit();
    const reason = form.getFieldValue('unpublished_reason');
    form.validateFields().then((res) => {
      onholdJob(job.id, reason)
        .then((res) => {
          message.success(t('JOB_IS_ONHOLD'), 5);
          if (onAction) {
            onAction(res);
          }
        })
        .then((res) => handleOnClose());
    });
  };

  const handleOnClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <StyledModal
      closable={true}
      visible={open}
      onCancel={handleOnClose}
      title={t('UNPUBLISH_JOB_TITLE')}
      footer={[
        <Button type="primary" onClick={handleUnpublish} loading={loading}>
          {t('UNPUBLISH_TITLE')}
        </Button>,
        showOnHold && (
          <Button type="primary" onClick={handleOnHold} loading={loading}>
            {t('ON_HOLD_TITLE')}
          </Button>
        ),
      ]}
    >
      <StyledForm
        layout="vertical"
        name="add-client"
        form={form}
        scrollToFirstError={true}
      >
        <FormItem
          label={t('UNPUBLISH_JOB_REASON')}
          name="unpublished_reason"
          rules={[...requiredRule(t)]}
        >
          <Textarea autoSize={{ minRows: 4 }} />
        </FormItem>
      </StyledForm>
    </StyledModal>
  );
};

export default UnpublishJobModal;
