import React from 'react';
import SingleJob from '../SingleJob';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';
import SmallBackButton from '../../UI/buttons/SmallBackButton';
import { useParams } from 'react-router-dom';

const EmbedSingleJobPage = () => {
  const history = useHistoryWithUrlBase();
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <SmallBackButton size="middle" onClick={() => { history.push(`/assign-recruiters?local_id=${id}`) }} />
      <SingleJob
        isPublic={false}
        showBackButton={false}
      />
    </>
  )
};

export default EmbedSingleJobPage;
