import React, { useCallback, useEffect, useState } from 'react';
import RoundAddButton from '../../UI/buttons/RoundAddButton';
import { RecruiterManagementBoardModal } from '../recruiter/RecruiterManagementBoard';
import {
  CandidateInfo,
  Certificate,
  City,
  InterviewRoom,
  Job,
  Language,
  Role,
  Seniority,
  Skill,
  Specialization,
  Education,
} from '../../types';
import ClientJobListItem from '../jobs/jobList/ClientJobListItem';
import { JOBS } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { CLIENT_ROLE } from '../../constants/roles';
import CandidateInfoModal from '../candidates/CandidateInfoModal';
import { useAppSelector } from '../../store/hooks';
import axios from 'axios';
import { getRolesList } from '../../api/roles';
import { getCityList } from '../../api/regions';
import { getSkillsList } from '../../api/skills';
import { getSpecializationList } from '../../api/specialization';
import { getSeniorityList } from '../../api/seniority';
import { getCertificatesList } from '../../api/certificates';
import { getEducationList } from '../../api/education';
import { getAllInfo } from '../../utils/candidate';
import { getCandidateByInterview } from '../../api/candidates';
import ShowFeedback from '../candidates/ShowFeedback';
import { getInterview } from '../../api/interviews';

interface JobProps {
  jobs: Job[];
  languages: Language[];
  setJobs: React.Dispatch<React.SetStateAction<any>>;
}

const ClientJobs = ({ jobs, setJobs, languages }: JobProps) => {
  const [info, setInfo] = useState<CandidateInfo | null>(null);
  const [interview, setInterview] = useState<InterviewRoom | null>(null);

  const [data, setData] = useState<{
    seniority: Seniority[];
    certificates: Certificate[];
    cities: City[];
    domains: Specialization[];
    skills: Skill[];
    roles: Role[];
    education: Education[];
  }>({
    seniority: [],
    certificates: [],
    cities: [],
    domains: [],
    skills: [],
    roles: [],
    education: [],
  });

  const { user } = useAppSelector((state) => state.user);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const candidate = params.get('history');
  const interview_id = params.get('interview_id');
  const feedback_id = params.get('feedback_id');
  const [selectedJob, setSelectedJob] = useState<string | null>(null);
  const [selectedJobSpecialization, setSelectedJobSpecialization] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const talentSupplierModuleEnabled =
    user?.company?.talent_supplier_module_enabled;
  let [showModal, setShowModal] = useState<boolean>(false);

  const isClient = user?.role === CLIENT_ROLE;

  useEffect(() => {
    axios
      .all([
        getRolesList(),
        getCityList(),
        getSkillsList(),
        getSpecializationList(),
        getSeniorityList(),
        getCertificatesList(),
        getEducationList(),
      ])
      .then((res) => {
        setData({
          roles: res[0].data,
          cities: res[1].data,
          skills: res[2].data,
          domains: res[3].data,
          seniority: res[4].data,
          certificates: res[5].data,
          education: res[6].data,
        });
      });
  }, [user]);

  const collectAllData = useCallback(
    (list: CandidateInfo[]) => {
      return getAllInfo({
        candidates: list,
        cities: data.cities,
        skills: data.skills,
        roles: data.roles,
        domains: data.domains,
        certificates: data.certificates,
        education: data.education,
      });
    },
    [data],
  );

  useEffect(() => {
    if (candidate && interview_id) {
      getCandidateByInterview(interview_id).then((res) => {
        const item = {
          ...res.data,
          interviews: res.data.interviews,
        };
        setInfo(collectAllData([item])[0]);
      });
    } else {
      setInfo(null);
    }
  }, [candidate, interview_id, collectAllData]);

  useEffect(() => {
    if (interview_id) {
      getInterview(interview_id, history).then((res) => setInterview(res.data));
    } else {
      setInterview(null);
    }
  }, [interview_id, history]);

  return (
    <>
      {/*<RoundAddButton onClick={() => history.push(JOBS + '/edit/new/company_info')} />*/}
      <RoundAddButton onClick={() => history.push(JOBS + '/edit/new/simple')} />
      {/*
// @ts-ignore */}
      {/* Remove checkbox and header for now */}
      {/* {talentSupplierModuleEnabled && (
        <ClientJobListHeader
          selectedJob={selectedJob}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )} */}
      {jobs.map((job) => (
        <ClientJobListItem
          setShowModal={setShowModal}
          showModal={showModal}
          talentSupplierModuleEnabled={talentSupplierModuleEnabled}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          setSelectedJobSpecialization={setSelectedJobSpecialization}
          isClient={isClient}
          job={job}
          setJobs={setJobs}
          key={job.id}
          languages={languages}
        />
      ))}
      <RecruiterManagementBoardModal
        fromJobCreation={false}
        selectedJob={selectedJob}
        selectedJobSpecialization={selectedJobSpecialization}
        isVisible={showModal}
        onComplete={() => setShowModal(false)}
      />
      {user?.role === CLIENT_ROLE && info && (
        <CandidateInfoModal current={info} />
      )}
      {feedback_id && interview && <ShowFeedback info={interview} />}
    </>
  );
};

export default ClientJobs;
