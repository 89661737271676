import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { CandidateInfo } from '../../types';
import CandidateNameAvatar from '../candidates/CandidateNameAvatar';
import CandidateRole from '../candidates/CandidateRole';
import JobListInfoItem from '../jobs/jobList/JobListInfoItem';
import { ReactComponent as StarRoundedIcon } from '../../icons/star-rounded.svg';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import { ReactComponent as WalkIcon } from '../../icons/walk.svg';
import TagButton from '../../UI/buttons/TagButton';
import SalaryText from '../candidates/SalaryText';
import Button from '../../UI/buttons/Button';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import CandidateActionButton from '../candidates/CandidateActionButton';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { addDecimalPoints, generateKey } from '../../utils/candidate';
import { useHistory } from 'react-router-dom';
import { CANDIDATES } from '../../constants/routes';
import useCurrency from '../../hooks/useCurrency';

interface CandidateList {
  candidates: CandidateInfo[];
  setCandidates: React.Dispatch<React.SetStateAction<CandidateInfo[]>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}
const StyledTable = styled(Table)`
  & .ant-table-tbody > tr > td {
    padding: 1rem 0.5rem;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      & button {
        margin-left: auto;
      }
    }
  }
`;
const ActionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 70px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: #627d95;
`;

const RecruiterCandidates = ({
  candidates,
  setCandidates,
  setId,
}: CandidateList) => {
  const [t] = useTranslation();
  const defaultCurrency = useCurrency();
  const history = useHistory();
  const openModal = (candidate: CandidateInfo) => {
    const search = new URLSearchParams(history.location.search);
    search.set('history', candidate.id);
    candidate.interviews.length &&
      search.set('interview_id', candidate.interviews[0]?.id);
    const query = search.toString();
    history.push(CANDIDATES + '?' + query);
  };
  const columns: ColumnsType<any> = [
    {
      title: '',
      width: 200,
      key: 'name',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'name',
      render: (name, record) => (
        <CandidateNameAvatar
          name={name}
          color={record.color}
          onClick={() => openModal(record)}
        />
      ),

      fixed: 'left',
    },
    {
      title: '',
      width: 200,
      dataIndex: 'name',
      key: 'name-sm',
      responsive: ['xs'],
      render: (name, record) => (
        <CandidateNameAvatar
          name={name}
          color={record.color}
          onClick={() => openModal(record)}
        />
      ),
    },
    {
      title: '',
      width: 150,
      key: 'role',
      dataIndex: 'current_role',
      render: (role, record) => (
        <CandidateRole
          company={record.current_company}
          role={record.current_role}
        />
      ),
    },
    {
      title: '',
      width: 140,
      key: 'info',
      dataIndex: 'travel_max',
      render: (_, record) => {
        return (
          <div>
            <JobListInfoItem
              icon={<StarRoundedIcon />}
              title={
                record.specializations.length
                  ? record.specializations.join(', ')
                  : '-'
              }
            />
            <JobListInfoItem
              icon={<LocationIcon />}
              title={record.city_of_residence ? record.city_of_residence : '-'}
            />
            <JobListInfoItem
              icon={<WalkIcon />}
              title={`${record.travel_max ? record.travel_max : 0} ${t(
                'MINUTES',
              )}`}
            />
          </div>
        );
      },
    },
    {
      title: '',
      width: 172,
      key: 'skills',
      dataIndex: 'skills',
      render: (skills) => (
        <Skills>
          {skills.map((el: string) => (
            <TagButton key={el}>{el}</TagButton>
          ))}
        </Skills>
      ),
    },
    {
      title: '',
      width: 170,
      key: 'salary',
      dataIndex: 'salary',
      render: (salary, record) => {
        const { curr } = defaultCurrency.changeCurrency(record.compensation_currency);
        return (
          <div>
            <SalaryText>{`${t('MONTHLY_SALARY')}: ${curr(addDecimalPoints(
              salary.toString(),
            ))}`}</SalaryText>
            <SalaryText>{`${t('TOTAL_SALARY')}: ${curr(salary &&
              addDecimalPoints(
                Math.round(
                  parseInt(salary) * 12.96 +
                  (record.bonus ? 12 * record.bonus : 0),
                ).toString(),
              )
            )}`}</SalaryText>
          </div>
        )
      },
    },
    {
      title: '',
      width: 170,
      key: 'action',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => (
        <ActionSection>
          <Button size="small" type="primary" onClick={() => setId(record.id)}>
            {t('APPOINT')}
            <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
          </Button>
          <CandidateActionButton candidate={record} onAction={setCandidates} />
        </ActionSection>
      ),
    },
    {
      title: '',
      width: 140,
      dataIndex: 'id',
      key: 'action-xs',
      responsive: ['xs'],
      render: (id, record) => (
        <ActionSection>
          <Button size="small" type="primary" onClick={() => setId(record.id)}>
            {t('APPOINT')}
            <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
          </Button>
          <CandidateActionButton candidate={record} onAction={setCandidates} />
        </ActionSection>
      ),
    },
  ];
  return (
    <StyledTable
      locale={{ emptyText: ' ' }}
      columns={columns}
      dataSource={candidates}
      showHeader={false}
      scroll={{ x: 950 }}
      pagination={false}
      rowKey={() => generateKey(5)}
    />
  );
};

export default RecruiterCandidates;
