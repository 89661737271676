import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import CandidateNameAvatar from '../candidates/CandidateNameAvatar';
import CandidateRole from '../candidates/CandidateRole';
import CandidateRecruiterInfo from '../candidates/CandidateRecruiterInfo';
import InfoText from '../candidates/InfoText';
import MatchTag from '../candidates/MatchTag';
import Button from '../../UI/buttons/Button';
import { addDecimalPoints } from '../../utils/candidate';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { generateKey } from '../../utils/candidate';
import { getClientDashboardCandidates } from '../../api/dashboard';
import ListWidget from './ListWidget';
import { DashboardCandidate } from '../../types';
import { CANDIDATES } from '../../constants/routes';
import { getRandomColor } from '../../constants/palette';
import useCurrency from '../../hooks/useCurrency';

const EmptyText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
  padding: 5rem 0;
`;
const StyledTable = styled(Table)`
  & .ant-table-tbody > tr {
    &:hover {
      & > td {
        background: #ffffff;
      }
    }
    & > td {
      padding: 0.5rem 0.5rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        text-align: right;
        padding-right: 0;
        & button {
          margin-left: auto;
        }
      }
    }
  }
`;
const ClientDashboardCandidates = () => {
  const [candidates, setCandidates] = useState<DashboardCandidate[]>([]);
  const { t } = useTranslation();
  const defaultCurrency = useCurrency();
  const history = useHistory();
  const loader = useRef(null);
  const [nextPage, setNextPage] = useState(null);
  const loadCandidates = useCallback(async (query: string) => {
    let res = await getClientDashboardCandidates(query);
    if (res) {
      setCandidates((state: any) => [
        ...state,
        ...res.data.results.map((el: DashboardCandidate) => ({
          ...el,
          color: getRandomColor(),
        })),
      ]);
      setNextPage(res.data.pagination.next);
    }
  }, []);

  useEffect(() => {
    loadCandidates('');
  }, [loadCandidates]);

  const handleObserver = useCallback(
    async (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search + '&cursor=' + nextPage
        : '?cursor=' + nextPage
        }`;
      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        await loadCandidates(cursor);
      }
    },
    [nextPage, history.location.search, loadCandidates],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [nextPage, handleObserver]);


  const handleInfo = (el: DashboardCandidate) => {
    const search = new URLSearchParams(history.location.search);
    search.set('history', el.candidatesnapshot.id);
    search.set('interview_id', el.interview_id);
    const query = search.toString();
    history.push(CANDIDATES + '?filter=in-process&' + query);
  };

  const columns: ColumnsType<any> = [
    {
      title: '',
      width: 180,
      key: 'name',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'name',
      render: (name, record) => {
        return (
          <CandidateNameAvatar
            name={record.candidatesnapshot.name}
            color={record.color}
          />
        );
      },

      fixed: 'left',
    },
    {
      title: '',
      width: 170,
      dataIndex: 'name',
      key: 'name-sm',
      responsive: ['xs'],
      render: (name, record) => {
        return (
          <CandidateNameAvatar
            name={record.candidatesnapshot.name}
            color={record.color}
          />
        );
      },
    },
    {
      title: '',
      width: 200,
      key: 'role',
      dataIndex: 'current_role',
      render: (role, record) => (
        <CandidateRole
          company={record.candidatesnapshot.current_company}
          role={record.candidatesnapshot.current_role}
          color="light"
        />
      ),
    },
    {
      title: '',
      width: 80,
      key: 'salary',
      dataIndex: 'salary',
      render: (salary, record) => {
        const { curr } = defaultCurrency.changeCurrency(record.compensation_currency);
        return (
          <InfoText>{`${curr(record.candidatesnapshot.salary) &&
            addDecimalPoints(record.candidatesnapshot.salary.toString())
            }`}</InfoText>
        )
      },
    },
    {
      title: '',
      width: 190,
      key: 'interviews',
      dataIndex: 'interviews',
      render: (interviews, record) => {
        return (
          <CandidateRecruiterInfo
            recruiter={record.recruiter}
            color={record.color}
          />
        );
      },
    },
    {
      title: '',
      width: 160,
      key: 'interviews',
      dataIndex: 'interviews',
      render: (interviews, record) => <InfoText>{record.job.title}</InfoText>,
    },
    {
      title: '',
      width: 100,
      key: 'interviews',
      dataIndex: 'interviews',
      render: (interviews, record) =>
        record.match_score > 89 && <MatchTag>{t('TOP_MATCH')}</MatchTag>,
    },
    {
      title: '',
      width: 110,
      key: 'action',
      responsive: ['sm', 'xxl', 'xl', 'lg', 'md'],
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => (
        <Button size="small" type="primary" onClick={() => handleInfo(record)}>
          {t('REVIEW')}
          <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
        </Button>
      ),
    },
    {
      title: '',
      width: 110,
      dataIndex: 'id',
      key: 'action-xs',
      responsive: ['xs'],
      render: (id, record) => (
        <Button size="small" type="primary" onClick={() => handleInfo(record)}>
          {t('REVIEW')}
          <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
        </Button>
      ),
    },
  ];

  return (
    <ListWidget title={t('NEW_CANDIDATES')}>
      {candidates && candidates.length === 0 && (
        <EmptyText>{t('NO_CANDIDATE_YET')}</EmptyText>
      )}
      {candidates && candidates.length > 0 && (
        <>
          <StyledTable
            locale={{ emptyText: ' ' }}
            columns={columns}
            dataSource={candidates}
            showHeader={false}
            scroll={{ x: 950 }}
            pagination={false}
            rowKey={() => generateKey(5)}
          />
        </>
      )}
      <div ref={loader} />
    </ListWidget>
  );
};

export { ClientDashboardCandidates };
