import React from 'react';
import CheckboxInput from '../../UI/inputs/CheckboxInput';
import { SortingType, SortOrder } from './RecruiterManagementBoard';
import { ReactComponent as UpArrow } from '../../icons/sort_asc.svg';
import { ReactComponent as DownArrow } from '../../icons/sort_desc.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useHirePortTheme from '../../hooks/useHirePortTheme';

const StyledUpArrow = styled(UpArrow)`
  & path {
    stroke: ${(props) => props.color};
  }
`;
const StyledDownArrow = styled(DownArrow)`
  & path {
    stroke: ${(props) => props.color};
  }
`;

const HoverDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

interface TableHeaderNameProps {
  name: string;
  intendedSortType: SortingType;
  currentSortingType: SortingType;
  currentSortingOrder: SortOrder;
}
function TableHeaderName({
  name,
  intendedSortType,
  currentSortingOrder,
  currentSortingType,
}: TableHeaderNameProps) {
  const theme = useHirePortTheme();
  let upArrowColor = '#627d95';
  let downArrowColor = '#627d95';
  if (intendedSortType === currentSortingType) {
    if (currentSortingOrder === 'DESC') {
      downArrowColor = theme.primaryColor;
      upArrowColor = '#627d95';
    }
    if (currentSortingOrder === 'ASC') {
      upArrowColor = theme.primaryColor;
      downArrowColor = '#627d95';
    }
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <StyledDownArrow
          color={downArrowColor}
          style={{ marginBottom: '-9px' }}
        />
        <StyledUpArrow color={upArrowColor} />
      </span>
      {name}
    </div>
  );
}

interface RecruiterSelectTableHeaderProps {
  handleSelectAll: (isChecked: boolean) => void;
  setSortType: React.Dispatch<React.SetStateAction<SortingType>>;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>;
  currentSortType: SortingType;
  currentSortOrder: SortOrder;
}
export default function RecruiterSelectTableHeader({
  handleSelectAll,
  setSortOrder,
  setSortType,
  currentSortType,
  currentSortOrder,
}: RecruiterSelectTableHeaderProps) {
  const [t] = useTranslation();
  let handleSortingByHeader = (sortType: SortingType) => {
    if (sortType !== currentSortType) {
      setSortType(sortType);
      setSortOrder('ASC');
    } else {
      if (currentSortOrder === 'DESC') setSortOrder('ASC');
      if (currentSortOrder === 'ASC') setSortOrder('DESC');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderBottom: '1px solid #DAE5EC',
        padding: '6px 0',
        color: '#627D95',
        fontWeight: 600,
      }}
    >
      <div style={{ flex: '5%' }}>
        <CheckboxInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSelectAll(e.target.checked);
          }}
        />
      </div>
      <HoverDiv
        style={{ flex: '20%' }}
        onClick={() => {
          handleSortingByHeader('NAME');
        }}
      >
        <TableHeaderName
          currentSortingOrder={currentSortOrder}
          currentSortingType={currentSortType}
          intendedSortType={'NAME'}
          name={'Name'}
        />
      </HoverDiv>
      <HoverDiv
        style={{ flex: '11%' }}
        onClick={() => {
          handleSortingByHeader('TYPE');
        }}
      >
        <TableHeaderName
          currentSortingOrder={currentSortOrder}
          currentSortingType={currentSortType}
          intendedSortType={'TYPE'}
          name={'Type'}
        />
      </HoverDiv>
      <div style={{ flex: '35%' }}>{t('RECRUITER_MANAGEMENT_DOMAINS')}</div>
      <div style={{ flex: '19%' }}>
        {t('RECRUITER_MANAGEMENT_FEE_STRUCTURE')}
      </div>
      <HoverDiv
        style={{ flex: '5%' }}
        onClick={() => {
          handleSortingByHeader('RATING');
        }}
      >
        <TableHeaderName
          currentSortingOrder={currentSortOrder}
          currentSortingType={currentSortType}
          intendedSortType={'RATING'}
          name={'Rating'}
        />
      </HoverDiv>
      <div style={{ width: '10px' }} />
    </div>
  );
}
