import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';

const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox-inner {
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid #aebeca;
    border-radius: 0.2rem;
    &:after {
      left: 24%;
    }
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #627d95;
    border-color: #627d95;
    border-radius: 0.2rem;
  }

  &:hover::after,
  &:hover .ant-checkbox::after {
    visibility: hidden;
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #627d95;
    border-radius: 0.2rem;
    &::after {
      left: 24%;
    }
  }

  & .ant-checkbox-input:hover {
    & + .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.activeColor};
      border-radius: 0.2rem;
    }
  }

  & .ant-checkbox-checked {
    & .ant-checkbox-input:hover {
      & + .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.activeColor};
        border-radius: 0.2rem;
      }
    }
  }
`;

const CheckboxInput = ({ ...rest }) => {
  return <StyledCheckbox {...rest} />;
};

export default CheckboxInput;
