import React from 'react';
import { CandidateInfo } from '../../types';
import styled from 'styled-components';
import NameAvatar from '../../UI/NameAvatar';
import { useTranslation } from 'react-i18next';
import JobListInfoItem from '../jobs/jobList/JobListInfoItem';
import { ReactComponent as StarRoundedIcon } from '../../icons/star-rounded.svg';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import { ReactComponent as WalkIcon } from '../../icons/walk.svg';
import TagButton from '../../UI/buttons/TagButton';
import SalaryText from './SalaryText';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import Button from '../../UI/buttons/Button';
import { addDecimalPoints } from '../../utils/candidate';
import PointsLeft from "../recruiter/PointsLeft";
import useCurrency from '../../hooks/useCurrency';

interface ListProps {
  candidate: CandidateInfo;
  recruiterPoints?: number;
  setCandidate: React.Dispatch<React.SetStateAction<CandidateInfo | null>>;
}
const Wrapper = styled.div`
  display: flex;
  padding: 0.75rem 0 1rem;
  border-bottom: 1px solid #dae5ec;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;
const Candidate = styled.div`
  display: flex;
  align-items: center;
  width: 25%;
  @media (max-width: 992px) {
    width: 60%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const Text = styled.div`
  margin-left: 0.5rem;
`;
const Name = styled.h5`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  margin: 0 0 0.25rem;
`;
const Company = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
`;
const Match = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #eff9f3;
  text-transform: uppercase;
  margin: 0 0 0.5rem;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
`;
const Global = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const Action = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 992px) {
    justify-content: flex-start;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const Info = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 992px) {
    width: 40%;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 1rem;
  }
`;
const Skills = styled.div`
  width: 28%;
  flex-wrap: wrap;
  max-height: 70px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 1rem;
  }
`;
const Salary = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 1rem;
  }
`;
const StyledButton = styled(Button)`
  &.full-width {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const AppointCandidateList = ({ candidate, recruiterPoints, setCandidate }: ListProps) => {
  const [t] = useTranslation();
  const { curr } = useCurrency(candidate.compensation_currency);
  return (
    <Wrapper>
      <Global>
        <Candidate>
          <NameAvatar name={candidate.name} color={candidate.color} />
          <Text>
            <Name>{candidate.name}</Name>
            <Company>{candidate.current_role}</Company>
            <Company>
              {t('AT')} {candidate.current_company}
            </Company>
          </Text>
        </Candidate>
        <Info>
          <JobListInfoItem
            icon={<StarRoundedIcon />}
            title={candidate.specializations.join(', ')}
          />
          <JobListInfoItem
            icon={<LocationIcon />}
            title={candidate.city_of_residence}
          />
          <JobListInfoItem
            icon={<WalkIcon />}
            title={`${candidate.travel_max ? candidate.travel_max : 0} ${t('MINUTES')}`}
          />
        </Info>
        <Skills>
          {candidate.skills.map((el: string) => (
            <TagButton key={el}>{el}</TagButton>
          ))}
        </Skills>
        <Salary>
          <SalaryText>{`${t('MONTHLY_SALARY')}: ${curr(candidate.salary && addDecimalPoints(candidate.salary.toString()))
            }`}</SalaryText>
          <SalaryText>{`${t('TOTAL_SALARY')}: ${curr(candidate.salary &&
            addDecimalPoints(
              (parseInt(String(candidate.salary)) * 12).toString(),
            )
          )}`}</SalaryText>
        </Salary>
      </Global>
      <Action>
        {!!candidate.match_score && (
          <Match>
            {candidate.match_score ? candidate.match_score : 0}% {t('MATCH')}
          </Match>
        )}
        <StyledButton
          className="full-width"
          size="small"
          type="primary"
          onClick={() => setCandidate(candidate)}
        >
          {t('APPOINT')}
          <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
        </StyledButton>
        <PointsLeft points={recruiterPoints} customClass={'color-black'} />
      </Action>
    </Wrapper>
  );
};

export default AppointCandidateList;
