import SelectInput from '../../UI/inputs/Select';
import Button from '../../UI/buttons/Button';
import NewSearchInput from '../../UI/inputs/NewSearchInput';

import { Switch } from 'antd';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { ReactComponent as EmailIcon } from '../../icons/info-email.svg';
import { Col, Form, Row, Select } from 'antd';
import styled from 'styled-components';
import FormItem from '../form/FormItem';
import AddCompanyRecruiterForm from '../recruiter/AddCompanyRecruiterForm';
import { RATING_FILTERS } from '../../constants/recruiters';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { User } from '../../types';
import useHirePortTheme from '../../hooks/useHirePortTheme';

const RecruiterManagementForm = styled.div`
  padding: 1.5rem 2rem 0;
  border-radius: ${({ theme }) => theme.radius};
  background: #ffffff;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 998;
  @media (max-width: 576px) {
    position: relative;
  }
`;

const StyledEmailIcon = styled(EmailIcon)`
  width: 20px;
  height: 20px;
  margin-right: 0;
  stroke-width: 1.5;
  & path {
    stroke: ${({ theme }) => theme.primaryColor};
    &:disabled,
    &.disabled {
      stroke: #aebeca;
    }
  }
`;

const MediumSelectInput = styled(SelectInput)`
  &.ant-select-multiple {
    & .ant-select-selection-item {
      line-height: 1.5rem;
      font-size: 0.75rem;
      color: #4b6276;
      border-radius: 0.25rem;
      background-color: ${({ theme }) => theme.lozengeColor};
      padding: 0;
      padding-left: 5px;
    }
  }
`;

const EmailInviteBtn = styled(Button)`
  box-sizing: border-box;
  height: 40px;
  &:disabled,
  &.disabled {
    height: 40px;
    cursor: not-allowed;
    background: #fff;
    color: #aebeca;
    border-color: #aebeca;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
      & path {
        transition: all 0.3s;
        stroke: #fff;
      }
    }
    & path {
      stroke: #aebeca;
    }
  }
`;

const RecruiterManagementFilters = ({
  domains,
  subUser,
  onFilter,
  showMarketplaceRecruiters,
  setShowMarketplaceRecruiters,
}: {
  domains: any[];
  subUser: User['sub_user'];
  showMarketplaceRecruiters: boolean;
  onFilter: (_: any, allValues: any) => void;
  setShowMarketplaceRecruiters: (value: boolean) => void;
}) => {
  const theme = useHirePortTheme();
  const [t] = useTranslation();

  const [isAddRecruiterFormVisible, setIsAddRecruiterFormVisible] =
    useState<boolean>(false);

  const handleInviteRecruiterBtnClick = () => {
    setIsAddRecruiterFormVisible(true);
  };

  return (
    <RecruiterManagementForm>
      <Form
        scrollToFirstError={true}
        layout="horizontal"
        onFieldsChange={onFilter}
      >
        <Row justify="space-between" gutter={10} wrap={false}>
          <Row justify="start" gutter={10}>
            <Col>
              <FormItem name="full_name">
                <NewSearchInput
                  placeholder={t('RECRUITER_MANAGEMENT_FILTER_FULL_NAME')}
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem name="company_name">
                <NewSearchInput placeholder="Company name" />
              </FormItem>
            </Col>
            <Col>
              <FormItem>
                <EmailInviteBtn
                  size="middle"
                  onClick={handleInviteRecruiterBtnClick}
                  disabled={isAddRecruiterFormVisible}
                >
                  <StyledEmailIcon />
                  {t('Invite talent supplier')}
                </EmailInviteBtn>
              </FormItem>
            </Col>
          </Row>
          {!subUser && (
            <Col>
              <FormItem name="own_recruiter">
                <Switch
                  defaultChecked={showMarketplaceRecruiters}
                  checkedChildren={t('RECRUITER_MANAGEMENT_SWITCH_TOOLTIP_OFF')}
                  unCheckedChildren={t(
                    'RECRUITER_MANAGEMENT_SWITCH_TOOLTIP_ON',
                  )}
                  style={{
                    marginTop: '0.6rem',
                    verticalAlign: 'start',
                    backgroundColor: showMarketplaceRecruiters
                      ? theme.primaryColor
                      : '#627d95',
                  }}
                  onChange={setShowMarketplaceRecruiters}
                />
              </FormItem>
            </Col>
          )}
        </Row>
        <AddCompanyRecruiterForm
          isVisible={isAddRecruiterFormVisible}
          setIsVisible={setIsAddRecruiterFormVisible}
        />
        <Row justify="start" gutter={10}>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem name="recommended_badge">
              <SelectInput
                showSearch
                placeholder={t('RECRUITER_MANAGEMENT_FILTER_CATEGORY')}
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
                allowClear
              >
                <Select.Option value="Recommended">
                  Recommended recruiters
                </Select.Option>
              </SelectInput>
            </FormItem>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem name="domain">
              <MediumSelectInput
                showSearch
                placeholder={t('RECRUITER_MANAGEMENT_FILTER_SPECIALISATION')}
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
                mode="multiple"
                allowClear
              >
                {domains.map((dom) => (
                  <Select.Option value={dom.value} key={dom.value}>
                    {dom.title}
                  </Select.Option>
                ))}
              </MediumSelectInput>
            </FormItem>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 8 }}>
            <FormItem name="ratings">
              <SelectInput
                showSearch
                placeholder={t('RECRUITER_MANAGEMENT_FILTER_RATINGS')}
                optionFilterProp="children"
                filterOption={true}
                dropdownMatchSelectWidth={false}
                className="custom-select"
                suffixIcon={<ArrowDownIcon />}
              >
                <Select.Option value="">{t('NONE')}</Select.Option>
                {RATING_FILTERS.map((ratingFilter, index) => (
                  <Select.Option value={ratingFilter.value} key={index}>
                    {ratingFilter.name}
                  </Select.Option>
                ))}
              </SelectInput>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </RecruiterManagementForm>
  );
};

export default RecruiterManagementFilters;
