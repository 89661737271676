export const RATING_FILTERS = [
  {
    name: '1 or more stars',
    value: 1,
  },
  {
    name: '2 or more stars',
    value: 2,
  },
  {
    name: '3 or more stars',
    value: 3,
  },
  {
    name: '4 or more stars',
    value: 4,
  },
  {
    name: '5 stars',
    value: 5,
  },
];

/**
 * Determines the pre-filled recruiter compensation type when adding and editing a job
 */
export const HIREPORT_DEFAULT_COMPENSATION_TYPE = 'percentage';
/**
 * Determines the pre-filled recruiter compensation amount when adding and editing a job
 */

export const HIREPORT_DEFAULT_GUARANTEE_PERIOD = 30;

export const HIREPORT_DEFAULT_COMMUNICATION_TYPE = 'chat';
