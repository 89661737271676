import React from 'react';
import Layout from '../components/layout/Layout';
import { useTranslation } from 'react-i18next';
import Header from '../components/layout/Header';
import PageWrapper from '../components/layout/PageWrapper';
import styled from 'styled-components';

const Content = styled.div`
  margin: 2.5rem auto;
  max-width: 50%;
  width: 100%;
`;
const Title = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  color: #061c2e;
  margin: 0 0 0.5rem;
`;
const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  text-align: left;
  color: #061c2e;
  margin: 0 0 1.5rem;
`;
const PrivacyPolicy = () => {
  const [t] = useTranslation();
  return (
    <Layout>
      <PageWrapper>
        <Header title={t('PRIVACY_POLICY')} />
        <Content>
          <Title>{t('PRIVACY_TITLE_1')}</Title>
          <Text>{t('PRIVACY_TEXT_1')}</Text>
          <Text>{t('PRIVACY_TEXT_1_1')}</Text>
          <Text>{t('PRIVACY_TEXT_1_2')}</Text>

          <Title>{t('PRIVACY_TITLE_2')}</Title>
          <Text>{t('PRIVACY_TEXT_2')}</Text>

          <Title>{t('PRIVACY_TITLE_3')}</Title>
          <Text>{t('PRIVACY_TEXT_3_1')}</Text>
          <Text>{t('PRIVACY_TEXT_3_2')}</Text>
          <Text>{t('PRIVACY_TEXT_3_3')}</Text>
          <Text>{t('PRIVACY_TEXT_3_4')}</Text>
          <Text>{t('PRIVACY_TEXT_3_5')}</Text>

          <Title>{t('PRIVACY_TITLE_4')}</Title>
          <Text>{t('PRIVACY_TEXT_4_1')}</Text>
          <Text>{t('PRIVACY_TEXT_4_2')}</Text>

          <Title>{t('PRIVACY_TITLE_5')}</Title>
          <Text>{t('PRIVACY_TEXT_5')}</Text>

          <Title>{t('PRIVACY_TITLE_6')}</Title>
          <Text>{t('PRIVACY_TEXT_6_1')}</Text>
          <Text>{t('PRIVACY_TEXT_6_2')}</Text>

          <Title>{t('PRIVACY_TITLE_7')}</Title>
          <Text>{t('PRIVACY_TEXT_7')}</Text>

          <Title>{t('PRIVACY_TITLE_8')}</Title>
          <Text>{t('PRIVACY_TEXT_8_1')}</Text>
          <Text>{t('PRIVACY_TEXT_8_2')}</Text>

          <Title>{t('PRIVACY_TITLE_9')}</Title>
          <Text>{t('PRIVACY_TEXT_9')}</Text>

          <Title>{t('PRIVACY_TITLE_10')}</Title>
          <Text>{t('PRIVACY_TEXT_10')}</Text>
        </Content>
      </PageWrapper>
    </Layout>
  );
};

export default PrivacyPolicy;
