import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DepartmentIcon } from '../../../icons/department.svg';
import { ReactComponent as LocationIcon } from '../../../icons/location.svg';
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg';
import { ReactComponent as SalaryIcon } from '../../../icons/salary.svg';
import { ReactComponent as VisaIcon } from '../../../icons/visa-sponsorship.svg';
import { ReactComponent as RemoteIcon } from '../../../icons/remote-days.svg';
import { Job, Language } from '../../../types';
import JobStage from './JobStage';
import JobStatistic from './JobStatistic';
import ListAction from './ListAction';
import { CLIENT_ROLE } from '../../../constants/roles';
import ActionButton from './ActionButton';
import JobDescription from '../jobList/JobDescription';
import JobListInfoItem from './JobListInfoItem';
import { useHistory } from 'react-router-dom';
import { JOBS } from '../../../constants/routes';
import {
  getCandidateStatistics,
  groupByStageName,
} from '../../../utils/candidate';
import { deleteJob, publishJob } from '../../../api/jobs';
import { workingTypeOptions } from '../../../constants/common';
import { useAppSelector } from '../../../store/hooks';
import UnpublishJobModal from '../UnpublishJobModal';
import getEmploymentTypeSummary from '../../../utils/getEmploymentTypeSummary';
import useEmploymentTypes from '../../../hooks/useEmploymentTypes';
import {
  calculateDaysBefore,
  calculateDaysSinceJobOpened,
} from '../../../utils/jobs';
import JobSelectedRecruitersStatistic from './JobSelectedRecruitersStatistic';
import useCurrency from '../../../hooks/useCurrency';

interface JobProps {
  job: Job;
  languages: Language[];
  setJobs: React.Dispatch<React.SetStateAction<any>>;
  setSelectedJobSpecialization: React.Dispatch<React.SetStateAction<any>>;
  role?: string;
  isClient: boolean;
  selectedJob: string | null;
  setSelectedJob: React.Dispatch<React.SetStateAction<any>>;
  talentSupplierModuleEnabled?: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
}

export const ListItem = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #dae5ec;
  padding: 1.5rem 0 1rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

export const JobInfo = styled.div`
  width: 18%;
  &.draft {
    & p {
      color: #aebeca;
    }
  }
  @media (max-width: 992px) {
    width: 45%;
  }
  @media (max-width: 576px) {
    width: 100%;
    order: 3;
    margin-top: 1rem;
  }
`;
export const JobStageInfo = styled.div`
  width: 25%;
  &.draft p {
    color: #aebeca;
  }
  @media (max-width: 992px) {
    width: 100%;
    order: 5;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const JobStatisticsList = styled.div`
  width: 14%;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    order: 6;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

const { confirm } = Modal;
const ClientJobListItem = ({
  job,
  setJobs,
  role = CLIENT_ROLE,
  languages,
  isClient,
  setSelectedJob,
  setSelectedJobSpecialization,
  talentSupplierModuleEnabled,
  setShowModal,
  showModal,
}: JobProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const currency = useCurrency(job.compensation_currency);
  const { user } = useAppSelector((state) => state.user);
  const [isUnpublishModalOpen, setisUnpublishModalOpen] =
    useState<boolean>(false);
  const [online, setOnline] = useState(
    Boolean(job.status === 'published' || job.status === 'under review'),
  );

  const daysOnline = calculateDaysBefore(job) + 1;
  const interviews: { [key: string]: any } = useMemo(() => {
    let stages: { [key: string]: [] } = {};
    job.stages?.forEach((stage) => {
      // @ts-ignore
      stages[stage.stage.name] = [];
    });
    return groupByStageName(job.interviews, {
      '1st Interview': [],
      '2nd+ Interview': [],
      offered: [],
      hired: [],
    });
  }, [job.interviews, job.stages]);

  const statistics = getCandidateStatistics(job.interviews);

  const onDelete = () => {
    confirm({
      title: `${t('DELETE_POPUP_TITLE')}?`,
      okText: t('CONFIRM_TITLE'),
      okType: 'danger',
      cancelText: t('REJECT_TITLE'),
      cancelButtonProps: {
        type: 'default',
      },
      onOk() {
        deleteJob(job.id).then(() => {
          message.error(t('JOB_IS_DELETED'), 5);
          setJobs((st: any) => st.filter((el: any) => el.id !== job.id));
        });
      },
    });
  };

  const editJob = () => {
    if (user?.onboarding_status !== 'completed') {
      history.push(JOBS + `/edit/${job.id}/company_info`);
    } else {
      history.push(JOBS + `/edit/${job.id}/info`);
    }
  };
  const onPublish = () => {
    publishJob(job.id).then((res) => {
      setJobs((st: any) =>
        st.map((el: any) => {
          if (el.id === job.id) {
            return { ...el, status: res.data.status };
          }
          return el;
        }),
      );
    });
  };
  const onUnpublish = () => {
    setisUnpublishModalOpen(true);
    /*unpublishJob(job.id).then((res) => {
      message.success(t('JOB_IS_UNPUBLISHED'), 5);
      setJobs((st: any) =>
        st.map((el: any) => {
          if (el.id === job.id) {
            return { ...el, status: res.data.status };
          }
          return el;
        }),
      );
    });*/
  };

  const workingTp = workingTypeOptions.find(
    ({ id }) => id === job.working_type,
  );

  // const shouldCheckBoxBeDisabled = (): boolean => {
  //   if (selectedJob === null) return false;
  //   return job.id !== selectedJob ? true : false;
  // };

  const employmentTypes = useEmploymentTypes();
  const employmentTypeSummary = getEmploymentTypeSummary(
    t,
    currency,
    job.employment_type,
    employmentTypes,
  );
  const detailFields = employmentTypeSummary?.fields['client_list'] || [];

  return (
    <ListItem>
      {/* Currently do not use the checkbox functionatlity. To bring back in the future */}
      {/* {talentSupplierModuleEnabled && (
        <CheckboxInput
          disabled={shouldCheckBoxBeDisabled()}
          checked={job.id === selectedJob ? true : false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              setSelectedJobSpecialization(job.specialization);
              setSelectedJob(job.id);
            } else {
              setSelectedJobSpecialization(null);
              setSelectedJob(null);
            }
          }}
        />
      )} */}
      <JobDescription
        job={job}
        online={online}
        setOnline={setOnline}
        employmentTypeName={employmentTypeSummary?.employmentType}
        role={CLIENT_ROLE}
        language={
          job.languages?.map(
            (el) => languages.find((lng) => lng.code === el)?.name,
          ) || []
        }
      />
      <JobInfo className={!online ? 'draft' : ''}>
        {job?.department && (
          <JobListInfoItem icon={<DepartmentIcon />} title={job?.department} />
        )}
        <JobListInfoItem
          icon={<LocationIcon />}
          title={job.company?.city?.name ? job.company?.city?.name : '-'}
        />
        {detailFields.map(({ id, label, value, displayHint }) => (
          <JobListInfoItem
            key={id}
            icon={displayHint === 'clock' ? <ClockIcon /> : <SalaryIcon />}
            title={`${label} ${value}`}
          />
        ))}

        {job.working_type === 'hybrid' && (
          <JobListInfoItem
            icon={<RemoteIcon />}
            title={t('REMOTE_WORKING_DAY_TEXT', {
              day: job.days_working_from_home || 0,
            })}
          />
        )}

        {job.working_type === 'full_office' && (
          <JobListInfoItem
            icon={<RemoteIcon />}
            title={t('REMOTE_NOT_POSSIBLE_TEXT')}
          />
        )}

        {job.working_type !== 'hybrid' &&
          job.working_type !== 'full_office' && (
            <JobListInfoItem
              icon={<RemoteIcon />}
              title={t(`${workingTp?.label}_POSSIBLE` || '')}
            />
          )}

        <JobListInfoItem
          icon={<VisaIcon />}
          title={
            job.is_visa_available ? t('VISA_TITLE') : t('NO_VISA_POSSIBLE_TEXT')
          }
        />
      </JobInfo>
      <JobStageInfo className={!online ? 'draft' : ''}>
        {Object.keys(interviews).length
          ? Object.keys(interviews).map((stage) => (
            <JobStage
              stage={stage}
              candidates={interviews[stage]}
              key={stage}
            />
          ))
          : ''}
      </JobStageInfo>
      <JobStatisticsList>
        <>
          {!isClient && (
            <JobStatistic title={calculateDaysSinceJobOpened(job, role, t)} />
          )}
          {talentSupplierModuleEnabled && (
            <JobSelectedRecruitersStatistic
              setShowModal={setShowModal}
              job={job}
              showModal={showModal}
              setSelectedJob={setSelectedJob}
              setSelectedJobSpecialization={setSelectedJobSpecialization}
              numberOfSelectedRecruiters={job.number_of_selected_recruiters}
              highThreshold={35}
            />
          )}
          <JobStatistic title={online ? `${t('DAYS_ONLINE')}: ${daysOnline}` : t('JOB_STATUS_OFFLINE')} />
          <JobStatistic title={`${t('VIEWS')}: ${job.number_of_views}`} />
          <JobStatistic title={`${t('APPLY_TITLE')}: ${statistics.sum}`} />
        </>
      </JobStatisticsList>
      <ListAction
        status={job.status}
        id={job.id}
        content={
          role === CLIENT_ROLE ? (
            <>
              <ActionButton onClick={editJob} actionType="edit" />
              {/*
              {(job.status === 'draft' || job.status === 'unpublished') && (
                <ActionButton onClick={onDelete} actionType="delete" />
              )}
              */}
              {job.status !== 'draft' && job.status !== 'unpublished' && (
                <ActionButton onClick={onUnpublish} actionType="unpublish" />
              )}
              {(job.status === 'unpublished' || job.status === 'on_hold') && (
                <ActionButton onClick={onPublish} actionType="publish" />
              )}
            </>
          ) : (
            <>
              {job.status !== 'draft' && (
                <ActionButton onClick={onUnpublish} actionType="unpublish" />
              )}
              {(job.status === 'unpublished' ||
                job.status === 'on_hold' ||
                job.status === 'under review') && (
                  <ActionButton onClick={onPublish} actionType="publish" />
                )}
              {(job.status === 'draft' ||
                job.status === 'unpublished' ||
                job.status === 'under review') && (
                  <ActionButton onClick={editJob} actionType="edit" />
                )}
              <ActionButton onClick={onDelete} actionType="delete" />
            </>
          )
        }
      />
      <UnpublishJobModal
        onAction={(res) => {
          setJobs((st: any) =>
            st.map((el: any) => {
              if (el.id === job.id) {
                return { ...el, status: res.data.status };
              }
              return el;
            }),
          );
        }}
        job={job}
        show={isUnpublishModalOpen}
      />
    </ListItem>
  );
};

export default ClientJobListItem;
