import Linkify from 'linkify-react';
import styled from 'styled-components';

type Iprops = {
  tagName: string,
  text?: string,
}

const StyledLinkify = styled(Linkify)`
  font-size: 14px;
  line-height: 20px;
  color: #061c2e;
  white-space: pre-line;

  a {
    color: ${({ theme }) => theme.primaryColor}
  }
`;

const TextWithUrl = ({ tagName, text }: Iprops) => (
  <StyledLinkify tagName={tagName}>{text}</StyledLinkify>
);

export default TextWithUrl;
