import { Form, message, Select } from 'antd';
import { CaretDownOutlined } from "@ant-design/icons";
import SelectInput from "../../UI/inputs/Select";
import React, { useEffect, useState } from "react";
import { CandidatesStagesStatuses, InterviewRoom } from "../../types";
import { requiredRule } from "../../utils/validationRules";
import FormItem from '../form/FormItem';
import { useTranslation } from "react-i18next";
import { setCandidatesStagesStatuses } from "../../api/candidates";
import styled from 'styled-components';

interface StatusProps {
  interview: InterviewRoom,
  statuses: CandidatesStagesStatuses[];
  onClick: any,
  isClient: boolean;
  customClass?: string;
  customWrapperClass?: string;
}

const StatusFormWrapper = styled('div')`
  .candidate-status-form .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select-selection-item {
    white-space: normal;
  }
`

const StyledSelect = styled(SelectInput)`

  &.ant-select-focused,
  &.ant-select-auto-complete.ant-select-focused:not(.ant-select-disabled) {
    & .ant-select-selector {
      border-color: transparent;
      box-shadow: none !important;
    }
  }
  
  outline: none;
  max-width: 85%;
  width: auto !important;
  height: 24px;
  font-size: 12px;
  box-shadow: none !important;
  &.ant-select-arrow {
    right: 1rem;
  }
  & .ant-select-selection-overflow {
    overflow: hidden;
  }
  &.ant-select {
    &.ant-select-sm {
      &:not(.ant-select-customize-input), &.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
        & .ant-select-selector {
          border-radius: 4px;
          border: solid 1px ${({ theme }) => theme.primaryColor};
          background-color: ${({ theme }) => theme.primaryColor};
          height: 24px;
        }
      }
    &.kanban-status {
      max-width: 100%;
      &:not(.ant-select-customize-input), &.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
        & .ant-select-selector {
          color: #627d95;
          font-size: 10px;
          border-radius: 4px;
          border: solid 1px ${({ theme }) => theme.lozengeColor};
          background-color: ${({ theme }) => theme.lozengeColor};
          height: 24px;
        }
      }

      & .ant-select-selection-item {
        color: #627d95;
      }
    }

      &.ant-select-multiple {
        & .ant-select-selector {
          padding: 5px 1rem;
        }
      }

      &.ant-select-focused,
      &.ant-select-auto-complete.ant-select-focused:not(.ant-select-disabled) {
        & .ant-select-selector {
          border-color: transparent;
          box-shadow: 0 0 8px 0 ${({ theme }) => theme.primaryColor};
        }
      }
    }
  }
  &.ant-select-disabled {
    &.ant-select:not(.ant-select-customize-input) {
      & .ant-select-selector {
        color: #627d95;
        border: solid 1px #aebeca;
        background-color: ${({ theme }) => theme.lozengeColor};
        cursor: not-allowed;
      }
    }
  }
  &.custom-select {
    & .ant-select-selection-placeholder {
      color: #aebeca;
    }
    & .ant-select-arrow {
      right: 0.2rem;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.25rem;
      & .anticon {
        color: #fff !important;
      }
    }
    &.ant-select-single.ant-select-lg {
      &:not(.ant-select-customize-input):not(.ant-select-customize-input) {
        & .ant-select-selection-search-input {
          height: 3rem;
        }
      }
    }

    & .ant-select-selection-item {
      line-height: 16px !important;
      color: #fff;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
    }
    & .ant-select-selector .ant-select-selection-placeholder {
      line-height: 14px;
    }
  }
`;

const CandidateStatusDropdown = ({ interview, statuses, onClick, isClient, customClass, customWrapperClass }: StatusProps) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { t } = useTranslation();
  const [newStatus, setNewStatus] = useState<string | null>(null);
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);

  const updateCandidateStatus = (status: string) => {
    setCandidatesStagesStatuses(interview.id, status).then((res) => {
      // @ts-ignore
      if (interview.candidate_stage_status) {
        interview.candidate_stage_status.id = status;
      } else {
        setNewStatus(status);
      }
      message.success(t('CANDIDATE_STATUS_CHANGED'));
    });
  }

  statuses = statuses.filter(status => {
    let candidateInterviewStatus = interview.status;
    if (candidateInterviewStatus === 'on hold' ||
      candidateInterviewStatus === 'hired' ||
      candidateInterviewStatus === 'introduced' ||
      candidateInterviewStatus === 'offered' ||
      candidateInterviewStatus === 'rejected') {
      return status.stage_name?.toLowerCase() === interview.status?.toLowerCase();
    }
    return status.stage_name?.toLowerCase() === interview.candidate_stage_status?.stage_name?.toLowerCase() ||
      status.stage_name?.toLowerCase() === interview.current_stage?.stage_name?.toLowerCase()
  });

  useEffect(() => {
    let stageStatus = '';
    if (typeof interview.candidate_stage_status === 'object') {
      stageStatus = interview.candidate_stage_status?.id ?? '';
    } else if (typeof interview.candidate_stage_status === 'string') {
      stageStatus = interview.candidate_stage_status ?? '';
    }
    if (newStatus) {
      stageStatus = newStatus;
    }
    setCurrentStatus(stageStatus);
    if (statuses.length > 0) {
      form.setFieldsValue({
        'status': stageStatus
      });
    } else {
      form.setFieldsValue({
        'status': ''
      });
    }
  },
    // eslint-disable-next-line
    [
      form,
      statuses,
      interview.candidate_stage_status?.id
    ]);


  return (
    <StatusFormWrapper className={customWrapperClass ? `${customWrapperClass}` : ''} onClick={onClick}>
      <Form
        scrollToFirstError={true}
        layout="vertical"
        className={'candidate-status-form'}
        name="candidate-status-form"
        form={form}
      >
        {((statuses.length > 0 && (statuses.find(status => status.id === currentStatus) || !currentStatus) && (interview.candidate_stage_status?.stage_name || interview.candidate_stage_status)) || (statuses.length > 0 && isClient)) && (
          <FormItem
            name="status"
            rules={requiredRule(t)}
          >
            <StyledSelect
              size="small"
              className={customClass ? `custom-select ${customClass}` : 'custom-select'}
              showArrow={isClient}
              disabled={!isClient}
              dropdownMatchSelectWidth={false}
              suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
              onChange={(e) => {
                if (!isClient) {
                  return false;
                }
                updateCandidateStatus(e ? e.toString() : '')
              }
              }
            >
              <Option value="">Set status</Option>
              {statuses.map((status, idx) => (
                <Option key={status.id} value={status.id}>
                  {status.status_name}
                </Option>
              ))}
            </StyledSelect>
          </FormItem>
        )}
      </Form>
    </StatusFormWrapper>
  );
}

export default CandidateStatusDropdown;
