import styled from 'styled-components';
import OfferText from './OfferText';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { format } from 'date-fns';
import useEmploymentTypes from '../../../hooks/useEmploymentTypes';
import getEmploymentTypeSummary from '../../../utils/getEmploymentTypeSummary';
import { Offer } from '../../../types';
import useCurrency from '../../../hooks/useCurrency';
type OfferResultProps = {
  offer: Pick<Offer, 'compensation' | 'compensation_currency' | 'start_date' | 'role' | 'other_benefits' | 'comment'>,
}

const Wrapper = styled.div`
  margin-top: 5rem;
`;
const OfferResult = ({ offer }: OfferResultProps) => {
  const [t] = useTranslation();
  const currency = useCurrency(offer.compensation_currency);
  const employmentTypes = useEmploymentTypes();
  const employmentTypeSummary = getEmploymentTypeSummary(t, currency, offer.compensation, employmentTypes);
  const offerFields = employmentTypeSummary?.fields['offer']
  return (
    <Wrapper>
      <Row gutter={0}>
        <Col md={16}>
          <OfferText
            title={t('START_DATE')}
            text={format(new Date(offer.start_date), 'LLL d, yyyy')}
          />
          <OfferText title={t('ROLE')} text={offer.role} />
          <OfferText title={t('CONTRACT')} text={employmentTypeSummary?.employmentType} />
          {offerFields?.map((field) => {
            const secondaryLabel = field.secondaryLabel ? ` (${field.secondaryLabel})` : '';
            const label = `${field.label}${secondaryLabel}`;
            return <OfferText title={t(label)} text={field.value} />
          })}

        </Col>
        <Col md={8}>
          <OfferText
            title={t('OTHER_BENEFITS')}
            text={offer.other_benefits}
            direction={'vertical'}
          />
          <OfferText
            title={t('COMMENT')}
            text={offer.comment}
            direction={'vertical'}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default OfferResult;
