import React from 'react';
import { Form, message } from 'antd';
import FormTitle from '../../components/auth/FormTitle';
import styled from 'styled-components';
import { publishJob } from '../../api/jobs';
import { Job } from '../../types';
import { JOBS } from '../../constants/routes';
import RecruiterManagementBoard from '../recruiter/RecruiterManagementBoard';
import { useTranslation } from 'react-i18next';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 0.5rem 0;
`;

type JobPSLFormProps = {
  job: Job;
};

export default function JobPSLForm({ job }: JobPSLFormProps) {
  const [t] = useTranslation();
  const history = useHistoryWithUrlBase();

  return (
    <Form scrollToFirstError={true} layout="vertical" name="job-form">
      <FormTitle text={'Recruiter specification'} />
      <div style={{ marginBottom: '20px' }}>
        <RecruiterManagementBoard
          fromJobCreation={false}
          selectedJob={job.id}
          onComplete={() => { }}
          onConfirm={async (publish) => {
            try {
              if (job.status !== 'published' && publish) {
                await publishJob(job.id);
              }
            } catch (error) {
              message.error(t('UPDATE_ERROR'));
            } finally {
              message.success(t('JOB_UPDATED'));
              history.push(`${JOBS}/${job.id}`);
            }
          }}
        />
      </div>
    </Form>
  );
}
