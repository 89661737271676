import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../icons/arrow-left.svg';

interface BackButtonProps {
  onClick: () => void
}

const BackButtonWrapper = styled.button`
  border-radius: ${({ theme }) => theme.radius};
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.lozengeColor};
  color: ${({ theme }) => theme.lozengeColor};
  background: transparent;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1;
  transition: all ease .3s;
  &:hover{
    color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;
const BackButton = ({ onClick }: BackButtonProps) => {
  return (
    <BackButtonWrapper onClick={onClick}>
      <ArrowLeftIcon />
    </BackButtonWrapper>
  );
};

export default BackButton;
