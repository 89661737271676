import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import {
  getNotificationList,
  markAllNotificationsRead,
  markNotificationAsRead,
} from '../../api/notifications';
import { getRandomColor } from '../../constants/palette';
import Button from '../buttons/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { decreaseCounter } from '../../store/notificationsSlice';
import { resetCounter } from '../../store/notificationsSlice';
import { NOTIFICATION_TYPES } from '../../constants/notifications';
import {
  CANDIDATES,
  CANDIDATES_SPECIFICATION_SETTINGS,
  JOBS,
  MESSAGES,
} from '../../constants/routes';
import { CLIENT_ROLE, IS_ADMIN_KEY } from '../../constants/roles';
import { getNameFirstLetters } from '../../utils/candidate';
import Loader from '../Loader';
import {
  INTERVIEW_ID_PARAM,
  SHOW_OFFER_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  SHOW_FEEDBACK_PARAM,
  FEEDBACK_ID_PARAM,
  STAGE_NAME_PARAM,
  FEEDBACK_INTERVIEW_ID_PARAM,
} from '../../constants/queryParams';
import { getToken } from '../../utils/localStorage';

interface NotificationPopupProps {
  show: boolean;
  handleShow: React.Dispatch<React.SetStateAction<boolean>>;
}
interface NotificationProps {
  created_at: string;
  data: any;
  is_clickable: boolean;
  id: string;
  notification_type: number;
  read: boolean;
}
const NotificationWrapper = styled.div`
  padding: 1rem 2rem 1rem;
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: 0 2rem 5rem 0 rgba(6, 28, 46, 0.2),
    0 1rem 1.25rem 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: calc(100% + 0.75rem);
  min-width: 22rem;
  z-index: 9999999;
`;
const NotificationHeader = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #dae5ec;
`;
const NotificationHeading = styled.h5`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  color: #061c2e;
  text-transform: uppercase;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NotificationBody = styled.div`
  padding: 1rem 0;
  max-height: 50vh;
  overflow-y: auto;
`;
const NotificationItem = styled.div`
  padding: 0.75rem 0;
  display: flex;
  width: 100%;
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
const NotificationItemAvatar = styled(Avatar)`
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  line-height: 2.5rem;
  & .ant-avatar-string {
    font-size: 0.875rem;
  }
`;
const NotificationItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.5rem;
  align-items: flex-start;
  width: 100%;
`;
const NotificationItemTitle = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-right: 0.25rem;
`;
const Distance = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.33;
  text-align: right;
  color: #aebeca;
  width: 100%;
  max-width: 50%;
  display: block;
`;
const NotificationItemDescription = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
  max-width: 185px;
  width: 100%;
`;
const Unread = styled.span`
  width: 0.5rem;
  min-width: 0.5rem;
  height: 0.5rem;
  min-height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor};
  margin-right: 0.25rem;
  display: inline-block;
`;
const Name = styled.span`
  width: auto;
`;
const NoData = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.43;
  color: #061c2e;
  margin: 1rem 0;
`;

const NotificationPopup = ({ show, handleShow }: NotificationPopupProps) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { unreadCount } = useAppSelector((state) => state.notifications);
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;
  const loader = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [next, setNext] = useState<string | null>(null);
  const locale = localStorage.getItem('app_locale') || 'en';
  const dispatch = useAppDispatch();

  const isAdmin = getToken(IS_ADMIN_KEY);

  useEffect(() => {
    if (show) {
      getNotificationList()
        .then((res) => {
          setNotifications(res.data.results);
          setNext(res.data.pagination.next);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [show]);
  const markAsRead = () => {
    markAllNotificationsRead().then((res) => {
      dispatch(resetCounter());
    });
  };
  const handleObserver = useCallback(
    (entities: any) => {
      const target = entities[0];
      if (target.isIntersecting) {
        next &&
          getNotificationList(`cursor=${next}`).then((res) => {
            setNotifications((prev) => [...prev, ...res.data.results]);
            setNext(res.data.pagination.next);
          });
      }
    },
    [next],
  );
  useEffect(() => {
    const current = loader.current;
    let options = {
      root: popupRef.current,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (!next) {
      observer.disconnect();
    } else {
      current && observer.observe(current);
    }
    return () => observer.unobserve(current as Element);
  }, [next, handleObserver]);
  const [t] = useTranslation();

  const handleNotificationClick = (link: string, read: boolean, id: string) => {
    if (read) {
    } else {
      markNotificationAsRead(id).then((res) => {
        history.push(link);
        handleShow(false);
        dispatch(decreaseCounter());
      });
    }
  };

  return (
    <NotificationWrapper
      style={{ display: show ? 'block' : 'none' }}
      ref={popupRef}
    >
      <NotificationHeader>
        <NotificationHeading>
          {t('NOTIFICATION_TITLE')}{' '}
          {!!unreadCount && !isAdmin && (
            <Button size="small" onClick={markAsRead}>
              {t('MARK_AS_READ')}
            </Button>
          )}
        </NotificationHeading>
      </NotificationHeader>
      {!loading && !notifications.length && (
        <NoData>{t('NO_NOTIFICATIONS')}</NoData>
      )}
      <Loader spinning={loading}>
        <NotificationBody>
          {notifications
            .filter((nt) => nt.notification_type !== 16)
            .map((notification) => {
              const search = new URLSearchParams();
              const color = getRandomColor();
              let stage = '';
              let link = '';
              let name = '';

              /* check NOTIFICATION_TYPES */
              if (notification.notification_type === 2) {
                // show dates modal
                name = notification.data.candidate.name;
                stage = notification.data.current_stage.name;
                search.set(INTERVIEW_ID_PARAM, notification.data.interview.id);
                search.set(STAGE_PARAM, stage.toLowerCase());
                search.set(SHOW_DATE_PROPOSE_PARAM, 'true');
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }

              if (notification.notification_type === 3) {
                name = notification.data.candidate.name;
                stage = notification.data.current_stage.name;
                search.delete('feedback');
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }

              if (notification.notification_type === 4) {
                // feedback required - show feedback form
                name = notification.data.candidate.name;
                stage = notification.data.current_stage.name;

                search.set(INTERVIEW_ID_PARAM, notification.data.interview.id);
                search.set(STAGE_PARAM, stage.toLowerCase());
                search.set(SHOW_FEEDBACK_PARAM, 'true');

                const query = search.toString();

                link += `${CANDIDATES}?filter=in-process&${query}`;
              }

              if (notification.notification_type === 5) {
                // feedback received - show feedback
                name = notification.data.candidate.name;

                search.set(INTERVIEW_ID_PARAM, notification.data.interview.id);
                search.set(FEEDBACK_ID_PARAM, notification.data.feedback_id);
                search.set(
                  FEEDBACK_INTERVIEW_ID_PARAM,
                  notification.data.interview.id,
                );
                notification.data.current_stage?.name &&
                  search.set(
                    STAGE_NAME_PARAM,
                    notification.data.current_stage.name,
                  );
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }

              if (notification.notification_type === 6) {
                name = notification.data.candidate.name;
                search.set('history', notification.data.candidate.id);
                search.set(INTERVIEW_ID_PARAM, notification.data.interview.id);
                notification.data.current_stage?.name &&
                  search.set(
                    STAGE_NAME_PARAM,
                    notification.data.current_stage.name,
                  );
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }

              if (notification.notification_type === 7) {
                // open new message
                name = notification.data?.sender
                  ? notification.data?.sender.name
                  : isClient
                    ? notification.data.recruiter.name
                    : notification.data.client.name;

                const user_id = isClient
                  ? notification.data.recruiter.id
                  : notification.data.client.company_id
                    ? notification.data.client.company_id
                    : notification.data.client.id;

                const interview_id = notification.data.interview.id;

                link = `${MESSAGES}/${user_id}/${interview_id}`;
              }

              if (notification.notification_type === 8) {
                name = notification.data.job_title;
                link += `${JOBS}/${notification.data.job_id}`;
              }

              if (notification.notification_type === 9) {
                name = 'HirePort';
                search.delete('feedback');
                link += `${CANDIDATES_SPECIFICATION_SETTINGS}`;
              }

              if (
                notification.notification_type === 10 ||
                notification.notification_type === 13
              ) {
                // show offer popup
                name = notification.data.candidate.name;
                search.set(INTERVIEW_ID_PARAM, notification.data.interview.id);
                search.set(SHOW_OFFER_PARAM, notification?.data?.candidate?.id);
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }
              if (
                notification.notification_type === 11 ||
                notification.notification_type === 14
              ) {
                name = notification.data.candidate.name;
                link += `${CANDIDATES}?filter=in-process`;
              }
              if (
                notification.notification_type === 12 ||
                notification.notification_type === 15 ||
                notification.notification_type === 16
              ) {
                search.set(INTERVIEW_ID_PARAM, notification.data.interview.id);
                name = notification.data.candidate.name;
                search.set('rejected_feedback', '1');
                search.set(
                  FEEDBACK_INTERVIEW_ID_PARAM,
                  notification.data.interview.id,
                );
                notification.data.current_stage?.name &&
                  search.set(
                    STAGE_NAME_PARAM,
                    notification.data.current_stage.name,
                  );
                const query = search.toString();
                link += `${CANDIDATES}?filter=in-process&${query}`;
              }

              let message = '';
              const { notification_type } = notification;

              if (notification_type === 15 || notification_type === 16) {
                if (notification.data.current_offer && user?.role !== CLIENT_ROLE) {
                  message = t('CANDIDATE_IS_REJECTED')
                } else {
                  message = t('CANDIDATE_REJECT')
                }
              } else if (notification_type === 12 && user?.role === CLIENT_ROLE) {
                message = t('OFFER_REJECT')
              } else {
                message = t(NOTIFICATION_TYPES[notification.notification_type]);
              }

              return (
                <NotificationItem
                  key={notification.id}
                  onClick={() =>
                    handleNotificationClick(
                      link,
                      !notification.is_clickable &&
                      notification.notification_type !== 5,
                      notification.id,
                    )
                  }
                  className={
                    !notification.is_clickable &&
                      notification.notification_type !== 5
                      ? 'disabled'
                      : ''
                  }
                >
                  <NotificationItemAvatar
                    style={{
                      color: color.main,
                      backgroundColor: color.light,
                    }}
                  >
                    {name
                      ? notification.notification_type === 9
                        ? 'HP'
                        : getNameFirstLetters(name)
                      : 'NN'}
                  </NotificationItemAvatar>
                  <NotificationItemText>
                    <NotificationItemTitle>
                      <Name>{name ? name : 'No Name'} </Name>
                      <Distance>
                        {notification.is_clickable && !notification.read && (
                          <Unread></Unread>
                        )}
                        {formatDistanceStrict(
                          new Date(notification.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                            locale: locale === 'en' ? en : nl,
                          },
                        )}
                      </Distance>
                    </NotificationItemTitle>
                    <NotificationItemDescription>
                      {stage} {stage && '—'}{' '}
                      {message}
                    </NotificationItemDescription>
                  </NotificationItemText>
                </NotificationItem>
              );
            })}
          <div className="loader" ref={loader} />
        </NotificationBody>
      </Loader>
    </NotificationWrapper>
  );
};

export default NotificationPopup;
