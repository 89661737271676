import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  format,
  formatRelative,
  isPast,
  isYesterday,
  isToday,
  isTomorrow,
} from 'date-fns';

import Header from '../../layout/Header';
import NameAvatar from '../../../UI/NameAvatar';
import { useAppSelector } from '../../../store/hooks';
import helloImage from '../../../icons/spaceman.svg';
import chevronRightIcon from '../../../icons/icon-chevron-right.svg';
import ListWidget from '../../dashboard/ListWidget';
import {
  getRecruiterMetrics,
  getRecruiterDashboardNewJobs,
  getRecruiterDashboardFavoriteJobs,
  getRecruiterDashboardRequiredActions,
  getRecruiterDashboardUpcomingActions,
  archiveDashboardJob
} from '../../../api/dashboard';
import { useHistory } from 'react-router';
import { CANDIDATES, CLIENT, JOBS } from '../../../constants/routes';
import { DashboardAction, DashboardJob } from '../../../types';
import { addToFavorites, removeFromFavorites } from '../../../api/jobs';
import { CalendarWidget } from '../../calendar/CalendarWidget';
import { addDecimalPoints } from '../../../utils/candidate';
import TooltipPrompt from '../../../UI/tootlip';
import {
  ArchiveIcon,
  PageWrapper,
  DashboardGrid,
  DashboardTopBlock,
  StatisticsWrapper,
  HelloBlock,
  HelloBlockTitle,
  HelloBlockDescription,
  HelloBlockImage,
  StatisticsItem,
  StatisticsItemStages,
  StatisticsItemStage,
  StatisticsItemTitle,
  StatisticsItemValue,
  StatisticsItemValueBadge,
  DashboardMiddleBlock,
  DashboardBottomBlock,
  CandidatesListItem,
  CandidateNameContainer,
  CandidateName,
  CandidateInfoContainer,
  CandidateCompanyName,
  CandidatePosition,
  CandidateStageProgressContainer,
  CandidateStage,
  CandidateStageProgress,
  CandidateStageProgressBar,
  CandidateActionContainer,
  CandidateActionName,
  CandidateActionDateRelative,
  CandidateActionDate,
  CandidateActionIcon,
  JobListItem,
  JobTitle,
  JobCompanyName,
  JobSalary,
  JobLocation,
  JobActions,
  Image,
  EmptyText
} from './styles';
import {
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_DATE_PROPOSE_PARAM,
  HISTORY_PARAM,
  SHOW_FEEDBACK_PARAM,
  SHOW_OFFER_PARAM,
} from '../../../constants/queryParams';
import { OFFERED } from '../../../constants/statuses';
import Emoji from "../../helper/Emoji";
import useCurrency from '../../../hooks/useCurrency';

interface StageInterface {
  stage_name: string;
  count: number;
  new: number;
}


const fields = [
  'available_jobs',
  'total_clients',
  'uploaded_candidates',
  'in_process_candidates',
];

const lastFields = ['offered_candidates', 'hired_candidates'];

const RecruiterDashboard = () => {
  const loader = useRef(null);
  const jobLoader = useRef(null);
  const [nextPage, setNextPage] = useState(null);
  const [nextJob, setNextJob] = useState(null);
  const [jobs, setJobs] = useState<DashboardJob[]>([]);
  const [actions, setActions] = useState<DashboardAction[]>([]);
  const [stages, setStages] = useState<StageInterface[]>([]);
  const [metrics, setMetrics] = useState(null);
  const [jobsFilter, setJobsFilter] = useState(0);
  const [actionsFilter, setActionsFilter] = useState(0);
  const { user } = useAppSelector((state) => state.user);
  const [t] = useTranslation();
  const defaultCurrency = useCurrency();
  const history = useHistory();

  const loadJobs = useCallback(
    async (query?: string) => {
      let res = null as any;
      if (jobsFilter === 0) {
        res = await getRecruiterDashboardNewJobs(query);
      }
      if (jobsFilter === 1) {
        res = await getRecruiterDashboardFavoriteJobs(query);
      }
      if (res) {
        if (query) {
          setJobs((prev) => [...prev, ...res.data.results]);
        } else {
          setJobs(res.data.results);
        }
        setNextJob(res.data.pagination.next);
      }
    },
    [jobsFilter],
  );
  useEffect(() => { }, [jobsFilter]);

  const loadActions = useCallback(
    async (query: string) => {
      let res = null as any;
      if (actionsFilter === 0) {
        res = await getRecruiterDashboardRequiredActions(query);
      }
      if (actionsFilter === 1) {
        res = await getRecruiterDashboardUpcomingActions(query);
      }
      if (res) {
        setActions((state: any) =>
          query ? [...state, ...res.data.results] : [...res.data.results],
        );
        setNextPage(res.data.pagination.next);
      }
    },
    [actionsFilter],
  );

  const handleObserver = useCallback(
    async (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search + '&cursor=' + nextPage
        : '?cursor=' + nextPage
        }`;
      const target = entities[0];
      if (target.isIntersecting && nextPage) {
        await loadActions(cursor);
      }
    },
    [nextPage, history.location.search, loadActions],
  );

  useEffect(() => {
    const current = loader.current;
    let options = {
      root: document.querySelector('#list-widget-2 ul'),
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);

    if (!nextPage) {
      observer.disconnect();
    } else {
      if (loader && current) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [nextPage, handleObserver]);

  const handleJobObserver = useCallback(
    async (entities: any) => {
      const cursor = `${history.location.search
        ? history.location.search + '&cursor=' + nextJob
        : '?cursor=' + nextJob
        }`;
      const target = entities[0];
      if (target.isIntersecting && nextJob) {
        await loadJobs(cursor);
      }
    },
    [nextJob, history.location.search, loadJobs],
  );

  useEffect(() => {
    const current = jobLoader.current;
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleJobObserver, options);

    if (!nextJob) {
      observer.disconnect();
    } else {
      if (jobLoader && current) {
        observer.observe((current as unknown) as Element);
      }
    }
    return () => observer.unobserve((current as unknown) as Element);
  }, [jobLoader, handleJobObserver, nextJob]);
  useEffect(() => {
    getRecruiterMetrics().then((res) => {
      if (res.data.stages && res.data.stages.length > 0) {
        const stageList = res.data.stages;
        const firstEl = stageList.shift();
        const secondEl = stageList.length
          ? stageList.reduce(
            (acc: StageInterface, curr: StageInterface) => {
              acc.count += curr.count;
              acc.new += curr.new;
              return acc;
            },
            {
              stage_name: '2nd+ interview',
              count: 0,
              new: 0,
            },
          )
          : { stage_name: '2nd+ interview', count: 0, new: 0 };
        setStages([firstEl, secondEl]);
      } else {
        setStages([
          { stage_name: '1st interview', count: 0, new: 0 },
          { stage_name: '2nd+ interview', count: 0, new: 0 },
        ]);
      }
      setMetrics(res.data);
    });
  }, [user]);

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  useEffect(() => {
    loadActions('');
  }, [loadActions]);

  const handleStatisticsClick = (field: string) => {
    switch (field) {
      case 'available_jobs':
        history.push(JOBS);
        break;
      case 'total_clients':
        history.push(JOBS);
        break;
      case 'uploaded_candidates':
        history.push(CANDIDATES);
        break;
      case 'in_process_candidates':
        history.push(CANDIDATES + '?filter=in-process');
        break;
      case 'hired_candidates':
        history.push(CANDIDATES + '?filter=in-process');
        break;
      default:
        history.push(CANDIDATES);
        break;
    }
  };

  const handleChangeJobsFilter = (filter: string, index: number) => {
    setJobsFilter(index);
    setJobs([]);
  };

  const handleChangeActionsFilter = (filter: string, index: number) => {
    setActionsFilter(index);
    setActions([]);
  };

  const handleCandidateClick = (action: DashboardAction) => {
    if (action.stage && action.interview.status !== 'offered') {
      if (actionsFilter === 0 && action.action_type === 'feedback') {
        // show feedback modal
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);
        query.set(SHOW_FEEDBACK_PARAM, 'true');

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (actionsFilter === 0 && action.action_type === 'planning') {
        // show proposed date modal
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);
        query.set(SHOW_DATE_PROPOSE_PARAM, 'true');

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (
        actionsFilter === 0 &&
        action.action_type === 'move_next_stage'
      ) {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else if (action.action_type === 'decision' || action.action_type === 'upload_document') {
        // show tasks overview
        const query = new URLSearchParams();
        query.set(INTERVIEW_ID_PARAM, action.interview.id);
        query.set(STAGE_PARAM, action.stage.stage_name);

        history.push(CANDIDATES + '?filter=in-process&' + query.toString());
      } else {
        // show candidate info
        history.push(
          CANDIDATES +
          `?filter=in-process&${HISTORY_PARAM}=` +
          action.interview.candidate.id +
          `&${INTERVIEW_ID_PARAM}=` +
          action.interview.id,
        );
      }
    } else if (action.interview.status === 'offered') {
      // show offer modal
      const query = new URLSearchParams();
      query.set(INTERVIEW_ID_PARAM, action.interview.id);
      query.set(STAGE_PARAM, OFFERED);
      query.set(SHOW_OFFER_PARAM, action?.interview?.candidate?.id);

      history.push(CANDIDATES + '?filter=in-process&' + query.toString());
    }
  };

  const handleArchiveJob = useCallback(
    async (job: DashboardJob) => {
      const result = await archiveDashboardJob(job.id);
      if (result) {
        loadJobs();
      }
    },
    [loadJobs],
  );

  const handleFavoriteJob = useCallback(
    async (job: DashboardJob, remove: boolean) => {
      let result = null;
      if (remove) {
        result = await removeFromFavorites(job.id);
      } else {
        result = await addToFavorites(job.id);
      }
      if (result) {
        loadJobs();
      }
    },
    [loadJobs],
  );

  const handleJobClick = (job: DashboardJob) => {
    history.push(JOBS + '/' + job.id);
  };

  const handleCompanyClick = (job: DashboardJob, e: any) => {
    e.stopPropagation();
    history.push(CLIENT + '/' + job.company.id);
  };

  return (
    <PageWrapper>
      <Header title={t('DASHBOARD_TITLE')}>{/* <SearchInput/> */}</Header>
      <DashboardGrid>
        <DashboardTopBlock>
          <HelloBlock>
            <HelloBlockTitle>
              {t('HELLO')} {user?.name}!
            </HelloBlockTitle>
            <HelloBlockDescription>
              {t('HELLO_DESCRIPTION')}
            </HelloBlockDescription>
            <HelloBlockImage src={helloImage} />
          </HelloBlock>
          <StatisticsWrapper>
            {fields.map((field, fieldIndex) => {
              const value = (metrics as any)?.[field];
              const fieldTitle = 'DASHBOARD_' + field.toUpperCase();
              if (value) {
                return (
                  <StatisticsItem
                    onClick={() => handleStatisticsClick(field)}
                    key={fieldIndex}
                  >
                    <StatisticsItemTitle>{t(fieldTitle)}</StatisticsItemTitle>
                    <StatisticsItemValueBadge count={value.new ? value.new : 0}>
                      <StatisticsItemValue>{value?.count}</StatisticsItemValue>
                    </StatisticsItemValueBadge>
                  </StatisticsItem>
                );
              }
              return null;
            })}
            {stages.length > 0 && (
              <>
                <StatisticsItem
                  stages
                  onClick={() =>
                    history.push(CANDIDATES + '?filter=in-process')
                  }
                >
                  <StatisticsItemStages>
                    <StatisticsItemStage>
                      <StatisticsItemTitle>
                        {stages[0].stage_name}
                      </StatisticsItemTitle>
                      <StatisticsItemValueBadge
                        count={stages[0].new ? stages[0].new : 0}
                      >
                        <StatisticsItemValue>
                          {stages[0].count}
                        </StatisticsItemValue>
                      </StatisticsItemValueBadge>
                    </StatisticsItemStage>
                  </StatisticsItemStages>
                </StatisticsItem>
                <StatisticsItem
                  stages
                  onClick={() =>
                    history.push(CANDIDATES + '?filter=in-process')
                  }
                >
                  <StatisticsItemStages>
                    <StatisticsItemStage>
                      <StatisticsItemTitle>
                        {stages[1].stage_name}
                      </StatisticsItemTitle>
                      <StatisticsItemValueBadge
                        count={stages[1].new ? stages[1].new : 0}
                      >
                        <StatisticsItemValue>
                          {stages[1].count}
                        </StatisticsItemValue>
                      </StatisticsItemValueBadge>
                    </StatisticsItemStage>
                  </StatisticsItemStages>
                </StatisticsItem>
              </>
            )}
            {lastFields.map((field, fieldIndex) => {
              const value = (metrics as any)?.[field];

              const fieldTitle =
                field === 'offered_candidates'
                  ? 'DASHBOARD_OFFERED_CANDIDATES'
                  : field === 'hired_candidates'
                    ? 'DASHBOARD_PLACEMENTS'
                    : '';
              if (value) {
                return (
                  <StatisticsItem
                    onClick={() => handleStatisticsClick(field)}
                    key={fieldIndex}
                  >
                    <StatisticsItemTitle>{t(fieldTitle)}</StatisticsItemTitle>
                    <StatisticsItemValueBadge count={value.new ? value.new : 0}>
                      <StatisticsItemValue>{value?.count}</StatisticsItemValue>
                    </StatisticsItemValueBadge>
                  </StatisticsItem>
                );
              }
              return null;
            })}
          </StatisticsWrapper>
        </DashboardTopBlock>
        <DashboardMiddleBlock>
          <ListWidget
            id={'list-widget'}
            title={t('JOBS')}
            tabs={['NEW', 'FAVORITES_TITLE']}
            onChangeTab={handleChangeJobsFilter}
          >
            {jobs && jobs.length === 0 && (
              <EmptyText>{t('NO_JOBS_YET')}</EmptyText>
            )}
            {jobs?.map((job, index) => {
              const { curr } = defaultCurrency.changeCurrency(job.compensation_currency);
              return (
                <JobListItem
                  onClick={() => handleJobClick(job)}
                  key={job.id + index}
                >
                  <JobTitle>{job.title}</JobTitle>
                  <JobCompanyName onClick={(e) => handleCompanyClick(job, e)}>
                    <Image>
                      <img
                        src={`${job.company?.image
                          ? job.company?.image
                          : process.env.PUBLIC_URL + '/images/company-logo.png'
                          }`}
                        alt={job.company.name}
                      />
                    </Image>
                    <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}>{job.company.name}</TooltipPrompt>
                  </JobCompanyName>
                  <JobSalary>
                    {t('UP_TO_TITLE')} {job.salary_max ? curr(addDecimalPoints(job.salary_max.toString())) : ' -'}
                  </JobSalary>
                  <JobLocation>{job.company.city.name}</JobLocation>
                  <JobActions>
                    <TooltipPrompt title={t('DASHBOARD_FAVORITE_ICON_TOOLTIP')}>
                      <Emoji
                        symbol={(jobsFilter === 1 ? true : !!job.is_favorite) ? '🔔' : '🔕'}
                        customClass={'dashboard-list'}
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleFavoriteJob(
                            job,
                            jobsFilter === 1 ? true : !!job.is_favorite,
                          );
                        }}
                      />
                    </TooltipPrompt>
                    {jobsFilter !== 1 && (
                      <TooltipPrompt title={t('DASHBOARD_ARCHIVE_ICON_TOOLTIP')}>
                        <ArchiveIcon
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleArchiveJob(job);
                          }}
                        />
                      </TooltipPrompt>
                    )}
                  </JobActions>
                </JobListItem>
              )
            })}
            <div className="loader" ref={jobLoader} />
          </ListWidget>
        </DashboardMiddleBlock>
        <DashboardBottomBlock>
          <CalendarWidget />
          <ListWidget
            id={'list-widget-2'}
            title={t('CANDIDATES')}
            tabs={['ACTION_REQUIRED', 'UPCOMING']}
            onChangeTab={(filter, index) =>
              handleChangeActionsFilter(filter, index)
            }
          >
            {actions && actions.length === 0 && (
              <EmptyText>{t('NO_CANDIDATE_YET')}</EmptyText>
            )}
            {actions?.map((action) => (
              <CandidatesListItem
                onClick={() => handleCandidateClick(action)}
                key={action.id}
              >
                <CandidateNameContainer>
                  <NameAvatar
                    name={action.interview.candidate.name}
                    color={{
                      main: '#7949cd',
                      light: 'rgba(121, 73, 205, 0.1)',
                    }}
                  />
                  <CandidateName>
                    {action.interview.candidate.name}
                  </CandidateName>
                </CandidateNameContainer>
                <CandidateInfoContainer>
                  <CandidateCompanyName>
                    {action.interview.job.company.name}
                  </CandidateCompanyName>
                  <CandidatePosition>
                    {action.interview.job.title}
                  </CandidatePosition>
                </CandidateInfoContainer>
                <CandidateStageProgressContainer>
                  {action.stage && action.interview.status !== 'offered' && (
                    <>
                      <CandidateStage>{action.stage.stage_name}</CandidateStage>
                      <CandidateStageProgress>
                        {action.stage_progress && (
                          <CandidateStageProgressBar
                            progress={action.stage_progress}
                          />
                        )}
                      </CandidateStageProgress>
                    </>
                  )}
                  {action.interview.status === 'offered' && (
                    <>
                      <CandidateStage>{t('OFFER_STAGE')}</CandidateStage>
                      <CandidateStageProgress>
                        {action.stage_progress && (
                          <CandidateStageProgressBar
                            progress={action.stage_progress}
                          />
                        )}
                      </CandidateStageProgress>
                    </>
                  )}
                </CandidateStageProgressContainer>

                <CandidateActionContainer>
                  <CandidateActionName>
                    {action.interview.status !== 'offered'
                      ? action.task_description
                      : action.action_type === 'move_next_stage'
                        ? action.task_description
                        : t('REVIEW_OFFER_TASK')}
                  </CandidateActionName>
                  {actionsFilter === 0 && (
                    <CandidateActionDate
                      isExpired={isPast(new Date(action.expired_at))}
                    >
                      {isToday(new Date(action.expired_at)) ||
                        isTomorrow(new Date(action.expired_at)) ||
                        isYesterday(new Date(action.expired_at)) ? (
                        <CandidateActionDateRelative>
                          {formatRelative(
                            new Date(action.expired_at),
                            new Date(),
                          )}
                        </CandidateActionDateRelative>
                      ) : (
                        format(new Date(action.expired_at), 'MMMM d, yyyy')
                      )}
                    </CandidateActionDate>
                  )}
                </CandidateActionContainer>
                <CandidateActionIcon src={chevronRightIcon} />
              </CandidatesListItem>
            ))}
            <div className="loader" ref={loader} />
          </ListWidget>
        </DashboardBottomBlock>
      </DashboardGrid>
    </PageWrapper>
  );
};

export default RecruiterDashboard;
