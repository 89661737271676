import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: max-content;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: 768px) {
    order: 3;
    max-width: 100%;
    margin: 1rem auto 0;
    flex-wrap: wrap;
  }
`;
const FilterItem = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.96px;
  padding: 1rem 0;
  color: #061c2e;
  max-width: max-content;
  width: 100%;
  margin: 0 0.75rem;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 0;
    background: ${({ theme }) => theme.primaryColor};
    transition: width ease 0.3s;
  }
  &.active {
    &:before {
      width: 100%;
    }
  }
`;
const AdminHeaderFilters = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const status = query.get('status');
  const [filter, setFilter] = useState(status ? status : 'draft');

  const [t] = useTranslation();
  const filterList = [
    {
      title: t('DRAFT'),
      value: 'draft',
    },
    {
      title: t('UNDER_REVIEW'),
      value: 'under review',
    },
    {
      title: t('UPCOMING'),
      value: 'upcoming',
    },
    {
      title: t('PUBLISHED'),
      value: 'published',
    },
    {
      title: t('UNPUBLISHED'),
      value: 'unpublished',
    },
    {
      title: t('ON_HOLD'),
      value: 'on_hold',
    },
  ];
  const handleChange = (value: string) => {
    setFilter(value);
  };
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const current = query.get('status');
    if (!current) {
      setFilter('draft');
      query.set('status', filter);
      history.push({ search: query.toString() });
    } else {
      query.set('status', filter);
      history.push({ search: query.toString() });
    }
  }, [filter, history, history.location.search]);
  return (
    <FilterWrapper>
      {filterList.map((item) => (
        <FilterItem
          key={item.title}
          onClick={() => handleChange(item.value)}
          className={filter === item.value ? 'active' : ''}
        >
          {item.title}
        </FilterItem>
      ))}
    </FilterWrapper>
  );
};

export default AdminHeaderFilters;
