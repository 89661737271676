import { Recruiter } from '../types';
import { structuredDomains } from './general';

export const trimSpecialisations = (specs: any, sortByName?: string) => {
  if (specs.length < 1)
    return {
      structured_specs: [],
      suffix: '',
    };
  let structured_specs = structuredDomains(specs).sort((a: any, b: any) => {
    if (sortByName) {
      if (a === sortByName) return -1;
      if (b === sortByName) return 1;
      return 0;
    }
    return a.title.localeCompare(b.title);
  });
  let suffix = '';
  if (specs.length > 2 && structured_specs.length > 2) {
    suffix = ` + ${specs.length - 2}`;
    structured_specs = structured_specs.slice(0, 2);
  }

  return {
    structured_specs: structured_specs
      .map((spec: any) => spec.title)
      .map((specName: any) => specName.replace('(main)', '')),
    suffix,
  };
};

export const formatRating = (recs: Recruiter) => {
  if (recs.ratings.length <= 0 || !recs.ratings) return '-';
  for (let i = 0; i < recs.ratings.length; i++) {
    if (recs.ratings[i].subject === 'Overall') return recs.ratings[i].rating;
  }
  return '-';
};
