import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import NameAvatar from '../../UI/NameAvatar';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { ReactComponent as LinkedinIcon } from '../../icons/linkedin-filled.svg';
import { InterviewRecruiter } from '../../types';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE } from '../../constants/roles';
import { RECRUITER } from "../../constants/routes";
import { Link } from "react-router-dom";
import TooltipPrompt from "../../UI/tootlip";

interface PersonalInfoProps {
  name: string;
  role?: string;
  company: string;
  color?: { main: string; light: string };
  recruiter?: InterviewRecruiter;
  cv?: string;
  offer?: string;
  linkedin?: string;
}

const Wrapper = styled.div`
  padding: 1.25rem 2rem;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.lozengeColor};
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const InfoLeft = styled.div`
  display: flex;
  @media (max-width: 992px) {
    margin-bottom: 1rem;
  }
`;
const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const Text = styled.div`
  margin-left: 1rem;
  &.no-margin {
    margin-left: 0.5rem;
  }
`;
const Name = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.33;
  text-align: left;
  color: #061d31;
  margin: 0.25rem 0 0.5rem;
  &.small {
    font-size: 0.875rem;
    margin: 0;
  }
`;
const Description = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
`;
const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
  @media (max-width: 992px) {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;
const ActionLink = styled.a`
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.33;
  color: #627d95;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #627d95;
  border-radius: 0.25rem;
  margin-left: 1rem;
  @media (max-width: 992px) {
    margin-bottom: 0.5rem;
  }
  & svg {
    min-width: 1rem;
    min-height: 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
  &:hover {
    color: #627d95;
  }
`;
const RecruiterWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    width: 100%;
    justify-content: flex-start;
  }
`;
const CandidatePersonalInfo = ({
  name,
  color,
  role,
  company,
  recruiter,
  cv,
  linkedin,
  offer,
}: PersonalInfoProps) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  return (
    <Wrapper>
      <InfoLeft>
        <NameAvatar name={name} color={color} />
        <Text>
          <Name>{name}</Name>
          <Description>{role}</Description>
          <Description>{company}</Description>
        </Text>
      </InfoLeft>
      <InfoRight>
        {!(!cv && !offer && !linkedin) && (
          <ActionWrapper>
            {cv && (
              <ActionLink href={cv} target="_blank" rel="noreferrer">
                <DownloadIcon />
                {t('DOWNLOAD_CV_TITLE')}
              </ActionLink>
            )}
            {offer && (
              <ActionLink href={offer} target="_blank" rel="noreferrer">
                <DownloadIcon />
                {t('DOWNLOAD_OFFER')}
              </ActionLink>
            )}
            {linkedin && (
              <ActionLink href={linkedin} target="_blank" rel="noreferrer">
                <LinkedinIcon /> {t('LINKEDIN_PROFILE')}
              </ActionLink>
            )}
          </ActionWrapper>
        )}
        {recruiter && (
          <RecruiterWrapper>
            <NameAvatar
              name={recruiter.name}
              color={color}
              image={recruiter.image}
            />
            <Text className="no-margin">
              <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link to={RECRUITER + '/' + recruiter.id}>
                <Name className="small">{recruiter.name}</Name>
              </Link></TooltipPrompt>
              {!(user?.role === CLIENT_ROLE && recruiter.recruiter_type === 'freelance') &&
                <Description>
                  {t('RECRUITER_FROM')} {recruiter.company.name}
                </Description>
              }
            </Text>
          </RecruiterWrapper>
        )}
      </InfoRight>
    </Wrapper>
  );
};

export default CandidatePersonalInfo;
