import styled from 'styled-components';
import React from 'react';
import { Job } from '../../../types';
import useHirePortTheme from '../../../hooks/useHirePortTheme';

const StatisticItem = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: solid 1px #dae5ec;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-align: center;
  color: #061c2e;
  margin-bottom: 0.25rem;
  position: relative;

  @media (max-width: 992px) {
    width: 23%;
  }
  @media (max-width: 576px) {
    width: 48%;
  }
`;

interface JobSelectedRecruitersStatisticProps {
  numberOfSelectedRecruiters: number;
  highThreshold: number;
  setShowModal: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
  setSelectedJob: React.Dispatch<React.SetStateAction<any>>;
  setSelectedJobSpecialization: React.Dispatch<React.SetStateAction<any>>;
  job: Job;
}

export default function JobSelectedRecruitersStatistic({
  numberOfSelectedRecruiters,
  highThreshold,
  showModal,
  setShowModal,
  setSelectedJob,
  setSelectedJobSpecialization,
  job,
}: JobSelectedRecruitersStatisticProps) {
  const theme = useHirePortTheme();
  let color = '#34CE72';

  if (numberOfSelectedRecruiters === 0) {
    return (
      <StatisticItem
        typeof="button"
        style={{
          color: theme.primaryColor,
          borderWidth: '1px',
          borderColor: theme.primaryColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowModal(!showModal);
          setSelectedJob(job.id);
          setSelectedJobSpecialization(job.specialization);
        }}
      >
        {'Assign talent suppliers'}
      </StatisticItem>
    );
  }

  return (
    <StatisticItem
      typeof="button"
      style={{
        color: color,
        borderWidth: '1px',
        borderColor: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        cursor: 'pointer',
      }}
      onClick={() => {
        setShowModal(!showModal);
        setSelectedJob(job.id);
        setSelectedJobSpecialization(job.specialization);
      }}
    >
      {`Talent suppliers: ${numberOfSelectedRecruiters}`}
    </StatisticItem>
  );
}
