import { useMemo } from 'react';
import currencies from '../utils/currencies.json';

const DEFAULT_CURRENCY = 'EUR';
const DEFAULT_REPLACMENT_TOKEN = '¤';

export const ALL_CURRENCIES = currencies;
export const SUGGESTED_CURRENCIES = currencies.filter(({ suggested }) => suggested);
const LOOKUP = Object.fromEntries(ALL_CURRENCIES.map((currency) => [currency.code, currency]));

export type CurrencyISOCode = string;

type CurrencySubject = Object | undefined | null;

export type CurrencyHandler = {
  /** Accepts a value which should be formatted as a currency and returns a formatted currency string */
  curr: (str: CurrencySubject) => string,
  /** Changes the current currency to a new currency */
  changeCurrency: (newCurrency: CurrencyISOCode) => CurrencyHandler,
  /** Replaces a generic currency token (defaults to '¤') with the current currency */
  currReplaceToken: (str: CurrencySubject) => string,
  /** Returns the current currency */
  value: string,
};

const createFormatter = (currency: CurrencyISOCode, replacementToken = DEFAULT_REPLACMENT_TOKEN) => {
  const formatter = (str: CurrencySubject) => {
    const { symbol } = LOOKUP[currency] || {};
    return `${symbol || (currency + '\xa0')}${str}`;
  }
  const tokenRegexp = new RegExp(replacementToken, 'gi');
  return {
    currReplaceToken: (str: CurrencySubject) => str?.toString().replace(tokenRegexp, currency) || '',
    curr: (str: CurrencySubject) => formatter(str),
    changeCurrency: (newCurrency: CurrencyISOCode) => createFormatter(newCurrency || DEFAULT_CURRENCY, replacementToken),
    value: currency,
  };
}

/** Returns a set of utilities for formatting currency values */
const useCurrency: (currency?: string | undefined, replacementToken?: string) => CurrencyHandler = (currency, replacementToken = DEFAULT_REPLACMENT_TOKEN) => {
  return useMemo(() => createFormatter(
    currency || DEFAULT_CURRENCY, // WB: one day this could come from the user or company's default for example... 
    replacementToken,
  ), [currency, replacementToken]);
};

export default useCurrency;
