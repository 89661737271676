import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/trash.svg';
import { ReactComponent as EyeInvisibleIcon } from '../../../icons/eye-invisible.svg';
import { ReactComponent as EyeIcon } from '../../../icons/eye.svg';
import { useTranslation } from 'react-i18next';

interface ActionButtonProps {
  actionType: string;
  onClick: () => void;
  disabled?: boolean;
}

const StyledButton = styled.button`
  width: 100%;
  padding: 0.25rem 0.5rem;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.33;
  text-align: left;
  color: ${({ theme }) => theme.primaryColor};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  background: #ffffff;
  margin: 0.25rem 0;
  cursor: pointer;
  &:focus {
    border-color: ${({ theme }) => theme.hoverColor};
color: ${({ theme }) => theme.hoverColor};
outline: none;
  }
  & span {
  display: flex;
  align-items: center;
}
  & svg {
  font-size: 1rem;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
  &.danger {
  color: #eb5757;
  border-color: #eb5757;
}
  &:disabled {
  border: solid 1px #dde7ee;
  color: #dde7ee;
  cursor: not-allowed;
}
`;

const ActionButton = ({ actionType, onClick, disabled }: ActionButtonProps) => {
  const [t] = useTranslation();
  return (
    <div>
      <StyledButton
        onClick={onClick}
        className={
          actionType === 'delete' || actionType === 'remove' ? 'danger' : ''
        }
        disabled={disabled}
      >
        <span>
          {actionType === 'remove' && <DeleteIcon />}
          {actionType === 'delete' && <DeleteIcon />}
          {actionType === 'unpublish' && <EyeInvisibleIcon />}
          {actionType === 'publish' && <EyeIcon />}
          {actionType === 'edit' && <EditIcon />}
          {actionType === 'view' && <EyeIcon />}
        </span>
        {t(`${actionType.toUpperCase()}_TITLE`)}
      </StyledButton>
    </div>
  );
};

export default ActionButton;
