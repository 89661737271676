import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ConnectAtsForm from '../../../components/settings/atsSettings/ConnectAtsForm';
import AwaitingInitialSyncNotice from '../../../components/settings/atsSettings/AwaitingInitialSyncNotice';
import useAtsStatus from './hooks/useAtsStatus';
import { ATS_SETTINGS, JOBS } from '../../../constants/routes';
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';

const PageLayout = styled.div`
  padding: 2rem;
`;

const ChromiumAtsSettings = () => {
  const { redirectTo, connectedState, status, localJob, refreshStatus } = useAtsStatus();
  const historyWithUrlBase = useHistoryWithUrlBase();
  const absoluteHistory = useHistory();

  useEffect(() => {
    // if a redirect URL was specified, use that as a preference
    // otherwise if a local job was resolved, redirect to the ATS job settings page
    if (!connectedState)
      return;
    if (redirectTo)
      absoluteHistory.push(redirectTo);
    else
      if (localJob)
        historyWithUrlBase.push(`${ATS_SETTINGS}${JOBS}/${localJob.id}`);
  }, [connectedState, redirectTo, absoluteHistory, localJob, historyWithUrlBase])

  if (!status)
    return null;

  if (status.state === 'NOT_CONNECTED') {
    return (
      <PageLayout>
        <ConnectAtsForm onConnectionComplete={refreshStatus} />
      </PageLayout>
    )
  };

  if (status.state === 'AWAITING_INITIAL_SYNC') {
    return (
      <AwaitingInitialSyncNotice onRefresh={refreshStatus} />
    )
  };

  return null;
};

export default ChromiumAtsSettings;
