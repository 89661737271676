import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import {
  LOGIN,
  RATE,
  MAGIC_LINK,
} from '../../constants/routes';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import {
  getToken,
  storePathname,
  storeSearch
} from '../../utils/localStorage';
import axios from 'axios';
import { getUserByToken, logoutUser } from '../../store/userSlice';
import Layout from '../layout/Layout';
import Error from '../../pages/Error';
import { IS_ADMIN_KEY } from '../../constants/roles';
import useGtmDataLayerPush from '../../hooks/useGtmDataLayerPush';

interface PrivateRouteProps extends RouteProps {
  component?: any;
  render?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, render, ...rest } = props;

  const [onboard, setOnboard] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const token = getToken('access_token');
  const isAdmin = getToken(IS_ADMIN_KEY);

  const { isAuth, loading, user } = useAppSelector((state) => state.user);

  useGtmDataLayerPush(user);

  useEffect(() => {
    if (history.location.search.includes('magic_link')) {
      storePathname(history.location.pathname);
      storeSearch(history.location.search);
      history.push(MAGIC_LINK);
    } else {
      axios.defaults.headers.common['Authorization'] = token
        ? `Token ${token}`
        : '';

      if (isAdmin) {
        // this header need only if admin login as customer
        axios.defaults.headers.common['X-Login-As-User'] = true;
      } else {
        delete axios.defaults.headers.common['X-Login-As-User'];
      }

      axios.interceptors.response.use((response) => response, (error) => {
        if (error.response.status >= 500) {
          setError(true)
        } else {
          return Promise.reject(error);
        }
      });
      if (token && !isAuth) {
        dispatch(getUserByToken(token));
      }
      if (!token && !isAuth) {
        history.push(LOGIN);
      }
      if (user && user.role === 'decision_maker') {
        dispatch(logoutUser());
      }
      if (
        user &&
        user.role !== 'admin' &&
        user.onboarding_status !== 'completed'
      ) {
        setOnboard(true);
        //TODO: Previous logic based on completing the company info screen
        //setOnboard(false);
        //history.push(ONBOARD);
      } else if (
        user &&
        user.role !== 'admin' &&
        user.onboarding_status === 'completed'
      ) {
        setOnboard(true);
      }
    }
  }, [dispatch, history, isAuth, token, user, isAdmin]);

  return (
    <Route
      {...rest}
      render={(props) =>
        error ? <Error /> : loading ? (
          ''
        ) : isAuth ? (
          !history.location.pathname.includes(RATE) ? (
            onboard ? (
              <Layout>
                {render ? render() : <Component {...props} />}
              </Layout>
            ) : (
              render ? render() : <Component {...props} />
            )
          ) : (
            render ? render() : <Component {...props} />
          )
        ) : (
          <Redirect to={LOGIN} />
        )
      }
    />
  );
};

export default PrivateRoute;
