import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, message, Col, Row, Select, Upload } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FormTitle from '../auth/FormTitle';
import Button from '../../UI/buttons/Button';
import { getRolesList } from '../../api/roles';
import { updateJobTeamInfo, getJobTeamInfo } from '../../api/jobs';
import FormItem from './FormItem';
import { ReactComponent as AddIcon } from '../../icons/plus.svg';
import { ReactComponent as DeleteBaseIcon } from '../../icons/delete.svg';
import { ReactComponent as EmptyAvatarIcon } from '../../icons/userpic.svg';
import { ReactComponent as UploadFileIcon } from '../../icons/button-add.svg';
import { ReactComponent as RemoveFileIcon } from '../../icons/button-remove.svg';
import {
  numberRule,
  numberRuleNotRequired,
  requiredRule,
} from '../../utils/validationRules';

import { ColleagueQuote, Role, Job, Language } from '../../types';
import { RcFile } from 'antd/lib/upload';
import NumberInput from '../../UI/buttons/NumberInput';
import SelectInput from '../../UI/inputs/Select';
import Input from '../../UI/inputs/Input';
import Textarea from '../../UI/inputs/Textarea';
import { getLanguagesList } from '../../api/languages';
import RouterBlocker from '../jobs/RouterBlocker';
import { ReactComponent as InfoIcon } from '../../icons/info-icon-rounded.svg';
import { JOBS } from '../../constants/routes';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';

const { Option } = Select;

const CustomDropdown = styled.div`
  & .ant-select-item.ant-select-item-option-disabled {
    display: none;
  }
`;
const ColAction = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`;

const DeleteIcon = styled(DeleteBaseIcon)`
  color: #eb5757;
  cursor: pointer;
  margin-left: 1rem;
`;

const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.lozengeColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 1.5rem 0;
`;

const FormSubtitleHint = styled.p`
  margin-bottom: 1.5rem;
`;

const QuoteContainer = styled.div`
  background-color: ${({ theme }) => theme.lozengeColor};
  border-radius: ${({ theme }) => theme.radius};
  padding: 2rem;
  margin-bottom: 1rem;
  position: relative;
`;

const UploadFileButton = styled(UploadFileIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

const RemoveFileButton = styled(RemoveFileIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

const QuoteImage = styled.div`
  width: 80px;
  height: 80px;
  background-size: cover;
  border-radius: 50%;
  float: right;
`;

type JobTeamFormProps = {
  job: Job | undefined;
  onFinish: (id: string) => void;
};

const JobTeamForm = ({ job, onFinish }: JobTeamFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const [saveLoading, setSaveLoading] = useState(false);

  const [quotes, setQuotes] = useState<ColleagueQuote[]>([]);

  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);

  useEffect(() => {
    getRolesList().then((res) => {
      setRoles(res.data);
    });
    getLanguagesList().then((res) => {
      setLanguages(res.data);
    });
    if (job && job.id) {
      getJobTeamInfo(job.id).then((res) => {
        let formData = { ...res.data };
        form.setFieldsValue({
          ...formData,
          team_roles:
            res.data.team_roles.length > 0
              ? res.data.team_roles
              : [
                {
                  role: '',
                  number_of_people: '',
                },
              ],
        });
        if (res.data.colleagues.length > 0) {
          setQuotes(res.data.colleagues);
          res.data.colleagues.forEach((el: ColleagueQuote, index: number) => {
            form.setFieldsValue({
              [`colleague_name${index}`]: el.name,
              [`colleague_quote${index}`]: el.comments,
              [`position${index}`]: el.title,
              [`image${index}`]: el.image,
            });
          });
        }
        if (res.data.team_roles.length) {
          setSelectedRoles(res.data.team_roles.map((el: any) => el.role));
        }
      });
    } else {
      form.setFieldsValue({
        team_roles: [
          {
            role: '',
            number_of_people: '',
          },
        ],
      });
    }
  }, [job, form]);
  const collectFormValues = () => {
    const draft = form.getFieldsValue();

    draft.colleagues = quotes;
    return draft;
  };

  const saveJobInfo = async (isDraft: boolean) => {
    let jobId = job?.id;
    if (jobId) {
      const draft = collectFormValues();

      await updateJobTeamInfo(jobId, draft);
      message.success(t('INFORMATION_SAVE_SUCCESS'), 5);

      return jobId;
    }
    return jobId;
  };

  const handleNext = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (job?.id) {
      handleSave(e)
        .then(() => {
          onFinish(job.id);
          setBlockTransition(false);
        })
        .catch((error) => {
          if (error.response.data.error_code === 'access_forbidden_error') {
            return;
          }
        });
    }
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setSaveLoading(true);
      await saveJobInfo(true);
      setBlockTransition(false);
      setSaveLoading(false);
    } catch (error: any) {
      if (error.response.data.error_code === 'access_forbidden_error') {
        message.error(t('JOB_UNDER_REVIEW'), 5);
        setBlockTransition(false);
        setSaveLoading(false);
        throw error;
      }
      message.error(error.response.data.message, 5);
      setBlockTransition(false);
      setSaveLoading(false);
    }
  };

  const [blockTransition, setBlockTransition] = useState(false);
  const handleFieldsChange = (changed: any, all: any) => {
    setBlockTransition(true);
  };

  const handleAddQuote = () => {
    setQuotes((state) => [
      ...state,
      {
        name: '',
        title: '',
        comments: '',
        image: '',
      },
    ]);
  };
  const handleRoleChange = (e: string, idx: number) => {
    const ids = form.getFieldValue('team_roles').map((el: any) => el?.role);
    setSelectedRoles(ids);
  };
  const handleRemoveQuote = (index: number) => {
    form.setFieldsValue({
      [`colleague_name${quotes.length - 1}`]: undefined,
      [`colleague_quote${quotes.length - 1}`]: undefined,
      [`position${quotes.length - 1}`]: undefined,
      [`image${quotes.length - 1}`]: undefined,
    });

    setQuotes((state) => state.filter((quote, i) => i !== index));
  };

  const handleUploadFile = (file: RcFile, quoteIndex: number): boolean => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        if (base64) {
          setQuotes((state) =>
            state.map((quote, i) => {
              if (i === quoteIndex) {
                return { ...quote, image: base64.toString() };
              }
              return quote;
            }),
          );
          form.setFieldsValue({ [`image${quoteIndex}`]: file.name });
        }
      };
      reader.onerror = (error) => reject(error);
    });
    return false;
  };

  const handleChangeQuoteField = (
    quoteIndex: number,
    field: string,
    value: string,
  ) => {
    setQuotes((state) =>
      state.map((quote, i) => {
        if (i === quoteIndex) {
          return { ...quote, [field]: value };
        }
        return quote;
      }),
    );
  };

  const handleRemoveImage = (quoteIndex: number) => {
    setQuotes((state) =>
      state.map((quote, i) => {
        if (i === quoteIndex) {
          return { ...quote, image: '' };
        }
        return quote;
      }),
    );
    form.setFieldsValue({ [`image${quoteIndex}`]: undefined });
  };
  return (
    <>
      <RouterBlocker isBlocked={blockTransition} />
      <Form
        scrollToFirstError={true}
        layout="vertical"
        name="job-form"
        form={form}
        onFieldsChange={handleFieldsChange}
      >
        <FormTitle text={t('TEAM_INFO_TITLE')} />

        <FormItem className="no-margin">
          <Row gutter={16} justify="space-between">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <FormItem
                label={t('TEAM_MEMBERS_AMOUNT')}
                name="team_members_num"
                rules={requiredRule(t)}
                tooltip={
                  t('JOB_TEAM_FORM_TEAM_MEMBERS_NUM_TOOLTIP')
                    ? {
                      title: t('JOB_TEAM_FORM_SALARY_FROM_TOOLTIP'),
                      icon: <InfoIcon />,
                    }
                    : false
                }
              >
                <NumberInput size="large" type={'number'} />
              </FormItem>
            </Col>
          </Row>
        </FormItem>

        <FormItem
          label={t('SPOKEN_LANGUAGES')}
          name="spoken_languages"
          tooltip={
            t('JOB_TEAM_FORM_SPOKEN_LANGUAGES_TOOLTIP')
              ? {
                title: t('JOB_TEAM_FORM_SPOKEN_LANGUAGES_TOOLTIP'),
                icon: <InfoIcon />,
              }
              : false
          }
        >
          <SelectInput
            size="large"
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
          >
            {languages.map((language) => {
              return (
                <Option key={language.code} value={language.code}>
                  {language.name}
                </Option>
              );
            })}
          </SelectInput>
        </FormItem>

        <Form.List name="team_roles">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <FormItem className={'no-margin'}>
                  <Row gutter={16}>
                    <Col
                      xs={{ span: 16 }}
                      sm={{ span: 16 }}
                      md={{ span: 16 }}
                      lg={{ span: 16 }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'role']}
                        fieldKey={[fieldKey, 'role']}
                        rules={
                          index || fields.length > 1 ? requiredRule(t) : []
                        }
                        label={t('OTHER_TEAM_ROLES')}
                        tooltip={
                          t('JOB_TEAM_FORM_OTHER_TEAM_ROLES_TOOLTIP')
                            ? {
                              title: t(
                                'JOB_TEAM_FORM_OTHER_TEAM_ROLES_TOOLTIP',
                              ),
                              icon: <InfoIcon />,
                            }
                            : false
                        }
                      >
                        <SelectInput
                          size="large"
                          filterOption={true}
                          suffixIcon={
                            <CaretDownOutlined style={{ color: '#000' }} />
                          }
                          onChange={(e) => handleRoleChange(e, index)}
                          dropdownRender={(menu) => (
                            <CustomDropdown>{menu}</CustomDropdown>
                          )}
                        >
                          {roles.map((r) => (
                            <Option
                              key={r.id}
                              value={r.id}
                              disabled={selectedRoles.includes(r.id)}
                            >
                              {r.name}
                            </Option>
                          ))}
                        </SelectInput>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 6 }}
                      sm={{ span: 6 }}
                      md={{ span: 6 }}
                      lg={{ span: 6 }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'number_of_people']}
                        fieldKey={[fieldKey, 'number_of_people']}
                        rules={
                          index || fields.length > 1
                            ? numberRule(t)
                            : numberRuleNotRequired(t)
                        }
                        label={t('NUMBER_OF_PEOPLE')}
                        tooltip={
                          t('JOB_TEAM_FORM_NUMBER_OF_PEOPLE_TOOLTIP')
                            ? {
                              title: t(
                                'JOB_TEAM_FORM_NUMBER_OF_PEOPLE_TOOLTIP',
                              ),
                              icon: <InfoIcon />,
                            }
                            : false
                        }
                      >
                        <NumberInput size="large" type={'number'} />
                      </Form.Item>
                    </Col>
                    {index > 0 && (
                      <Col
                        xs={{ span: 1 }}
                        sm={{ span: 1 }}
                        md={{ span: 1 }}
                        lg={{ span: 1 }}
                      >
                        <ColAction>
                          <DeleteIcon
                            onClick={() => {
                              setSelectedRoles((prevState) =>
                                prevState.filter((el, idx) => idx !== name),
                              );
                              remove(name);
                            }}
                          />
                        </ColAction>
                      </Col>
                    )}
                  </Row>
                </FormItem>
              ))}
              <FormItem>
                <Button
                  type="ghost"
                  size="large"
                  icon={<AddIcon />}
                  onClick={() => add()}
                >
                  {t('ADD_ROLE')}
                </Button>
              </FormItem>
            </>
          )}
        </Form.List>

        <FormSubtitle>{t('COLLEAGUE_QUOTES')}</FormSubtitle>
        <FormSubtitleHint>{t('COLLEAGUE_QUOTES_HINT')}</FormSubtitleHint>
        {quotes.map((quote, index) => {
          return (
            <QuoteContainer>
              <FormItem className="no-margin">
                <Row gutter={16}>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 10 }}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                  >
                    <FormItem
                      label={t('COLLEAGUE_NAME')}
                      name={'colleague_name' + index}
                      rules={requiredRule(t)}
                    >
                      <Input
                        size="large"
                        value={quote.name}
                        onChange={(e: any) =>
                          handleChangeQuoteField(index, 'name', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 10 }}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                  >
                    <FormItem
                      label={t('POSITION')}
                      name={'position' + index}
                      rules={requiredRule(t)}
                    >
                      <Input
                        size="large"
                        value={quote.title}
                        onChange={(e: any) =>
                          handleChangeQuoteField(index, 'title', e.target.value)
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col
                    xs={{ span: 4 }}
                    sm={{ span: 4 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}
                  >
                    <FormItem name={'image' + index} rules={requiredRule(t)}>
                      {!quote.image && (
                        <EmptyAvatarIcon style={{ float: 'right' }} />
                      )}
                      {!quote.image && (
                        <Upload
                          accept="image/png, image/jpeg"
                          beforeUpload={(file: RcFile) =>
                            handleUploadFile(file, index)
                          }
                          showUploadList={false}
                        >
                          <UploadFileButton />
                        </Upload>
                      )}
                      {quote.image && (
                        <RemoveFileButton
                          onClick={() => handleRemoveImage(index)}
                        />
                      )}
                      {quote.image && (
                        <QuoteImage
                          style={{
                            backgroundImage: 'url(' + quote.image + ')',
                          }}
                        ></QuoteImage>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}
                >
                  <FormItem
                    label={t('COLLEAGUE_QUOTE')}
                    name={'colleague_quote' + index}
                    rules={requiredRule(t)}
                  >
                    <Textarea
                      autoSize={{ minRows: 4 }}
                      value={quote.comments}
                      onChange={(e: any) =>
                        handleChangeQuoteField(
                          index,
                          'comments',
                          e.target.value,
                        )
                      }
                    />
                  </FormItem>
                </Col>
              </Row>

              <Button
                type="ghost"
                size={'small'}
                danger
                icon={<DeleteBaseIcon />}
                onClick={() => handleRemoveQuote(index)}
              >
                {t('DELETE_QUOTE')}
              </Button>
            </QuoteContainer>
          );
        })}
        <FormItem>
          <Button
            type="ghost"
            size="large"
            icon={<AddIcon />}
            onClick={handleAddQuote}
          >
            {t('ADD_QUOTE')}
          </Button>
        </FormItem>

        <FormActions>
          <Button
            type="ghost"
            loading={saveLoading}
            onClick={(e) => {
              form.submit();
              form.validateFields().then((res) => {
                handleSave(e).then(() => {
                  history.push(`${JOBS}/${job?.id}`);
                });
              });
            }}
          >
            {t('SAVE_AND_PREVIEW_DRAFT_TITLE')}
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              form.submit();
              form.validateFields().then((res) => {
                handleNext(e);
              });
            }}
          >
            {t('NEXT_TITLE')}
          </Button>
        </FormActions>
      </Form>
    </>
  );
};

export default JobTeamForm;
