export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const DASHBOARD = '/dashboard';
export const JOBS = '/jobs';
export const CANDIDATES = '/candidates';
export const RECRUITERS = '/recruiters';
export const COMPANIES = '/clients';
export const MESSAGES = '/messages';
export const RECRUITER_MANAGEMENT = '/recruiter-management';
export const ANALYTICS = '/analytics';
export const MASTER_CONTRACTS = '/master-contracts';
export const PARTNER_DASHBOARD = '/partner-dashboard';
export const RECRUITER = '/recruiter';
export const CLIENT = '/client';
export const INVITE = '/invite';
export const ONBOARD = '/onboard';
export const RESET_PASSWORD = '/reset-password';
export const CALENDAR = '/calendar';
export const LINKEDIN_REDIRECT = '/linkedin';
export const GOOGLE_REDIRECT_HANDLER = '/signInCallback/google';
export const PROFILE_SETTINGS = '/profile';
export const CANDIDATES_SPECIFICATION_SETTINGS = '/candidates-specification';
export const PAYMENT_INFO_SETTINGS = '/payment-info';
export const ATS_SETTINGS = '/ats-settings';
export const PAYMENTS = '/payments';
export const NOTIFICATIONS_SETTINGS = '/notifications-settings';
export const HIREPORT_ACADEMY = 'https://help.hireport.nl/';
export const NOTIFICATIONS = '/notifications';
export const GUEST = '/guest';
export const PRIVACY_POLICY = '/privacy-policy';
export const PRICING_TERMS = '/pricingterms';
export const PRICING_TERMS_ABSOLUTE = 'https://hireport.io/pricingterms';
export const RATE = '/rate';
export const MAGIC_LINK = '/loading';
export const MAGIC_LINK_INIT = '/links';
export const BROWSER_PLUGINS = '/plugins';
export const EMBED = '/embed';
