import React from 'react';
import styled from 'styled-components';
import useHirePortContext from '../../hooks/useHirePortContext';

interface PageWrapperProps extends React.HTMLAttributes<HTMLDivElement> { }

const StyledPageWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 0 2.5rem 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &.full-width {
    padding-right: 0;
  }
  @media (max-width: 576px) {
    padding: 0 1.5rem;
  }
`;
const PageWrapper = ({ children, ...rest }: PageWrapperProps) => {
  const { renderPageWrapper } = useHirePortContext();
  return renderPageWrapper
    ? <StyledPageWrapper {...rest}>{children}</StyledPageWrapper>
    : <>{children}</>;
};

export default PageWrapper;
