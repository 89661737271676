import React, { ChangeEvent, useState } from 'react';
import { requiredRule } from '../../utils/validationRules';
import { Form, Row, Col, message, Select } from 'antd';
import Button from '../../UI/buttons/Button';
import FormItem from '../form/FormItem';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Input from '../../UI/inputs/Input';
import SelectInput from '../../UI/inputs/Select';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import { getToken, removeToken } from '../../utils/localStorage';

const SignUpForm = ({ onSuccess }: any) => {
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();
  const options = [
    { id: 1, name: 'Company' },
    { id: 2, name: 'Recruiter' }
  ];

  const { Option } = Select;

  const onFinish = (values: any) => {
    const jobId: string | null = getToken('job');
    if (jobId) {
      values.job = jobId;
      removeToken('job');
    }
    axios
      .post(process.env.REACT_APP_API_URL + 'recruiters/invite/request/', {
        ...values,
        phone,
      })
      .then((response) => {
        message.success(response.data.detail, 5);
        onSuccess();
      })
      .catch((e) => console.error(e));
  };

  const handleValidation = (e: ChangeEvent<HTMLInputElement>) => {
    let reg = /^\+?\d+$/;
    if (
      e.target.value === '' ||
      e.target.value === '+' ||
      reg.test(e.target.value)
    ) {
      setPhone(e.target.value);
    } else {
      setPhone(phone);
    }
  };
  // @ts-ignore
  return (
    <Form layout="vertical" name="request-invite" onFinish={onFinish} scrollToFirstError={true}>
      <FormItem
        label={t('YOUR_NAME_LABEL')}
        name="name"
        validateTrigger={'onBlur'}
        rules={requiredRule(t)}
      >
        <Input size="large" />
      </FormItem>
      <FormItem style={{ marginBottom: 'auto' }}>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 11 }}
            md={{ span: 24 }}
            lg={{ span: 11 }}
          >
            <FormItem
              label={t('EMAIL_LABEL')}
              name="email"
              validateTrigger={'onBlur'}
              rules={[
                {
                  type: 'email',
                  message: t('INVALID_EMAIL_ERROR'),
                  required: true,
                },
              ]}
            >
              <Input size="large" />
            </FormItem>
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 11, offset: 2 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 11, offset: 2 }}
          >
            <FormItem label={`${t('PHONE_TEXT')} (${t('OPTIONAL_TEXT')})`}>
              <Input
                value={phone}
                size="large"
                onChange={(e) => handleValidation(e)}
              />
            </FormItem>
          </Col>
        </Row>
      </FormItem>
      <FormItem
        label={t('COMPANY_TITLE')}
        name="company"
        validateTrigger={'onBlur'}
        rules={requiredRule(t)}
      >
        <Input size="large" />
      </FormItem>
      <FormItem
        label={t('WANT_ACCESS_AS')}
        name="access_as"
        rules={requiredRule(t)}
        validateTrigger={'onBlur'}
      >
        <SelectInput
          size="large"
          showSearch
          placeholder={t('WANT_ACCESS_AS')}
          optionFilterProp="children"
          filterOption={true}
          className="custom-select"
          suffixIcon={<ArrowDownIcon />}
        >
          {options.map((item) => (
            <Option value={item.name.toLowerCase()} key={item.id}>
              {item.name}
            </Option>
          ))}
        </SelectInput>
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit">
          {t('REQUEST_INVITE_TITLE')}
        </Button>
      </FormItem>
    </Form>
  );
};

export default SignUpForm;
