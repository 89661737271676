import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Select } from 'antd';
import { getUrlString } from '../../constants/queryParams';
import FormItem from '../form/FormItem';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import styled from 'styled-components';
import { City, Specialization } from '../../types';
import { getCityList } from '../../api/regions';
import { getSpecializationList } from '../../api/specialization';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import SelectInput from '../../UI/inputs/Select';
import Input from '../../UI/inputs/Input';

const FilterWrapper = styled.div`
  padding: 1rem 2rem 0;
  border-radius: ${({ theme }) => theme.radius};
  background: #ffffff;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 998;
  @media (max-width: 576px) {
    position: relative;
  }
`;

const StyledButton = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.primaryColor};
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  left: 100%;
  transform: translateX(-50%);
  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  &:hover {
    background: ${({ theme }) => theme.hoverColor};
  }
`;
const SORT_BY = [
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Date',
    value: 'date',
  },
];
const { Option } = Select;
const RecruiterListFilter = () => {
  const [locations, setLocations] = useState<City[]>([]);
  const [domains, setDomains] = useState<Specialization[]>([]);
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const company_name = params.get('company_name');
  const contact_name = params.get('contact_name');
  const location = params.get('location');
  const domain = params.get('domain');
  const sort = params.get('sort');
  const [t] = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => {
    getCityList().then((res) => setLocations(res.data));
    getSpecializationList().then((res) => setDomains(res.data));
  }, []);
  const onFinish = (values: any) => {
    const query = getUrlString(values);
    history.push({ search: '?' + query });
  };
  useEffect(() => {
    form.setFieldsValue({
      company_name,
      location: !!locations.length && location,
      domain: !!domains.length && domain,
      contact_name,
      sort,
    });
  }, [
    form,
    company_name,
    location,
    contact_name,
    domain,
    domains,
    sort,
    locations,
  ]);
  return (
    <div>
      <FilterWrapper>
        <Form
          scrollToFirstError={true}
          layout="vertical"
          name="client-filter"
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={16}>
            <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 5 }}>
              <FormItem label={t('COMPANY_NAME_TITLE')} name="company_name">
                <Input placeholder={t('COMPANY_NAME_TITLE')} size={'large'} />
              </FormItem>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }} xl={{ span: 5 }}>
              <FormItem label={t('CONTACT_PERSON_NAME')} name="contact_name">
                <Input placeholder={t('CONTACT_PERSON_NAME')} size={'large'} />
              </FormItem>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 8 }} xl={{ span: 5 }}>
              <FormItem label={t('LOCATION_LABEL')} name="location">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('LOCATION_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {locations.map((city) => (
                    <Option value={city.id} key={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 12 }} xl={{ span: 5 }}>
              <FormItem label={t('DOMAIN_TITLE')} name="domain">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('DOMAIN_PLACEHOLDER')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {domains.map((dom) => (
                    <Option value={dom.id} key={dom.id}>
                      {dom.name}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 4 }}>
              <FormItem label={t('SORT_BY')} name="sort">
                <SelectInput
                  size="large"
                  showSearch
                  placeholder={t('SORT_BY')}
                  optionFilterProp="children"
                  filterOption={true}
                  className="custom-select"
                  suffixIcon={<ArrowDownIcon />}
                >
                  <Option value="">{t('NONE')}</Option>
                  {SORT_BY.map((city) => (
                    <Option value={city.value} key={city.value}>
                      {city.title}
                    </Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
          </Row>
          <StyledButton type="submit">
            <SearchIcon />
          </StyledButton>
        </Form>
      </FilterWrapper>
    </div>
  );
};

export default RecruiterListFilter;
