import { formatJobStatusForClient } from '../components/jobs/jobList/JobDescription/jobDescriptionHelpers';
import { Job } from '../types';
import moment from 'moment';

export const calculateDaysBefore = (job: Job): number => {
  return job?.published_at
    ? moment().diff(moment(job?.published_at), 'days')
    : 0;
};

export function calculateDaysSinceJobOpened(
  job: Job,
  role: string,
  t: (key: string) => string,
  online?: boolean,
): string {
  let daysBefore = calculateDaysBefore(job);

  const publishedAtDate =
    job.status === 'published' && job?.published_at
      ? `${t('FOR_JOIN_TITLE')} ${daysBefore + 1} ${
          daysBefore === 0 ? t('DAYS_TITLE') : t('DAYS_TITLE')
        }`
      : '';

  return `${formatJobStatusForClient({ role, status: job.status, t, online })}${
    publishedAtDate && ' ' + publishedAtDate
  }`;
}
