import { useCallback, useState } from 'react';
import { AutoCompleteProps } from 'antd';
import { ALL_CURRENCIES, SUGGESTED_CURRENCIES } from '../../../../hooks/useCurrency';
import SelectInput from '../../../../UI/inputs/Select';

const SEARCHABLE_CURRENCIES = ALL_CURRENCIES.map((currency) => ({ ...currency, searchTokens: `${currency.code} ${currency.description} ${currency.symbol || ''}`.toLowerCase() }))

const CurrencySelect = (props: AutoCompleteProps) => {
  const [options, setOptions] = useState(SUGGESTED_CURRENCIES)
  const onSearch = useCallback(
    (search: string) => {
      if (!search) {
        setOptions(SUGGESTED_CURRENCIES);
        return;
      }
      setOptions(SEARCHABLE_CURRENCIES.filter(({ searchTokens }) => searchTokens.includes(search.toLowerCase())));
    },
    [setOptions],
  );

  return (
    <SelectInput
      showSearch
      notFoundContent={null}
      onSearch={onSearch}
      filterOption={false}
      options={options.map(({ code, symbol }: { code: string, symbol?: string }) => ({ value: code, label: `${code}${symbol ? ' (' + symbol + ')' : ''}` }))}
      {...props}
    />
  );
};

export default CurrencySelect;
