import { ReactComponent as StarIcon } from '../../icons/star-green-filled.svg';
import { useTranslation } from 'react-i18next';
import { Recruiter, Specialization } from '../../types';
import TooltipPrompt from '../../UI/tootlip';
import { Link } from 'react-router-dom';
import { RECRUITER } from '../../constants/routes';
import NameImage from '../client/NameImage';
import useCurrency, { CurrencyHandler } from '../../hooks/useCurrency';

import {
  formatRating,
  trimSpecialisations,
} from '../../utils/formatRecruiters';
import styled from 'styled-components';
import { calcRecruiterType } from '../recruiter/RecruiterSelectTableRow';
import ActionButton from '../jobs/jobList/ActionButton';
import ListAction from '../jobs/jobList/ListAction';
import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import useHirePortTheme from '../../hooks/useHirePortTheme';
import useHirePortContext, { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';

const RecruiterTableRowDiv = styled.div`
  display: flex;
  flexdirection: row;
  alignitems: center;
  justifycontent: space-around;
  borderbottom: 1px solid #dae5ec;
  padding: 6px 0;
`;

const Text = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  margin: 0 0;
  &:first-child {
    margin: 0;
  }
  align-self: center;
`;

export const formatFeeForRecruiterManagement = (
  fee_type: string,
  fee_description: number,
  currFunc: CurrencyHandler['curr'],
) => {
  switch (fee_type) {
    case 'percentage':
      return `${fee_description}%`;
    case 'fixed':
      return currFunc(fee_description);
    default:
      return '-';
  }
};

function RecruiterManagementTableRow({
  recruiter,
  domains,
  handleDeleteRecruiterCompanyAgreement,
}: {
  recruiter: Recruiter;
  domains: Specialization[];
  handleDeleteRecruiterCompanyAgreement: (recruiter: Recruiter) => void;
}) {
  const theme = useHirePortTheme();
  const { urlBase } = useHirePortContext();
  const [t] = useTranslation();
  const { curr } = useCurrency(recruiter.fee_currency);
  const history = useHistoryWithUrlBase();
  const [specializations, setSpecializations] = useState<string[]>([]);
  const [suffix, setSuffix] = useState<string>('');

  const showEditOrViewSupplier = () => {
    if (recruiter.own_recruiter && recruiter.company_recruiter_agreement_id) {
      history.push(
        `${RECRUITER}/${recruiter.id}/edit/${recruiter.company_recruiter_agreement_id}`,
      );
    } else {
      history.push(`${RECRUITER}/${recruiter.id}`);
    }
  };

  useEffect(() => {
    const recruiterSpecializations = trimSpecialisations(
      recruiter.specializations,
    );
    setSpecializations(() => recruiterSpecializations.structured_specs);
    setSuffix(() => recruiterSpecializations.suffix ?? '');
  }, [recruiter.specializations]);

  return (
    <RecruiterTableRowDiv>
      <div style={{ flex: '20%' }}>
        <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}>
          <Link
            to={
              recruiter.own_recruiter &&
                recruiter.company_recruiter_agreement_id
                ? `${urlBase}${RECRUITER}/${recruiter.id}/edit/${recruiter.company_recruiter_agreement_id}`
                : `${RECRUITER}/${recruiter.id}`
            }
            rel="noopener noreferrer"
          >
            <NameImage
              title={recruiter.name}
              image={recruiter.image}
              color={
                recruiter.own_recruiter
                  ? { main: '#7949cd', light: '#f2edfa' }
                  : { main: '#29ae5e', light: '#eaf7ef' }
              }
              description={
                recruiter.recruiter_type !== 'freelance'
                  ? recruiter.company.name
                  : recruiter.title
              }
            />
          </Link>
        </TooltipPrompt>
      </div>
      <div style={{ flex: '10%', alignSelf: 'center' }}>
        {calcRecruiterType(recruiter, t)}
      </div>
      <Text style={{ flex: '35%' }}>
        {specializations.length > 0
          ? specializations.map((spec: string) => (
            <Tag
              key={spec}
              style={{
                borderRadius: '8px',
              }}
              color={domains.find((x) => x.name === spec)?.color ?? 'blue'}
            >
              {spec}
            </Tag>
          ))
          : '-'}
        {suffix && (
          <Tag
            style={{
              borderRadius: '8px',
            }}
            color={'#A6B1BB'}
          >
            {suffix}
          </Tag>
        )}
      </Text>
      <Text style={{ flex: '15%' }}>
        {formatFeeForRecruiterManagement(
          recruiter.fee_type,
          recruiter.fee_description,
          curr,
        )}
      </Text>
      <Text
        style={{
          flex: '5%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        {formatRating(recruiter) === '-' ? (
          <div
            style={{
              marginLeft: '0.5em',
            }}
          >
            -
          </div>
        ) : (
          [...Array(formatRating(recruiter))].map((_, i) => (
            <StarIcon
              key={i}
              style={{
                height: '1em',
                width: '1em',
                color: theme.primaryColor,
              }}
            />
          ))
        )}
      </Text>
      <div
        style={{
          flex: '5%',
          textAlign: 'center',
          paddingTop: '0.5em',
        }}
      >
        <ListAction
          status={recruiter.agreement}
          id={recruiter.id}
          content={
            <>
              <ActionButton
                onClick={() => showEditOrViewSupplier()}
                actionType="view"
              />
              {recruiter.own_recruiter && (
                <>
                  <ActionButton
                    onClick={() =>
                      handleDeleteRecruiterCompanyAgreement(recruiter)
                    }
                    actionType="remove"
                  />
                </>
              )}
            </>
          }
        />
      </div>
    </RecruiterTableRowDiv>
  );
}

export default RecruiterManagementTableRow;
