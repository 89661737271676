import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
  ANALYTICS,
  PARTNER_DASHBOARD,
  CANDIDATES,
  COMPANIES,
  DASHBOARD,
  JOBS,
  MESSAGES,
  RECRUITERS,
  RECRUITER_MANAGEMENT,
  MASTER_CONTRACTS,
} from '../../constants/routes';
import { Badge } from 'antd';
import { ReactComponent as NavLogo } from '../../icons/nav-logo.svg';
import { ReactComponent as DashboardIcon } from '../../icons/dashboard.svg';
import { ReactComponent as JobIcon } from '../../icons/jobs.svg';
import { ReactComponent as CandidatesIcon } from '../../icons/candidates.svg';
import { ReactComponent as RecruitersIcon } from '../../icons/recruiters.svg';
import { ReactComponent as AnalyticsIcon } from '../../icons/analytics.svg';
import { ReactComponent as CompanyIcon } from '../../icons/companies.svg';
import { ReactComponent as MessageIcon } from '../../icons/messages.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as AcademyIcon } from '../../icons/market.svg';
import { ReactComponent as HeartIcon } from '../../icons/heart.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-icon.svg';

import useClickAwayListener from '../../hooks/useClickAwayListener';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  ADMIN_ROLE,
  RECRUITER_ROLE,
  IS_ADMIN_KEY,
  CLIENT_ROLE,
} from '../../constants/roles';
import NotificationMenuItem from '../../UI/buttons/NotificationMenuItem';
import ProfileMenuPopup from '../../UI/Popups/ProfileMenuPopup';
import { getGlobalUnread } from '../../store/messagesSlice';
import { getUnreadCount } from '../../store/notificationsSlice';
import NameAvatar from '../../UI/NameAvatar';
import { trackPageView } from '../../api/events';
import TooltipPrompt from '../../UI/tootlip';
import { useTranslation } from 'react-i18next';
import SwitchUser from '../admin/SwitchUser';
import { getToken } from '../../utils/localStorage';
import LinksMenuPopup from '../../UI/Popups/LinksMenuPopup';

const LeftNavigation = styled.div`
  padding: 2rem 0.75rem 1.5rem;
  width: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar:hover {
    height: 5px;
    width: 5px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #babac0;
    border-radius: 5px;
    height: 5px;
    width: 5px;
  }
  &.mobile-hide {
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 576px) {
    width: auto;
    padding: 1.5rem 0.5rem;
  }
`;

const NavigationLogo = styled(Link)`
  display: block;
`;
const NavigationMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const MenuItem = styled(NavLink)`
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #627d95;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -0.75rem;
    width: 4px;
    height: 0;
    transition: height ease 0.3s;
    background: ${({ theme }) => theme.primaryColor};
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    @media (max-width: 576px) {
      right: -0.5rem;
      width: 2px;
    }
  }
  &.active {
    color: ${({ theme }) => theme.activeColor};
    & svg path {
      stroke: ${({ theme }) => theme.activeColor};
      transition: all ease 0.3s;
    }
    &:after {
      height: 100%;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.hoverColor};
    & svg path {
  stroke: ${({ theme }) => theme.hoverColor};
transition: all ease 0.3s;
}
  }
`;

const UnreadMessagesBadge = styled(Badge)`
  & .ant-badge-count {
  top: 0.25rem;
  right: 2px;
  min-width: 1rem;
  height: 1rem;
  padding: 3px;
  line-height: 1rem;
  background: ${({ theme }) => theme.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
    & .ant-scroll-number-only {
    height: auto;
      & > p.ant-scroll-number-only-unit {
      height: auto;
    }
  }
}
`;

const SettingsMenu = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background: #627d95;
width: 2.5rem;
height: 2.5rem;
border-radius: 50 %;
cursor: pointer;
position: relative;
  &.transparent {
  background: transparent;
}
`;

const LinksMenu = styled.div`
margin: 0 0 1.5rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background: #627d95;
width: 2.5rem;
height: 2.5rem;
border-radius: 50 %;
cursor: pointer;
position: relative;
  &.transparent {
  background: transparent;
}
`;

const SwitchUserWrap = styled.div`
margin-bottom: 16px;
`;

const Navigation = () => {
  const { user } = useAppSelector((state) => state.user);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showLinksMenu, setShowLinksMenu] = useState(false);
  const [t] = useTranslation();
  const menuRef = useRef(null);
  const linksRef = useRef(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMessagePage = history.location.pathname.includes('/messages');
  const { globalUnread } = useAppSelector((state) => state.messages);
  const isNotAdmin = user?.role !== ADMIN_ROLE;
  useEffect(() => {
    const requestGlobalCounter = () => {
      dispatch(getGlobalUnread());
      dispatch(getUnreadCount());
    };

    requestGlobalCounter();

    const interval = setInterval(requestGlobalCounter, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  useClickAwayListener(menuRef, () => {
    if (showProfileMenu) {
      setShowProfileMenu(false);
    }
  });
  useClickAwayListener(linksRef, () => {
    if (showLinksMenu) {
      setShowLinksMenu(false);
    }
  });
  const handleClickProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };
  const handleHideProfileMenu = () => {
    setShowProfileMenu(false);
  };

  const handleClickLinksMenu = () => {
    setShowLinksMenu(!showLinksMenu);
  };
  const handleHideLinksMenu = () => {
    setShowLinksMenu(false);
  };

  const role = user?.role;
  const isAdmin = role === ADMIN_ROLE;
  const isAdminUser = getToken(IS_ADMIN_KEY);
  const showSwitchUser = isAdmin || isAdminUser;
  const talentSupplierModuleEnabled =
    user?.company?.talent_supplier_module_enabled;
  const analytics_module_enabled = user?.company?.analytics_module_enabled;
  const partner_dashboard_enabled = user?.partner_dashboard_enabled;
  const master_contracts_enabled =
    user?.company?.company_wide_fee_settings_enabled;

  const handleMessagesClick = useCallback(() => {
    // dispatch(resetInnerCounters()); this function cause a bug with counters,
    history.push(MESSAGES);
  }, [history]);
  const trackPage = (page: string) => {
    if (history.location.pathname !== page && isNotAdmin) {
      trackPageView(page);
    }
  };

  return (
    <LeftNavigation className={isMessagePage ? 'mobile-hide' : ''}>
      <NavigationLogo to={DASHBOARD} onClick={() => trackPage(DASHBOARD)}>
        <NavLogo />
      </NavigationLogo>
      {user && (
        <>
          <NavigationMenu>
            <TooltipPrompt
              title={t('MENU_DASHBOARD_TOOLTIP')}
              placement="right"
            >
              <MenuItem to={DASHBOARD} onClick={() => trackPage(DASHBOARD)}>
                <DashboardIcon />
              </MenuItem>
            </TooltipPrompt>
            <TooltipPrompt title={t('MENU_JOBS_TOOLTIP')} placement="right">
              <MenuItem to={JOBS} onClick={() => trackPage(JOBS)}>
                <JobIcon />
              </MenuItem>
            </TooltipPrompt>
            <TooltipPrompt
              title={t('MENU_CANDIDATES_TOOLTIP')}
              placement="right"
            >
              <MenuItem
                to={`${CANDIDATES}?filter =in -process`}
                onClick={() => trackPage(CANDIDATES)}
              >
                <CandidatesIcon />
              </MenuItem>
            </TooltipPrompt>
            {role === CLIENT_ROLE && talentSupplierModuleEnabled && (
              <TooltipPrompt
                title={t('MENU_RECRUITER_MANAGEMENT_TOOLTIP')}
                placement="right"
              >
                <MenuItem to={RECRUITER_MANAGEMENT}>
                  <RecruitersIcon />
                </MenuItem>
              </TooltipPrompt>
            )}
            {role === CLIENT_ROLE && analytics_module_enabled && (
              <TooltipPrompt
                title={t('MENU_ANALYTICS_TOOLTIP')}
                placement="right"
              >
                <MenuItem to={ANALYTICS}>
                  <AnalyticsIcon />
                </MenuItem>
              </TooltipPrompt>
            )}
            {role === CLIENT_ROLE && master_contracts_enabled && (
              <TooltipPrompt
                title={t('MENU_MASTER_CONTRACTS_TOOLTIP')}
                placement="right"
              >
                <MenuItem to={MASTER_CONTRACTS}>
                  <EditIcon />
                </MenuItem>
              </TooltipPrompt>
            )}
            {partner_dashboard_enabled && (
              <TooltipPrompt
                title={t('MENU_PARTNER_DASHBOARD')}
                placement="right"
              >
                <MenuItem to={PARTNER_DASHBOARD}>
                  <HeartIcon />
                </MenuItem>
              </TooltipPrompt>
            )}
            {isAdmin ? (
              <>
                <TooltipPrompt
                  title={t('MENU_RECRUITERS_TOOLTIP')}
                  placement="right"
                >
                  <MenuItem to={RECRUITERS}>
                    <RecruitersIcon />
                  </MenuItem>
                </TooltipPrompt>
                <TooltipPrompt
                  title={t('MENU_COMPANIES_TOOLTIP')}
                  placement="right"
                >
                  <MenuItem to={COMPANIES}>
                    <CompanyIcon />
                  </MenuItem>
                </TooltipPrompt>
              </>
            ) : (
              <></>
            )}
            <TooltipPrompt title={t('MENU_MESSAGES_TOOLTIP')} placement="right">
              <MenuItem
                to={MESSAGES}
                onClick={() => {
                  trackPage(MESSAGES);
                  handleMessagesClick();
                }}
              >
                <UnreadMessagesBadge count={globalUnread}>
                  <MessageIcon />
                </UnreadMessagesBadge>
              </MenuItem>
            </TooltipPrompt>
          </NavigationMenu>
          <NavigationMenu>
            {!isAdmin && <NotificationMenuItem />}
            {showSwitchUser && (
              <SwitchUserWrap>
                <SwitchUser />
              </SwitchUserWrap>
            )}
            {!isAdmin && (
              <LinksMenu
                onClick={handleClickLinksMenu}
                ref={linksRef}
                onBlur={handleHideLinksMenu}
                className={!isAdmin ? 'transparent' : ''}
              >
                <AcademyIcon />
                <LinksMenuPopup show={showLinksMenu} />
              </LinksMenu>
            )}
            <SettingsMenu
              onClick={handleClickProfileMenu}
              ref={menuRef}
              onBlur={handleHideProfileMenu}
              className={!isAdmin ? 'transparent' : ''}
            >
              {isAdmin ? (
                <SettingsIcon />
              ) : (
                <NameAvatar
                  name={user?.name || ''}
                  color={{
                    main: '#29ae5e',
                    light: '#eaf7ef',
                  }}
                  image={
                    role === RECRUITER_ROLE ? user?.image : user?.company?.image
                  }
                />
              )}
              <ProfileMenuPopup show={showProfileMenu} />
            </SettingsMenu>
          </NavigationMenu>
        </>
      )}
    </LeftNavigation>
  );
};

export default Navigation;
