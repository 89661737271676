import axios from 'axios';
import moment from 'moment';

export type JobRef = {
  id: string;
  name: string;
  location?: string;
  url?: string;
};

export type CandidateRef = {
  id: string;
  ats_id?: string;
}

export type InterviewRef = {
  id: string;
  ats_id?: string;
}

export type StageRef = {
  id: string;
  name: string;
  order: number;
  is_required?: boolean;
  is_interview?: boolean;
};

type WithStages = {
  stages: StageRef[];
};

export type AtsStatus = {
  state: 'NOT_CONNECTED' | 'AWAITING_INITIAL_SYNC' | 'CONNECTED';
  tool_name: string;
  last_sync_date?: Date;
  ats_jobs: JobRef[];
  job_connections: {
    ats_job: JobRef;
    local_job?: JobRef;
  }[];
};

export type AtsJobStatus = {
  local_job: JobRef & WithStages;
  ats_job?: JobRef & WithStages;
  stage_mappings: {
    ats_stage: StageRef;
    local_stage?: StageRef;
  }[];
};

export type AtsCandidateStatus = {
  local_candidate: CandidateRef;
  ats_candidate: CandidateRef;
  interview: InterviewRef;
}

export const getConnectLink = async (options?: {
  environment?: string;
  tool?: string;
}) => {
  const data =
    options?.environment || options?.tool
      ? { options: { environment: options.environment, tool: options.tool } }
      : {};
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}integrations/ats/connection/link/`,
    data,
  );
  return response.data.link as string | undefined;
};

export const activateConnection = async (token: string) => {
  // will throw if problem
  await axios.post(
    `${process.env.REACT_APP_API_URL}integrations/ats/connection/activate/`,
    { token },
  );
};

export const forceSync = async () => {
  // will throw if problem
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/sync/`);
};

type GetAtsStatusQuery = {
  post_url?: string,
  remote_ids?: string,
  job_codes?: string,
  name_contains?: string,
  statuses?: string,
};

export const ATS_JOB_FILTER_OPEN_JOBS: GetAtsStatusQuery = {
  statuses: 'OPEN',
};

export const getAtsStatus = async (jobFilters: GetAtsStatusQuery = {}) => {
  const queryString = new URLSearchParams(jobFilters).toString()
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}integrations/ats/connection/?${queryString}`,
  );
  const result: AtsStatus = {
    ...response.data,
    last_sync_date: response.data.last_sync_date
      ? moment(response.data.last_sync_date).toDate()
      : response.data.last_sync_date,
  };
  return result;
};

/**
 * Connects an existing HirePort job to an ATS job, with no stage mappings
 * @param localJobId the id of the job in HirePort
 * @param remoteJobId the id of the job in the ATS broker
 */
export const connectJob = async (localJobId: string, remoteJobId: string) => {
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/`, {
    local_job: { id: localJobId },
    ats_job: { id: remoteJobId },
    stage_mappings: [],
  });
}

/**
 * Creates a new HirePort job which is connected to an ATS job,
 * and is created with automatic maximal stage mappings
 * @param remoteJobId the ide of the job in the ATS broker to connect to
 */
export const createConnectedJob = async (remoteJobId: string) => {
  await axios.post(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/`, {
    ats_job: { id: remoteJobId },
  });
}

export const getAtsJobStatus = async (jobId: string) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/`,
  );
  return response.data as AtsJobStatus;
};

export const findAtsJobsByRemoteId = async (remoteId: string) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/?remote_ids=${remoteId}`);
  return response.data as AtsJobStatus[];
}

export const getAtsCandidates = async (jobId: string) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/candidates/`);
  return response.data as AtsCandidateStatus[];
}

export const createStageMappings = async (
  jobId: string,
  mappings: { localId: string; atsId: string }[],
) => {
  await axios.post(
    `${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/stages/`,
    mappings.map(({ localId, atsId }) => ({
      local_stage: { id: localId },
      ats_stage: { id: atsId },
    })),
  );
};

export const disconnectJob = async (jobId: string) => {
  await axios.delete(
    `${process.env.REACT_APP_API_URL}integrations/ats/jobs/${jobId}/`,
  );
};

export const disconnectAts = async () => {
  await axios.delete(
    `${process.env.REACT_APP_API_URL}integrations/ats/connection/`,
  );
};
