import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GiftIcon } from '../../../icons/gift.svg';
import { ReactComponent as RoundedPlusIcon } from '../../../icons/plus-bordered.svg';
import { ReactComponent as WalletIcon } from '../../../icons/wallet2.svg';
import TextWithIcon from './TextWithIcon';
import { Job } from '../../../types';
import getEmploymentTypeSummary from '../../../utils/getEmploymentTypeSummary';
import useEmploymentTypes from '../../../hooks/useEmploymentTypes';
import useCurrency from '../../../hooks/useCurrency';

type BenefitSectionProps = {
  job?: Job;
};

const StyledText = styled(TextWithIcon)`
  & span {
    white-space: pre-line;
    color: #627d95;
  }
  &:last-child {
    margin-bottom: 4.5rem;
  }
  & path {
    stroke: ${({ theme }) => theme.primaryColor};
  }
`;

const BenefitSection = ({ job }: BenefitSectionProps) => {
  const [t] = useTranslation();
  const currency = useCurrency(job?.compensation_currency);
  const employmentTypes = useEmploymentTypes();

  if (!job) return <span />;

  const {
    extras,
    employment_type,
  } = job;

  const employmentTypeSummary = getEmploymentTypeSummary(t, currency, employment_type, employmentTypes);
  const compensationValues = employmentTypeSummary?.fields['compensation']?.filter(({ value }) => value);
  const benefitsValues = employmentTypeSummary?.fields['benefits']?.filter(({ value }) => value);

  return (
    <>
      <StyledText
        icon={<WalletIcon />}
        title="Compensation"
      >
        {compensationValues && compensationValues.map(({ value, label }) => (
          <span>
            {t(label)}: {value}
          </span>
        ))}
      </StyledText>

      {(benefitsValues && benefitsValues.length > 0) &&
        <StyledText
          icon={<GiftIcon />}
          title={t('BENEFIT_TITLE')}
        >
          {benefitsValues.map(({ value, label }) => (
            <span>
              {t(label)}: {value}
            </span>
          ))}
        </StyledText>
      }

      {
        extras && (
          <StyledText icon={<RoundedPlusIcon />} title={t('EXTRAS_TITLE')}>
            <span>{extras}</span>
          </StyledText>
        )
      }
    </>
  );
};

export default BenefitSection;
