import { Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CheckboxInput from './CheckboxInput';

interface CheckboxGroupProps {
  data: any[];
  fields?: { label: string; value: string };
}
const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  & .ant-checkbox-wrapper{
    padding: .5rem 1rem;
    background-color: ${({ theme }) => theme.lozengeColor};
    color: #627d95;
    margin: 0;
    border-right: 1px solid #dae5ec;
    transition:all ease .3s;
    &:first-child{
      border-bottom-left-radius: ${({ theme }) => theme.radius};
      border-top-left-radius: ${({ theme }) => theme.radius};
    }
    &:last-child{
      border-bottom-right-radius: ${({ theme }) => theme.radius};
      border-top-right-radius: ${({ theme }) => theme.radius};
      border-right: none;
    }
    &.ant-checkbox-wrapper-checked{
       background: #627d95;
       color: #ffffff;
    }
    & .ant-checkbox{
      display: none;
    }
  }
`;

const CheckboxGroup = ({ data, fields, ...rest }: CheckboxGroupProps) => {
  return (
    <StyledCheckboxGroup {...rest}>
      {data.map((el) => {
        return (
          <CheckboxInput
            value={fields ? el[fields.value] : el}
            key={fields ? el[fields.value] : el}
          >
            {fields ? el[fields.label] : el}
          </CheckboxInput>
        );
      })}
    </StyledCheckboxGroup>
  );
};

export default CheckboxGroup;
