import React from 'react';
import styled from 'styled-components';

const Text = styled.h1`
  font-size: 2.5rem;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.primaryColor};
  margin-top: 2.5rem;
  max-width: 200px;
  width: 100%;
  margin-left: 2rem;
  position: relative;
  z-index: 99;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }
`;
interface BannerTextProps {
  title: string;
}
const BannerText = ({ title }: BannerTextProps) => {
  return <Text>{title}</Text>;
};

export default BannerText;
