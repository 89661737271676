import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useClickAwayListener from '../../hooks/useClickAwayListener';
import { ReactComponent as AlertIcon } from '../../icons/icon-alert.svg';
import NameAvatar from '../../UI/NameAvatar';
import { getEvents } from '../../api/events';
import { getInterview } from '../../api/interviews';
import { CANDIDATES } from '../../constants/routes';

const UnconfirmedButton = styled.button`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #eb5757;
  position: absolute;
  top: 2.4rem;
  left: 340px;
  border: none;
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
`;

const UnconfirmedPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  right: 0;
  width: 368px;
  height: 304px;
  padding: 16px 32px 32px;
  border-radius: ${({ theme }) => theme.radius};
  box-shadow: 0 32px 80px 0 rgba(6, 28, 46, 0.2),
    0 16px 20px 0 rgba(6, 28, 46, 0.16);
  background-color: #ffffff;
  z-index: 1000;
`;

const PanelHeader = styled.div`
  border-bottom: 1px solid #dae5ec;
  padding: 1rem 0;
  display: flex;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
`;

const UnconfirmedList = styled.ul`
  list-style: none;
  max-height: calc(100% - 3rem);
  overflow-y: auto;
  margin: 0;
`;

const UnconfirmedEvent = styled.li`
  padding: 12px 0;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`;

const UnconfirmedEventInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const UnconfirmedEventInfoTitle = styled.h5`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 4px;
`;

const UnconfirmedEventInfoDescription = styled.h5`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin: 0;
`;

const UnconfirmedEvents = () => {
  const { t } = useTranslation();
  const [unconfirmedEvents, setUnconfirmedEvents] = useState<any[]>([]);
  const [showUnconfirmed, setShowUnconfirmed] = useState<boolean>(false);

  const history = useHistory();
  const panelRef = useRef(null);

  useClickAwayListener(panelRef, () => {
    if (showUnconfirmed) {
      setShowUnconfirmed(false);
    }
  });

  const loadUnconfirmedEvents = useCallback(async () => {
    const result = await getEvents('unconfirmed=true');
    setUnconfirmedEvents(
      result.data.map((e: any) => ({
        ...e,
        date: e.event_date,
        title: e.data.title,
      })),
    );
  }, []);

  useEffect(() => {
    loadUnconfirmedEvents();
  }, [loadUnconfirmedEvents]);

  const toggleUnconfirmed = () => {
    setShowUnconfirmed(!showUnconfirmed);
  };

  const handleEventClick = async (ev: any) => {
    const interviewId = ev.data.interview_id;
    const result = await getInterview(interviewId, history);
    const interview = result.data;

    history.push(
      CANDIDATES +
      '?filter=in-process&proposed_date=' +
      interview.id +
      '&stage=' +
      interview.current_stage.stage_name,
    );
  };

  const uniqueEvents = unconfirmedEvents.reduce((a, b) => {
    const id = b.data.interview_id;
    a[id] = b;
    return a;
  }, {});
  return (
    <>
      {unconfirmedEvents.length > 0 && (
        <UnconfirmedButton ref={panelRef} onClick={() => toggleUnconfirmed()}>
          <AlertIcon style={{ marginRight: '0.5rem' }} />
          {Object.keys(uniqueEvents).length} {t('EVENTS_UNCONFIRMED')}
          {showUnconfirmed && (
            <UnconfirmedPanel>
              <PanelHeader>{t('UNCONFIRMED_EVENTS')}</PanelHeader>
              <UnconfirmedList>
                {Object.keys(uniqueEvents).map((ev, i) => (
                  <UnconfirmedEvent
                    key={i}
                    onClick={() => handleEventClick(uniqueEvents[ev])}
                  >
                    <NameAvatar
                      name={uniqueEvents[ev].data.title}
                      color={{
                        main: '#7949cd',
                        light: '#f2edfa',
                      }}
                    />
                    <UnconfirmedEventInfo>
                      <UnconfirmedEventInfoTitle>
                        {uniqueEvents[ev].data.title}
                      </UnconfirmedEventInfoTitle>
                      <UnconfirmedEventInfoDescription>
                        {uniqueEvents[ev].data.interview_stage_name} -{' '}
                        {uniqueEvents[ev].company_name}
                      </UnconfirmedEventInfoDescription>
                    </UnconfirmedEventInfo>
                  </UnconfirmedEvent>
                ))}
              </UnconfirmedList>
            </UnconfirmedPanel>
          )}
        </UnconfirmedButton>
      )}
    </>
  );
};

export default UnconfirmedEvents;
