import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';

const RoundButton = styled.button`
width: 3rem;
height: 3rem;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
overflow: hidden;
background: ${({ theme }) => theme.primaryColor};
color: #ffffff;
border: none;
outline: none;
position: fixed;
top: calc(100vh - 8rem);
right: 1.25rem;
cursor:pointer;
  z-index: 999;
& svg{
width: 1.5rem;
height: 1.5rem;
}
`;
const RoundAddButton = ({ onClick, style }: React.HTMLProps<HTMLButtonElement>) => {
  return (
    <RoundButton onClick={onClick} style={style}>
      <PlusIcon />
    </RoundButton>
  );
};

export default RoundAddButton;
