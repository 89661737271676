import { useCallback, useEffect, useState } from "react";
import { showKomboConnect } from "@kombo-api/connect"
import { useHistoryWithUrlBase } from "../../hooks/useHirePortContext";
import { postMessage } from "../../components/helper/browserPlugins";

const EmbedActivate = () => {
  const history = useHistoryWithUrlBase();
  const { location } = history;
  const link_token = new URLSearchParams(location.search).get("link_token");
  const [isConnectComplete, setIsConnectComplete] = useState(false);

  const connect = useCallback(async () => {
    if (!link_token)
      return;
    await showKomboConnect(atob(link_token));
    setIsConnectComplete(true);
    postMessage({ event: "UIActionComplete", action: "activate" });
  }, [link_token])

  useEffect(() => { connect() }, [connect])

  return <h1>{!isConnectComplete ? "Activating..." : "Activation complete"}</h1>
};

export default EmbedActivate;
