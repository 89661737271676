import React from 'react';
import styled from "styled-components";
import { ReactComponent as ClockIcon } from '../../../icons/clock3.svg';
import { ReactComponent as PlusBorderedIcon } from '../../../icons/plus-bordered.svg';
import { ReactComponent as VacationIcon } from '../../../icons/vacation2.svg';
import { ReactComponent as VisaIcon } from '../../../icons/visa.svg';
import { ReactComponent as RemoteIcon } from '../../../icons/remote.svg';
import { ReactComponent as RelocationIcon } from '../../../icons/relocation.svg';
import TextWithIcon from './TextWithIcon';
import { useTranslation } from 'react-i18next';
import { workingTypeOptions } from '../../../constants/common';
import { relocationOptions } from "../../../constants/relocations";

const StyledTextWithIcon = styled(TextWithIcon)`
  & path {
    stroke: ${({ theme }) => theme.primaryColor};
  }
`;

type CompanyFactSectionProps = {
  visa?: boolean;
  daysAtTheOffice?: number;
  daysWorkingFromHome?: number;
  holidays?: number;
  relocationPackage?: string;
  workingType?:
  | 'full_office'
  | 'hybrid'
  | 'fully_remote_home_country'
  | 'fully_remote_same_timezone'
  | 'fully_remote_worldwide';
  relocation?: number;
  additional?: string[];
}

const CompanyFactSection = ({
  visa,
  daysAtTheOffice,
  daysWorkingFromHome,
  holidays,
  relocationPackage,
  workingType,
  relocation,
  additional = [],
}: CompanyFactSectionProps) => {
  const [t] = useTranslation();

  const workingTp = workingTypeOptions.find(({ id }) => id === workingType);
  const relocationObject = relocationOptions.find((option) => option.id === relocation);

  return (
    <>
      {daysAtTheOffice && daysWorkingFromHome && (
        <StyledTextWithIcon
          icon={<ClockIcon />}
          title={t('HOME_OFFICE_BALANCE', {
            office: daysAtTheOffice,
            home: daysWorkingFromHome,
          })}
        />
      )}

      {holidays && (
        <StyledTextWithIcon
          icon={<VacationIcon />}
          title={`${holidays} ${t('VACATION_DAYS')}`}
        />
      )}

      <StyledTextWithIcon
        icon={<VisaIcon />}
        title={visa ? t('VISA_TITLE') : t('NO_VISA_POSSIBLE_TEXT')}
      />

      {workingType === 'full_office' && (
        <StyledTextWithIcon
          icon={<RemoteIcon />}
          title={t('REMOTE_NOT_POSSIBLE_TEXT')}
        />
      )}

      {workingType === 'hybrid' && (
        <StyledTextWithIcon
          icon={<RemoteIcon />}
          title={t('WORKING_TYPE_HYBRID_VERBOSE')}
        />
      )}

      {workingType !== 'full_office' && workingType !== 'hybrid' && (
        <StyledTextWithIcon
          icon={<RemoteIcon />}
          title={t(`${workingTp?.label}_POSSIBLE` || '')}
        />
      )}

      {relocationPackage && relocationObject ? (
        <StyledTextWithIcon
          icon={<RelocationIcon />}
          title={t(relocationObject.label)}
          subtitle={relocationPackage}
        />
      ) : !relocationPackage && relocationObject ? (
        <StyledTextWithIcon
          icon={<RelocationIcon />}
          title={t(relocationObject.label)}
          subtitle={''}
        />
      ) : (
        <StyledTextWithIcon
          icon={<RelocationIcon />}
          title={relocationObject ? t(relocationObject.label) : t('NO_RELOCATION_POSSIBLE')}
          subtitle={''}
        />
      )}

      {additional.map((additionalItem) => (
        <StyledTextWithIcon
          icon={<PlusBorderedIcon />}
          title={additionalItem}
        />
      ))}

    </>
  );
};

export default React.memo(CompanyFactSection);
