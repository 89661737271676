import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CheckboxInput from '../../../UI/inputs/CheckboxInput';
import { Radio, RadioChangeEvent, Upload } from 'antd';
import RadioInput from '../../../UI/inputs/RadioInput';
import { ReactComponent as DownloadIcon } from '../../../icons/download.svg';
import { useTranslation } from 'react-i18next';
import { updateTask } from '../../../api/interviews';
import Button from '../../../UI/buttons/Button';
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg';
import { RcFile } from 'antd/lib/upload';
import { useAppSelector } from '../../../store/hooks';
import { InterviewRoom } from '../../../types';
import { navigate } from '../../../utils/routes';
import { useHistory } from 'react-router-dom';
import RescheduleDate from './RescheduleDate';

interface TaskProps {
  data: any;
  name?: string;
  interview: InterviewRoom;
  handleComplete: (taskId: string) => void;
}
const Wrapper = styled.div<{ completed: boolean }>`
  padding: 1.25rem 1rem;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme, completed }) => completed ? theme.lozengeColor : '#ffeeee'};
  margin-bottom: 0.5rem;
  display: flex;
`;
const Text = styled.div`
  margin-left: 1rem;
  width: 100%;
`;
const Title = styled.h4`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Description = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
`;
const ActionLink = styled.a`
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 1.33;
  color: #627d95;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #627d95;
  border-radius: 0.25rem;
  max-width: max-content;
  @media (max-width: 992px) {
    margin-bottom: 0.5rem;
  }
  & svg {
    min-width: 1rem;
    min-height: 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
  &:hover {
    color: #627d95;
  }
`;
const FileUploader = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  width: 100%;
`;
const FileName = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: #061c2e;
  margin: 0 0.5rem 0 0 !important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const DeleteFileIcon = styled(DeleteIcon)`
  color: #eb5757;
  margin-left: 0.5rem;
  cursor: pointer;
`;
const UploadAction = styled.div`
  display: flex;
  align-items: center;
`;
const StyledUploader = styled(Upload)`
  display: flex;
  align-items: center;
  & .ant-upload-select {
    width: 100%;
    border: 1px solid #aebeca;
    border-radius: ${({ theme }) => theme.radius};
  }
`;
const NoFile = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  opacity: 0.8;
`;
const TaskItem = ({ data, handleComplete, name, interview }: TaskProps) => {
  const [t] = useTranslation();
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.id === interview.client.id;
  const isDecisionMaker = !isClient && data.email === user?.email;
  const isAllowed = isClient || isDecisionMaker;
  const isPlaning = data.is_planning_task;
  const isDateProposed = data.is_planning_task && data.time_from;
  const isDecision = data.is_decision_task;
  const isUpload = data.is_upload_document_task;
  const [completed, setCompleted] = useState<boolean>(
    data.status === 'completed',
  );
  const history = useHistory();
  const [file, setFile] = useState(data.document);
  const [originalFile, setOriginalFile] = useState<RcFile | null>(null);
  const [isPositive, setIsPositive] = useState<boolean | null>(
    data.positive_decision,
  );
  const updateTaskItem = async (info: any) => {
    return updateTask(interview.id, data.id, info);
  };
  const handleChange = (e: CheckboxChangeEvent) => {
    if (!completed && isAllowed) {
      if (isDecision && typeof isPositive == 'boolean') {
        updateTaskItem({ positive_decision: isPositive }).then((res) => {
          handleComplete(data.id);
          setCompleted(true);
        });
      } else if (isUpload) {
        const formData = new FormData();
        originalFile && formData.append('document', originalFile);
        updateTaskItem(originalFile && formData).then((res) => {
          setFile(res.data.document);
          setOriginalFile(null);
          handleComplete(data.id);
          setCompleted(true);
        });
      } else {
        handleComplete(data.id);
        setCompleted(true);
      }
    }
  };
  const onChange = (e: RadioChangeEvent) => {
    isAllowed && !completed && setIsPositive(e.target.value);
  };
  const handleFeedback = () => {
    navigate(history, { feedback: interview.id }, ['tasks']);
  };
  const handleUploadFile = (file: RcFile): boolean => {
    setOriginalFile(file);
    return true;
  };
  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setOriginalFile(null);
  };
  const leftOnlyFeedback = data.is_feedback_task;
  return (
    <Wrapper completed={completed}>
      <CheckboxInput
        checked={completed}
        onChange={data.is_feedback_task ? handleFeedback : handleChange}
        disabled={
          (isDecision && typeof isPositive != 'boolean') ||
          (isUpload && !originalFile && !file) ||
          !isAllowed
        }
      />
      <Text>
        <Title
          onClick={() => {
            if (leftOnlyFeedback) {
              handleFeedback();
            }
          }}
          style={{ cursor: leftOnlyFeedback ? 'pointer' : 'unset' }}
        >
          {data.is_decision_task ? data.description : data.task_type}{' '}
          {isPlaning && isDateProposed && (
            <RescheduleDate interview={interview} history={history} />
          )}
        </Title>
        <Description>
          {data.decision_maker ? data.decision_maker : name}, {data.title}
          {isAllowed && ', ' + data.email}
        </Description>
        {isDecision && isAllowed && (
          <Radio.Group
            onChange={onChange}
            value={isPositive}
            disabled={!isAllowed}
          >
            <RadioInput value={true}>Yes</RadioInput>
            <RadioInput value={false}>No</RadioInput>
          </Radio.Group>
        )}
        {isUpload &&
          isAllowed &&
          (file ? (
            <ActionLink href={file} target="_blank" rel="noreferrer">
              <DownloadIcon />
              {t('DOWNLOAD_FILE')}
            </ActionLink>
          ) : isAllowed ? (
            <StyledUploader
              showUploadList={false}
              beforeUpload={handleUploadFile}
              customRequest={() => void 0}
              disabled={!isAllowed}
            >
              <FileUploader>
                <FileName>{originalFile?.name}</FileName>
                <UploadAction>
                  <Button type={'default'} size={'small'} disabled={!isAllowed}>
                    {t('CHOOSE_FILE')}
                  </Button>
                  {originalFile && (
                    <DeleteFileIcon onClick={handleFileDelete} />
                  )}
                </UploadAction>
              </FileUploader>
            </StyledUploader>
          ) : (
            <NoFile>{t('NO_FILE')}</NoFile>
          ))}
      </Text>
    </Wrapper>
  );
};

export default TaskItem;
