import React from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

const StyledRadio = styled(Radio)`
  & .ant-radio-inner {
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid #aebeca;
  }

  & .ant-radio-inner::after {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &:hover .ant-radio-inner,
  &:hover .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.activeColor};
  }

  &:hover .ant-radio-inner::after,
  &:hover .ant-radio-checked .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.activeColor};
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: #627d95;
  }

  & .ant-radio-input:focus + .ant-radio-inner,
  & .ant-radio-input:focus + .ant-radio-inner::after {
    border-color: ${({ theme }) => theme.activeColor};
  }

  & .ant-radio-inner::after {
    background: #627d95;
  }

  & .ant-radio-checked .ant-radio-input:hover + .ant-radio,
  & .ant-radio-input:focus + .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.activeColor};
  }
`;

const RadioInput = ({ ...rest }) => {
  return <StyledRadio {...rest} />;
};

export default RadioInput;
