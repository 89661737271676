import axios from 'axios';

export const getCompanyByName = async (name: string) => {
  return await axios
    .get(process.env.REACT_APP_API_URL + 'client-companies/?search=' + name)
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const getClientCompanyById = async (id: string) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}client-companies/${id}/`)
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const getRecruiterCompanyByName = async (name: string) => {
  return await axios
    .get(process.env.REACT_APP_API_URL + 'recruiter-companies/?search=' + name)
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const getCompanyDepartments = async (id: string) => {
  return await axios
    .get(process.env.REACT_APP_API_URL + `client-companies/${id}/departments/`)
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const getClientCompanyDepartments = async (id: string) => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}client-companies/${id}/departments/`)
    .then((res) => res)
    .catch((e) => {
      return e.response;
    });
};

export const getCompanyFeeTemplates = async () =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}client-company-fee-templates/`,
  );

export const getCompanyFeeTemplate = async (id: string) =>
  await axios.get(
    `${process.env.REACT_APP_API_URL}client-company-fee-templates/${id}/`,
  );

export const createCompanyFeeTemplate = async (data: any) =>
  await axios.post(
    `${process.env.REACT_APP_API_URL}client-company-fee-templates/`,
    data,
  );

export const updateCompanyFeeTemplate = async (id: string, data: any) =>
  await axios.patch(
    `${process.env.REACT_APP_API_URL}client-company-fee-templates/${id}/`,
    data,
  );

export const deleteCompanyFeeTemplate = async (id: string) =>
  await axios.delete(
    `${process.env.REACT_APP_API_URL}client-company-fee-templates/${id}/`,
  );


