import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AtsStatus, createConnectedJob, getAtsStatus } from '../../../../api/integrations';

const useAtsStatus = () => {
  const [status, setStatus] = useState<AtsStatus | null>(null);
  const location = useLocation();
  const { search } = location;
  const params = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
  const { local_id } = params;

  const jobConnectionsFromLocalId = (local_id && status?.job_connections.filter((job) => job.local_job?.id === local_id)) || [];
  const jobConnections = jobConnectionsFromLocalId.length === 1 ? jobConnectionsFromLocalId : status?.job_connections;
  const jobConnection = jobConnections?.length === 1 ? jobConnections[0] : undefined;
  const atsJob = jobConnection?.ats_job;
  const localJob = jobConnection?.local_job;

  const refreshStatus = useCallback(async () => {
    const status = await getAtsStatus(params);
    setStatus(status);
    return status;
  }, [params]);

  // the frontend sends some clue (likely post_url, but could depend on ATS) so that we can resolve the ATS status here
  useEffect(() => {
    refreshStatus();
  }, [refreshStatus]);

  const redirectTo = params.redirect;
  useEffect(() => {
    if (localJob && status && status.job_connections.length > 1)
      console.warn(`Warning: more than one possible job matched to ATS job: ${status.job_connections.map((connection) => connection.ats_job.id).join(', ')}`);

    if (localJob || !atsJob || redirectTo)
      return;

    createConnectedJob(atsJob.id).then(() => {
      refreshStatus();
    });
  }, [localJob, status, redirectTo, refreshStatus, atsJob]);

  const connectedState = status?.state === 'CONNECTED';

  return {
    redirectTo,
    status,
    connectedState,
    localJob,
    atsJob,
    refreshStatus,
  };
};

export default useAtsStatus;
