import { useCallback, useEffect, useReducer, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Select } from 'antd';
import Button from '../../../UI/buttons/Button';
import FormItem from '../../../components/form/FormItem';
import { FormSubtitle } from '../../form/JobInfoForm/styles';
import ListWidget from '../../dashboard/ListWidget';
import { AtsJobStatus, AtsStatus, StageRef, createStageMappings, disconnectJob, getAtsJobStatus, getAtsStatus } from '../../../api/integrations';
import { JobActions, EmptyText, JobListItem, LocalJobTitle, ListContainer, RemoteJobTitle, JobActionButton } from './styles';
import SelectInput from '../../../UI/inputs/Select';
import { CaretDownOutlined } from "@ant-design/icons";
import { ATS_SETTINGS } from '../../../constants/routes';
import ConfirmStageEditModal from './ConfirmStageEditModal';
import ConfirmDisconnectJobModal from './ConfirmDisconnectJobModal';
import RefreshDataSection from './RefreshDataSection';
import { ReactComponent as CheckIcon } from '../../../icons/check-icon-brand.svg'
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';

const WarningText = styled.p`
  width: 66%;
`

const { Option } = Select;

type StageSelectProps = {
  selected: StageRef | null,
  stages: StageRef[],
  emptyText: string,
  onChange?: (key: string, value?: StageRef) => void,
}

const RequiredText = styled.i`
  text-transform: none;
`

const StageSelect = ({ selected, stages, emptyText, onChange }: StageSelectProps) => (
  <SelectInput
    suffixIcon={<CaretDownOutlined style={{ color: '#000' }} />}
    value={selected?.id ?? ''}
    onChange={(key) => onChange && onChange(key, stages.find((job) => job.id === key))}
  >
    <Option value={''}>{emptyText}</Option>
    {stages.map((stage) => {
      const stageName = !stage.is_interview ? `[${stage.name}]` : stage.name;
      return (
        <Option key={stage.id} value={stage.id}>
          {stageName}{stage.is_required && <RequiredText> - required</RequiredText>}
        </Option>
      )
    })}
    {selected && !stages.some((stage) => stage.id === selected.id) && (
      <Option value={selected.id}>{selected.name}</Option>
    )}
  </SelectInput>
)
type ValidationTextProps = {
  error: boolean;
};

const ValidationText = styled.p<ValidationTextProps>`
  color: ${(props) => props.error ? 'red' : 'inherit'};
`

type AtsJobSettingsState = {
  jobStatus?: AtsJobStatus,
  mappings: { ats_stage: StageRef, local_stage?: StageRef, editing: boolean }[];
  options: StageRef[];
  stageWarningsEnabled: boolean;
  stageWarningVisible: boolean;
  deleteJobWarningVisible: boolean;
  isValid: boolean;
  showValidation: boolean;
  editPending: string | null;
  selectPending: { localStage: StageRef, atsStage: StageRef } | null;
};

type EditClickedAction = { type: 'EDIT_CLICKED', remoteStageId: string };
type StageSelectedAction = { type: 'STAGE_SELECTED', atsStage: StageRef, localStage?: StageRef };
type StageWarningOkAction = { type: 'STAGE_WARNING_OK' };
type StageWarningCancelAction = { type: 'STAGE_WARNING_CANCEL' };
type DisconnectJobAction = { type: 'DISCONNECT_JOB' };
type DisconnectJobCancelAction = { type: 'DISCONNECT_JOB_CANCEL' };
type ValidateSaveAction = { type: 'VALIDATE_SAVE' };
type JobStatusUpdatedAction = { type: 'JOB_STATUS_UPDATED', jobStatus: AtsJobStatus };
type Action = EditClickedAction | StageSelectedAction | StageWarningOkAction | StageWarningCancelAction | DisconnectJobAction | DisconnectJobCancelAction | ValidateSaveAction | JobStatusUpdatedAction;

const reducer: (state: AtsJobSettingsState, action: Action) => AtsJobSettingsState = (state, action) => {
  const newState = (() => {
    const defaultNewState = {
      ...state,
      editPending: null,
      selectPending: null,
    }
    switch (action.type) {
      case 'JOB_STATUS_UPDATED':
        return {
          ...state,
          jobStatus: action.jobStatus,
          mappings: action.jobStatus.stage_mappings.map((mapping) => ({ ...mapping, editing: !mapping.local_stage })) ?? [],
          options: action.jobStatus.local_job?.stages?.map((option) => ({ ...option, selectable: true })) ?? [],
        }

      case 'EDIT_CLICKED':
        if (state.stageWarningsEnabled)
          return { ...defaultNewState, stageWarningVisible: true, editPending: action.remoteStageId };
        return {
          ...defaultNewState,
          mappings: state.mappings.map((mapping) => ({ ...mapping, editing: mapping.editing || (mapping.ats_stage.id === action.remoteStageId) })),
        };
      case 'STAGE_SELECTED': {
        if (action.localStage) {
          const conflictingStage = state.mappings.find((mapping) => mapping.local_stage?.id === action.localStage?.id)
          if (state.stageWarningsEnabled && conflictingStage && !conflictingStage.editing)
            return { ...defaultNewState, stageWarningVisible: true, editPending: conflictingStage.ats_stage.id, selectPending: { localStage: action.localStage, atsStage: action.atsStage } };
        }
        const newMappings = state.mappings.map((mapping) => {
          if ((!action.localStage && (mapping.ats_stage.id === action.atsStage.id)) || (mapping.local_stage?.id === action.localStage?.id))
            return { ats_stage: mapping.ats_stage, editing: true };
          if (mapping.ats_stage.id === action.atsStage.id)
            return { ...mapping, local_stage: action.localStage }

          return mapping;
        })
        return {
          ...defaultNewState,
          mappings: newMappings,
        };
      }
      case 'STAGE_WARNING_OK': {
        const newMappings = state.mappings.map((mapping) => {
          if (state.editPending && mapping.ats_stage.id === state.editPending)
            return state.selectPending ? { ats_stage: mapping.ats_stage, editing: true } : { ...mapping, editing: true };
          if (state.selectPending && mapping.ats_stage.id === state.selectPending.atsStage.id)
            return { ...mapping, ats_stage: state.selectPending.atsStage }
          return mapping;
        })
        return {
          ...defaultNewState,
          stageWarningVisible: false,
          stageWarningsEnabled: false,
          mappings: newMappings,
        };
      }
      case 'STAGE_WARNING_CANCEL':
        return { ...defaultNewState, stageWarningVisible: false };
      case 'DISCONNECT_JOB':
        return { ...defaultNewState, deleteJobWarningVisible: true };
      case 'DISCONNECT_JOB_CANCEL':
        return { ...defaultNewState, deleteJobWarningVisible: false };
      case 'VALIDATE_SAVE':
        return {
          ...defaultNewState,
          showValidation: true,
        };
    }
    // return defaultNewState;
  })()
  // mappings are valid when:
  const isValid =
    (
      // 1) all of the local stages which are defined as required have been mapped
      newState.jobStatus?.local_job.stages.every((stageDef) => !stageDef.is_required || newState.mappings.some((mapping) => mapping.local_stage?.id === stageDef.id))
      // 2) at least one of the interview stages has been mapped
      && newState.jobStatus.local_job.stages.some((stageDef) => stageDef.is_interview && newState.mappings.some((mapping) => mapping.local_stage?.id === stageDef.id))
    ) || false;
  return {
    ...newState,
    isValid,
    showValidation: !isValid && newState.showValidation,
  };
}

type AtsJobSettingsFormProps = {
  id: string;
  allowDisconnect?: boolean;
  onShouldWarnChanged?: (shouldWarn: boolean) => void;
  onJobTitleChanged?: (jobTitle: string) => void;
  onFinished: () => void;
}

const AtsJobSettingsForm = ({ id, allowDisconnect = true, onShouldWarnChanged, onJobTitleChanged, onFinished }: AtsJobSettingsFormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading] = useState(false);
  const history = useHistoryWithUrlBase();
  const [atsStatus, setAtsStatus] = useState<AtsStatus | null>(null);

  const defaultState: AtsJobSettingsState = {
    mappings: [],
    options: [],
    deleteJobWarningVisible: false,
    isValid: true,
    showValidation: false,
    stageWarningsEnabled: true,
    stageWarningVisible: false,
    editPending: null,
    selectPending: null,
  }
  const [state, dispatch] = useReducer(reducer, defaultState);

  const checkAtsJobStatus = useCallback(async () => {
    const status = await getAtsJobStatus(id);
    dispatch({ type: "JOB_STATUS_UPDATED", jobStatus: status });
  }, [id]);

  const checkAtsStatus = () => {
    getAtsStatus().then(setAtsStatus)
  }

  useEffect(checkAtsStatus, []);
  useEffect(() => { checkAtsJobStatus() }, [checkAtsJobStatus]);

  const saveMappings = async (mappings: { localId: string, atsId: string }[]) => {
    await createStageMappings(id, mappings);
    await checkAtsJobStatus();
    onFinished();
  }

  const updateShouldWarn = () => {
    if (!onShouldWarnChanged)
      return;
    onShouldWarnChanged(!state.isValid || (state.mappings.some((mapping) => !mapping.ats_stage)));
  }

  const handleSave = () => {
    dispatch({ type: 'VALIDATE_SAVE' })
    if (!state.isValid) return;

    updateShouldWarn();
    const payload = state.mappings.filter((mapping) => mapping.local_stage).map((mapping) => ({
      atsId: mapping.ats_stage.id,
      localId: mapping.local_stage!?.id,
    }))
    saveMappings(payload)
  }

  const { jobStatus } = state;
  const handleDisconnectJob = async () => {
    if (!allowDisconnect || !jobStatus)
      return;
    updateShouldWarn();
    await disconnectJob(jobStatus.local_job.id);
    history.push(ATS_SETTINGS)
  }

  const jobTitle = jobStatus?.local_job.name || "";
  useEffect(() => {
    if (!onJobTitleChanged)
      return;
    onJobTitleChanged(jobTitle);
  }, [jobTitle, onJobTitleChanged]);

  if (!jobStatus || !atsStatus)
    return null;

  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="invitation"
      form={form}
    >
      <FormItem>
        <FormSubtitle>{t('ats.STAGE_MAPPINGS')}</FormSubtitle>
        <ListContainer>
          <ListWidget
            id={'connected-job-widget'}
            title={t('ats.STAGE_MAPPING_LEGEND')}
          >
            {state.mappings.length === 0 && (
              <EmptyText>{t('ats.NO_STAGE_MAPPINGS')}</EmptyText>
            )}
            {state.mappings.map((mapping) => (
              <JobListItem
                key={mapping.ats_stage.id}
              >
                <RemoteJobTitle>{mapping.ats_stage.name}</RemoteJobTitle>
                {(mapping.local_stage && !mapping.editing)
                  ? <LocalJobTitle>{mapping.local_stage.name}</LocalJobTitle>
                  : <StageSelect
                    selected={mapping.local_stage ?? null}
                    stages={state.options}
                    emptyText={t('ats.STAGE_NOT_MAPPED')}
                    onChange={(_, value) => { dispatch({ type: 'STAGE_SELECTED', atsStage: mapping.ats_stage, localStage: value }) }} />
                }
                <JobActions>
                  <JobActionButton size="small" type="primary" disabled={mapping.editing} onClick={() => { dispatch({ type: 'EDIT_CLICKED', remoteStageId: mapping.ats_stage.id }) }}>
                    {t('ats.EDIT_STAGE_BUTTON')}
                  </JobActionButton>
                </JobActions>
              </JobListItem>
            ))}
          </ListWidget>
        </ListContainer>
        <p>{t('ats.STAGE_MAPPING_EXPLAINER')}</p>
        <ValidationText error={state.showValidation && !state.isValid}>
          <Trans
            i18nKey="ats.STAGE_MAPPING_VALIDATION"
            values={{ requiredStages: jobStatus.local_job.stages.filter((stage) => stage.is_required).map((stage) => `[${stage.name}]`).join(', ') }}
            components={{ b: <strong /> }}
          />
          {state.isValid && <CheckIcon />}
        </ValidationText>
        <Button type="primary" disabled={false} onClick={handleSave}>
          {t('SAVE_TITLE')}
        </Button>
      </FormItem>
      <RefreshDataSection atsStatus={atsStatus} onCheckAtsStatus={() => { checkAtsStatus() }} />
      {allowDisconnect && (
        <FormItem>
          <FormSubtitle>{t('DANGER_ZONE')}</FormSubtitle>
          <Button className={'ant-btn-dangerous'} loading={loading} onClick={() => { dispatch({ type: 'DISCONNECT_JOB' }) }}>
            {t('ats.DISCONNECT_JOB')}
          </Button>
        </FormItem>
      )}
      <WarningText>{t('ats.DISCONNECT_JOB_CONFIRM_TEXT_SHORT')}</WarningText>
      <ConfirmStageEditModal
        isOpen={state.stageWarningVisible}
        onOk={() => { dispatch({ type: 'STAGE_WARNING_OK' }) }}
        onCancel={() => { dispatch({ type: 'STAGE_WARNING_CANCEL' }) }}
      />
      {allowDisconnect && (
        <ConfirmDisconnectJobModal
          isOpen={state.deleteJobWarningVisible}
          onOk={handleDisconnectJob}
          onCancel={() => { dispatch({ type: 'DISCONNECT_JOB_CANCEL' }) }}
        />
      )}
    </Form>
  )
}

export default AtsJobSettingsForm;
