import { IS_ADMIN_KEY, ADMIN_TOKEN } from '../constants/roles';

export const storeToken = (token: string) => {
  localStorage.setItem('access_token', token);
};

export const storeAdminToken = (token: string) => {
  localStorage.setItem(ADMIN_TOKEN, token);
};

export const removeToken = (key: string) => {
  localStorage.removeItem(key);
};

// !WB: huh?
export const getToken = (key: string) => {
  return localStorage.getItem(key);
};

export const storeAdminMarker = () => {
  localStorage.setItem(IS_ADMIN_KEY, 'true');
};

export const storePathname = (pathname: string) => {
  localStorage.setItem('pathname', pathname);
};

export const storeJobId = (jobId: string) => {
  localStorage.setItem('job', jobId);
};

export const storeSearch = (search: string) => {
  localStorage.setItem('search', search);
};
