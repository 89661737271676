import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { message } from 'antd';

import { getJob } from '../api/jobs';
import CompanyInfoForm from '../components/form/CompanyInfoForm';
import JobInfoForm from '../components/form/JobInfoForm';
import JobCandidateProfileForm from '../components/form/JobCandidateProfileForm';
import JobOfferForm from '../components/form/JobOfferForm';
import JobTeamForm from '../components/form/JobTeamForm';
import JobProcessForm from '../components/form/JobProcessForm';
import JobProgress from '../components/jobs/JobProgress';
import { JOBS } from '../constants/routes';
import { Job } from '../types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../store/hooks';
import { RECRUITER_ROLE } from '../constants/roles';
import ReactGa from 'react-ga4';
import JobSimpleCreationForm from '../components/form/JobSimpleCreationForm';
import JobPSLForm from '../components/form/JobPSLForm';
import JobFeeSettings from '../components/form/JobFeeSettings';
import { useHistoryWithUrlBase } from '../hooks/useHirePortContext';

const STEPS = [
  {
    title: 'JOB_INFO_TITLE',
    value: 'info',
    draft: 'info',
  },
  {
    title: 'CANDIDATE_PROFILE_TITLE',
    value: 'profile',
    draft: 'candidate_profile',
  },
  {
    title: 'WHAT_IS_OFFERED',
    value: 'offer',
    draft: 'offer',
  },
  {
    title: 'TEAM_INFO_TITLE',
    value: 'team',
    draft: 'team_info',
  },
  {
    title: 'INTERVIEW_PROCESS',
    value: 'process',
    draft: 'interview_process',
  },
  {
    title: 'FEE_SETTINGS',
    value: 'fees',
    draft: 'fee_settings',
  },
  {
    title: 'PSL',
    value: 'psl',
    draft: 'psl',
  },
];

const PageWrapper = styled.section`
  background: ${({ theme }) => theme.lozengeColor};
  width: 100%;
  display: flex;
  min-height: 100vh;
  ${({ theme }) => theme.simplified ? '' : 'flex-flow: row-reverse'};
`;

const FormWrapper = styled.div`
  left: ${({ theme }) => theme.simplified ? '350px' : 'inherit'};
  top: ${({ theme }) => theme.simplified ? '16px' : 'inherit'};
  background: #fff;
  width: 61%;
  padding: 4.5rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;

  & .simple-job-info-card {
    left: -300px;
    width: 300px;
    border-radius: ${({ theme }) => theme.radius};
    position: absolute;
    svg {
      position: relative;
      float: right;
    }
    .ant-card-meta-title {
      font-weight: bold;
      font-size: 18px;
    }
  }

  & .stage-delete-icon {
    position: relative;
    top: 40px;
    color: #ff4d4f;
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 3rem;
  }
`;

export type UrlParams = {
  id: string;
  step: string;
};

export type JobStepsPageProps = {
  title?: string;
};

const JobStepsPage = ({ title }: JobStepsPageProps) => {
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const { user } = useAppSelector((state) => state.user);
  const [job, setJob] = useState<Job | undefined>();
  const { id, step }: UrlParams = useParams();
  const [progress, setProgress] = useState(0);
  let flexFeeModuleEnabled =
    user?.role === 'client' && user?.company?.flexible_fees_module_enabled
      ? true
      : false;
  let talentSupplierModuleEnabled =
    user?.role === 'client' && user?.company?.talent_supplier_module_enabled
      ? true
      : false;

  useEffect(() => {
    if (id && id !== 'new') {
      getJob(id, history).then((res) => setJob(res.data));
    }
  }, [id, step, history]);

  useEffect(() => {
    if (user?.role === RECRUITER_ROLE) {
      history.push('/not-found');
    }
  }, [user, history]);

  useEffect(() => {
    ReactGa.pageview('/jobs/create');
  }, []);

  const handleNextStep = (jobId: string) => {
    const currentIndex = STEPS.map((s) => s.value).indexOf(step);
    if (currentIndex < STEPS.length - 1) {
      if (
        STEPS[currentIndex + 1].value === 'psl' &&
        !talentSupplierModuleEnabled
      ) {
        return;
      } else if (
        STEPS[currentIndex + 1].value === 'fees' &&
        !flexFeeModuleEnabled
      ) {
        return;
      }
      history.push(
        `${JOBS}/edit/${jobId}/${STEPS[currentIndex + 1].value}`,
      );
    } else if (currentIndex === STEPS.length - 1) {
      history.push(`${JOBS}/`);
      if (job?.status === 'draft') {
        message.success(t('JOB_UPDATED'), 5);
      } else {
        message.success(t('JOB_UPDATED'), 5);
      }
    }
  };

  useEffect(() => {
    if (job && job.sections) {
      let percentages = Object.keys(job.sections).map((key: any) => {
        return (job.sections as any)[key].progress;
      });

      let percentage = Math.round(
        percentages.reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0) /
        percentages.length,
      );

      if (percentage > 0) {
        setProgress(percentage);
      }
    }
  }, [job, step]);

  return (
    <PageWrapper>
      <FormWrapper>
        {step !== 'simple' && <JobProgress job={job} step={step} title={title || t('CREATE_JOB')} />}

        {id && step === 'simple' && (
          <JobSimpleCreationForm showBanner={false} jobId={id} />
        )}

        {step === 'company_info' && <CompanyInfoForm jobId={id} />}

        {step === 'info' && <JobInfoForm job={job} onFinish={handleNextStep} />}

        {job && step === 'profile' && (
          <JobCandidateProfileForm job={job} onFinish={handleNextStep} />
        )}

        {job && step === 'offer' && (
          <JobOfferForm job={job} onFinish={handleNextStep} />
        )}

        {job && step === 'team' && (
          <JobTeamForm job={job} onFinish={handleNextStep} />
        )}

        {job && step === 'process' && (
          <JobProcessForm
            job={job}
            onFinish={handleNextStep}
            progress={progress}
            hasPSL={flexFeeModuleEnabled || talentSupplierModuleEnabled}

          />
        )}

        {job && step === 'fees' && flexFeeModuleEnabled && (
          <JobFeeSettings
            job={job}
            onFinish={handleNextStep}

          />
        )}

        {job && step === 'psl' && talentSupplierModuleEnabled && (
          <JobPSLForm job={job} />
        )}
      </FormWrapper>
    </PageWrapper>
  );
};

export default JobStepsPage;
