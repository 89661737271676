import React, { ReactNode, useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ANALYTICS, ATS_SETTINGS, JOBS, MASTER_CONTRACTS, MESSAGES, RECRUITER, RECRUITER_MANAGEMENT } from '../../constants/routes';
import BrowserPluginRoute from '../../components/route/BrowserPluginRoute';
import EmbedAssignRecruitersPage from './EmbedAssignRecruiters';
import EmbedChat from './EmbedChat';
import EmbedAtsSettingsPage from './EmbedAtsSettings';
import EmbedSingleJobPage from './EmbedSingleJob';
import RecruiterManagement from '../RecruiterManagement';
import AnalyticsPage from '../Analytics';
import EmbedJobStepsPage from './EmbedJobSteps';
import MasterContracts from '../MasterContracts/MasterContracts';
import EmbedAtsJobSettingsPage from './EmbedAtsJobSettings';
import RecruiterPublicProfile from '../RecruiterPublicProfile';
import Messages from '../Messages';
import { packs } from './constants';
import EditMasterContractPage from '../MasterContracts/EditMasterContract';
import EmbedActivate from './EmbedActivate';
import InvoicingPage from '../Invoicing';

const RedirectComponent = () => {
  const history = useHistory();
  const location = history.location;
  const { to } = Object.fromEntries(new URLSearchParams(location.search));

  useEffect(() => {
    if (!to)
      return;
    history.replace(to.toString());
  }, [history, to]);

  return (
    to && <>Redirecting to <a href={to.toString()}>this page</a>...</>
  ) || null;
}

// !WB: I didn't figure out a way to automatically pass through routing to the next level, but ideally only
// entries with custom behaviour should be declared here. As it stands, all plugin-accessible pages need to be
const EmbedRoutes = ({ noMatch }: { noMatch: ReactNode }) => {
  const match = useRouteMatch();
  return (
    <Switch>
      {packs.map((pack) => (
        <Route key={pack} path={`${match.path}/${pack}` as string}>
          <Switch>
            <BrowserPluginRoute authHeaderType="Bearer" skipAuth pack={pack} path={`${match.path}/${pack}/activate`} exact component={EmbedActivate} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}/assign-agencies`} exact component={EmbedAssignRecruitersPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${RECRUITER_MANAGEMENT}`} exact component={RecruiterManagement} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${MASTER_CONTRACTS}`} exact component={MasterContracts} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${MASTER_CONTRACTS}/:id`} exact component={EditMasterContractPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}/chat`} exact component={EmbedChat} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}/direct-chat`} exact component={Messages} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}/invoicing`} exact component={InvoicingPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${MESSAGES}`} exact component={Messages} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${MESSAGES}/:contactId`} exact component={Messages} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${MESSAGES}/:contactId/:interviewId`} exact component={Messages} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${ANALYTICS}`} exact component={AnalyticsPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${ATS_SETTINGS}`} exact component={EmbedAtsSettingsPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${ATS_SETTINGS}${JOBS}/:id`} exact component={EmbedAtsJobSettingsPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${JOBS}/:id`} exact component={EmbedSingleJobPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${JOBS}/edit/:id/:step`} exact component={EmbedJobStepsPage} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}${RECRUITER}/:recruiterId/edit/:recruiterCompanyAgreementId`} exact component={RecruiterPublicProfile} />
            <BrowserPluginRoute authHeaderType="Bearer" pack={pack} path={`${match.path}/${pack}/redirect`} exact component={RedirectComponent} />
          </Switch>
        </Route>
      ))}
      {noMatch}

    </Switch>
  )
};

export default EmbedRoutes;
