import React, { useState } from 'react';
import { Job } from '../../types';
import styled from 'styled-components';
import NameAvatar from '../../UI/NameAvatar';
import { ReactComponent as HeartIcon } from '../../icons/heart-centered.svg';
import { addToFavorites, removeFromFavorites } from '../../api/jobs';
import { useTranslation } from 'react-i18next';
import JobListInfoItem from '../jobs/jobList/JobListInfoItem';
import { ReactComponent as LocationIcon } from '../../icons/location.svg';
import { ReactComponent as ClockIcon } from '../../icons/clock.svg';
import TagButton from '../../UI/buttons/TagButton';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import Button from '../../UI/buttons/Button';
import { Link, useHistory } from 'react-router-dom';
import { CLIENT, JOBS } from '../../constants/routes';
import { addDecimalPoints } from '../../utils/candidate';
import { getMonthYearSalary } from '../../utils/salary';
import TooltipPrompt from "../../UI/tootlip";
import useCurrency from '../../hooks/useCurrency';

interface JobItemProps {
  job: Job;
  candidateId: string | null;
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  border-bottom: 1px solid #dae5ec;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
const Global = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    width: 75%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const Company = styled.div`
  display: flex;
  width: 30%;
  @media (max-width: 992px) {
    width: 50%;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const Text = styled.div`
  margin-left: 0.5rem;
`;
const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.43;
  color: #061c2e;
  display: flex;

  & .title {
    color: #061c2e;
    max-height: 2.5rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;
const Description = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #627d95;
  margin: 0.25rem 0 0.5rem;

  & .title {
    color: #627d95;
    max-height: 2.5rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;
const FavoriteIcon = styled(HeartIcon)`
  margin-left: 0.25rem;
  color: #aebeca;
  cursor: pointer;
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: 1px;
  &.active {
    color: ${({ theme }) => theme.primaryColor};
    fill: ${({ theme }) => theme.primaryColor};
  }
`;
const Info = styled.div`
  width: 22%;
  text-transform: capitalize;
  margin: 0 auto;
  @media (max-width: 992px) {
    width: 45%;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 1rem;
  }
`;
const Skills = styled.div`
  max-height: 105px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  width: 30%;
  margin: 0 auto;
  @media (max-width: 992px) {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Salary = styled.div`
  width: 13%;
  margin: 0 auto;
  @media (max-width: 992px) {
    width: 100%;
  }
`;
const Action = styled.div`
  width: 20%;
  & button {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 576px) {
      width: auto;
    }
  }
  @media (max-width: 992px) {
    width: 25%;
  }
  @media (max-width: 576px) {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: space-between;
  }
`;
const SalaryItem = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  color: #061c2e;
  text-align: center;
  @media (max-width: 992px) {
    text-align: left;
  }
`;

const SalaryItemLight = styled.div`
  font-size: 12px;
  line-height: 1.33;
  color: #4b6276;
  padding-top: 8px;
  text-align: center;
  @media (max-width: 992px) {
    text-align: left;
  }
`;

const FilterJobItem = ({ job, candidateId }: JobItemProps) => {
  const [t] = useTranslation();
  const { curr } = useCurrency(job.compensation_currency);
  const [favorite, setFavorite] = useState(job.is_favorite);
  const history = useHistory();
  const handleFavorite = () => {
    if (favorite) {
      removeFromFavorites(job.id);
    } else {
      addToFavorites(job.id);
    }
    setFavorite(!favorite);
  };

  const { salary_max, bonus_scheme, bonus_yearly } = job;

  const { monthSalary, yearSalary } = getMonthYearSalary({
    salary: salary_max,
    bonusMonthly: bonus_scheme,
    bonusYearly: bonus_yearly,
  });

  return (
    <Wrapper>
      <Global>
        <Company>
          <NameAvatar
            name={job.title}
            image={
              job.company.image
                ? job.company.image
                : process.env.PUBLIC_URL + 'images/company-logo.png'
            }
          />
          <Text>
            <Title>
              <Link className="title" to={`${JOBS}/${job.id}`}>
                {job.title}
              </Link>{' '}
              <FavoriteIcon
                className={favorite ? 'active' : ''}
                onClick={() => handleFavorite()}
              />
            </Title>
            <Description>
              <TooltipPrompt title={t('CLICK_TO_SEE_PROFILE')}><Link className="title" to={`${CLIENT}/${job.company.id}`}>
                {t('AT')} {job.company.name}
              </Link></TooltipPrompt>
            </Description>
            {job.required_seniority?.name && (
              <TagButton>{job.required_seniority.name}</TagButton>
            )}
          </Text>
        </Company>
        <Info>
          <JobListInfoItem
            icon={<LocationIcon />}
            title={`${job.job_type ? job.job_type : ''}, ${job.company?.city?.name ? job.company?.city?.name : ''
              }`}
          />
          {job.hours_per_week > 0 && (
            <JobListInfoItem
              icon={<ClockIcon />}
              title={`${job.hours_per_week ? job.hours_per_week : '0'} ${t(
                'HOURS_WEEK_TITLE',
              )}`}
            />
          )}
        </Info>
        <Skills>
          {job.key_skills.map((skill) => (
            <TagButton key={skill.id}>{skill.name}</TagButton>
          ))}
          {job.preferable_skills.map((skill) => (
            <TagButton key={skill.id} light={true}>
              {skill.name}
            </TagButton>
          ))}
        </Skills>

        <Salary>
          <SalaryItem>{`${yearSalary ? t('UP_TO_TITLE') : ''} ${yearSalary
            ? ' ' + curr(addDecimalPoints(Math.round(yearSalary).toString()))
            : ' -'
            }`}</SalaryItem>
          <SalaryItemLight>
            {`${monthSalary
              ? ' ' + curr(addDecimalPoints(Math.round(monthSalary).toString()))
              : ' -'
              } ${monthSalary ? t('PER_MONTH') : ''}`}</SalaryItemLight>
        </Salary>

      </Global>
      <Action>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            ((job.is_active_recruiter_points !== false) && job.recruiter_points > 0) ?
              history.push(`${JOBS}/${job.id}/${candidateId}`) :
              history.push(`${JOBS}/${job.id}`);
          }}
        >
          {t('APPOINT_JOB')}
          <ArrowRightIcon style={{ marginRight: 0, marginLeft: '.5rem' }} />
        </Button>
      </Action>
    </Wrapper>
  );
};

export default FilterJobItem;
