import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Header from '../components/layout/Header';
import { Contact } from '../types';
import { ContactList } from '../components/messages/ContactList';
import { Room } from '../components/messages/Room';
import ReactGa from 'react-ga4';
import SearchInput from '../components/search/SearchInput';
import { useAppSelector } from '../store/hooks';
import { ADMIN_ROLE } from '../constants/roles';
import { useParams } from 'react-router-dom';

export interface UrlParams {
  contactId: string;
  interviewId: string;
}

const PageWrapper = styled.div`
  padding: 0 2.5rem 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &.full-width {
    padding-right: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const MessagerLayout = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  &.hide-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Messages = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [contactListLoaded, setContactListLoaded] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>();
  const { interviewId } = useParams<{ interviewId: string }>();
  const { t } = useTranslation();
  const handleSelectContact = useCallback(async (contact: Contact | null) => {
    setSelectedContact(contact);
  }, []);

  const { user } = useAppSelector((state) => state.user);
  const isAdmin = user?.role === ADMIN_ROLE;

  const handleSearch = (searchString: string) => {
    setSearchQuery(searchString);
  };

  const handleContactListLoaded = useCallback(() => {
    setContactListLoaded(true);
  }, []);
  useEffect(() => {
    ReactGa.pageview('/messages');
  }, []);
  return (
    <PageWrapper>
      <HeaderWrapper>
        <Header
          title={t('MESSAGES_TITLE')}
          className={
            selectedContact ? 'mobile-hide mobile-header' : 'mobile-header'
          }
        />
        {!isAdmin && <SearchInput onSearch={handleSearch} />}
      </HeaderWrapper>
      <MessagerLayout>
        <ContactList
          className={selectedContact ? 'hide-mobile' : ''}
          onSelectContact={handleSelectContact}
          onLoaded={handleContactListLoaded}
          searchQuery={searchQuery}
        />
        {contactListLoaded && (
          <Room
            searchQuery={searchQuery}
            contact={selectedContact}
            className={selectedContact ? '' : 'hide-mobile'}
            setContact={handleSelectContact}
            interviewId={interviewId}
          />
        )}
      </MessagerLayout>
    </PageWrapper>
  );
};

export default Messages;
