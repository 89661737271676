import { Col, Row, } from 'antd';
import FormItem from '../../FormItem';
import Textarea from '../../../../UI/inputs/Textarea';
import SingleEmploymentTypeField from './SingleEmploymentTypeField';
import { EmploymentType, EmploymentTypeField } from '../../../../types';
import { TFunction, useTranslation } from 'react-i18next';
import { InternalNamePath } from 'antd/lib/form/interface';
import { CurrencyHandler } from '../../../../hooks/useCurrency';

const getLabel = (t: TFunction<"translation">, currency: CurrencyHandler, field: EmploymentTypeField) => {
  const replaceCurrToken = field.is_currency ? currency.currReplaceToken : (str: unknown) => str;
  const secondaryLabel = field.secondary_label ? replaceCurrToken(t(field.secondary_label)) : '';
  const a = `${t(field.label)}${secondaryLabel ? ' (' + secondaryLabel + ')' : ''}`;
  return a;
}

export type EmploymentTypeFieldsFormSectionProps = {
  employmentType: EmploymentType;
  name: InternalNamePath;
  displayContext: 'candidate' | 'introduction' | 'job' | 'offer';
  currency: CurrencyHandler;
  hidden?: boolean;
  validationEnabled?: boolean;
};

const EmploymentTypeFieldsFormSection = ({ name, employmentType, displayContext, currency, hidden = false, validationEnabled = false }: EmploymentTypeFieldsFormSectionProps) => {
  const { t } = useTranslation();
  const visibleFields = employmentType.fields.filter((field) =>
    (displayContext === 'candidate' && field.is_applicable_to_candidates) ||
    (displayContext === 'introduction' && field.is_applicable_to_introductions) ||
    (displayContext === 'job' && field.is_applicable_to_jobs) ||
    (displayContext === 'offer' && field.is_applicable_to_offers)
  );
  return (
    <>
      {visibleFields.map((field, index) => {
        // multiline fields take up two slots so flip the even/odd order every time they occur
        const isEvenField = field.is_multiline || ((index + visibleFields.slice(0, index).filter((previousField) => previousField.is_multiline).length) % 2) === 0
        const nextField = visibleFields.slice(index + 1).find(_ => true)
        const namePrefix = [...name, 'values', employmentType.id, 'field']
        return isEvenField && (
          <FormItem key={field.id} noStyle hidden={hidden}>
            <Row gutter={16} justify="space-between">
              {field.is_multiline ? (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                >
                  <FormItem
                    label={t(field.label)}
                    name={[...namePrefix, field.id, 'text_value']}
                  >
                    <Textarea autoSize={{ minRows: 3 }} />
                  </FormItem>
                </Col>
              ) : (
                <>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    <SingleEmploymentTypeField field={field} label={getLabel(t, currency, field)} name={[...namePrefix, field.id]} isRequired={validationEnabled && !hidden} />
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                  >
                    {nextField && !nextField.is_multiline &&
                      <SingleEmploymentTypeField field={nextField} label={getLabel(t, currency, nextField)} name={[...namePrefix, nextField.id]} isRequired={validationEnabled && !hidden} />
                    }
                  </Col>
                </>
              )}
            </Row>
          </FormItem>
        )
      })}
    </>
  )
}

export default EmploymentTypeFieldsFormSection
