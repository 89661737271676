import styled from 'styled-components';

import Button from '../../../../../UI/buttons/Button';

export const TasksList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 16px;
  button {
    text-align: inherit;
  }
`;

interface ITasksListItem {
  isActive?: boolean,
  isActiveLine?: boolean,
}

export const TasksListItem = styled.li<ITasksListItem>`
  padding-bottom: 32px;
  padding-left: 4px;
  display: flex;
  align-items: top;
  position: relative;

  & .list-item-bullet {
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    padding: 1px 2px;
    z-index: 2;
    box-sizing: border-box;
    height: 24px;
    width: 24px;
    border: 2px ${props => props.isActive ? '#34ce72' : '#aebeca'} solid;
    border-radius: 50%;
    background: #fff;
  }

  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    top: 0;
    left: 15px;
    height: 100%;
    width: 2px;
    background: ${props => props.isActiveLine ? '#34ce72' : '#aebeca'};
  }

  &:last-child {
    padding-bottom: 16px;
    &:before {
      display: none;
    }
  }
`;

export const TasksListItemWrap = styled.div`
  padding-left: 12px;
`;

interface IListItemTitle {
  isBold?: boolean
}

export const ListItemTitle = styled.h6<IListItemTitle>`
  margin-bottom: 4px;
  font-family: Lato;
  font-size: 14px;
  font-weight: ${props => props.isBold ? 'bold' : 'normal'};
  line-height: 1.43;
  color: ${props => props.isBold ? '#061c2e' : 'rgb(6, 28, 46, 0.6)'};
`;

export const ListItemTitleButton = styled.button`
  margin-bottom: 4px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #061c2e;
  cursor: pointer;
  border: 0;
  outline: 0;
  background: transparent;

  &:hover,
  &:focus {
    opacity: 0.75;
  }
`;

export const ListItemText = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  color: #627d95;
  margin: 0;
  white-space: pre-line;
`;

export const ButtonWrap = styled.div`
  display: flex;
  padding-top: 12px;

  & button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DangerButton = styled(Button)`
  &.ant-btn-dangerous {
    color: #f58316 !important;
    border-color: #f58316 !important;
    &:focus,
    &:hover {
      color: #f58316 !important;
      border-color: #f58316 !important;
    }
  }
`;

export const InterviewButton = styled(Button)`
  &.ant-btn-interview {
    text-align: inherit;
    color: ${({ theme }) => theme.hoverColor};
    border: 1px solid ${({ theme }) => theme.hoverColor};
    &:focus,
    &:hover {
      color: #ffffff;
      background: ${({ theme }) => theme.hoverColor};
      border: 1px solid ${({ theme }) => theme.hoverColor};
      svg {
        fill: #ffffff;
      }
    }
    
  }
`;
