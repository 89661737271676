import { EmploymentTypeValue } from '../../../../types';

export const mapEmploymentTypeFormToHtmlForm = (formData: any) => ({
  [`type.id`]: formData.type.id,
  ...Object.entries<object>(formData.values[formData.type.id].field).reduce((acc, [key, value], idx) => ({
    ...acc,
    [`values[${idx}]field.id`]: key,
    [`values[${idx}]text_value`]: (value as EmploymentTypeValue).text_value || '',
    [`values[${idx}]numeric_value`]: (value as EmploymentTypeValue).numeric_value || 0,
  }), {}),
});

export const mapEmploymentTypeFormToApi = (formData: any) => ({
  type: {
    ...formData.type,
  },
  values: Object.entries<object>(formData.values[formData.type.id].field).map(([key, value]) => ({
    field: {
      id: key,
    },
    ...value,
  })),
});

export const mapEmploymentTypeApiToForm = (apiData: any) => ({
  ...apiData,
  values: {
    [apiData.type.id]: {
      field:
        (apiData.values).reduce((acc: any, entry: EmploymentTypeValue) => {
          return {
            ...acc,
            [entry.field.id]: {
              numeric_value: entry.numeric_value,
              text_value: entry.text_value
            }
          }
        }, {}),
    }
  },
});
