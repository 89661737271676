import React from 'react';
import JobStepsPage from '../../JobSteps';

const ChromiumJobStepsPage = () => {
  return (
    <JobStepsPage title={'Edit job spec'} />
  )
};

export default ChromiumJobStepsPage;
