import styled from 'styled-components';
import { SortOrder, SortingType } from '../recruiter/RecruiterManagementBoard';
import { ReactComponent as UpArrow } from '../../icons/sort_asc.svg';
import { ReactComponent as DownArrow } from '../../icons/sort_desc.svg';
import { Recruiter, Specialization } from '../../types';

import RecruiterManagementTableRow from './RecruiterManagementTableRow';
import { useTranslation } from 'react-i18next';
import useHirePortTheme from '../../hooks/useHirePortTheme';

const RecruiterManagementTableDiv = styled.div`
  padding: 0 2rem;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const HoverDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const StyledUpArrow = styled(UpArrow)`
  & path {
    stroke: ${(props) => props.color};
  }
`;
const StyledDownArrow = styled(DownArrow)`
  & path {
    stroke: ${(props) => props.color};
  }
`;

const TableHeaderName = ({
  name,
  intendedSortType,
  currentSortingOrder,
  currentSortingType,
}: {
  name: string;
  intendedSortType: SortingType;
  currentSortingType: SortingType;
  currentSortingOrder: SortOrder;
}) => {
  const theme = useHirePortTheme();
  let upArrowColor = '#627d95';
  let downArrowColor = '#627d95';

  if (intendedSortType === currentSortingType) {
    if (currentSortingOrder === 'DESC') {
      downArrowColor = theme.primaryColor;
      upArrowColor = '#627d95';
    }
    if (currentSortingOrder === 'ASC') {
      upArrowColor = theme.primaryColor;
      downArrowColor = '#627d95';
    }
  }
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <span
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <StyledDownArrow
          color={downArrowColor}
          style={{ marginBottom: '-9px' }}
        />
        <StyledUpArrow color={upArrowColor} />
      </span>
      {name}
    </div>
  );
};

const RecruiterManagementTable = ({
  recruiters,
  domains,
  handleSortingByHeader,
  handleDeleteRecruiterCompanyAgreement,
  currentSortingOrder,
  currentSortingType,
}: {
  recruiters: Recruiter[];
  domains: Specialization[];
  currentSortingOrder: SortOrder;
  currentSortingType: SortingType;
  handleDeleteRecruiterCompanyAgreement: (recruiter: Recruiter) => void;
  handleSortingByHeader: (sortType: SortingType) => void;
}) => {
  const [t] = useTranslation();

  return (
    <RecruiterManagementTableDiv>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          borderBottom: '1px solid #DAE5EC',
          padding: '6px 0',
          color: '#627D95',
          fontWeight: 600,
        }}
      >
        <HoverDiv
          style={{ flex: '20%' }}
          onClick={() => {
            handleSortingByHeader('NAME');
          }}
        >
          <TableHeaderName
            currentSortingOrder={currentSortingOrder}
            currentSortingType={currentSortingType}
            intendedSortType={'NAME'}
            name={t('RECRUITER_MANAGEMENT_NAME')}
          />
        </HoverDiv>
        <HoverDiv
          style={{ flex: '10%' }}
          onClick={() => {
            handleSortingByHeader('TYPE');
          }}
        >
          <TableHeaderName
            currentSortingOrder={currentSortingOrder}
            currentSortingType={currentSortingType}
            intendedSortType={'TYPE'}
            name={t('RECRUITER_MANAGEMENT_TYPE')}
          />
        </HoverDiv>
        <div style={{ flex: '35%' }}>{t('RECRUITER_MANAGEMENT_DOMAINS')}</div>
        <div style={{ flex: '15%' }}>
          {t('RECRUITER_MANAGEMENT_FEE_STRUCTURE')}
        </div>
        <HoverDiv
          style={{
            flex: '5%',
          }}
          onClick={() => {
            handleSortingByHeader('RATING');
          }}
        >
          <TableHeaderName
            currentSortingOrder={currentSortingOrder}
            currentSortingType={currentSortingType}
            intendedSortType={'RATING'}
            name={t('RECRUITER_MANAGEMENT_RATING')}
          />
        </HoverDiv>
        <div
          style={{
            flex: '5%',
            textAlign: 'center',
          }}
        >
          {t('RECRUITER_MANAGEMENT_ACTIONS')}
        </div>
      </div>
      <div
        style={{
          overflow: 'auto',
        }}
      >
        {recruiters.map((rec) => (
          <RecruiterManagementTableRow
            recruiter={rec}
            domains={domains}
            handleDeleteRecruiterCompanyAgreement={
              handleDeleteRecruiterCompanyAgreement
            }
          />
        ))}
      </div>
    </RecruiterManagementTableDiv>
  );
};

export default RecruiterManagementTable;
