import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LiveIcon } from '../../../../icons/live.svg';
import TagButton from '../../../../UI/buttons/TagButton';
import { Job } from '../../../../types';
import {
  ADMIN_ROLE,
  CLIENT_ROLE,
  RECRUITER_ROLE,
} from '../../../../constants/roles';
import { JOBS } from '../../../../constants/routes';
import {
  addToFavorites,
  publishJob,
  removeFromFavorites,
} from '../../../../api/jobs';
import {
  JobDescriptionWrapper,
  JobCompanyWrapper,
  CompanyLogo,
  JobTitle,
  JobAbout,
  TagWrapper,
  BadgeNew,
  BadgeUpdated,
  BadgeCustom,
  BadgeLive,
  BadgeWarning,
} from './styles';
import Emoji from '../../../helper/Emoji';
import { Switch, message } from 'antd';
import UnpublishJobModal from '../../UnpublishJobModal';
import useHirePortTheme from '../../../../hooks/useHirePortTheme';

type JobDescriptionProps = {
  job: Job;
  online: boolean;
  employmentTypeName?: string;
  role?: string;
  setOnline: (online: boolean) => void;
  addToFavoriteList?: (id: string) => void;
  language: any[];
};

const JobDescription = ({
  job,
  online,
  employmentTypeName,
  role,
  setOnline,
  addToFavoriteList,
  language,
}: JobDescriptionProps) => {
  const theme = useHirePortTheme();
  const [t] = useTranslation();
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);

  const isAdmin = role === ADMIN_ROLE;
  const isRecruiter = role === RECRUITER_ROLE;
  const isClient = role === CLIENT_ROLE;

  const [favorite, setFavorite] = useState(job.is_favorite);

  const handleFavorite = () => {
    if (favorite) {
      removeFromFavorites(job.id);
    } else {
      addToFavorites(job.id).then(() => {
        if (addToFavoriteList) {
          addToFavoriteList(job.id);
        }
      });
    }
    setFavorite(!favorite);
  };

  const { is_new, is_updated, urgent, tags } = job;

  return (
    <JobDescriptionWrapper
      className={isRecruiter || isAdmin ? 'company-img' : ''}
    >
      {(isRecruiter || isAdmin) && (
        <CompanyLogo>
          <img
            src={
              job.company.image
                ? job.company.image
                : process.env.PUBLIC_URL + '/images/company-logo.png'
            }
            alt=""
          />
        </CompanyLogo>
      )}
      <JobCompanyWrapper className={!online ? 'draft' : ''}>
        <JobTitle
          className={
            isRecruiter && (!online || job.is_active_recruiter_points === false)
              ? 'grey'
              : ''
          }
        >
          {isRecruiter && !job.open_spots ? (
            <span className="job-title">{job.title} </span>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Link to={JOBS + '/' + job.id}>
                <span
                  className={
                    job.status === 'unpublished' || job.status === 'on_hold'
                      ? 'unpublished'
                      : ''
                  }
                >
                  {job.title}
                </span>
              </Link>
              {isClient && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Switch
                    size="small"
                    checked={online}
                    style={{
                      backgroundColor: online ? theme.primaryColor : '#627d95',
                      opacity: online ? 1 : 0.5,
                      padding: '0 2px',
                    }}
                    checkedChildren={t('JOB_STATUS_ONLINE')}
                    unCheckedChildren={t('JOB_STATUS_OFFLINE')}
                    onClick={() => { }}
                    onChange={async () => {
                      try {
                        if (online) {
                          setIsUnpublishModalOpen(true);
                        } else {
                          await publishJob(job.id);
                          setOnline(true);

                          message.success(
                            t('JOB_ONLINE', { jobTitle: job.title ?? '' }),
                          );
                        }
                      } catch (error) {
                        message.error(t('UPDATE_ERROR'));
                      }
                    }}
                  />
                  <UnpublishJobModal
                    onAction={() => {
                      setOnline(false);
                      message.success(
                        t('JOB_OFFLINE', { jobTitle: job.title ?? '' }),
                      );
                    }}
                    onClose={() => setIsUnpublishModalOpen(false)}
                    job={job}
                    show={isUnpublishModalOpen}
                    showOnHold={false}
                  />
                </div>
              )}
            </div>
          )}
          {role === RECRUITER_ROLE && (
            <>
              {!!tags?.length &&
                tags?.map((tag, index) => (
                  <BadgeCustom key={`${tag}${index}`}>{tag}</BadgeCustom>
                ))}
              {is_new && <BadgeNew>{t('BADGE_NEW')}</BadgeNew>}
              {is_updated && !is_new && (
                <BadgeUpdated>{t('BADGE_UPDATED')}</BadgeUpdated>
              )}
              {!is_new && !is_updated && (
                <BadgeLive>
                  <LiveIcon />
                  <span>{t('BADGE_LIVE')}</span>
                </BadgeLive>
              )}
              {urgent && <BadgeWarning>{t('BADGE_URGENT')}</BadgeWarning>}
              <Emoji
                symbol={favorite ? '🔔' : '🔕'}
                customClass={'job-list'}
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleFavorite();
                }}
              />
            </>
          )}
        </JobTitle>
        {isClient && job.published_by ? (
          <span className="published-by">
            {`${job.published_by_first_name ?? ''} ${job.published_by_last_name ?? ''}`}
          </span>
        ) : (<> </>)}
        <JobAbout>{job.short_description}</JobAbout>
        <TagWrapper>
          {employmentTypeName && (
            <TagButton className="yellow">{employmentTypeName}</TagButton>
          )}
          {job.required_seniority?.name && (
            <TagButton>{job.required_seniority.name}</TagButton>
          )}
          {language.map((lang, index) => (
            <TagButton key={`${lang}${index}`} className="green">
              {lang}
            </TagButton>
          ))}
        </TagWrapper>
      </JobCompanyWrapper>
    </JobDescriptionWrapper>
  );
};

export default JobDescription;
