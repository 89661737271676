import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Upload } from 'antd';
import CompanyInfo from '../form/shared/CompanyInfo';
import { getRegions } from '../../api/regions';
import { getIndustries } from '../../api/industries';
import { createClientAddressList, editClientCompany } from '../../api/client';
import Button from '../../UI/buttons/Button';
import FormItem from '../form/FormItem';
import styled from 'styled-components';
import { useAppDispatch } from '../../store/hooks';
import { getUserByToken } from '../../store/userSlice';
import { useHistory } from 'react-router-dom';
import { JOBS } from '../../constants/routes';
import FormTitle from '../auth/FormTitle';
import { getToken } from '../../utils/localStorage';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { RcFile } from 'antd/lib/upload';
import { ReactComponent as JobIcon } from '../../icons/jobs.svg';

const StyledButton = styled(Button)`
  margin-top: 2.5rem;
`;
const CompanyLogo = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background: #627d95 no-repeat center;
  &.bg-white {
    background: #ffffff no-repeat center;
  }
  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
  }
`;
const StyledIcon = styled(JobIcon)`
  color: #aebeca;
  width: 3rem;
  height: 3rem;
`;
const ImageUploadButton = styled.div`
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.lozengeColor};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor};
  position: absolute;
  bottom: 2px;
  right: 0;
  z-index: 9;
`;
const StyledUpload = styled(Upload)`
  & .ant-upload {
    display: flex;
    align-items: center;
  }
`;
const FormatHint = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: #627d95;
  margin-left: 1rem;
`;
const ClientOnboard = ({ user }: any) => {
  const { company } = user;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [logo, setLogo] = useState<string>(company.image ? company.image : '');
  const [fileError, setFileError] = useState<string>('');
  const [officeLocations, setOfficeLocations] = useState(
    company.office_locations,
  );
  const [address, setAddress] = useState(company.address);
  const { t } = useTranslation();
  useEffect(() => {
    getRegions().then((res) => setRegions(res.data));
    getIndustries().then((res) => setIndustries(res.data));
    form.setFieldsValue({
      company_name: company.name,
      city: company.city?.id,
      industry: company.industry?.id,
      region: company.region?.id,
      notable_clients: company.notable_clients,
      number_of_employees: company.number_of_employees,
      average_age: company.average_age,
      male_percentage: company.male_percentage,
      dress_code: company.dress_code,
      description: company.description,
      difference_description: company.difference_description,
      image: company.image,
    });
  }, [company, form]);

  const onFinish = (values: any) => {
    if (validateCustomList()) {
      setLoading(true);
      if (logo.startsWith('http')) {
        delete values.image;
      } else if (!logo) {
        delete values.image;
      }
      createClientAddressList(company.id, officeLocations).then((res) => {
        editClientCompany({ ...values, address }, company.id)
          .then((res) => {
            const token = getToken('access_token');
            const jobId = getToken('job');
            dispatch(getUserByToken(token));
            setLoading(false);
            message.success(t('INFORMATION_SAVE_SUCCESS'), 5);
            if (jobId) {
              history.push(JOBS + `/edit/${jobId}/info`);
            } else {
              history.push(JOBS + '/edit/new/simple');
            }

          })
          .catch(() => setLoading(false));
      });
    }
  };

  const validateCustomList = () => {
    let validated = false;
    const hasError = officeLocations.map((el: any) => {
      if (!el.address) {
        setValidating(true);
      }
      return !!el.address;
    });
    hasError.push(!!address);
    if (hasError.includes(false)) {
      validated = false;
    } else {
      validated = true;
    }
    setValidating(!validated);
    return validated;
  };
  const handleUploadFile = useCallback(
    (file: RcFile): boolean => {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        setFileError('');
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result;
            if (base64) {
              setLogo(base64?.toString());
              form?.setFieldsValue({
                ...form.getFieldsValue(),
                image: base64.toString(),
              });
            }
          };
          reader.onerror = (error) => reject(error);
        });
      } else {
        setFileError(t('FILE_FORMAT_VALIDATION'));
      }
      return false;
    },
    [form, t],
  );
  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="client-onboard"
      onFinish={onFinish}
      form={form}
      onValuesChange={(e) => {
        if (e.male_percentage && e.male_percentage > 100) {
          form.setFieldsValue({ male_percentage: 100 });
        }
      }}
    >
      <FormTitle text={t('COMPANY_INFO_TITLE')} className="mb-4" />
      <FormItem label={t('COMPANY_LOGO')} name="image">
        <StyledUpload
          accept="image/png, image/jpeg, image/jpg"
          beforeUpload={handleUploadFile}
          showUploadList={false}
        >
          <CompanyLogo className={logo ? 'bg-white' : ''}>
            {logo ? <img src={logo} alt="" /> : <StyledIcon />}
            <ImageUploadButton>
              <EditIcon />
            </ImageUploadButton>
          </CompanyLogo>
          <FormatHint>PNG or JPG</FormatHint>
        </StyledUpload>
      </FormItem>
      {fileError && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{fileError}</div>
        </div>
      )}
      <CompanyInfo
        form={form}
        regions={regions}
        industries={industries}
        locations={officeLocations}
        setLocations={setOfficeLocations}
        setAddress={setAddress}
        address={address}
        companyId={company.id}
        disabled={true}
        validating={validating}
      />
      <FormItem className="no-margin">
        <StyledButton
          type="primary"
          htmlType="submit"
          onClick={(e) => {
            e.preventDefault();
            validateCustomList();
            form.submit();
          }}
          loading={loading}
        >
          {t('NEXT_TITLE')}
        </StyledButton>
      </FormItem>
    </Form>
  );
};

export default ClientOnboard;
